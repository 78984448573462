import RichTextToolbar from "./RichTextToolbar";
import { Box, Separator } from "@radix-ui/themes";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import styled from "styled-components";

const extensions = [StarterKit, Underline, TextStyle];

const RichTextContainer = styled(Box)<{}>`
  border: 1px solid var(--border-primary);
  border-radius: 10px;
  & div[contenteditable] {
    min-height: 80px;
    max-height: 300px;
    overflow-y: auto;
  }
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  & p {
    font-size: 16px;
  }
`;

type RichTextAreaProps = {
  value: string;
  fontSize: number;
  onChange: (value: string) => void;
  onFontSizeChange: (value: number) => void;
} & Omit<React.ComponentProps<typeof RichTextContainer>, "onChange">;

const RichTextArea: React.FC<RichTextAreaProps> = ({
  value,
  fontSize,
  onChange,
  onFontSizeChange,
}) => {
  const styles = `
    font-family: inherit;
    font-weight: inherit;
    text-align: inherit;
    word-spacing: inherit;
  `;

  const applyStylesToElements = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const applyStyles = (node: Node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        element.setAttribute("style", styles);
      }
      node.childNodes.forEach(applyStyles);
    };

    doc.body.childNodes.forEach(applyStyles);
    return doc.body.innerHTML;
  };

  return (
    <RichTextContainer p="3">
      <EditorProvider
        slotBefore={
          <>
            <RichTextToolbar
              fontSize={fontSize}
              onFontSizeChange={onFontSizeChange}
            />
            <Separator
              size={"1"}
              style={{
                width: "auto",
                margin: "var(--space-2) calc(-1 * var(--space-3))",
              }}
              orientation="horizontal"
            />
          </>
        }
        extensions={extensions}
        content={value}
        onUpdate={(update) => {
          const htmlContent = applyStylesToElements(
            update.editor
              .getHTML()
              .replace(/^<p>/, `<span style="${styles}">`)
              .replace(/<\/p>$/, "</span>")
          );
          onChange(htmlContent);
        }}
      />
    </RichTextContainer>
  );
};

export default RichTextArea;
