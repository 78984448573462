import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import CampaignProductsPreview from "../common/CampaignProductsPreview";
import { ItemSetType } from "@openapi";
import { Flex, Separator, Text } from "@radix-ui/themes";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { ArrowRightIcon, PackageOpenIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

export const campaignRecommendationTypeContent: Partial<{
  [key in ItemSetType]: {
    icon: React.ComponentType<{
      color?: string | undefined;
      size?: string | number | undefined;
      style?: CSSProperties;
    }>;
    title: string;
    background: string;
  };
}> = {
  [ItemSetType.new_products]: {
    icon: PackageOpenIcon,
    title: "New Products",
    background: "#EBD6BD",
  },
  [ItemSetType.best_sellers]: {
    icon: PackageOpenIcon,
    title: "Best Sellers",
    background: "#EBD6BD",
  },
  [ItemSetType.discounted_products]: {
    icon: PackageOpenIcon,
    title: "Discounted Products",
    background: "#EBD6BD",
  },
  [ItemSetType.new_collection]: {
    icon: PackageOpenIcon,
    title: "New Collection",
    background: "#EBD6BD",
  },
  [ItemSetType.new_hero_product]: {
    icon: PackageOpenIcon,
    title: "New Hero Product",
    background: "#EBD6BD",
  },
  [ItemSetType.purchased_together]: {
    icon: PackageOpenIcon,
    title: "Purchased Together",
    background: "#EBD6BD",
  },
  [ItemSetType.slower_moving_goods]: {
    icon: PackageOpenIcon,
    title: "Slower Moving Goods",
    background: "#EBD6BD",
  },
  [ItemSetType.slowest_moving_goods]: {
    icon: PackageOpenIcon,
    title: "Slowest Moving Goods",
    background: "#EBD6BD",
  },
};

const Card = styled(Flex)`
  padding: 16px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const RecommenderCard = ({
  recommendation,
  buttonVariant,
}: {
  recommendation: CampaignRecommendation;
  buttonVariant?: React.ComponentProps<typeof AppButton>["variant"];
}) => {
  const productsData = recommendation.products ?? [];

  const navigate = useNavigate();

  const IconComponent =
    campaignRecommendationTypeContent[recommendation.item_set_type]?.icon;

  return (
    <Card direction="column" position="relative" gap="5">
      <CampaignProductsPreview productsData={productsData} />

      <Flex direction="column" gap="4">
        <Text size="7" weight="medium">
          {recommendation.creative_recommendations?.[0]?.title}
        </Text>
        <Flex align="center" gap="10px">
          {IconComponent && (
            <IconComponent
              size={24}
              style={{ color: "var(--primary-vibrant-orange)" }}
            />
          )}
          <Text size="3" weight="medium">
            {
              campaignRecommendationTypeContent[recommendation.item_set_type]
                ?.title
            }
          </Text>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            {productsData.length > 1
              ? `${productsData.length} Products`
              : `${productsData.length} Product`}
          </Text>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            $$
          </Text>
        </Flex>
      </Flex>
      <AppButton
        onClick={() => navigate(`/campaigns/wizard/${recommendation.id}`)}
        mt="auto"
        style={{ width: "max-content" }}
        variant={buttonVariant}
      >
        Open Campaign
        <ArrowRightIcon />
      </AppButton>
    </Card>
  );
};

export default RecommenderCard;
