import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type BrandResponse =
  operations["brand_api_get_brand"]["responses"][200]["content"]["application/json"];

const useBrandQuery = (brandID: string | null) => {
  const {
    data: brandData,
    isLoading,
    isSuccess,
  } = useQuery<BrandResponse>({
    queryKey: ["brand", brandID],
    queryFn: async (): Promise<BrandResponse> => {
      const { data } = await axios.get(`/api/v1/brand/${brandID}`);
      return data;
    },
    enabled: !!brandID,
  });

  return {
    brandData,
    isLoading,
    isSuccess,
  };
};

export default useBrandQuery;
