import styled from "styled-components";
import { ReactComponent as BackgroundGradientVariant1 } from "~/assets/orangeGradientVariant1.svg";
import { ReactComponent as BackgroundGradientVariant2 } from "~/assets/orangeGradientVariant2.svg";
import { ReactComponent as BackgroundGradientVariant3 } from "~/assets/orangeGradientVariant3.svg";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

interface BackgroundGradientProps {
  variant?: "1" | "2" | "3";
}

/**
 * @prop {string} variant - The background gradient variant to be applied. Defaults to `1`.
 *
 * @example
 * // Render a background gradient variant 2
 * <BackgroundGradient variant="2" />
 */
const BackgroundGradient = ({ variant }: BackgroundGradientProps) => {
  switch (variant) {
    case "1":
      return (
        <Wrapper>
          <BackgroundGradientVariant1 />
        </Wrapper>
      );
    case "2":
      return (
        <Wrapper>
          <BackgroundGradientVariant2 />
        </Wrapper>
      );
    case "3":
      return (
        <Wrapper>
          <BackgroundGradientVariant3 />
        </Wrapper>
      );
    default:
      return (
        <Wrapper>
          <BackgroundGradientVariant1 />
        </Wrapper>
      );
  }
};

export default BackgroundGradient;
