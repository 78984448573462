import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type BrandUsersResponse =
  operations["user_api_get_brand_users"]["responses"][200]["content"]["application/json"];
export type BrandUsersRequestParams =
  operations["user_api_get_brand_users"]["parameters"]["query"];

const useBrandUsersQuery = (brandID: string | null) => {
  return useQuery<BrandUsersResponse, BrandUsersRequestParams>({
    queryKey: ["get_brand_users", brandID],
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/user/list`, {
        params: { brand_id: brandID } as BrandUsersRequestParams,
      });
      return data;
    },
    enabled: !!brandID,
  });
};

export default useBrandUsersQuery;
