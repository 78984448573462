import { Section } from "../../../routes/root";
import { capitalizeFirstLetter } from "../../editor/utils";
import { Flex, Text } from "@radix-ui/themes";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";

const LinkRoot = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: inherit;
  text-decoration: none;
  margin-bottom: 40px;
  cursor: pointer;
`;

const Icon = styled(Flex)<{ $isSelected: boolean }>`
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$isSelected ? "var(--primary-deep-purple)" : "var(--primary-white)"};
  color: ${(props) =>
    props.$isSelected ? "var(--primary-white)" : "var(--primary-deep-purple)"};
  padding: 16px;
  stroke-width: 1.5;
  border-radius: 50%;
  border: 1px solid var(--border-primary);
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "var(--primary-deep-purple)" : "rgba(0, 0, 0, 0.1)"};
  }
`;

export function SidePanelNavigator({ section }: { section: Section }) {
  const resolved = useResolvedPath(section.route);
  const isSelected = useMatch({
    path: resolved.pathname,
    end: section.route === "/",
  });
  return (
    <LinkRoot to={section.route}>
      <Icon $isSelected={!!isSelected}>
        <section.icon strokeWidth={1.3} />
      </Icon>

      <Text size="1" style={{ fontWeight: 600 }}>
        {section.title ?? capitalizeFirstLetter(section.type)}
      </Text>
    </LinkRoot>
  );
}
