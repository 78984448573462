import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";

export type InternCreateBrandAdminProfileResponse =
  operations["intern_api_create_brand_admin_profile"]["responses"][200];

const useInternCreateBrandAdminProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      brandId: string
    ): Promise<InternCreateBrandAdminProfileResponse> => {
      const { data } = await axios.post(
        `/api/v1/intern/brands/brand_admin_profile?brand_id=${brandId}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"] });

      toast.success("Brand admin profile created");
    },
  });
};

export default useInternCreateBrandAdminProfileMutation;
