import { useDynamicHeader } from "../../contexts/DynamicHeaderContext";
import { INTERN_CAMPAIGNS_ROUTE } from "../constants";
import { TabNav } from "@radix-ui/themes";
import { useEffect } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import { styled } from "styled-components";

const DynamicHeaderTabNavRoot = styled(TabNav.Root)`
  box-shadow: none;
`;

const DynamicHeaderTabNavLink = styled(TabNav.Link)`
  font-size: 18px;
  margin: 10px;
`;

const templatePaths = [INTERN_CAMPAIGNS_ROUTE];

const InternCampaignsRoute = () => {
  const { setDynamicHeader } = useDynamicHeader();

  const location = useLocation();

  useEffect(() => {
    setDynamicHeader(
      <DynamicHeaderTabNavRoot>
        <DynamicHeaderTabNavLink
          asChild
          active={templatePaths.some((substring) =>
            location.pathname.includes(substring)
          )}
        >
          <Link to={INTERN_CAMPAIGNS_ROUTE}>Campaigns</Link>
        </DynamicHeaderTabNavLink>
      </DynamicHeaderTabNavRoot>
    );
    return () => {
      setDynamicHeader(<></>);
    };
  }, [setDynamicHeader, location]);

  return (
    <div style={{ height: "100%" }}>
      <Outlet />
    </div>
  );
};

export default InternCampaignsRoute;
