import { ColorType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import SidePanelComponents from "~/components/core/editor/sidepane";
import ColorBox from "~/components/style-library/color-palette/ColorBox";
import { AdMediaShapeElementSchema, ColorTypeStrings } from "~/types/ads";

interface AdMediaAccentColorsComponentProps {
  elements: AdMediaShapeElementSchema[];
  onElementUpdate: (element: AdMediaShapeElementSchema) => void;
}

const AdMediaAccentColorsComponent: React.FC<
  AdMediaAccentColorsComponentProps
> = ({ elements, onElementUpdate }) => {
  if (!elements.length) {
    return null;
  }

  const colorTypes = Array.from(
    new Set(
      elements
        .map((element) => [element.fill_color_type, element.stroke_color_type])
        .flat()
        .filter((color) => color !== undefined && color !== null)
    )
  );

  const handleColorUpdate = (colorType: ColorType, color: string) => {
    elements.forEach((element) => {
      let updatedElement = { ...element };
      if (updatedElement.fill_color_type === colorType) {
        updatedElement.fill_color = color;
      }
      if (updatedElement.stroke_color_type === colorType) {
        updatedElement.stroke_color = color;
      }
      onElementUpdate(updatedElement);
    });
  };

  return (
    <SidePanelComponents.EditorCard checkable={false} title="Colors">
      <Flex wrap="wrap" direction="row" gap="4">
        {colorTypes.map((colorType) => {
          var isFound = false;
          return elements.map((element) => {
            if (isFound) {
              return <></>;
            }
            if (
              (element.fill_color_type !== null &&
                element.fill_color_type === colorType) ||
              (element.stroke_color_type !== null &&
                element.stroke_color_type === colorType)
            ) {
              isFound = true;
              return (
                <ColorBox
                  key={`${element.id}-${colorType}`}
                  onChange={(color) => handleColorUpdate(colorType, color)}
                  color={
                    element.fill_color ?? element.stroke_color ?? undefined
                  }
                  label={ColorTypeStrings[colorType]}
                />
              );
            }
            return null;
          });
        })}
      </Flex>
    </SidePanelComponents.EditorCard>
  );
};

export default AdMediaAccentColorsComponent;
