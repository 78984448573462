import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternBrandsResponse =
  operations["intern_api_get_brands"]["responses"][200]["content"]["application/json"];

const useInternBrandsQuery = () => {
  const {
    data: brandsData,
    isLoading,
    isSuccess,
  } = useQuery<InternBrandsResponse>({
    queryKey: ["internBrands"],
    queryFn: async (): Promise<InternBrandsResponse> => {
      const { data } = await axios.get("/api/v1/intern/brands");
      return data;
    },
  });

  return {
    brandsData,
    isLoading,
    isSuccess,
  };
};

export default useInternBrandsQuery;
