import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import { campaignRecommendationTypeContent } from "./RecommenderCard";
import MarkPopup from "@components/core/MarkPopup";
import { Flex, Separator, Text, Skeleton } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import ThreeSlotsPreview from "~/components/core/layout/ThreeSlotsPreview";

const Hero = styled(ThreeSlotsPreview)`
  width: 100%;
  flex: 1;
  height: 100%;
  min-width: 350px;
  max-width: calc(50% - 16px);
  background-color: var(--primary-white);
  padding: 16px;
  border-radius: 30px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 3.86px 7.71px 0px #0000000a;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const MarkPopupStyled = styled(MarkPopup)`
  position: absolute;
  top: -14px;
  left: -55px;
  z-index: 1;
  width: 100%;
  max-width: 480px;
`;

const RecommenderHero = ({
  recommendation,
  isSkeleton,
}: {
  recommendation?: CampaignRecommendation;
  isSkeleton?: boolean;
}) => {
  const navigate = useNavigate();

  if (isSkeleton) {
    return (
      <Flex gap="6" height="393px">
        <Hero>
          <Skeleton width="100%" height="100%" />
          <Skeleton width="100%" height="100%" />
          <Skeleton width="100%" height="100%" />
        </Hero>
        <Flex
          direction="column"
          height="100%"
          justify="end"
          style={{ position: "relative" }}
        >
          <Skeleton width="351px" height="40px" mb="32px" />
          <Flex
            mb="48px"
            gap="16px"
            align="center"
            style={{ color: "#3b3b3b" }}
          >
            <Skeleton width="300px" height="20px" />
          </Flex>
          <AppButton disabled style={{ width: "150px" }}>
            <></>
          </AppButton>
        </Flex>
      </Flex>
    );
  }

  if (!recommendation) return null;

  const IconComponent =
    campaignRecommendationTypeContent[recommendation.item_set_type]?.icon;

  return (
    <Flex gap="6" height="393px">
      <Hero gap="16px">
        {recommendation?.products?.slice(0, 3)?.map((prod) => (
          <CarouselImage
            src={prod.image_url ?? ""}
            alt={prod.title}
            key={prod.product_id}
          />
        ))}
      </Hero>
      <Flex
        direction="column"
        height="100%"
        justify="end"
        style={{ position: "relative", flex: "0.5" }}
      >
        <MarkPopupStyled sayHello>
          <Text>Here are your latest campaign recommendations.</Text>
        </MarkPopupStyled>
        <Text mb="32px" size="8" weight="bold">
          {recommendation?.creative_recommendations?.[0]?.title}
        </Text>
        <Flex mb="48px" gap="16px" align="center" style={{ color: "#3b3b3b" }}>
          <Flex align="center" gap="10px">
            {IconComponent && (
              <IconComponent
                size={24}
                style={{ color: "var(--primary-vibrant-orange)" }}
              />
            )}
            <DraperText clamp={2} size="3" weight="medium">
              {
                campaignRecommendationTypeContent[recommendation.item_set_type]
                  ?.title
              }
            </DraperText>
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            {(recommendation.products?.length || 0) > 1
              ? `${recommendation.products?.length || 0} Products`
              : `${recommendation.products?.length || 0} Product`}
          </Text>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            $$
          </Text>
        </Flex>
        <AppButton
          onClick={() => navigate(`/campaigns/wizard/${recommendation.id}`)}
          style={{ width: "max-content" }}
        >
          Open Campaign
          <ArrowRightIcon />
        </AppButton>
      </Flex>
    </Flex>
  );
};

export default RecommenderHero;
