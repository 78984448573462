import { Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

type PillSize = "small" | "medium" | "large" | "full";

interface PillProps {
  backgroundColor?: string;
  size?: PillSize;
  children: React.ReactNode;
}

const PillContainer = styled.div<{ backgroundColor: string; size: PillSize }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => {
    switch (props.size) {
      case "small":
        return "4px 8px";
      case "medium":
        return "6px 12px";
      case "large":
        return "8px 16px";
      case "full":
        return "8px 16px";
      default:
        return "6px 12px";
    }
  }};
  border-radius: ${(props) => (props.size === "full" ? "9999px" : "12px")};
`;

const DraperPill: React.FC<PillProps> = ({
  backgroundColor = "#DFF4F5",
  size = "medium",
  children,
}) => {
  return (
    <PillContainer backgroundColor={backgroundColor} size={size}>
      <Text
        size={size === "small" ? "1" : size === "large" ? "3" : "2"}
        weight="medium"
        style={{ color: "#4C4747" }}
      >
        {children}
      </Text>
    </PillContainer>
  );
};

export default DraperPill;
