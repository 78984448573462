import Tiptap from "../editor/TipTap";
import styles from "./Preview.module.css";
import { StrictModeDroppable } from "@core/StrictModeDroppable";
import Modal from "@core/dialog/Modal";
import LayoutComponents from "@core/editor/layout";
import { EmailSectionType } from "@openapi";
import { Flex, IconButton, Spinner, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import _ from "lodash";
import { Blocks, GripVertical } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { PlusIcon, Trash2Icon } from "~/assets/icons";
import {
  EmailSection,
  useEmailState,
  useEmailDispatch,
  useMoveSections,
  useDeleteSection,
  EmailEditorViews,
  useAddEmptySection,
  useReplaceEditorView,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  getTipTapCustomFonts,
  useEditableTipTapElement,
} from "~/utils/emails/tipTapUtils";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";
import { convertSectionToEnum } from "~/utils/emails/useSectionTypeCheck";

const MobilePreviewContainer = styled.div`
  width: 375px;
  height: 667px;
  margin: 20px auto;
  border: 10px solid #333;
  border-radius: 30px;
  overflow: hidden;
`;

const AddSectionButton = ({
  direction,
  onClick,
}: {
  direction: "top" | "bottom";
  onClick: () => void;
}) => {
  const replaceView = useReplaceEditorView();
  const topStyle = {
    top: "-16px",
    left: "50%",
    transform: "translateX(-50%)",
  };
  const bottomStyle = {
    bottom: "-16px",
    left: "50%",
    transform: "translateX(-50%)",
  };
  const style = direction === "top" ? topStyle : bottomStyle;

  return (
    <IconButton
      style={{
        position: "absolute",
        zIndex: 100,
        backgroundColor: "#EDEBF0",
        ...style,
      }}
      radius="full"
      variant="outline"
      color="gray"
      onClick={(event) => {
        event.stopPropagation();
        replaceView(EmailEditorViews.AddBlock);
        onClick();
      }}
    >
      <PlusIcon size={20} />
    </IconButton>
  );
};

const DragAndTrashButton = ({
  type,
  onDelete,
}: {
  type: EmailSectionType | null;
  onDelete: () => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const name = type
    ? `${type.replace("_", " ").replace(/\b\w/g, (c) => c.toUpperCase())} Block`
    : "Block";

  return (
    <>
      <Flex
        style={{
          position: "absolute",
          zIndex: 100,
          top: "50%",
          right: "-48px",
          transform: "translate(50%, -50%)",
          padding: "10px",
          borderRadius: "12px",
          border: "1px solid #ddd7d7",
          backgroundColor: "white",
        }}
        direction="column"
        gap={"2"}
      >
        <IconButton radius="large" variant="ghost" color="gray">
          <GripVertical color="#595D62" size={24} style={{ cursor: "grab" }} />
        </IconButton>
        <IconButton
          radius="large"
          variant="ghost"
          color="red"
          onClick={() => setIsDialogOpen(true)}
        >
          <Trash2Icon size={24} color="#D34840" style={{ cursor: "pointer" }} />
        </IconButton>
      </Flex>
      <Modal
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        title={`Delete ${name}`}
        submitBtnText={`Delete Block`}
        cancelBtnText="Cancel"
        onSubmit={onDelete}
        width="460px"
      >
        <Flex direction="column" gap="3" p="24px">
          <Text size="3">Are you sure you want to delete this {name}?</Text>
        </Flex>
      </Modal>
    </>
  );
};

const EmailEditorPreview = () => {
  useHandlebarsInit();
  const dispatch = useEmailDispatch();
  const moveSections = useMoveSections();
  const deleteSection = useDeleteSection();
  const addEmptySection = useAddEmptySection();
  const replaceView = useReplaceEditorView();
  const { setEditableOnClick } = useEditableTipTapElement();
  const { deviceView, sections, selectedSectionId } = useEmailState();
  const [hoveredSectionId, setHoveredSectionId] = useState<string | null>(null);
  const handleMouseEnter = useCallback(
    (sectionId: string) => setHoveredSectionId(sectionId),
    []
  );
  const handleMouseLeave = useCallback(() => setHoveredSectionId(null), []);

  const handleSectionSelect = useCallback(
    (section: EmailSection) => {
      dispatch({
        type: "SET_SELECTED_SECTION",
        payload: section.id,
      });
      const enumValue = convertSectionToEnum(section);
      replaceView(enumValue);
    },
    [dispatch]
  );

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId !== source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    moveSections(source.index, destination.index);
  };
  const templates = useMemo(() => {
    return _(sections ?? [])
      .filter((section) => section.type !== null)
      .map((section) => {
        return {
          section,
          template: Handlebars.compile(section.template.html),
        };
      })
      .value();
  }, [sections]);

  const htmlBySectionId = useMemo(() => {
    return _(templates)
      .map(({ section, template: loader }) => {
        return {
          id: section.id,
          renderedHtml: loader(section, {
            helpers: {
              editable: (id: string) => {
                // need to call with timeout so that the element is rendered
                setTimeout(() => {
                  setEditableOnClick(id);
                  const fonts = getTipTapCustomFonts(id);
                  dispatch({
                    type: "SET_ELEMENT_ID_FONT_NAMES",
                    payload: {
                      elementId: id,
                      fontNames: fonts ?? [],
                    },
                  });
                }, 50);
              },
            },
          }),
        };
      })
      .keyBy((record) => record.id)
      .value();
  }, [templates]);

  const sectionComponents = useMemo(
    () =>
      (sections ?? []).map((section, index) => {
        let content = null;
        const isSelected = selectedSectionId === section.id;
        const isHovered = hoveredSectionId === section.id;

        if (section.type === null) {
          content = (
            <div
              style={{
                width: "100%",
                height: "130px",
                position: "relative",
                background: "#EDEBF0",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "24px",
                  right: "24px",
                  bottom: "24px",
                  left: "24px",
                  border: "2px dotted #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Blocks size={24} />
                  <div>Select Block Type</div>
                </div>
              </div>
            </div>
          );
        } else {
          const template = htmlBySectionId[section.id];
          content =
            template && template.renderedHtml ? (
              <div
                style={{
                  position: "relative",
                }}
                dangerouslySetInnerHTML={{
                  __html: template.renderedHtml,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Spinner loading />
              </div>
            );
        }

        return (
          <div className={styles.sectionContainer} key={section.id}>
            <Draggable key={section.id} draggableId={section.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    position: "relative",
                    ...provided.draggableProps.style,
                  }}
                  onClick={() => {
                    if (
                      section.type === EmailSectionType.header ||
                      section.type === EmailSectionType.footer
                    ) {
                      return;
                    } else {
                      handleSectionSelect(section);
                    }
                  }}
                  onMouseEnter={() => {
                    if (
                      section.type === EmailSectionType.header ||
                      section.type === EmailSectionType.footer
                    ) {
                      return;
                    } else {
                      handleMouseEnter(section.id);
                    }
                  }}
                  onMouseLeave={() => {
                    if (
                      section.type === EmailSectionType.header ||
                      section.type === EmailSectionType.footer
                    ) {
                      return;
                    } else {
                      handleMouseLeave();
                    }
                  }}
                >
                  <div style={{ pointerEvents: isSelected ? "auto" : "none" }}>
                    {content}
                  </div>
                  {(isSelected || isHovered) && (
                    <div
                      style={{
                        position: "absolute",
                        top: isSelected ? -2 : -1,
                        left: isSelected ? -2 : -1,
                        right: isSelected ? -2 : -1,
                        bottom: isSelected ? -2 : -1,
                        border: isSelected
                          ? "2px solid #206583"
                          : "1px solid #206583",
                        pointerEvents: "none",
                        zIndex: 1,
                      }}
                    />
                  )}
                  {isHovered ? (
                    <>
                      <AddSectionButton
                        direction="top"
                        onClick={() => {
                          addEmptySection(index);
                        }}
                      />
                      <AddSectionButton
                        direction="bottom"
                        onClick={() => {
                          addEmptySection(index + 1);
                        }}
                      />
                    </>
                  ) : null}
                  {isSelected ? (
                    <DragAndTrashButton
                      type={section.type}
                      onDelete={() => deleteSection(section.id)}
                    />
                  ) : null}
                </div>
              )}
            </Draggable>
          </div>
        );
      }),
    [sections, htmlBySectionId, selectedSectionId, hoveredSectionId]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Flex
        justify="center"
        width="70%"
        py="24px"
        overflow="scroll"
        id="email-editor-preview"
        pt="calc(var(--editor-top-bar-height) + var(--space-3))"
      >
        <LayoutComponents.PreviewContainer direction="column">
          <StrictModeDroppable
            droppableId="email-editor-preview-droppable"
            type="group"
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {deviceView === "desktop" ? (
                  sectionComponents
                ) : (
                  <MobilePreviewContainer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                      }}
                    >
                      {sectionComponents}
                    </div>
                  </MobilePreviewContainer>
                )}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
          <Tiptap />
        </LayoutComponents.PreviewContainer>
      </Flex>
    </DragDropContext>
  );
};

export default EmailEditorPreview;
