import { components } from "@openapi";
import { Box, Flex, TextArea, Text } from "@radix-ui/themes";
import { useRef, useEffect, useState, KeyboardEvent } from "react";
import styled from "styled-components";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { cleanHtmlText, wrapHtmlStyle } from "~/components/editor/utils";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useEmailRegenerateTextMutation from "~/hooks/emails/useEmailRegenerateTextMutation";

const RegenTextWithBackground = styled(Box)`
  background-color: #edebf0;
  padding: 12px;
  border-radius: 10px;
`;

const TextModifierGenerationSection = ({
  label,
  textElement,
  onRegenerateComplete,
  onCancel,
}: {
  label?: React.ReactNode;
  textElement: components["schemas"]["EmailTextElementSchema"];
  onRegenerateComplete: (newValue: string) => void;
  onCancel: () => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const activeBrandId = useActiveBrandID();

  const [regenPrompt, setRegenPrompt] = useState("");

  const { regenerateText, isLoading } = useEmailRegenerateTextMutation({
    onSuccess: (data) => {
      const styledResponse = wrapHtmlStyle({
        source: textElement.text,
        target: data.regenerated_text,
      });
      onRegenerateComplete(styledResponse);
      setRegenPrompt("");
      textAreaRef.current?.focus();
    },
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const submitButton = document.getElementById("submit-button");
      if (submitButton) {
        submitButton.click();
      }
    }
  };

  useEffect(() => {
    if (regenPrompt === "" && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [regenPrompt]);

  const regenActiveComponent = (
    <Flex direction="column" gap="4">
      {label}
      <RegenTextWithBackground>
        <Text>{cleanHtmlText(textElement.text)}</Text>
      </RegenTextWithBackground>
      <TextArea
        ref={textAreaRef}
        size="3"
        radius="large"
        value={regenPrompt}
        onChange={(e) => setRegenPrompt(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isLoading}
      />
      <Flex justify="end" align="center" gap="2">
        <SidePanelComponents.Button disabled={isLoading} onClick={onCancel}>
          Cancel
        </SidePanelComponents.Button>
        <SidePanelComponents.Button
          id="submit-button"
          disabled={isLoading}
          variant="dark"
          onClick={() => {
            regenerateText({
              brand_id: activeBrandId,
              original_text: cleanHtmlText(textElement.text),
              user_prompt: regenPrompt,
            });
          }}
        >
          Submit
        </SidePanelComponents.Button>
      </Flex>
    </Flex>
  );

  return regenActiveComponent;
};

export default TextModifierGenerationSection;
