import InternAssetHeroImageDialog from "../assets/intern/InternAssetHeroImageDialog";
import { ImageAssetSchema } from "../style-library/assets/BrandImageAsset";
import { ImageAssetCategory, operations } from "@openapi";
import { Flex, DropdownMenu, Button } from "@radix-ui/themes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { Ellipsis } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1;
`;

const CardWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px; /* Adjust the size as necessary */
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const CardSubtitle = styled.p`
  margin: 2px 0;
  font-size: 0.65rem;
  color: #666;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:hover {
    transform: translateY(-5px);
  }
`;

type ReclassifyImageAssetResponse =
  operations["media_api_reclassify_image_asset"]["responses"][200]["content"]["application/json"];

const InternImageAssetCard = ({
  asset,
  queryKey,
}: {
  asset: ImageAssetSchema;
  queryKey?: any[];
}) => {
  const queryClient = useQueryClient();

  const [mutatingCardId, setMutatingCardId] = useState<string | null>(null);

  const [isHeroImageDialogOpen, setIsHeroImageDialogOpen] =
    useState<boolean>(false);

  const [selectedAssetID, setSelectedAssetID] = useState<string | null>(null);

  const recategorize = useMutation<ReclassifyImageAssetResponse, Error, string>(
    {
      mutationFn: async (
        imageAssetId: string
      ): Promise<ReclassifyImageAssetResponse> => {
        const { data } = await axios.post(
          `/api/v1/media/image/${imageAssetId}/reclassify`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      },
      onSuccess: (response) => {
        setMutatingCardId(null);
        if (queryKey) {
          queryClient.invalidateQueries({
            queryKey,
          });
        }
        if (response.image_asset.category !== response.new_category) {
          alert(
            `Image classified to ${response.image_asset.category} and created new asset ${response.new_category}`
          );
        } else {
          alert(`Image reclassified to ${response.image_asset.category}`);
        }
      },
      onError: (error) => {
        setMutatingCardId(null);
        alert(error);
      },
    }
  );

  return (
    <>
      <InternAssetHeroImageDialog
        assetID={selectedAssetID}
        isDialogOpen={isHeroImageDialogOpen}
        setIsDialogOpen={setIsHeroImageDialogOpen}
      />
      <CardWrapper>
        <Card>
          <Image src={asset.url} alt={asset.original_filename} />
          <Flex justify="between" gap="16px">
            <Flex direction="column">
              <CardSubtitle>ID: {asset.id}</CardSubtitle>
              <CardSubtitle>Category: {asset.category}</CardSubtitle>
              <CardSubtitle>
                Commerce ID: {asset.commerce_platform_item_id}
              </CardSubtitle>
            </Flex>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="ghost">
                  <Ellipsis></Ellipsis>
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item
                  onClick={() => {
                    const currentHost = window.location.hostname;
                    const isLocalhost = currentHost === "localhost";
                    const baseUrl = isLocalhost ? "http://localhost:8000" : "";
                    window.open(
                      `${baseUrl}/admin/media/imageasset/${asset.id}/change/`
                    );
                  }}
                >
                  Edit
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => {
                    setMutatingCardId(asset.id);
                    recategorize.mutate(asset.id);
                  }}
                >
                  Re-categorize
                </DropdownMenu.Item>
                {asset.commerce_platform_item_id !== null &&
                  [
                    ImageAssetCategory.product,
                    ImageAssetCategory.product_cutout,
                  ].includes(asset.category) && (
                    <DropdownMenu.Item
                      onClick={() => {
                        setIsHeroImageDialogOpen(true);
                        setSelectedAssetID(asset.id);
                      }}
                    >
                      Generate Hero Image
                    </DropdownMenu.Item>
                  )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex>
        </Card>
        {mutatingCardId === asset.id && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}
      </CardWrapper>
    </>
  );
};

export default InternImageAssetCard;
