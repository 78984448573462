import AppButton from "../core/buttons/AppButton/AppButton";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Text, TextField } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import { CheckIcon, XIcon } from "~/assets/icons";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import useBrandUpdateMutation, {
  UpdateBrandResponse,
} from "~/hooks/brand/useBrandUpdateMutation";

const TextInput = styled(TextField.Root)`
  height: 48px;
`;

const useConnectKlaviyoForm = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: UpdateBrandResponse) => void;
  onError?: (error: any) => void;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();
  const queryClient = useQueryClient();
  const [klaviyoAPIKey, setKlaviyoAPIKey] = useState<string | null>(null);
  const { brandData, isLoading } = useBrandQuery(activeBrandID);

  const [isCheckValid, setIsCheckValid] = useState<boolean | null>(null);

  const initKlaviyoAPIKey = brandData?.klaviyo_api_token ?? null;

  const isKlaviyoUnset = initKlaviyoAPIKey === null;
  const hasKlaviyoAPIKeyChanged =
    klaviyoAPIKey !== null && klaviyoAPIKey !== initKlaviyoAPIKey;

  const canProceed =
    !isKlaviyoUnset && (!hasKlaviyoAPIKeyChanged || isCheckValid === true);

  const { updateBrand, isLoading: isUpdatingBrand } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onMutate: () => {
      setIsCheckValid(null);
    },
    onSuccess: (data) => {
      setIsCheckValid(true);
      queryClient.setQueryData(["brand", activeBrandID], data);
      onSuccess?.(data);
    },
    onError: (error) => {
      setIsCheckValid(false);
      onError?.(error);
    },
    skipOptimisticUpdate: true,
  });
  const form = isLoading ? null : (
    <Form.Root
      onSubmit={(e) => {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.currentTarget));

        updateBrand({
          klaviyo_api_token: data.api_key as string,
        });
      }}
    >
      <Flex gap="3">
        <Box width={"100%"}>
          <Form.Field name="api_key" autoFocus>
            <Form.Label>
              <Text size={"2"}>Klaviyo Private Key</Text>
            </Form.Label>
            <Flex align="end" gap="4">
              <Flex flexGrow={"1"}>
                <Form.Control asChild>
                  <TextInput
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      height: "42px",
                      fontSize: "16px",
                    }}
                    size={"2"}
                    required
                    placeholder="Private Key"
                    value={klaviyoAPIKey ?? initKlaviyoAPIKey ?? ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setKlaviyoAPIKey(newValue);
                      setIsCheckValid(null);
                    }}
                  >
                    {
                      <>
                        <TextField.Slot></TextField.Slot>
                        <TextField.Slot pr="3">
                          {canProceed ? (
                            <CheckIcon color="#3F9D27" size={20} />
                          ) : isCheckValid === false ? (
                            <XIcon color="#D00000" size={20} />
                          ) : null}
                        </TextField.Slot>
                      </>
                    }
                  </TextInput>
                </Form.Control>
              </Flex>
              <Form.Submit asChild>
                <AppButton
                  radius="large"
                  disabled={!hasKlaviyoAPIKeyChanged || isUpdatingBrand}
                  style={{ width: "94px", height: "42px" }}
                  size="3"
                >
                  {isUpdatingBrand
                    ? "Connecting"
                    : canProceed
                    ? "Connected"
                    : "Connect"}
                </AppButton>
              </Form.Submit>
            </Flex>
            <Form.Message match="valueMissing" style={{ color: "red" }}>
              <Text size={"1"}>Please enter your Klaviyo Private API Key.</Text>
            </Form.Message>
          </Form.Field>
        </Box>
      </Flex>
    </Form.Root>
  );
  return {
    isLoading: isLoading,
    form: form,
    canContinue: canProceed,
  };
};
export default useConnectKlaviyoForm;
