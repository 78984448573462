import AppButton from "../core/buttons/AppButton/AppButton";
import AuthPageBase from "./AuthPageBase";
import SignInLink from "./SignInLink";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Heading, Text, TextField } from "@radix-ui/themes";
import Cookies from "js-cookie";
import { useState } from "react";

enum Status {
  Initial,
  Loading,
  Complete,
}

const ResetPassword = () => {
  const [saveState, setSaveState] = useState(Status.Initial);
  const [emailErrors, setEmailErrors] = useState("");
  const [serverErrors, setServerErrors] = useState("");

  const onSubmit = async (data: FormData) => {
    const values = Object.fromEntries(data);
    const email = values["email"];
    fetch("/api/v1/user/auth/password/reset/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if ("detail" in data || !data.ok) {
          setServerErrors(
            "A password reset email has been sent if an email belonging to this account is registered with Kinn."
          );
          setSaveState(Status.Complete);
        } else {
          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("non_field_errors" in data) {
            setServerErrors(data.non_field_errors.join(","));
          }
          setSaveState(0);
        }
      });
  };

  const content = (
    <Form.Root
      onSubmit={(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        onSubmit(data);
      }}
      onClearServerErrors={() => setServerErrors("")}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
        width: "480px",
      }}
    >
      <Flex
        direction={"column"}
        justify={"center"}
        gap={"24px"}
        align={"stretch"}
        p="40px"
      >
        <Text align="center" weight={"bold"} size={"7"}>
          Reset Password
        </Text>
        {serverErrors ? (
          <Box>
            <Heading
              mb="1"
              size="2"
              color={saveState === Status.Complete ? "gray" : "red"}
            >
              {serverErrors}
            </Heading>
          </Box>
        ) : (
          <></>
        )}
        <Form.Field name="email" autoFocus>
          <Form.Label>
            <Text size={"1"}>Email</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextField.Root
              size="3"
              radius="large"
              type="email"
              required
              autoComplete="email"
            />
          </Form.Control>
          <Form.Message match="valueMissing" style={{ color: "red" }}>
            Please enter your email.
          </Form.Message>
          <Form.Message
            match="typeMismatch"
            forceMatch={!!emailErrors}
            style={{ color: "red" }}
          >
            {emailErrors}
          </Form.Message>
        </Form.Field>
        <Form.Submit asChild>
          <AppButton radius="large" disabled={saveState === Status.Loading}>
            Reset Password
          </AppButton>
        </Form.Submit>
        <Box
          style={{
            textAlign: "center",
          }}
        >
          <SignInLink
            style={{ color: "var(--text-secondary)", fontSize: "14px" }}
          />
        </Box>
      </Flex>
    </Form.Root>
  );

  return <AuthPageBase>{content}</AuthPageBase>;
};

export default ResetPassword;
