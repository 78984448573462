import { Flex, IconButton } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "~/assets/icons";

const Paginator = ({
  hasNextPage,
  hasPreviousPage,
  currentPage,
  updatePage,
}: {
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  currentPage: number;
  updatePage: (addend: number) => void;
}) => {
  return (
    <Flex gap="2">
      <IconButton
        style={{
          cursor: !hasPreviousPage ? "default" : "pointer",
          backgroundColor: "transparent",
          border: "1px solid #DDD7D7",
        }}
        onClick={() => updatePage(-1)}
        disabled={!hasPreviousPage}
      >
        <ChevronLeftIcon color={!hasPreviousPage ? "#DDD7D7" : "#595D62"} />
      </IconButton>

      <IconButton
        style={{
          cursor: !hasNextPage ? "default" : "pointer",
          backgroundColor: "transparent",
          border: "1px solid #DDD7D7",
        }}
        disabled={!hasNextPage}
        onClick={() => updatePage(1)}
      >
        <ChevronRightIcon color={hasNextPage ? "#595D62" : "#DDD7D7"} />
      </IconButton>
    </Flex>
  );
};

export default Paginator;
