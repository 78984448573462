import { Avatar, Flex, FlexProps, Heading, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import markSrc from "~/assets/mark.png";

// TODO: should check if we want to move towards the compact variant for all cards (including Onboarding)
type CardVariant = "default" | "compact";

const Container = styled.div`
  width: 802px;
  position: relative;
`;

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 24px;
  background-color: var(--primary-white);
  box-shadow: 0px 12px 120px 0px #00000014;
  box-shadow: 0px 12px 32px -16px #0009320a;
`;

const Footer = styled(Flex)`
  border-top: 1px solid var(--border-primary);
  padding: 24px;
  justify-content: flex-end;
  gap: 16px;
`;

const Title = styled(Text)`
  color: var(--icon-primary-active);
  font-size: 18px;
  font-weight: 500;
`;

const Subtitle = styled(Heading)<{ $variant?: CardVariant }>`
  font-size: ${({ $variant }) => ($variant === "compact" ? "22px" : "24px")};
  font-weight: 600;
  white-space: pre-wrap;
`;

const AssistantAvatar = styled(Avatar)<{ $variant?: CardVariant }>`
  position: absolute;
  ${({ $variant = "default" }) =>
    $variant === "compact"
      ? "top: -32px; left: -32px; width: 64px; height: 64px;"
      : "top: -32px; left: -32px; width: 72px; height: 72px;"}
`;

interface CardSubtitleProps {
  text: string | string[];
  variant?: CardVariant;
}

const CardSubtitle: React.FC<CardSubtitleProps> = ({ text, variant }) => {
  if (typeof text === "string") {
    return <Subtitle $variant={variant}>{text}</Subtitle>;
  }
  return (
    <Flex direction="column" gap="6" align="start">
      {text
        .filter((sub) => sub)
        .map((sub, index) => (
          <Subtitle key={index} $variant={variant}>
            {sub}
          </Subtitle>
        ))}
    </Flex>
  );
};

interface ActionCardProps {
  title: string;
  subtitle?: CardSubtitleProps["text"];
  variant?: CardVariant;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  overflowY?: FlexProps["overflowY"];
  maxHeight?: FlexProps["maxHeight"];
}

/**
 * @prop {string} title - The title of the card.
 * @prop {string} subtitle - The subtitle of the card.
 * @prop {React.ReactNode} children - The content of the card.
 * @prop {React.ReactNode} footer - The footer of the card.
 *
 * @example
 * <ActionCard
 *  title="Welcome to Draper"
 *  subtitle="Let's get started"
 *  footer={<AppButton>Continue</AppButton>}
 * >
 *   <Text>Some content</Text>
 * </ActionCard>
 */
const Root = ({
  title,
  subtitle,
  variant = "default",
  children,
  footer,
  overflowY = "auto",
  maxHeight,
}: ActionCardProps) => {
  const padding =
    variant === "compact"
      ? "var(--action-card-compact-xb-padding)"
      : "var(--action-card-xb-padding)";
  return (
    <Container>
      <AssistantAvatar src={markSrc} fallback="Mark" $variant={variant} />
      <Card direction="column" overflowY={overflowY} maxHeight={maxHeight}>
        <Flex direction="column" pt="40px" pb={padding} px={padding} gap="24px">
          <Title>{title}</Title>
          {subtitle && <CardSubtitle text={subtitle} variant={variant} />}
          {children}
        </Flex>
        {!!footer && <Footer>{footer}</Footer>}
      </Card>
    </Container>
  );
};

export default Root;
export const ActionCard = {
  Root,
  Subtitle: CardSubtitle,
};
