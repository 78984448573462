import { IconButton, IconButtonProps } from "@radix-ui/themes";
import { XIcon } from "~/assets/icons";

const CloseIconButton = (props: IconButtonProps) => {
  return (
    <IconButton
      {...props}
      color={props.color || "gray"}
      variant={props.variant || "outline"}
      radius={props.radius || "large"}
    >
      <XIcon color="#000000A6" />
    </IconButton>
  );
};

export default CloseIconButton;
