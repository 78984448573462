/**
 * List of temporarly disabled/hidden functionality - centralized so that it's easier to restore once needed
 */

const TEMP_FLAGS = {
  /** Treat as false once the Meta app is published */
  OPTIONAL_META: true,
  FORCE_NULL_STATE: false,
} as const;

export default TEMP_FLAGS;
