import styles from "./NumberedPaginator.module.css";
import { Flex, IconButton } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";
import { Pagination } from "react-headless-pagination";
import { ChevronLeftIcon, ChevronRightIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const NumberedPaginator = ({
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalPages: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const PageButton = (props: any) => {
    const isActive = props.children === currentPage + 1;
    return (
      <AppButton
        {...props}
        variant={isActive ? "primary" : "outlined"}
        radius="medium"
        size="2"
        title={String(props.children)}
      >
        {props.children}
      </AppButton>
    );
  };
  return (
    <Pagination
      totalPages={totalPages}
      middlePagesSiblingCount={1}
      edgePageCount={1}
      currentPage={currentPage}
      setCurrentPage={handlePageChange}
      className={styles.container}
      truncableClassName={styles.truncable}
      truncableText="..."
    >
      <Pagination.PrevButton
        as={
          <IconButton
            className={styles.button}
            style={{
              cursor: currentPage === 0 ? "default" : "pointer",
              border: "1px solid #DDD7D7",
            }}
          >
            <ChevronLeftIcon
              color={currentPage === 0 ? "#DDD7D7" : "#595D62"}
            />
          </IconButton>
        }
      />

      <nav className={styles.nav}>
        <Flex gap="2">
          <Pagination.PageButton as={<PageButton />} />
        </Flex>
      </nav>

      <Pagination.NextButton
        as={
          <IconButton
            className={styles.button}
            style={{
              cursor: currentPage === totalPages - 1 ? "default" : "pointer",
              border: "1px solid #DDD7D7",
            }}
          >
            <ChevronRightIcon
              color={currentPage === totalPages - 1 ? "#DDD7D7" : "#595D62"}
            />
          </IconButton>
        }
      />
    </Pagination>
  );
};

export default NumberedPaginator;
