import Handlebars from "handlebars";

export function useHandlebarsInit() {
  Handlebars.registerHelper(
    "or",
    function (
      this: any,
      a: boolean,
      b: boolean,
      options: Handlebars.HelperOptions
    ) {
      if (a || b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    }
  );
  Handlebars.registerHelper("isEven", function (index: number): boolean {
    return index % 2 === 0;
  });
  Handlebars.registerHelper("isOdd", function (index: number): boolean {
    return index % 2 === 1;
  });
  Handlebars.registerHelper("editable", function (): void {
    // Do nothing - we're only enabling this while editing
  });

  Handlebars.registerHelper(
    "default",
    (option: String, defaultValue: String) => {
      return option ?? defaultValue;
    }
  );
  Handlebars.registerHelper("chunk", function (array, size) {
    if (!Array.isArray(array)) return [];
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }

    return chunked;
  });
  Handlebars.registerHelper("take", function (array, count) {
    if (!Array.isArray(array)) return [];
    return array.slice(0, count);
  });
  Handlebars.registerHelper("and", function (): boolean {
    var args = Array.prototype.slice.call(arguments, 0, -1);
    return args.every(function (arg) {
      return !!arg;
    });
  });
  Handlebars.registerHelper("divide", function (a: number, b: number): number {
    if (b === 0) return 0; // Prevent division by zero
    return parseFloat((a / b).toFixed(2)); // Return float with 2 decimal places
  });

  Handlebars.registerPartial(
    "button",
    ` {{#if enabled}}
  <tr>
  <td style="text-align: {{#if text_align}}{{text_align}}{{else}}center{{/if}}">
  <a
  id="ttid:{{id}}"
    target="_blank"
    href="{{link_url}}"
    {{editable id}}
    style="
      background-color: {{background_color}};
      color: {{color}};
      border-radius: {{border_radius}};
      border-style: {{border_style}};
      border-width: {{border_width}};
      border-color: {{border_color}};
      padding: {{padding}};
      font-size: {{font_styles.size}};
      font-weight: {{font_styles.weight}};
      font-style: {{font_styles.style}};
      font-family: {{font_styles.family}};
      text-transform: {{text_transform}};
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      display: {{#if display}}{{display}}{{else}}inline-block{{/if}};
  "
  >
    {{{text}}}
  </a>
  </td>
  </tr>
  {{/if}}`
  );
  Handlebars.registerPartial(
    "text",
    `{{#if enabled}}
  <tr>
    <td 
      id="ttid:{{id}}" 
      style="
        {{#if text_align}}text-align: {{text_align}};{{/if}}
        {{#if color}}color: {{color}};{{/if}}
        padding-bottom: {{#if padding_bottom}}{{padding_bottom}}{{else}}12px{{/if}};
        {{style}}
      " 
      {{editable id}}
    >
        {{{text}}}
    </td>
  </tr>
  {{/if}}`
  );
}
