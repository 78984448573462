import { TemplateEditorComponents } from "~/components/style-library/campaign-template/template-editor";
import { TemplateEditorProvider } from "~/contexts/TemplateEditorContext";

const TemplateEditorPage = () => {
  return (
    <TemplateEditorComponents.Layout>
      <TemplateEditorComponents.TopBar />
      <TemplateEditorComponents.Preview />
      <TemplateEditorComponents.Aside />
    </TemplateEditorComponents.Layout>
  );
};

const TemplateEditorContainer = () => {
  return (
    <TemplateEditorProvider>
      <TemplateEditorPage />
    </TemplateEditorProvider>
  );
};

export default TemplateEditorContainer;
