import { Spinner } from "@radix-ui/themes";

const FullscreenSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <Spinner loading />
      </div>
    </div>
  );
};

export default FullscreenSpinner;
