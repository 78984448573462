import { components, operations } from "@openapi";
import { ImageAssetCategory, ImageAssetSource } from "@openapi";
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { ImageAssetsDefaultKey } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/LibraryAssets";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type ImageAssetsResponse =
  operations["media_api_get_image_assets"]["responses"][200]["content"]["application/json"];

export type ImageAssets = ImageAssetsResponse["assets"];

export type ImageAssetsRequestParams =
  operations["media_api_get_image_assets"]["parameters"]["query"];

type UseImageAssetsQueryParams = {
  brandId: string | null;
  search?: string;
  source?: ImageAssetSource[];
  limit?: number;
  category?: ImageAssetCategory[];
  commercePlatformItemId?: string[];
};

export const getImageAssetsQueryKey = ({
  brandId,
  search,
  ...params
}: UseImageAssetsQueryParams) =>
  // the search should always be last, in order to properly add new images to the correct queries
  ["imageAssets", brandId, params, ...(search !== undefined ? [search] : [])];

const useImageAssetsQuery = ({
  brandId,
  source,
  category,
  commercePlatformItemId,
  search,
  limit,
}: UseImageAssetsQueryParams) => {
  const fetchAssets = async ({
    pageParam,
  }: {
    pageParam: unknown;
  }): Promise<ImageAssetsResponse> => {
    const params: ImageAssetsRequestParams = {
      brand_id: brandId ?? "",
      source: source?.join(","),
      category: category?.join(","),
      commerce_platform_item_id: commercePlatformItemId?.join(","),
      limit,
      offset: pageParam as ImageAssetsResponse["next_offset"],
      search,
    };
    const { data } = await axios.get("/api/v1/media/image-assets", {
      params,
    });
    return data;
  };
  const { data, error, fetchNextPage, hasNextPage, isSuccess, isLoading } =
    useInfiniteQuery<ImageAssetsResponse>({
      queryKey: getImageAssetsQueryKey({
        brandId,
        source,
        category,
        commercePlatformItemId,
        limit,
        search,
      }),
      queryFn: fetchAssets,
      initialPageParam: 0,
      getNextPageParam: (lastPage, pages) => lastPage.next_offset,
    });
  return {
    assetsData: data?.pages,
    isLoading,
    isSuccess,
    error,
    fetchNextPage,
    hasNextPage,
  };
};

export const useAddImageAssetToQuery = () => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  return (data: ImageAssets) =>
    queryClient.setQueriesData(
      {
        queryKey: ImageAssetsDefaultKey(activeBrandID),
        exact: false,
        predicate: (query) => {
          const search = (query.queryKey as Array<any>).at(-1);
          // adding the new image only to the queries that would contain it by search
          return (
            !search ||
            data[0].original_filename.includes((search as string).toLowerCase())
          );
        },
      },
      (
        oldData:
          | InfiniteData<
              {
                assets: components["schemas"]["ImageAssetSchema"][];
                next_offset: number | null;
              },
              unknown
            >
          | undefined
      ) =>
        //adding new asset to the top of first page
        oldData
          ? {
              ...oldData,
              pages: [
                {
                  ...oldData.pages[0],
                  assets: [data[0], ...oldData.pages[0].assets],
                },
                ...oldData.pages.slice(1),
              ],
            }
          : oldData
    );
};

export default useImageAssetsQuery;
