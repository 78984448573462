import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

type GenerateAdCreativeMediaResponse =
  operations["ads_api_generate_ad_creative_layout_variant"]["responses"][200]["content"]["application/json"];
type GenerateAdCreativeMediaVars = {
  adCreativeId: string;
};

const useGenerateAdCreativeMediaMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    data: GenerateAdCreativeMediaResponse,
    vars: GenerateAdCreativeMediaVars
  ) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const generateAdCreativeMedia = useMutation<
    GenerateAdCreativeMediaResponse,
    AxiosError,
    GenerateAdCreativeMediaVars
  >({
    mutationFn: async ({ adCreativeId }) => {
      const { data } = await axios.post(
        `/api/v1/ads/ad-creative/${adCreativeId}/generate-layout-variant`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess,
    onError: (error) => {
      console.log("Failed to generate ad media:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return generateAdCreativeMedia;
};

export default useGenerateAdCreativeMediaMutation;
