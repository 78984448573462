import EmailSectionPreview from "../preview/EmailSectionPreview";
import { Flex, ScrollArea, Skeleton, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { useMemo } from "react";
import { useTemplateEditorState } from "~/contexts/TemplateEditorContext";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";

const TemplateEditorPreview = () => {
  useHandlebarsInit();
  const { headerOptions, footerOptions, previewMode } =
    useTemplateEditorState();
  const state = useTemplateEditorState();

  const headerTemplate = useMemo(() => {
    const template = headerOptions.template;
    return Handlebars.compile(template.html);
  }, [headerOptions]);

  const footerTemplate = useMemo(() => {
    const template = footerOptions.template;
    return Handlebars.compile(template.html);
  }, [footerOptions]);

  const constrainedMaxWidth = previewMode === "mobile" ? "320px" : undefined;

  return (
    <ScrollArea mt="68px">
      <EmailSectionPreview
        template={headerTemplate}
        vars={state.headerOptions}
      />

      <Flex
        direction="column"
        mx="auto"
        maxWidth={constrainedMaxWidth ?? "600px"}
        gap="5"
      >
        <Flex gap="5">
          <Skeleton height="140px" width="140px" minWidth="140px" />
          <Text size="5" style={{ maxHeight: "140px", overflow: "hidden" }}>
            <Skeleton>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              accusantium voluptates recusandae vero ipsum reiciendis mollitia
              impedit autem est odit rerum dignissimos, voluptatem iure.
            </Skeleton>
          </Text>
        </Flex>
        <Text size="5" style={{ maxHeight: "140px", overflow: "hidden" }}>
          <Skeleton>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            accusantium voluptates recusandae vero ipsum reiciendis mollitia
            impedit autem est odit rerum dignissimos, voluptatem iure.
          </Skeleton>
        </Text>
      </Flex>

      <EmailSectionPreview
        template={footerTemplate}
        vars={state.footerOptions}
      />
    </ScrollArea>
  );
};

export default TemplateEditorPreview;
