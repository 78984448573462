import { Flex, Grid } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const SlotContainer = styled(Flex)`
  max-height: 100%;
  & > * {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }
`;

type ThreeSlotsPreviewProps = {
  /** `fixed` will always separate layout into 3 slots, while `dynamic` will try to fill blank space */
  layoutStyle?: "fixed" | "dynamic";
  children: React.ReactNode;
  style?: React.CSSProperties;
} & Pick<
  React.ComponentProps<typeof Grid>,
  "gap" | "p" | "pb" | "px" | "py" | "m" | "mb" | "mx" | "my"
>;

const ThreeSlotsPreview: React.FC<ThreeSlotsPreviewProps> = ({
  children: childrenProp,
  layoutStyle = "dynamic",
  ...props
}) => {
  const children = React.Children.toArray(childrenProp);
  const gap = props.gap ?? "24px";
  const halfGap = `calc(${gap} / 2)`;
  if (layoutStyle === "dynamic") {
    if (children.length <= 1) {
      return <SlotContainer {...props}>{children[0]}</SlotContainer>;
    }
    if (children.length === 2) {
      return (
        <Flex gap={gap} overflow="hidden" {...props}>
          <SlotContainer style={{ flex: 1 }}>{children[0]}</SlotContainer>
          <SlotContainer style={{ flex: 1 }}>{children[1]}</SlotContainer>
        </Flex>
      );
    }
  }

  return (
    <Grid
      gap={gap}
      overflow="hidden"
      columns={`calc(66% - ${halfGap}) calc(34% - ${halfGap})`}
      rows={`calc(50% - ${halfGap}) calc(50% - ${halfGap})`}
      {...props}
    >
      <SlotContainer
        style={{ flex: 2, gridColumn: "1 / 2", gridRow: "1 / span 2" }}
      >
        {children?.[0]}
      </SlotContainer>
      <SlotContainer style={{ flex: 1, gridColumn: "2 / 3", gridRow: "1 / 2" }}>
        {children[1]}
      </SlotContainer>
      {children[2] && (
        <SlotContainer
          style={{ flex: 1, gridColumn: "2 / 3", gridRow: "2 / 2" }}
        >
          {children[2]}
        </SlotContainer>
      )}
    </Grid>
  );
};

export default ThreeSlotsPreview;
