import { operations } from "@openapi";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, Flex, Heading, Table } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { Ellipsis } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

const styles = {
  tableContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    padding: "8px",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  tableText: {
    fontSize: "12px",
    whiteSpace: "normal",
  },
  tooltipContent: {
    background: "#fff",
    zIndex: 1000,
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

type GetBrandJobsResponse =
  operations["brand_api_get_brand_jobs"]["responses"][200]["content"]["application/json"];

interface InternBrandJobsCardProps {
  parentState: number;
  refresh: () => void;
}

const InternBrandJobsCard: React.FC<InternBrandJobsCardProps> = ({
  parentState,
  refresh,
}) => {
  const activeBrandID = useActiveBrandID();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["brand-jobs", activeBrandID],
    queryFn: async (): Promise<GetBrandJobsResponse> => {
      const { data } = await axios.get(`/api/v1/brand/${activeBrandID}/jobs`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return data;
    },
    retry: true,
    staleTime: 3600,
  });

  useEffect(() => {
    refetch();
  }, [parentState, refetch]);

  const sortedJobs = useMemo(() => {
    if (!data?.jobs) return [];
    return data.jobs.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, [data]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {"Loading..."}
      </div>
    );
  }

  return (
    <Flex direction="column" gap="24px" height="100%">
      <Flex justify="between">
        <Heading size="5">Jobs</Heading>
      </Flex>
      <Flex direction="column" style={styles.tableContainer} flexGrow="1">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              {["Job", "Created", "Status", "Action"].map(
                (header, headerIndex) => (
                  <Table.Cell key={headerIndex} style={styles.tableHeader}>
                    {header}
                  </Table.Cell>
                )
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedJobs.map((job) => (
              <Tooltip.Root key={job.id}>
                <Tooltip.Trigger asChild>
                  <Table.Row>
                    <Table.Cell style={styles.tableText}>
                      <span>{job.workflow_name}</span>
                    </Table.Cell>
                    <Table.Cell style={styles.tableText}>
                      {new Date(job.created_at).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell style={styles.tableText}>
                      {job.status}
                    </Table.Cell>
                    <Table.Cell style={styles.tableText}>
                      <Button variant="ghost">
                        <Ellipsis />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Tooltip.Trigger>
                <Tooltip.Content side="top" style={styles.tooltipContent}>
                  {job.id}
                </Tooltip.Content>
              </Tooltip.Root>
            ))}
          </Table.Body>
        </Table.Root>
      </Flex>
    </Flex>
  );
};

export default InternBrandJobsCard;
