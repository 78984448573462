import FooterView from "../editor/sidepanel-views/FooterView";
import HeaderView from "../editor/sidepanel-views/HeaderView";
import {
  MousePointerClick,
  PresentationIcon,
  LayoutGridIcon,
  LayoutPanelTop,
  ListIcon,
  NewspaperIcon,
} from "lucide-react";
import { ImageIcon, SparkleIcon } from "~/assets/icons";
import EditorAside from "~/components/core/editor/layout/EditorAside";
import AddBlockView from "~/components/editor/editor/sidepanel-views/AddBlockView";
import BlogView from "~/components/editor/editor/sidepanel-views/BlogView";
import ButtonView from "~/components/editor/editor/sidepanel-views/ButtonView";
import CollectionGridView from "~/components/editor/editor/sidepanel-views/CollectionGridView";
import CtaView from "~/components/editor/editor/sidepanel-views/CtaView";
import GenerateVariantView from "~/components/editor/editor/sidepanel-views/GenerateVariantView";
import HeroCollectionView from "~/components/editor/editor/sidepanel-views/HeroCollectionView";
import HeroProductView from "~/components/editor/editor/sidepanel-views/HeroProductView";
import HeroView from "~/components/editor/editor/sidepanel-views/HeroView";
import ImageView from "~/components/editor/editor/sidepanel-views/ImageView";
import ListicleView from "~/components/editor/editor/sidepanel-views/ListicleView";
import ProductGridView from "~/components/editor/editor/sidepanel-views/ProductGridView";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import {
  EmailEditorViews,
  useEmailState,
  usePopEditorView,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { assertNever } from "~/utils/typeUtils";

export const getViewValues = (
  view: EmailEditorViews
): {
  name: string;
  Component: React.FC;
  Icon?: React.FC;
} => {
  switch (view) {
    case EmailEditorViews.GenerateVariant:
      return {
        name: "Generate Variant",
        Component: GenerateVariantView,
      };
    case EmailEditorViews.Button:
      return {
        name: "Button",
        Component: ButtonView,
      };
    case EmailEditorViews.AddBlock:
      return {
        name: "Add Block",
        Component: AddBlockView,
      };
    case EmailEditorViews.blog:
      return {
        name: "Blog",
        Component: BlogView,
        Icon: NewspaperIcon,
      };
    case EmailEditorViews.collection_grid:
      return {
        name: "Collection Grid",
        Component: CollectionGridView,
        Icon: LayoutPanelTop,
      };
    case EmailEditorViews.cta_card:
      return {
        name: "Cta Card",
        Component: CtaView,
        Icon: MousePointerClick,
      };
    case EmailEditorViews.footer:
      return {
        name: "Footer",
        Component: FooterView,
      };
    case EmailEditorViews.header:
      return {
        name: "Header",
        Component: HeaderView,
      };
    case EmailEditorViews.hero:
      return {
        name: "Hero",
        Component: HeroView,
        Icon: PresentationIcon,
      };
    case EmailEditorViews.hero_collection:
      return {
        name: "Collection Spotlight",
        Component: HeroCollectionView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_product:
      return {
        name: "Featured Product",
        Component: HeroProductView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_sales_event:
      return {
        name: "Hero",
        Component: HeroView,
      };
    case EmailEditorViews.image:
      return {
        name: "Image",
        Component: ImageView,
        Icon: ImageIcon,
      };
    case EmailEditorViews.listicle:
      return {
        name: "Listicle",
        Component: ListicleView,
        Icon: ListIcon,
      };
    case EmailEditorViews.products:
      return {
        name: "Product Grid",
        Component: ProductGridView,
        Icon: LayoutGridIcon,
      };
    default:
      assertNever(view);
  }
};

const EmailEditorAside = () => {
  const { editorViewStack } = useEmailState();
  const popView = usePopEditorView();
  const currentEditorView = editorViewStack[editorViewStack.length - 1];

  const { name, Component } = getViewValues(currentEditorView);

  return (
    <BrandStylingProvider>
      <EditorAside
        showHeader={editorViewStack.length > 1}
        title={name}
        onBack={() => popView()}
      >
        <Component />
      </EditorAside>
    </BrandStylingProvider>
  );
};

export default EmailEditorAside;
