import { Flex, Grid, Text } from "@radix-ui/themes";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const VariantSelector = styled(Grid)`
  gap: 8px;
`;

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

interface ExploreLayoutVariantsSectionProps {
  title?: string;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

const ExploreLayoutVariantsSection = ({
  title = "Explore layout variants",
  isPreviousDisabled = false,
  isNextDisabled = false,
  onPrevious,
  onNext,
}: ExploreLayoutVariantsSectionProps) => {
  return (
    <Flex direction="column" gap="16px">
      <SectionTitle weight="bold" size="3">
        {title}
      </SectionTitle>
      <VariantSelector columns="2">
        <AppButton
          radius="large"
          variant="soft"
          disabled={isPreviousDisabled}
          onClick={onPrevious}
        >
          <ChevronLeftIcon /> Previous
        </AppButton>
        <AppButton
          radius="large"
          variant="soft"
          disabled={isNextDisabled}
          onClick={onNext}
        >
          Next <ChevronRightIcon />
        </AppButton>
      </VariantSelector>
    </Flex>
  );
};

export default ExploreLayoutVariantsSection;
