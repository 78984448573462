import { components } from "@openapi";
import { Grid } from "@radix-ui/themes";
import styled from "styled-components";

const Image = styled.img`
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  overflow: hidden;
  border: 0.5px solid var(--black-8, #00000014);
`;

const CampaignProductsPreview: React.FC<{
  productsData?: components["schemas"]["ShopifyProductInfoExtra"][] | null;
}> = ({ productsData }) => (
  <Grid
    gap="4"
    maxWidth="100%"
    width="100%"
    columns={`repeat(${Math.min(
      productsData?.length ?? 1,
      3
    )}, minmax(0, 1fr))`}
    style={{
      justifyItems: "center",
    }}
  >
    <Image
      src={productsData?.[0]?.image_url ?? ""}
      alt={productsData?.[0]?.title}
    />
    {productsData?.[1]?.image_url && (
      <Image src={productsData[1].image_url} alt={productsData[1].title} />
    )}
    {productsData?.[2]?.image_url && (
      <Image
        src={productsData[2].image_url ?? ""}
        alt={productsData[2].title}
      />
    )}
  </Grid>
);

export default CampaignProductsPreview;
