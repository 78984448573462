import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { PlusIcon, StarSparksIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const CoreValuesCardSection = () => {
  const handleAdd = () => {
    console.log("Edit");
  };

  return (
    <StyleLibraryCardContent
      title="Core Values"
      icon={<StarSparksIcon />}
      helperText={`
        Core values are the fundamental beliefs of a person or organization. 
        These guiding principles dictate behavior and can help people understand 
        the difference between right and wrong. 
        Core values also help companies to determine if they are on the right path 
        and fulfilling their goals by creating an unwavering guide.`}
      actions={
        <AppButton onClick={handleAdd} variant="ghost" size={"3"}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          New Value
        </AppButton>
      }
    >
      TODO
    </StyleLibraryCardContent>
  );
};
export default CoreValuesCardSection;
