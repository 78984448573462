import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type CampaignRecommendationsResponse =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"];
export type CampaignRecommendations =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"]["campaign_recommendations"];
export type CampaignRecommendation =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"]["campaign_recommendations"][0];
export type CampaignRecommendationType =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"]["campaign_recommendations"][0]["item_set_type"];

export const PAGE_SIZE = 15;

const useGetCampaignRecommendations = ({
  page = 0, // Fill this if you want to fetch a specific page
}: {
  page?: number;
} = {}) => {
  const activeBrandID = useActiveBrandID();
  return useInfiniteQuery({
    queryKey: [
      "recommender/brand-campaign-recommendations",
      activeBrandID,
      page,
    ],
    queryFn: async ({
      pageParam,
    }: {
      pageParam: number;
    }): Promise<CampaignRecommendationsResponse> => {
      const response = await axios.get(
        "/api/v1/recommender/brand-campaign-recommendations",
        {
          params: {
            brand_id: activeBrandID,
            first: PAGE_SIZE,
            after: pageParam,
          },
        }
      );
      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) =>
      lastPage.has_more ? lastPage.next_page : undefined,
  });
};

export default useGetCampaignRecommendations;
