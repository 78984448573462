import { components } from "@openapi";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import useGetAdCreativeWithMedia from "~/hooks/ads/useGetAdCreativeWithMedia";
import { assertNever } from "~/utils/typeUtils";

interface ErrorState {
  status: "error";
  message: string;
}

interface LoadingState {
  status: "loading";
}

interface AdEditorMediaStateLoaded {
  status: "loaded";
  list: components["schemas"]["AdMediaSchema"][];
  selectedMediaIndex: number;
}

export interface AdState {
  data: components["schemas"]["AdCreativeSchema"];
  media: ErrorState | LoadingState | AdEditorMediaStateLoaded;
}

interface AdEditorStateLoaded {
  status: "loaded";
  // TODO: need to clarify which ads the user can toggle between (e.g. the ones linked to same campaign?)
  availableAds: AdState[];
  selectedAdIndex: number;
}

export const AD_ZOOM_OPTIONS = [33, 50, 75, 100] as const;
interface AdEditorPermanentState {
  showDownloadButtons: boolean;
  zoom: (typeof AD_ZOOM_OPTIONS)[number];
  pendingChanges: number;
}

export type AdEditorState = (ErrorState | LoadingState | AdEditorStateLoaded) &
  AdEditorPermanentState;

const initialState: AdEditorState = {
  status: "loading",
  showDownloadButtons: false,
  zoom: 100,
  pendingChanges: 0,
};

interface ActionChangeZoom {
  type: "CHANGE_ZOOM";
  payload: AdEditorState["zoom"];
}
interface ActionSelectAdIndex {
  type: "SELECT_AD_INDEX";
  payload: number;
}
interface ActionUpdateAdData {
  type: "UPDATE_AD_DATA";
  payload: Partial<
    Pick<
      components["schemas"]["AdCreativeSchema"],
      "primary_text" | "description" | "headline"
    >
  > & {
    id: string;
  };
}
interface ActionResetAdMediaState {
  type: "RESET_AD_MEDIA_STATE";
  payload: {
    adCreativeId: string;
    media: AdState["media"];
  };
}
interface ActionSelectAdMediaIndex {
  type: "SELECT_AD_MEDIA_INDEX";
  payload: {
    adCreativeId: string;
    selectedMediaIndex: number;
  };
}
interface ActionToggleShowDownloadButtons {
  type: "TOGGLE_SHOW_DOWNLOAD_BUTTONS";
}
interface ActionAppendAdMedia {
  type: "APPEND_AD_MEDIA";
  payload: {
    media: components["schemas"]["AdMediaSchema"][];
  };
}
interface ActionResetState {
  type: "RESET_STATE";
  payload: AdEditorState;
}
interface ActionIncrementPendingChanges {
  type: "INCREMENT_PENDING_CHANGES";
  payload: number;
}

export type AdEditorActions =
  | ActionChangeZoom
  | ActionSelectAdIndex
  | ActionUpdateAdData
  | ActionResetState
  | ActionResetAdMediaState
  | ActionSelectAdMediaIndex
  | ActionAppendAdMedia
  | ActionToggleShowDownloadButtons
  | ActionIncrementPendingChanges;
function reducer(state: AdEditorState, action: AdEditorActions): AdEditorState {
  switch (action.type) {
    case "CHANGE_ZOOM":
      return {
        ...state,
        zoom: action.payload,
      };
    case "SELECT_AD_INDEX":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        selectedAdIndex: action.payload,
      };
    case "UPDATE_AD_DATA":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        availableAds: state.availableAds.map((ad) => {
          if (ad.data.id !== action.payload.id) {
            return ad;
          }

          return {
            ...ad,
            data: {
              ...ad.data,
              ...action.payload,
            },
          };
        }),
      };
    case "RESET_AD_MEDIA_STATE":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        availableAds: state.availableAds.map((ad) => {
          if (ad.data.id !== action.payload.adCreativeId) {
            return ad;
          }

          return {
            ...ad,
            media: action.payload.media,
          };
        }),
      };
    case "SELECT_AD_MEDIA_INDEX":
      if (state.status !== "loaded") {
        return state;
      }

      return {
        ...state,
        availableAds: state.availableAds.map((ad) => {
          if (ad.data.id !== action.payload.adCreativeId) {
            return ad;
          }
          if (
            ad.media.status !== "loaded" ||
            ad.media.list.length <= action.payload.selectedMediaIndex
          ) {
            return ad;
          }

          return {
            ...ad,
            media: {
              ...ad.media,
              selectedMediaIndex: action.payload.selectedMediaIndex,
            },
          };
        }),
      };
    case "APPEND_AD_MEDIA":
      if (state.status !== "loaded" || !action.payload.media.length) {
        return state;
      }

      const adCreativeId = action.payload.media[0].creative_id;

      return {
        ...state,
        availableAds: state.availableAds.map((ad) => {
          if (ad.data.id !== adCreativeId || ad.media.status !== "loaded") {
            return ad;
          }

          return {
            ...ad,
            media: {
              ...ad.media,
              list: [...ad.media.list, ...action.payload.media],
              selectedMediaIndex: ad.media.list.length,
            },
          };
        }),
      };
    case "RESET_STATE":
      return action.payload;
    case "TOGGLE_SHOW_DOWNLOAD_BUTTONS":
      return { ...state, showDownloadButtons: !state.showDownloadButtons };
    case "INCREMENT_PENDING_CHANGES":
      return {
        ...state,
        pendingChanges: state.pendingChanges + action.payload,
      };
    default:
      assertNever(action);
  }
}

const StateContext = createContext<AdEditorState>(initialState);
const DispatchContext = createContext<React.Dispatch<AdEditorActions> | null>(
  null
);

export const AdEditorProvider = ({
  children,
  adCreativeId,
}: {
  children: React.ReactElement;
  adCreativeId: string | undefined;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { creativeQuery, mediaQuery } = useGetAdCreativeWithMedia(adCreativeId);

  // update state from ad data response
  useEffect(() => {
    if (creativeQuery.data) {
      const ad = creativeQuery.data;
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "loaded",
          availableAds: [
            {
              data: ad,
              media: {
                status: "loading",
              },
            },
          ],
          selectedAdIndex: 0,
        },
      });
    } else if (creativeQuery.isError) {
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "error",
          message: creativeQuery.error.message,
        },
      });
    } else {
      dispatch({
        type: "RESET_STATE",
        payload: {
          ...state,
          status: "loading",
        },
      });
    }
  }, [creativeQuery.data, creativeQuery.isError, creativeQuery.error]);

  // update state from ad media response
  useEffect(() => {
    if (state.status !== "loaded") {
      return;
    }

    if (mediaQuery.data) {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "loaded",
            list: mediaQuery.data.media,
            selectedMediaIndex: mediaQuery.data.selected_media_index ?? 0,
          },
        },
      });
    } else if (mediaQuery.isError) {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "error",
            message: mediaQuery.error.message,
          },
        },
      });
    } else {
      dispatch({
        type: "RESET_AD_MEDIA_STATE",
        payload: {
          adCreativeId: adCreativeId ?? "",
          media: {
            status: "loading",
          },
        },
      });
    }
  }, [state.status, mediaQuery.data, mediaQuery.isError, mediaQuery.error]);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <StateContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useAdEditorState() {
  return useContext(StateContext);
}

export function useAdEditorSelectedAdState(): AdState {
  const state = useAdEditorState();
  if (state.status !== "loaded" || !state.availableAds.length) {
    throw new Error(
      "useAdEditorLoadedState must be used within a loaded state"
    );
  }
  return useMemo(() => {
    return state.availableAds[state.selectedAdIndex];
  }, [state.availableAds, state.selectedAdIndex]);
}

export function useAdEditorSelectedAdMediaState(): {
  media?: components["schemas"]["AdMediaSchema"];
  ad?: AdState;
} {
  const state = useAdEditorState();
  const ad = useMemo(() => {
    if (state.status !== "loaded") {
      return;
    }
    return state.availableAds[state.selectedAdIndex];
  }, [state]);

  const media = useMemo(() => {
    if (!ad || ad.media.status !== "loaded" || !ad.media.list.length) {
      return;
    }
    return ad.media.list[ad.media.selectedMediaIndex];
  }, [ad]);

  return { media, ad };
}

export function useAdEditorDispatch(): React.Dispatch<AdEditorActions> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useAdEditorDispatch must be used within a AdEditorProvider"
    );
  }
  return dispatch;
}
