import ColorPaletteModifier from "~/components/core/editor/sidepane/ColorPaletteModifier";
import {
  ColorPaletteSchema,
  useApplyPalette,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const BackgroundModifier = ({
  sectionId,
  palette,
}: {
  sectionId: string;
  palette: ColorPaletteSchema;
}) => {
  const applyPalette = useApplyPalette();

  return (
    <ColorPaletteModifier
      onClick={(palette) => {
        applyPalette(sectionId, palette, {
          backgroundColor: palette.accent_background,
          foregroundColor: palette.accent_foreground,
        });
      }}
      palette={palette}
      title="Palette"
    />
  );
};

export default BackgroundModifier;
