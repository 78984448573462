import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import { Box, Flex, Heading, Tooltip } from "@radix-ui/themes";
import { CSSProperties } from "styled-components";
import { QuestionMarkCircledIcon } from "~/assets/icons";

interface StyleLibraryCardContentProps {
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
  titleStyle?: CSSProperties;
  icon?: React.ReactNode;
  helperText?: string;
  isLoading?: boolean;
}

const StyleLibraryCardContent: React.FC<StyleLibraryCardContentProps> = ({
  actions,
  children,
  title,
  titleStyle,
  icon,
  helperText,
  isLoading,
}) => {
  return (
    <Flex direction="column" gap="5" width={"100%"}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex gap="3" align="center" style={titleStyle}>
          {icon}
          <Heading size="4">{title}</Heading>
          {helperText && (
            <Tooltip
              content={helperText}
              align="center"
              side="top"
              sideOffset={5}
            >
              <QuestionMarkCircledIcon size={20} />
            </Tooltip>
          )}
        </Flex>
        <Box
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {actions}
        </Box>
      </Box>
      {isLoading && <LoadingDots />}
      {!isLoading && children}
    </Flex>
  );
};

export default StyleLibraryCardContent;
