import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import KinLogo from "~/components/core/KinLogo";

const LogoWrapper = styled.div`
  position: absolute;
  top: 56px;
  left: 72px;
`;

const HighlightedText = styled(Text)`
  color: var(--primary-vibrant-orange);
`;

const Headline = styled(Text)`
  font-size: 77px;
  font-weight: 500;
  line-height: 97px;
`;

const CreateAccountHero = () => {
  return (
    <Flex width="100%" height="100%" align="center" justify="center" px="72px">
      <LogoWrapper>
        <KinLogo height="60px" />
      </LogoWrapper>
      <Headline size="9">
        Get your personal{" "}
        <HighlightedText as="span">chief marketing officer AI</HighlightedText>{" "}
      </Headline>
    </Flex>
  );
};

export default CreateAccountHero;
