import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { DislikeIcon, LikeIcon, PlusIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const DontsCardSection = () => {
  const handleAdd = () => {
    console.log("Edit");
  };

  return (
    <StyleLibraryCardContent
      title="Don'ts"
      titleStyle={{
        color: "var(--primary-vibrant-orange)",
      }}
      icon={<DislikeIcon />}
      helperText="TODO"
      actions={
        <AppButton onClick={handleAdd} variant="ghost" size="3">
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          Add Don't
        </AppButton>
      }
    >
      TODO
    </StyleLibraryCardContent>
  );
};
export default DontsCardSection;
