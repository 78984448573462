import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { HornIcon, PlusIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const OverallCharacteristicsCardSection = () => {
  const handleAdd = () => {
    console.log("Edit");
  };

  return (
    <StyleLibraryCardContent
      title="Overall Value Characteristics"
      icon={<HornIcon />}
      helperText="TODO"
      actions={
        <AppButton onClick={handleAdd} variant="ghost" size={"3"}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          New Characteristics
        </AppButton>
      }
    >
      TODO
    </StyleLibraryCardContent>
  );
};
export default OverallCharacteristicsCardSection;
