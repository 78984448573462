import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import { Flex } from "@radix-ui/themes";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isImageSection } from "~/utils/emails/useSectionTypeCheck";

const ImageView = () => {
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isImageSection(selectedSection)) {
    return null;
  }

  return (
    <Flex direction="column" gap="24px">
      <GeneratedVariantSelector />
      <ImageModifier
        enabled={selectedSection.image?.enabled ?? false}
        initialImageUrl={selectedSection.image?.image_url}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "image.enabled",
            value: enabled,
          });
        }}
        onAssetChange={(asset) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "image.image_url",
            value: asset.url,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </Flex>
  );
};

export default ImageView;
