import { Extension } from "@tiptap/react";

export const EscapeBlur = Extension.create({
  name: "EscapeBlur",
  addKeyboardShortcuts() {
    return {
      Escape: () => this.editor.commands.blur(),
    };
  },
});
