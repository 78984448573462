import { assertNever } from "../utils/typeUtils";
import { CampaignType, components } from "@openapi";
import { createContext, useReducer, useMemo, useContext } from "react";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

export enum RecommendedCampaignWizardSteps {
  SUMMARY = 0,
  PRODUCT,
  AUDIENCE,
  PROMOTION,
  CHANNEL,
  LANDING_DESTINATION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum NewCampaignWizardSteps {
  TYPE = 0,
  DETAILS,
  LANDING_DESTINATION,
  CUSTOM_ASSETS,
  CHANNEL,
  AUDIENCE,
  PRODUCT,
  PROMOTION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum ChannelType {
  EMAIL = "Email",
  META_ADS = "Meta Ads",
  // SMS,
  // PUSH_NOTIFICATIONS,
}

export type CampaignWizardSteps =
  | RecommendedCampaignWizardSteps
  | NewCampaignWizardSteps;

export const RecommendedCampaignWizardStepsLabels: Record<
  RecommendedCampaignWizardSteps,
  string
> = {
  [RecommendedCampaignWizardSteps.SUMMARY]: "Summary",
  [RecommendedCampaignWizardSteps.CHANNEL]: "Channel",
  [RecommendedCampaignWizardSteps.AUDIENCE]: "Audience",
  [RecommendedCampaignWizardSteps.PROMOTION]: "Promotion",
  [RecommendedCampaignWizardSteps.PRODUCT]: "Product",
  [RecommendedCampaignWizardSteps.LANDING_DESTINATION]: "Destination",
  [RecommendedCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [RecommendedCampaignWizardSteps.GENERATE]: "Generate",
};

export const NewCampaignWizardStepsLabels: Record<
  NewCampaignWizardSteps,
  string
> = {
  [NewCampaignWizardSteps.TYPE]: "Campaign type",
  [NewCampaignWizardSteps.CUSTOM_ASSETS]: "Custom assets",
  [NewCampaignWizardSteps.DETAILS]: "Campaign details",
  [NewCampaignWizardSteps.LANDING_DESTINATION]: "Landing destination",
  [NewCampaignWizardSteps.CHANNEL]: "Channel",
  [NewCampaignWizardSteps.AUDIENCE]: "Audience",
  [NewCampaignWizardSteps.PRODUCT]: "Product",
  [NewCampaignWizardSteps.PROMOTION]: "Promotion",
  [NewCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [NewCampaignWizardSteps.GENERATE]: "Generate",
};

type NewCampaignData = Partial<
  Omit<components["schemas"]["CampaignCreateDraftNewRequestData"], "products">
> & {
  products: MaybeFeaturedProduct[];
};
type RecommendedCampaignData = Partial<
  Omit<
    components["schemas"]["CampaignCreateDraftRecommendationRequestData"],
    "products"
  >
> & {
  products: MaybeFeaturedProduct[];
};

type CampaignData = NewCampaignData | RecommendedCampaignData;

interface CampaignWizardStateNew {
  wizardType: "NEW";
}

interface CampaignWizardStateRecommended {
  wizardType: "RECOMMENDED";
  recommendationID: string;
}

type CampaignWizardInterface = {
  /** Set as true after the first call to `SET_WIZARD_TYPE` */
  isWizardStateInitialized: boolean;
  isShowingMetaConnect: boolean;
  currentStep: CampaignWizardSteps;
  currentRequiredStep: CampaignWizardSteps;
  campaignData: CampaignData;
} & (CampaignWizardStateNew | CampaignWizardStateRecommended);

const initialState: CampaignWizardInterface = {
  isWizardStateInitialized: false,
  isShowingMetaConnect: false,
  currentStep: 0,
  currentRequiredStep: 0,
  wizardType: "NEW",
  campaignData: {
    asset_ids: [],
    audiences: [],
    campaign_type: CampaignType.other,
    destination: {},
    products: [],
    creative_id: "",
    brand_id: "",
    description_input: "",
  },
};

const isSameWizard = (
  wizard1: CampaignWizardStateNew | CampaignWizardStateRecommended,
  wizard2: CampaignWizardStateNew | CampaignWizardStateRecommended
) => {
  if (wizard1.wizardType !== wizard2.wizardType) {
    return false;
  }
  if (
    wizard1.wizardType === "RECOMMENDED" &&
    wizard2.wizardType === "RECOMMENDED"
  ) {
    return wizard1.recommendationID === wizard2.recommendationID;
  }
  return true;
};

interface SetCurrentstep {
  type: "SET_CURRENT_STEP";
  payload: {
    currentStep: CampaignWizardSteps;
  };
}

interface IncrementStep {
  type: "INCREMENT_STEP";
  payload?: number;
}

interface ResetRequiredStep {
  type: "RESET_REQUIRED_STEP";
}

interface SetWizardType {
  type: "SET_WIZARD_TYPE";
  payload: CampaignWizardStateNew | CampaignWizardStateRecommended;
}

interface PersistState {
  type: "PERSIST_STATE";
  payload: {
    isShowingMetaConnect?: boolean;
  };
}

interface ResetState {
  type: "RESET_STATE";
}

interface UpdateCampaignData {
  type: "UPDATE_CAMPAIGN_DATA";
  payload: Partial<CampaignData>;
}

export type CampaignWizardAction =
  | SetCurrentstep
  | IncrementStep
  | ResetRequiredStep
  | PersistState
  | ResetState
  | UpdateCampaignData
  | SetWizardType;

function reducer(
  state: CampaignWizardInterface,
  action: CampaignWizardAction
): CampaignWizardInterface {
  switch (action.type) {
    case "SET_WIZARD_TYPE":
      // restore presisted state, if any
      const wizardSession = sessionStorage.getItem("campaignWizardState");
      setTimeout(() => {
        sessionStorage.removeItem("campaignWizardState");
      }, 1000 * 60);
      let persistedState = wizardSession ? JSON.parse(wizardSession) : null;
      if (persistedState && isSameWizard(persistedState, action.payload)) {
        return {
          ...persistedState,
        };
      }

      return {
        ...state,
        ...action.payload,
        isWizardStateInitialized: true,
        currentRequiredStep:
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE,
        currentStep:
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE,
      };
    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload.currentStep,
        currentRequiredStep: Math.max(
          action.payload.currentStep,
          state.currentRequiredStep
        ),
      };
    case "INCREMENT_STEP":
      const nextStep = state.currentStep + (action.payload ?? 1);
      return {
        ...state,
        currentStep: nextStep,
        currentRequiredStep: Math.max(nextStep, state.currentRequiredStep),
      };
    case "RESET_REQUIRED_STEP":
      return {
        ...state,
        currentRequiredStep: state.currentStep,
      };
    case "UPDATE_CAMPAIGN_DATA":
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          ...action.payload,
        },
      };
    case "RESET_STATE":
      return initialState;
    case "PERSIST_STATE":
      const newState = {
        ...state,
        ...action.payload,
      };
      sessionStorage.setItem("campaignWizardState", JSON.stringify(newState));
      return newState;
    default:
      assertNever(action);
  }
}

const CampaginWizardContext =
  createContext<CampaignWizardInterface>(initialState);
const DispatchContext =
  createContext<React.Dispatch<CampaignWizardAction> | null>(null);

export const CampaignWizardProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <CampaginWizardContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </CampaginWizardContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useCampaignWizardState() {
  return useContext(CampaginWizardContext);
}

export function useCampaignWizardDispatch(): React.Dispatch<CampaignWizardAction> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useCampaignWizardDispatch must be used within a CampaignWizardProvider"
    );
  }
  return dispatch;
}

export function useCampaignWizardNextStep() {
  const dispatch = useCampaignWizardDispatch();
  const { currentStep } = useCampaignWizardState();
  return () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { currentStep: currentStep + 1 },
    });
  };
}

export function useCampaignWizardPreviousStep() {
  const dispatch = useCampaignWizardDispatch();
  const { currentStep } = useCampaignWizardState();
  return () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { currentStep: currentStep - 1 },
    });
  };
}
