import { EmailSectionWidthType, operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  getBrandEmailSectionsQueryKey,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";

type UpdateBrandEmailSectionsRequestData =
  operations["emails_api_update_brand_email_sections"]["requestBody"]["content"]["multipart/form-data"];

const useUpdateBrandEmailSections = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const state = useTemplateEditorState();
  const updateBrandEmailSections = useMutation<
    void,
    AxiosError,
    UpdateBrandEmailSectionsRequestData
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        `/api/v1/emails/update-brand-email-sections`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getBrandEmailSectionsQueryKey(activeBrandID),
        exact: true,
      });
      onSuccess?.();
    },
    onError: (error) => {
      console.log("Brand email sections update failed:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return {
    ...updateBrandEmailSections,
    mutate: () => {
      if (!state.headerOptions.logo) {
        onError?.("Please upload a logo");
        return;
      }

      const json_data: UpdateBrandEmailSectionsRequestData["request_data"] = {
        header: {
          id: state.headerOptions.id,
          type: state.headerOptions.type,
          palette: state.headerOptions.palette,
          template: state.headerOptions.template,
          width_type: EmailSectionWidthType.contained,
          logo: state.headerOptions.logo,
          menu: state.headerOptions.menu,
          custom_html: state.headerOptions.custom_html,
        },
        footer: {
          id: state.footerOptions.id,
          type: state.footerOptions.type,
          palette: state.footerOptions.palette,
          template: state.footerOptions.template,
          width_type: EmailSectionWidthType.contained,
          menu: state.footerOptions.menu,
          show_social_links: state.footerOptions.show_social_links,
          custom_html: state.footerOptions.custom_html,
          facebook_link: state.footerOptions.facebook_link,
          instagram_link: state.footerOptions.instagram_link,
          twitter_link: state.footerOptions.twitter_link,
          youtube_link: state.footerOptions.youtube_link,
          tiktok_link: state.footerOptions.tiktok_link,
        },
      };
      updateBrandEmailSections.mutate({
        request_data: JSON.stringify(
          json_data
        ) as unknown as UpdateBrandEmailSectionsRequestData["request_data"],
        header_logo: state.headerLogo as unknown as string,
      });
    },
  };
};

export default useUpdateBrandEmailSections;
