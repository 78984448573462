import HomeSection from "../../HomeSection";
import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import RecommenderCard from "../recommenderSection/RecommenderCard";
import { Grid } from "@radix-ui/themes";
import { useMemo } from "react";

const CampaignIdeasSection = ({}: {}) => {
  const { data, isLoading } = useGetCampaignRecommendations();

  const campaignIdeas = useMemo(() => {
    return data?.pages
      .flatMap((page) => page.campaign_recommendations)
      .slice(3);
  }, [data]);

  if (isLoading || !campaignIdeas?.length) {
    return null;
  }

  const content = (
    <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="6">
      {campaignIdeas.map((recommendation) => (
        <RecommenderCard
          key={recommendation.id}
          recommendation={recommendation}
          buttonVariant="outlined"
        />
      ))}
    </Grid>
  );

  return <HomeSection title="Other Campaign Ideas" content={content} />;
};

export default CampaignIdeasSection;
