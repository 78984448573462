import { Button, Table } from "@radix-ui/themes";
import { CircleCheck, CircleX } from "lucide-react";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";
import useInternBrandsQuery from "~/hooks/intern/useInternBrandsQuery";
import useInternCreateBrandAdminProfileMutation from "~/hooks/intern/useInternCreateBrandAdminProfileMutation";

const InternRootContainer = () => {
  const { user } = useCurrentUserState();

  const { brandsData, isLoading } = useInternBrandsQuery();
  const {
    mutate: createBrandAdminProfile,
    isPending: isCreatingBrandAdminProfile,
  } = useInternCreateBrandAdminProfileMutation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const brands = user.status === "LOGGED_IN" ? user.brands : [];

  return (
    <Table.Root
      style={{
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        padding: "16px",
        backgroundColor: "#fff",
      }}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Is Onboarded</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {brandsData?.map((brand) => (
          <Table.Row key={brand.id}>
            <Table.Cell>{brand.name}</Table.Cell>
            <Table.Cell>
              {brand.is_onboarded ? (
                <CircleCheck size={16} color="green" />
              ) : (
                <CircleX size={16} color="red" />
              )}
            </Table.Cell>
            <Table.Cell>
              {brands.find((b) => b.id === brand.id) ? null : (
                <Button
                  variant="outline"
                  size="1"
                  onClick={() => createBrandAdminProfile(brand.id!)}
                  disabled={isCreatingBrandAdminProfile}
                >
                  Become Admin
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default InternRootContainer;
