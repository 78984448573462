import { Avatar, Flex } from "@radix-ui/themes";
import markPng from "assets/mark.png";
import React from "react";

const MarkAvatar = (props: React.ComponentProps<typeof Flex>) => {
  return (
    <Flex direction="column" align="center" gap="6px" {...props}>
      <Avatar src={markPng} size="3" radius="full" fallback="M" />
    </Flex>
  );
};

export default MarkAvatar;
