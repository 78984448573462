import BrandAssetsCardSection from "~/components/style-library/assets/BrandAssetsCardSection";
import LogoAssetsCardSection from "~/components/style-library/assets/LogoAssetsCardSection";
import ButtonStylesCardSection from "~/components/style-library/button-styles/ButtonStylesCardSection";
import CampaignTemplateCardSection from "~/components/style-library/campaign-template/CampaignTemplateCardSection";
import ColorPaletteCardSection from "~/components/style-library/color-palette/ColorPaletteCardSection";
import TypographyCardSection from "~/components/style-library/typography/TypographyCardSection";
import { TemplateEditorProvider } from "~/contexts/TemplateEditorContext";

const sections: (() => JSX.Element)[] = [
  LogoAssetsCardSection,
  ColorPaletteCardSection,
  ButtonStylesCardSection,
  TypographyCardSection,
  BrandAssetsCardSection,
  () => (
    <TemplateEditorProvider>
      <CampaignTemplateCardSection />
    </TemplateEditorProvider>
  ),
];

export default sections;
