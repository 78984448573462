import { operations } from "../../../openapi";
import { BrandStylingResponse } from "../data/useBrandStyleQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type UpdateBrandColorPaletteResponse =
  operations["brand_api_update_color_palette"]["responses"][200]["content"]["application/json"];

type UpdateBrandColorPaletteRequestData =
  operations["brand_api_update_color_palette"]["requestBody"]["content"]["application/json"];

const useBrandColorPaletteUpdateMutation = ({
  brandID,
  onSuccess,
  onError,
}: {
  brandID: string;
  onSuccess?: (data: UpdateBrandColorPaletteResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  const updateBrandColorPalette = useDraperApiPostMutation<
    UpdateBrandColorPaletteResponse,
    UpdateBrandColorPaletteRequestData
  >({
    mutationKey: ["brand-color-palette-update"],
    path: `/brand/${brandID}/stylebook/color_palette`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);

      queryClient.setQueryData<BrandStylingResponse>(
        ["brand-style", brandID],
        (oldData) => {
          if (!oldData) return oldData;
          const updatedColorPalettes = oldData.color_palettes.map((palette) => {
            if (palette.id === data.id) {
              return {
                ...palette,
                background: data.background ?? palette.background,
                foreground: data.foreground ?? palette.foreground,
                accent_background:
                  data.accent_background ?? palette.accent_background,
                accent_foreground:
                  data.accent_foreground ?? palette.accent_foreground,
              };
            }
            return palette;
          });

          return {
            ...oldData,
            color_palettes: updatedColorPalettes,
          };
        }
      );
    },
  });

  return {
    updateBrandColorPalette: updateBrandColorPalette.mutate,
    isLoading: updateBrandColorPalette.isPending,
  };
};

export default useBrandColorPaletteUpdateMutation;
