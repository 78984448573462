import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import { useActiveBrandID } from "../../../../contexts/CurrentUserContext";
import { components } from "@openapi";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";

type CampaignRecommendationDestinationSchema =
  components["schemas"]["CampaignRecommendationDestinationSchema"];

export type LandingDestination = {
  title: string;
  url: string;
  id: string;
};

const useCampaignLandingDestinationProps = () => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType } = useCampaignWizardState();
  const activeBrandID = useActiveBrandID();

  const [isLandingDestinationEnabled, setIsLandingDestinationEnabled] =
    useState<boolean | null>(null);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [destination, setDestination] =
    useState<CampaignRecommendationDestinationSchema>({});

  const { recommendationID } = useParams();
  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    wizardType === "RECOMMENDED" ? recommendationID ?? null : null
  );

  useEffect(() => {
    if (wizardType === "RECOMMENDED") {
      if (campaignRecommendation?.destination) {
        setIsLandingDestinationEnabled(true);
        setDestination({
          collection_id: campaignRecommendation.destination.collection_id,
          name: campaignRecommendation.destination.name,
          url: campaignRecommendation.destination.url,
          type: campaignRecommendation.destination.type,
        });
      } else {
        setIsLandingDestinationEnabled(false);
      }
    }
  }, [campaignRecommendation?.destination, wizardType]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Landing Destination",
      nextButtonProps: {
        text: "Continue",
        disabled:
          isLandingDestinationEnabled === null ||
          (isLandingDestinationEnabled === true && !destination),
        validate: isLandingDestinationEnabled === true && !destination,
      },
    }),
    [destination, isLandingDestinationEnabled]
  );

  const { centerCurrentCard } = useCenterCampaignCard();

  useEffect(() => {
    centerCurrentCard();
  }, [isSelecting, destination, centerCurrentCard]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        destination,
      },
    });
  }, [destination, dispatch]);

  const inputProps = useMemo(
    () => ({
      isSelecting,
      setIsSelecting,
      destination,
      setDestination,
      isLandingDestinationEnabled,
      setIsLandingDestinationEnabled,
    }),
    [
      isSelecting,
      setIsSelecting,
      destination,
      setDestination,
      isLandingDestinationEnabled,
      setIsLandingDestinationEnabled,
    ]
  );

  return { cardProps, inputProps };
};

export default useCampaignLandingDestinationProps;
