import { EmailSectionType, operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type TemplateResponse =
  operations["emails_api_get_email_section_templates"]["responses"][200]["content"]["application/json"];

export const key = "emails/email-section-templates";

const useEmailSectionTemplatesQuery = (
  brandId: string,
  sectionType: EmailSectionType | null
) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [key, brandId, sectionType],
    queryFn: async (): Promise<TemplateResponse> => {
      const { data } = await axios.get(`/api/v1/${key}`, {
        params: { brand_id: brandId, section_type: sectionType },
      });
      return data;
    },
    enabled: !!sectionType,
  });
  return {
    templates: data?.templates ?? [],
    error: error,
    isLoading: isLoading,
  };
};
export default useEmailSectionTemplatesQuery;
