import CoreValuesCardSection from "~/components/style-library/voice/core-values";
import DosCardSection from "~/components/style-library/voice/do";
import DontsCardSection from "~/components/style-library/voice/donts";
import MissionStatementCardSection from "~/components/style-library/voice/mission-statement";
import OverallCharacteristicsCardSection from "~/components/style-library/voice/overall-characteristics";

const sections: (() => JSX.Element)[] = [
  MissionStatementCardSection,
  CoreValuesCardSection,
  OverallCharacteristicsCardSection,
  DosCardSection,
  DontsCardSection,
];

export default sections;
