import ModifierContainer from "./ModifierContainer";
import { ImageAssetCategory } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import { CropIcon, ImageIcon } from "~/assets/icons";
import AssetSelectorDialog, {
  AssetSelectorItem,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  padding: 16px;
  border: 1px dashed #ddd7d7;
  border-radius: 6px;
  background-color: var(--background_light_grey_2);
`;

const ImageModifier = ({
  initialImageUrl,
  onAssetChange,
}: {
  enabled: boolean;
  initialImageUrl?: string;
  onSwitchToggle: (enabled: boolean) => void;
  onAssetChange: (assets: AssetSelectorItem) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const { related_commerce_item_ids } = useEmailState();
  const dimensions = useGetImageDimensionsAndSize(initialImageUrl);
  const fileName = getFilenameFromUrl(initialImageUrl);

  const [isChangeImageDialogOpen, setIsChangeImageDialogOpen] = useState(false);
  const [isCropImageDialogOpen, setIsCropImageDialogOpen] = useState(false);

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useMediaBrandImagesUpload({
      data: {
        brandID: activeBrandID,
        category: ImageAssetCategory.other,
      },
      onSuccess: (data) => {
        onAssetChange?.({
          asset: data.new_assets[0],
          url: data.new_assets[0].url,
        });
      },
      onError: (error) => {
        // TODO
      },
    });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      images: [file as unknown as string],
    });
  };

  let contents = null;

  if (!initialImageUrl) {
    contents = (
      <Flex direction="column" gap="8px" justify={"start"}>
        <Text size={"3"}>Featured Image (optional)</Text>
        <AppButton
          onClick={() => setIsChangeImageDialogOpen(true)}
          variant="outlined"
          size="3"
          radius="large"
        >
          <ImageIcon size={16} />
          Add Image
        </AppButton>
      </Flex>
    );
  } else {
    contents = (
      <ModifierContainer title="Image" hideSwitch>
        <Flex gap="16px">
          <ImagePreview src={initialImageUrl} alt="logo" />

          <Flex direction="column" gap="12px">
            <DraperText clamp={2} size="2" color="gray">
              {fileName}
            </DraperText>
            <DraperText clamp={1} size="2" color="gray">
              {dimensions}
            </DraperText>
          </Flex>
        </Flex>
        <Grid columns="2" gap="12px">
          <AppButton
            disabled={isUploading}
            variant="outlined"
            size="3"
            radius="large"
            onClick={() => setIsChangeImageDialogOpen(true)}
          >
            <ImageIcon />
            Change
          </AppButton>
          <AppButton
            disabled={isUploading}
            variant="outlined"
            size="3"
            radius="large"
            onClick={() => setIsCropImageDialogOpen(true)}
          >
            <CropIcon />
            Crop
          </AppButton>
        </Grid>
        <CropImageDialog
          open={isCropImageDialogOpen}
          onOpenChange={setIsCropImageDialogOpen}
          img={initialImageUrl}
          onCrop={(data: string) => {
            const file = dataURIToFile(data, fileName ?? "cropped-image.png");
            handleUpload(file);
          }}
        />
      </ModifierContainer>
    );
  }
  const { productsData } = useShopifyProductsQuery(
    activeBrandID,
    related_commerce_item_ids
  );
  const relatedAssets = productsData
    ?.filter((product) => product.image_url)
    .map((product) => ({ url: product.image_url as string }));
  return (
    <>
      {contents}
      <AssetSelectorDialog
        singleAsset
        isDialogOpen={isChangeImageDialogOpen}
        onToggleDialogOpen={setIsChangeImageDialogOpen}
        onSelect={(assets) => onAssetChange(assets[0])}
        relatedAssets={relatedAssets}
        preselectedAssets={initialImageUrl ? [{ url: initialImageUrl }] : []}
      />
    </>
  );
};

export default ImageModifier;
