import { CampaignStatus } from "@openapi";
import {
  Badge,
  Code,
  DataList,
  Flex,
  IconButton,
  Separator,
  Text,
  Box,
  Grid,
  Table,
} from "@radix-ui/themes";
import { CopyIcon } from "lucide-react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import InternImageAssetCard from "~/components/intern/InternImageAssetCard";
import useInternCampaignsCampaignQuery from "~/hooks/intern/useInternCampaignsCampaignQuery";

const InternCampaignsCampaignContainer = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const { data } = useInternCampaignsCampaignQuery({
    campaignId: id,
  });
  return (
    <Flex gap="4" direction="column" gapY="4" width="100%">
      <Box>
        <DataList.Root>
          <DataList.Item align="center">
            <DataList.Label minWidth="88px">Status</DataList.Label>
            <DataList.Value>
              <Badge
                color={
                  data?.status === CampaignStatus.active ? "jade" : "yellow"
                }
                radius="full"
              >
                {data?.status}
              </Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">ID</DataList.Label>
            <DataList.Value>
              <Flex align="center" gap="2">
                <Code>{data?.id}</Code>
                <IconButton
                  size="1"
                  aria-label="Copy value"
                  color="gray"
                  variant="ghost"
                >
                  <CopyIcon />
                </IconButton>
              </Flex>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Type</DataList.Label>
            <DataList.Value>
              <Badge radius="full">{data?.type}</Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Destination</DataList.Label>
            <DataList.Value>
              <Flex align="center" gap="2">
                <Code>{data?.destination}</Code>
                <IconButton
                  size="1"
                  aria-label="Copy value"
                  color="gray"
                  variant="ghost"
                >
                  <CopyIcon />
                </IconButton>
              </Flex>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Destination Type</DataList.Label>
            <DataList.Value>
              <Badge radius="full">{data?.destination_type}</Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Emails</DataList.Label>
            <DataList.Value>
              {data?.email_creatives?.length ? (
                <Flex direction="column" gap="2">
                  {data.email_creatives.map((email) => (
                    <Box
                      key={email.id}
                      onClick={() =>
                        navigate(`/intern/campaign/${id}/email/${email.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Code>{email.id}</Code>
                    </Box>
                  ))}
                </Flex>
              ) : (
                <Text>No emails</Text>
              )}
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
      </Box>
      {data?.items?.length ? (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>IDs</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Assets</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.items.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Box>
                    <Flex align="center" gap="2">
                      <Code wrap={"nowrap"}>{item.id}</Code>
                      <IconButton
                        size="1"
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                      >
                        <CopyIcon />
                      </IconButton>
                    </Flex>
                  </Box>

                  <Box>
                    <Flex align="center" gap="2">
                      <Code>{item.commerce_platform_item_id}</Code>
                      <IconButton
                        size="1"
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                      >
                        <CopyIcon />
                      </IconButton>
                    </Flex>
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Grid
                    columns={{
                      initial: "1",
                      md: "2",
                      lg: "4",
                      xl: "6",
                    }}
                    gap="2"
                  >
                    {item.valid_assets.map((asset) => (
                      <InternImageAssetCard key={asset.id} asset={asset} />
                    ))}
                  </Grid>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      ) : (
        <Text>No items</Text>
      )}
      <Separator style={{ width: "100%" }} />
      <Box style={{ flex: 1 }}>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default InternCampaignsCampaignContainer;
