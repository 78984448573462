import { Button, Checkbox, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Calendar1Icon, PencilIcon } from "~/assets/icons";
import DraperPill from "~/components/core/DraperPill";
import { Audience } from "~/views/campaign/wizard/CampaignAudience";

const Root = styled.div<{ isAudienceMissing: boolean }>`
  border-radius: 12px;
  border: 1px solid var(--border-primary);
  width: 100%;
  flex-basis: 100%;
  display: flex;
  padding: 16px;
  background-color: var(--primary-white);
  box-sizing: border-box;
  ${({ isAudienceMissing }) =>
    isAudienceMissing &&
    `
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.2);
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img`
  height: 120px;
  height: 160px;
  object-fit: cover;
  background-color: var(--gray-5);
`;

const CampaignPublishDialogEmailCard = ({
  audiences,
  title,
  contentHeader,
  contentSubheader,
  publishedAt,
  emailId,
  campaignId,
  previewImage,
  onSelect,
  selected,
}: {
  onSelect?: () => void;
  audiences: Audience[];
  selected: boolean;
  campaignId: string;
  emailId: string;
  previewImage: string | null;
  title: string;
  contentHeader: string;
  contentSubheader: string;
  publishedAt: string | null;
}) => {
  const isAudienceMissing = audiences.length === 0;
  const audienceComponent = isAudienceMissing ? (
    <DraperPill backgroundColor="rgba(255, 0, 0, 0.2)">
      No audience selected
    </DraperPill>
  ) : (
    audiences.map((audience) => (
      <DraperPill key={audience.id}>{audience.name}</DraperPill>
    ))
  );
  return (
    <Root isAudienceMissing={isAudienceMissing}>
      <Flex width={"100%"} direction={"column"} gap="16px">
        <Header>
          <Text as="label" size="2">
            <Flex gap="2" align={"center"}>
              {onSelect && <Checkbox checked={selected} onClick={onSelect} />}
              <Text size="2" weight="bold">
                {title}
              </Text>
              {audienceComponent}
            </Flex>
          </Text>
          <Link to={`/campaign/${campaignId}/email/${emailId}`}>
            <Button size={"2"} radius="large" color="gray" variant="ghost">
              <PencilIcon size={16} /> Edit
            </Button>
          </Link>
        </Header>

        {publishedAt && (
          <Flex gap="2" align={"center"}>
            <Calendar1Icon size={16} />
            <Text size="2">{publishedAt}</Text>
          </Flex>
        )}

        <Flex direction={"row"} gap="16px">
          {!!previewImage ? (
            <Image src={previewImage} alt="Email image" />
          ) : (
            <Skeleton width="120px" height="160px" />
          )}

          <Flex direction={"column"}>
            <Text as="div" size="2" weight="bold">
              {contentHeader}
            </Text>
            <Text as="div" color="gray" size="2">
              {contentSubheader}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Root>
  );
};

export default CampaignPublishDialogEmailCard;
