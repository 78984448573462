import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import FeaturedProductModifier from "./modifiers/FeaturedProductModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isHeroProductSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const HeroProductView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const { mutate: initEmailSection } = useInitEmailSectionMutation();

  if (!selectedSection) {
    return null;
  }

  if (
    !isHeroProductSection(selectedSection) &&
    !isNewSection(selectedSection)
  ) {
    return null;
  }

  if (!isHeroProductSection(selectedSection)) {
    // Shouldnt get here as we generate directly in AddBlockView
    return null;
  }

  const image = selectedSection.image;

  const imageMod = (
    <ImageModifier
      enabled={image.enabled}
      initialImageUrl={image.image_url}
      onSwitchToggle={(enabled) => {
        updateSection({
          sectionId: selectedSection.id,
          field: "image.enabled",
          value: enabled,
        });
      }}
      onAssetChange={(asset) => {
        updateSection({
          sectionId: selectedSection.id,
          field: "image.image_url",
          value: asset.url,
        });
      }}
    />
  );

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const ctaButton = selectedSection.cta_button;
  return (
    <>
      <GeneratedVariantSelector />

      <FeaturedProductModifier
        onChangeProduct={(product) => {
          initEmailSection({
            brand_id: activeBrandId,
            section_type: EmailSectionType.hero_product,
            id: selectedSection.id,
            index: selectedSection.index,
            generate: false,
            product_ids: [product.product_id],
          });
        }}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />

      {imageMod}

      <ButtonModifier
        fieldName="cta_button"
        buttonElement={ctaButton}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value: enabled,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default HeroProductView;
