import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import { Editor } from "@tiptap/react";

export const isStrikethrough = (editor: Editor) => {
  const textDecoration =
    editor.getAttributes("textStyle")?.textDecoration || "";
  return textDecoration.includes("line-through");
};

export type StrikethroughOptions = {
  /**
   * The types where strikethrough can be applied
   * @default ['textStyle']
   * @example ['heading', 'paragraph']
   */
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    strikethrough: {
      /**
       * Set the text to strikethrough
       * @example editor.commands.setStrikethrough()
       */
      setStrikethrough: () => ReturnType;

      /**
       * Keeping this for typing since there's another editor that uses the base editor
       *
       */
      toggleStrikethrough: () => ReturnType;

      /**
       * Unset the strikethrough mark
       * @example editor.commands.unsetStrikethrough()
       */
      unsetStrikethrough: () => ReturnType;
    };
  }
}

/**
 * This extension allows you to use strikethrough text.
 * @see https://tiptap.dev/api/marks/strike
 */
const Strikethrough = Extension.create<StrikethroughOptions>({
  name: "strikethrough",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textDecoration: {
            default: null,
            parseHTML: (element) => element.style.textDecoration || null,
            renderHTML: (attributes) => {
              if (!attributes.textDecoration) {
                return {};
              }

              return {
                style: `text-decoration: ${attributes.textDecoration}`,
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setStrikethrough:
        () =>
        ({ chain, editor }) => {
          const currentDecoration =
            editor.getAttributes("textStyle").textDecoration?.trim() || "";
          if (currentDecoration.includes("line-through")) {
            return chain().run();
          }
          const decorations = currentDecoration.split(" ");
          decorations.push("line-through");
          return chain()
            .setMark("textStyle", { textDecoration: decorations.join(" ") })
            .run();
        },
      unsetStrikethrough:
        () =>
        ({ chain, editor }) => {
          const currentDecoration =
            editor.getAttributes("textStyle").textDecoration || "";
          const decorations = currentDecoration.includes("line-through")
            ? currentDecoration.replace("line-through", "").trim()
            : currentDecoration;
          return chain()
            .setMark("textStyle", {
              textDecoration: decorations || null,
            })
            .removeEmptyTextStyle()
            .run();
        },
      toggleStrikethrough:
        () =>
        ({ commands }) => {
          return isStrikethrough(this.editor)
            ? commands.unsetStrikethrough()
            : commands.setStrikethrough();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-x": () => this.editor.commands.toggleStrikethrough(),
    };
  },
});

export default Strikethrough;
