import { Text } from "@radix-ui/themes";
import { useParams } from "react-router-dom";
import { AdMediaContextProvider } from "~/components/ads/AdMediaContext";
import { AdEditorComponents } from "~/components/ads/editor";
import { AdEditorProvider } from "~/contexts/AdEditorContext";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";

const AdEditorPage = () => {
  const { id } = useParams();
  if (!id) {
    return <Text>Invalid URL</Text>;
  }

  return (
    // used for custom fonts
    <BrandStylingProvider>
      <AdEditorProvider adCreativeId={id}>
        <AdMediaContextProvider>
          <AdEditorComponents.Layout>
            <AdEditorComponents.TopBar />
            <AdEditorComponents.Preview />
            <AdEditorComponents.SidePanel />
          </AdEditorComponents.Layout>
        </AdMediaContextProvider>
      </AdEditorProvider>
    </BrandStylingProvider>
  );
};

export default AdEditorPage;
