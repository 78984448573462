import { useAuthenticatedUserState } from "../../../contexts/CurrentUserContext";
import {
  operations,
  ShopifyDiscountType,
  ShopifyDiscountValueType,
} from "@openapi";
import {
  Button,
  Container,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

type CreateCampaignQuickDraftParams =
  operations["campaign_api_create_quick_draft"]["requestBody"]["content"]["application/json"];

type CreateCampaignQuickDraftApiResponse =
  operations["campaign_api_create_quick_draft"]["responses"][200]["content"]["application/json"];

type CampaignRecommendationsResponse =
  operations["recommender_api_get_brand_campaign_recommendations"]["responses"][200]["content"]["application/json"];

interface InternCampaignQuickDraftCreationDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  onCreate: () => void;
}

const InternCampaignQuickDraftCreationDialog: React.FC<
  InternCampaignQuickDraftCreationDialogProps
> = ({ isDialogOpen, setIsDialogOpen, onCreate }) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const [
    selectedCampaignRecommendationID,
    setSelectedCampaignRecommendationID,
  ] = useState<string | null>(null);

  const [adCreativeCount, setAdCreativeCount] = useState<number>(0);

  const [emailCreativeCount, setEmailCreativeCount] = useState<number>(0);

  const [smsCreativeCount, setSmsCreativeCount] = useState<number>(0);

  const [pushNotificationCreativeCount, setPushNotificationCreativeCount] =
    useState<number>(0);

  const creativeCountOptions = Array.from({ length: 6 }, (_, i) =>
    i.toString()
  );

  const [discountType, setDiscountType] = useState<ShopifyDiscountType | null>(
    null
  );

  const [discountValueType, setDiscountValueType] =
    useState<ShopifyDiscountValueType>(ShopifyDiscountValueType.percentage);

  const [discountAmount, setDiscountAmount] = useState<string>("0.0");

  const [discountCode, setDiscountCode] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { data: campaignRecommendations } = useQuery({
    queryKey: ["brand-campaign-recommendations", activeBrandID],
    queryFn: async (): Promise<CampaignRecommendationsResponse> => {
      const { data } = await axios.get(
        "/api/v1/recommender/brand-campaign-recommendations",
        {
          params: {
            brand_id: activeBrandID,
          },
        }
      );
      return data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: 1000 * 60 * 60,
  });

  const mutation = useMutation<
    CreateCampaignQuickDraftApiResponse,
    Error,
    CreateCampaignQuickDraftParams
  >({
    mutationFn: async (
      params: CreateCampaignQuickDraftParams
    ): Promise<CreateCampaignQuickDraftApiResponse> => {
      setIsLoading(true);
      const { data } = await axios.post(
        `/api/v1/campaign/create-quick-draft`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setIsDialogOpen(false);
      alert("Campaign created successfully!");
      onCreate();
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error);
      console.error("Error creating campaign:", error);
    },
  });

  const handleCreateClick = () => {
    if (
      !selectedCampaignRecommendationID ||
      selectedCampaignRecommendationID.length === 0
    ) {
      alert("Campaign recommendation selection is required.");
      return;
    }

    const creatives =
      adCreativeCount > 0 ||
      emailCreativeCount > 0 ||
      smsCreativeCount > 0 ||
      pushNotificationCreativeCount > 0;

    if (!creatives) {
      alert("Specify at least one channel creatives to generate.");
      return;
    }

    var discount = null;

    if (discountType !== null) {
      let discountAmountNum = parseFloat(discountAmount);
      if (isNaN(discountAmountNum)) {
        alert("Enter a valid number for the discount amount.");
        return;
      }
      if (discountAmountNum < 1) {
        alert("Enter a valid number for the discount amount.");
        return;
      }
      if (
        discountValueType === ShopifyDiscountValueType.percentage &&
        discountAmountNum > 99
      ) {
        alert("Enter a valid number for the discount amount.");
        return;
      }
      discount = {
        type: discountType,
        value_type: discountValueType,
        amount: discountAmountNum,
        code: discountCode,
      };
    }

    mutation.mutate({
      brand_id: activeBrandID,
      campaign_recommendation_id: selectedCampaignRecommendationID,
      ad_creatives: adCreativeCount,
      email_creatives: emailCreativeCount,
      sms_creatives: smsCreativeCount,
      push_notifications: pushNotificationCreativeCount,
      discount: discount,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Quick Generate Campaign</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Quickly enerate a campaign with the selected campaign
            recommendation. This will also generate a campaign brief, ad, email,
            and sms creatives.
          </Dialog.Description>
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="1" mb="1" weight="bold">
                Campaign Recommendation
              </Text>
              <Select.Root
                defaultValue="none"
                onValueChange={(value) => {
                  if (value === "none") {
                    setSelectedCampaignRecommendationID(null);
                    return;
                  }
                  setSelectedCampaignRecommendationID(value);
                }}
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    <Select.Item key={"none"} value={"none"}>
                      {"---"}
                    </Select.Item>
                    {campaignRecommendations?.campaign_recommendations.map(
                      (recommendation) => (
                        <Select.Item
                          key={recommendation.id}
                          value={recommendation.id}
                        >
                          {recommendation.creative_recommendations[0]?.title ??
                            recommendation.summary ??
                            recommendation.id}
                        </Select.Item>
                      )
                    )}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </label>
            <label>
              <Text as="div" size="1" mb="1" weight="bold">
                Discount
              </Text>
              <Select.Root
                defaultValue="none"
                onValueChange={(value) => {
                  if (value === "none") {
                    setDiscountType(null);
                    return;
                  }
                  setDiscountType(value as ShopifyDiscountType);
                }}
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    <Select.Item value="none">{"---"}</Select.Item>
                    <Select.Item value={ShopifyDiscountType.coupon}>
                      {"Coupon"}
                    </Select.Item>
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </label>
            {discountType !== null && (
              <label>
                <Flex gap="2">
                  <TextField.Root
                    defaultValue={discountAmount}
                    placeholder="Amount"
                    onChange={(event) => setDiscountAmount(event.target.value)}
                    style={{ width: "80px" }}
                  />
                  <Select.Root
                    defaultValue={ShopifyDiscountValueType.percentage}
                    onValueChange={(value) =>
                      setDiscountValueType(value as ShopifyDiscountValueType)
                    }
                  >
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Group>
                        <Select.Item
                          value={ShopifyDiscountValueType.percentage}
                        >
                          {"%"}
                        </Select.Item>
                        <Select.Item
                          value={ShopifyDiscountValueType.fixed_amount}
                        >
                          {"$"}
                        </Select.Item>
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                  <TextField.Root
                    placeholder="Code (optional)"
                    onChange={(event) => setDiscountCode(event.target.value)}
                  />
                </Flex>
              </label>
            )}
            <Flex gap="5">
              <label>
                <Text as="div" size="1" mb="1" weight="bold">
                  Ads
                </Text>
                <Select.Root
                  defaultValue="0"
                  onValueChange={(value) => setAdCreativeCount(Number(value))}
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {creativeCountOptions.map((option) => (
                        <Select.Item key={option} value={option}>
                          {option}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </label>
              <label>
                <Text as="div" size="1" mb="1" weight="bold">
                  Emails
                </Text>
                <Select.Root
                  defaultValue="0"
                  onValueChange={(value) =>
                    setEmailCreativeCount(Number(value))
                  }
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {creativeCountOptions.map((option) => (
                        <Select.Item key={option} value={option}>
                          {option}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </label>
              <label>
                <Text as="div" size="1" mb="1" weight="bold">
                  SMS
                </Text>
                <Select.Root
                  defaultValue="0"
                  onValueChange={(value) => setSmsCreativeCount(Number(value))}
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {creativeCountOptions.map((option) => (
                        <Select.Item key={option} value={option}>
                          {option}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </label>
              <label>
                <Text as="div" size="1" mb="1" weight="bold">
                  Push Notifs
                </Text>
                <Select.Root
                  defaultValue="0"
                  onValueChange={(value) =>
                    setPushNotificationCreativeCount(Number(value))
                  }
                >
                  <Select.Trigger />
                  <Select.Content>
                    <Select.Group>
                      {creativeCountOptions.map((option) => (
                        <Select.Item key={option} value={option}>
                          {option}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </label>
            </Flex>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleCreateClick} loading={isLoading}>
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternCampaignQuickDraftCreationDialog;
