import MarkAvatar from "../core/avatars/MarkAvatar";
import { Flex, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const ChatBubble = styled.div`
  border: 1px solid var(--border-primary);
  border-radius: 6px 24px 24px 24px;
  width: 100%;
  padding: 20px;
  background: var(--primary-white);
  box-shadow: 0px 12px 48px 0px rgba(0, 0, 0, 0.08);
`;

const ChatMessageContainer = ({
  message,
}: {
  message: string | React.ReactNode;
}) => {
  return (
    <Flex direction={"row"} gap="8px" align={"start"}>
      <MarkAvatar />
      <ChatBubble>
        <Text weight="bold" size="4">
          {message}
        </Text>
      </ChatBubble>
    </Flex>
  );
};

export default ChatMessageContainer;
