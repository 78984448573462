import { Extension } from "@tiptap/core";
import { Editor } from "@tiptap/react";

export const isBold = (editor: Editor) =>
  editor.getAttributes("textStyle")?.fontWeight === "bold" ||
  editor.getAttributes("textStyle")?.fontWeight === "700";

export interface BoldOptions {
  /**
   * HTML attributes to add to the bold element.
   * @default {}
   * @example { class: 'foo' }
   */
  types: string[];
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    bold: {
      /**
       * Set a bold mark
       */
      setBold: () => ReturnType;
      /**
       * Toggle a bold mark
       * Need this for typing purposes
       */
      toggleBold: () => ReturnType;
      /**
       * Unset a bold mark
       */
      unsetBold: () => ReturnType;
    };
  }
}

/**
 * Matches bold text via `**` as input.
 */
export const starInputRegex =
  /(?:^|\s)(\*\*(?!\s+\*\*)((?:[^*]+))\*\*(?!\s+\*\*))$/;

/**
 * Matches bold text via `**` while pasting.
 */
export const starPasteRegex =
  /(?:^|\s)(\*\*(?!\s+\*\*)((?:[^*]+))\*\*(?!\s+\*\*))/g;

/**
 * Matches bold text via `__` as input.
 */
export const underscoreInputRegex =
  /(?:^|\s)(__(?!\s+__)((?:[^_]+))__(?!\s+__))$/;

/**
 * Matches bold text via `__` while pasting.
 */
export const underscorePasteRegex =
  /(?:^|\s)(__(?!\s+__)((?:[^_]+))__(?!\s+__))/g;

/**
 * This extension allows you to mark text as bold.
 * @see https://tiptap.dev/api/marks/bold
 */
const Bold = Extension.create<BoldOptions>({
  name: "bold",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ["textStyle"],
        attributes: {
          fontWeight: {
            default: null,
            parseHTML: (element) => element.style.fontWeight,
            renderHTML: (attributes) => {
              if (!attributes.fontWeight) {
                return {};
              }
              return {
                style: `font-weight: ${attributes.fontWeight}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBold:
        () =>
        ({ chain }) => {
          return chain().setMark("textStyle", { fontWeight: "bold" }).run();
        },
      unsetBold:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontWeight: null })
            .removeEmptyTextStyle()
            .run();
        },
      toggleBold:
        () =>
        ({ commands }) => {
          return isBold(this.editor)
            ? commands.unsetBold()
            : commands.setBold();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-b": () => this.editor.commands.toggleBold(),
    };
  },
});

export default Bold;
