import { SegmentedControl } from "@radix-ui/themes";
import styled, { CSSProperties } from "styled-components";

const DraperSegmentControl = styled(SegmentedControl.Root)<{
  $size?: "medium" | "large";
  $buttonWidth?: CSSProperties["width"];
}>`
  height: ${(props) => (props.$size === "large" ? "52px" : "44px")};
  border-radius: ${(props) => (props.$size === "large" ? "12px" : "9px")};
  color: var(--text-tertiary);
  & > .rt-SegmentedControlIndicator::before {
    background-color: white;
    border-radius: ${(props) => (props.$size === "large" ? "10px" : "7px")};
    margin: ${(props) => (props.$size === "large" ? "6px" : "4px")};
    box-shadow: 0px 2px 4px 0px #0000001f;
  }
  .rt-SegmentedControlItemSeparator {
    display: none;
  }
  & > button[aria-checked="true"] {
    color: var(--icon-primary-active);
  }
  ${(props) =>
    props.$buttonWidth &&
    `
    & > button {
      width: ${props.$buttonWidth};
      & > span {
        width: 100%;
        justify-content: center;
        padding: 0;
    }
  `}
`;

export default DraperSegmentControl;
