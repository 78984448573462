import FormErrorText from "../FormErrorText";
import SignInLink from "../SignInLink";
import * as Form from "@radix-ui/react-form";
import {
  Box,
  Flex,
  Heading,
  Link,
  Separator,
  Text,
  TextField,
} from "@radix-ui/themes";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Panel = styled(Flex)`
  background-color: var(--primary-white);
  border: 1px solid var(--border-primary);
  border-radius: 24px;
  padding: 24px 0px;
  box-shadow: 0px 20px 45px 0px #00000000;
  box-shadow: 0px 82px 82px 0px #0000000d;
  box-shadow: 0px 184px 110px 0px #0000000a;
  box-shadow: 0px 326px 131px 0px #00000003;
  box-shadow: 0px 510px 143px 0px #00000000;
  max-width: 480px;
`;

const SeparatorFlex = styled(Separator)`
  display: flex;
  width: 100%;
`;

const Label = styled(Text)`
  color: var(--text-secondary);
`;
const StyledLink = styled(Link)`
  color: var(--text-link);
  font-weight: 400;
`;

const CreateAccountPanel = () => {
  const [fullNameErrors, setFullNameErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [passwordErrors, setPasswordErrors] = useState("");
  const [password2Errors, setPassword2Errors] = useState("");
  const [serverErrors, setServerErrors] = useState("");

  const navigate = useNavigate();
  const search = useLocation().search;

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          navigate("/");
        }
      });
  }, [navigate]);

  const onSubmit = (data: FormData) => {
    // TODO: typing for FormData
    const values = Object.fromEntries(data);
    const [first_name, last_name] = String(values["fullName"]).split(" ");
    if (!first_name || !last_name) {
      setFullNameErrors("Please enter your first and last name.");
      return;
    } else {
      setFullNameErrors("");
    }
    const signupData = {
      first_name,
      last_name,
      email: values["email"],
      password1: values["password"],
      password2: values["confirmPassword"],
      registration_token: new URLSearchParams(search).get("register-token"),
    };

    fetch("/api/v1/user/auth/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify(signupData),
    })
      .then((res) => res.json())
      .then((data) => {
        setFullNameErrors("");
        setEmailErrors("");
        setPasswordErrors("");
        setPassword2Errors("");
        setServerErrors("");
        if ("detail" in data) {
          setServerErrors(data.detail);
          setTimeout(function () {
            navigate("/verify-email", { state: { email: values["email"] } });
          }, 2000);
        } else {
          if ("full_name" in data) {
            const [first_name, last_name] = String(values["fullName"]).split(
              " "
            );
            if (!first_name || !last_name) {
              setFullNameErrors("Please enter your first and last name.");
            }
            setFullNameErrors(data.full_name.join(","));
          }

          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("password1" in data) {
            setPasswordErrors(data.password1.join(","));
          }

          if ("password2" in data) {
            setPassword2Errors(data.password2.join(","));
          }

          if ("non_field_errors" in data) {
            setServerErrors(data.non_field_errors.join(","));
          }

          if ("registration_token" in data) {
            setServerErrors("Invalid registration token.");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setServerErrors("An error occurred. Please try again later.");
      });
  };

  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <Panel>
        <Form.Root
          onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            onSubmit(data);
          }}
          onClearServerErrors={() => setServerErrors("")}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Flex
            direction={"column"}
            px={"40px"}
            justify={"center"}
            gap={"1rem"}
            width={"100%"}
            align={"stretch"}
          >
            <Text weight={"bold"} mt="22px" size={"8"}>
              Create Account
            </Text>
            {serverErrors ? (
              <Box>
                <Heading mb="1" size="2" color="red">
                  {serverErrors}
                </Heading>
              </Box>
            ) : (
              <></>
            )}
            <Form.Field name="fullName" autoFocus>
              <Form.Label>
                <Label size={"1"}>Full Name</Label>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  size="3"
                  radius="large"
                  type="text"
                  required
                  autoComplete="given-name"
                />
              </Form.Control>
              <Form.Message match="valueMissing">
                <FormErrorText>Please enter your name.</FormErrorText>
              </Form.Message>
              {fullNameErrors && (
                <Form.Message>
                  <FormErrorText>{fullNameErrors}</FormErrorText>
                </Form.Message>
              )}
              <Form.Message match="typeMismatch">
                <FormErrorText>{fullNameErrors}</FormErrorText>
              </Form.Message>
            </Form.Field>

            <Form.Field name="email">
              <Form.Label>
                <Label size={"1"}>Email</Label>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  size="3"
                  radius="large"
                  type="email"
                  required
                  autoComplete="email"
                />
              </Form.Control>
              <Form.Message match="valueMissing">
                <FormErrorText>Please enter your email.</FormErrorText>
              </Form.Message>
              <Form.Message match="typeMismatch">
                <FormErrorText>Please provide a valid email</FormErrorText>
              </Form.Message>
              <Form.Message match="typeMismatch" forceMatch={!!emailErrors}>
                <FormErrorText>{emailErrors}</FormErrorText>
              </Form.Message>
            </Form.Field>

            <Form.Field name="password">
              <Form.Label>
                <Label size={"1"}>Password</Label>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  size="3"
                  radius="large"
                  required
                  name="password"
                  type="password"
                  minLength={8}
                  autoComplete="new-password-1"
                />
              </Form.Control>
              <Form.Message match="valueMissing">
                <FormErrorText>Please enter your password.</FormErrorText>
              </Form.Message>
              <Form.Message match="typeMismatch" forceMatch={!!passwordErrors}>
                <FormErrorText>{passwordErrors}</FormErrorText>
              </Form.Message>
              <Form.Message match="tooShort">
                <FormErrorText>
                  Password must be at least 8 characters
                </FormErrorText>
              </Form.Message>
            </Form.Field>

            <Form.Field name="confirmPassword">
              <Form.Label>
                <Label size={"1"}>Confirm Password</Label>
              </Form.Label>
              <Form.Control asChild>
                <TextField.Root
                  size="3"
                  radius="large"
                  required
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password-2"
                />
              </Form.Control>
              <Form.Message match="valueMissing">
                <FormErrorText>Please re-enter your password.</FormErrorText>
              </Form.Message>
              <Form.Message match="typeMismatch" forceMatch={!!password2Errors}>
                <FormErrorText>{password2Errors}</FormErrorText>
              </Form.Message>
              <Form.Message
                match={(value, formData) => value !== formData.get("password")}
              >
                <FormErrorText>Passwords don't match</FormErrorText>
              </Form.Message>
            </Form.Field>

            <Form.Submit asChild>
              <AppButton>
                <Flex justify="between" align="center" width="100%">
                  <Text weight="regular">Create account</Text>
                  <ArrowRightIcon size={24} />
                </Flex>
              </AppButton>
            </Form.Submit>

            {/* <Flex dir="row" align={"center"} gap={"2"} width={"100%"}>
          <SeparatorFlex />
          <Text size={"1"}>or</Text>
          <SeparatorFlex />
        </Flex>

        <Button variant="outline" size={"4"} color="gray">
          <GoogleIcon>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                display: "block",
              }}
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </GoogleIcon>
          <Text size={"3"}>Sign up with Google</Text>
        </Button> */}
            <Box
              style={{
                textAlign: "center",
                color: "var(--text-tertiary)",
              }}
              mb={"4"}
            >
              <Text size={"2"}>
                Already have an account? <SignInLink />
              </Text>
            </Box>
          </Flex>
          <SeparatorFlex />
          <Flex
            mt={"24px"}
            direction={"column"}
            px={"40px"}
            justify={"center"}
            gap={"1rem"}
            width={"100%"}
            align={"stretch"}
          >
            <Box
              style={{
                textAlign: "center",
                color: "var(--text-tertiary)",
              }}
            >
              <Text size={"1"}>
                By submitting this form you agree with{" "}
                <StyledLink href="" underline="hover" onClick={() => {}}>
                  Terms of Use
                </StyledLink>{" "}
                and{" "}
                <StyledLink
                  href="https://www.kincommerce.com/privacy"
                  underline="hover"
                  target="_blank"
                >
                  Privacy Policy
                </StyledLink>
              </Text>
            </Box>
          </Flex>
        </Form.Root>
      </Panel>
    </Flex>
  );
};

export default CreateAccountPanel;
