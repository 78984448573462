import { Loader2 } from "lucide-react";
import { styled, keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled(Loader2)`
  animation: ${spin} 1s linear infinite;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinningLoader = () => {
  return (
    <LoaderContainer>
      <StyledLoader size={24} />
    </LoaderContainer>
  );
};

export default SpinningLoader;
