import { EmailSectionImageElementState } from "./template-editor";

export const getEmailStateImageUrl = (
  image: EmailSectionImageElementState | undefined
): string | undefined => {
  if (!image) return undefined;
  if ("lastModified" in image) {
    return URL.createObjectURL(image);
  }
  if ("image_url" in image) {
    return image.image_url;
  }
  return undefined;
};
