import CampaignCardContent from "./CampaignCardContent";
import CampaignCardHeader from "./CampaignCardHeader";
import { components, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";

const Card = styled(Flex)`
  border-radius: 12px;
  border: 1px solid var(--border);
  transition: height 0.3s;
  background-color: var(--primary-white);
`;

const Transition = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
`;

type CampaignType = components["schemas"]["CampaignListSchema"];
type EmailMetricType =
  operations["cdp_api_klaviyo_metrics"]["responses"][200]["content"]["application/json"];
type AdMetricType =
  operations["meta_api_get_ad_analytics"]["responses"][200]["content"]["application/json"];

const CampaignCard = ({
  campaign,
  emailMetrics,
  adMetrics,
}: {
  campaign: CampaignType;
  emailMetrics: EmailMetricType;
  adMetrics: Partial<AdMetricType>;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const earliestPublishedDate = [
    ...campaign.email_assets,
    // ...campaign.ad_assets, # TODO: no published_at field on ads yet
  ]
    .filter((asset) => asset.published_at !== null)
    .reduce<string | null>((earliest, asset) => {
      if (
        !earliest ||
        (asset.published_at &&
          new Date(asset.published_at) < new Date(earliest))
      ) {
        return asset.published_at;
      }
      return earliest;
    }, null);

  return (
    <Flex direction="column" gap="12px" width="100%">
      <Text weight="medium">
        {campaign.status === "active" ? "Started on " : ""}{" "}
        {earliestPublishedDate}
      </Text>
      <Card direction="column">
        <CampaignCardHeader
          isExpanded={isExpanded}
          onExpandChange={() => setIsExpanded(!isExpanded)}
          campaign={campaign}
        />
        <Transition style={{ maxHeight: isExpanded ? "3000px" : "0px" }}>
          <CampaignCardContent
            campaign={campaign}
            emailMetrics={emailMetrics}
            adMetrics={adMetrics}
          />
        </Transition>
      </Card>
    </Flex>
  );
};

export default CampaignCard;
