import MarkAvatar from "../../avatars/MarkAvatar";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
  line-height: 1.2;
  & > .rt-Text {
    line-height: 1.2;
  }
`;

const MarkMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex gap="16px">
      <MarkAvatar />
      <TextContainer>{children}</TextContainer>
    </Flex>
  );
};

export default MarkMessage;
