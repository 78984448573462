import AppButton from "../core/buttons/AppButton/AppButton";
import AuthPageBase from "./AuthPageBase";
import FormErrorText from "./FormErrorText";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Heading, Text, TextField } from "@radix-ui/themes";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

enum Status {
  Initial,
  Loading,
  Complete,
}

const ConfirmResetPassword = () => {
  const navigate = useNavigate();
  const search = useLocation().search;

  const [saveState, setSaveState] = useState(Status.Initial);
  const [serverErrors, setServerErrors] = useState("");
  const [newPassword1Errors, setNewPassword1Errors] = useState("");
  const [newPassword2Errors, setNewPassword2Errors] = useState("");

  const onSubmit = (data: FormData) => {
    setSaveState(Status.Loading);
    const values = Object.fromEntries(data);
    const password1 = values["password"];
    const password2 = values["confirmPassword"];

    const urlParams = new URLSearchParams(search);

    const user = {
      token: urlParams.get("token"),
      uid: urlParams.get("uid"),
      new_password1: password1,
      new_password2: password2,
    };

    fetch("/api/v1/user/auth/password/reset/confirm/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        setNewPassword1Errors("");
        setNewPassword2Errors("");
        setServerErrors("");

        if ("detail" in data) {
          setServerErrors(data.detail);
          setSaveState(Status.Complete);

          setTimeout(function () {
            navigate("/login");
          }, 1000);
        } else {
          if ("uid" in data) {
            setServerErrors("Invalid reset token.");
          }
          if ("token" in data) {
            setServerErrors("Invalid reset token.");
          }
          if ("new_password1" in data) {
            setNewPassword1Errors(data.new_password1.join(","));
          }

          if ("new_password2" in data) {
            setNewPassword2Errors(data.new_password2.join(","));
          }

          if ("non_field_errors" in data) {
            setServerErrors(data.non_field_errors.join(","));
          }
          setSaveState(0);
        }
      });
  };
  const content = (
    <Form.Root
      onSubmit={(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        onSubmit(data);
      }}
      onClearServerErrors={() => setServerErrors("")}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
        width: "480px",
      }}
    >
      <Flex
        direction={"column"}
        justify={"center"}
        gap={"24px"}
        align={"stretch"}
        p="40px"
      >
        <Text align="center" weight={"bold"} size={"7"}>
          Reset your Password
        </Text>
        {serverErrors ? (
          <Box>
            <Heading
              mb="1"
              size="2"
              color={saveState === Status.Complete ? "gray" : "red"}
            >
              {serverErrors}
            </Heading>
          </Box>
        ) : (
          <></>
        )}
        <Text size="5">
          Enter a new password below to change your password.
        </Text>
        <Form.Field name="password">
          <Form.Label>
            <Text size={"1"}>New Password</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextField.Root
              size="3"
              radius="large"
              required
              name="password"
              type="password"
              minLength={8}
              autoComplete="new-password-1"
            />
          </Form.Control>
          <Form.Message match="valueMissing">
            <FormErrorText>Please enter your password.</FormErrorText>
          </Form.Message>
          <Form.Message match="typeMismatch" forceMatch={!!newPassword1Errors}>
            <FormErrorText>{newPassword1Errors}</FormErrorText>
          </Form.Message>
          <Form.Message match="tooShort">
            <FormErrorText>
              Password must be at least 8 characters
            </FormErrorText>
          </Form.Message>
        </Form.Field>

        <Form.Field name="confirmPassword">
          <Form.Label>
            <Text size={"1"}>Confirm Password</Text>
          </Form.Label>
          <Form.Control asChild>
            <TextField.Root
              size="3"
              radius="large"
              required
              name="confirmPassword"
              type="password"
              autoComplete="new-password-2"
            />
          </Form.Control>
          <Form.Message match="valueMissing">
            <FormErrorText>Please re-enter your password.</FormErrorText>
          </Form.Message>
          <Form.Message match="typeMismatch" forceMatch={!!newPassword2Errors}>
            <FormErrorText>{newPassword2Errors}</FormErrorText>
          </Form.Message>
          <Form.Message
            match={(value, formData) => value !== formData.get("password")}
          >
            <FormErrorText>Passwords don't match</FormErrorText>
          </Form.Message>
        </Form.Field>

        <Form.Submit asChild>
          <AppButton disabled={saveState === Status.Loading} radius="large">
            Reset Password
          </AppButton>
        </Form.Submit>
      </Flex>
    </Form.Root>
  );

  return <AuthPageBase>{content}</AuthPageBase>;
};

export default ConfirmResetPassword;
