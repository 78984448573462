import classes from "./AdPreview.module.css";
import AdCard from "./ad_cards/AdCard";
import FacebookAdCard from "./ad_cards/facebook/FacebookAdCard";
import InstagramAdCard from "./ad_cards/instagram/InstagramAdCard";
import StoryAdCard from "./ad_cards/story/StoryAdCard";
import { Dialog, Flex, ScrollArea } from "@radix-ui/themes";
import { XIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const AdPreview = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <AppButton variant="outlined" disabled={isDisabled}>
          Preview
        </AppButton>
      </Dialog.Trigger>

      <Dialog.Content className={classes.content}>
        <Flex
          className={classes.header}
          direction="row"
          justify="between"
          align="center"
        >
          <Dialog.Title style={{ alignContent: "center", marginBottom: 0 }}>
            Preview
          </Dialog.Title>

          <Dialog.Close>
            <AppButton variant="outlined">
              <XIcon color="#595D62" width="18px" height="18px" /> Close
            </AppButton>
          </Dialog.Close>
        </Flex>

        <ScrollArea className={classes.inner_content}>
          <Flex className={classes.previews_container} gap="32px">
            <AdCard title="Facebook (Square)">
              <FacebookAdCard />
            </AdCard>
            <AdCard title="Instagram (Square)">
              <InstagramAdCard />
            </AdCard>
            <AdCard title="Story (Square)">
              <StoryAdCard />
            </AdCard>
          </Flex>
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AdPreview;
