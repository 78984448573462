import { components } from "@openapi";
import { ButtonProps } from "@radix-ui/themes";
import { CSSProperties } from "react";
import rgbHex from "rgb-hex";

export interface BrandButtonStyleProps {
  radius: ButtonProps["radius"];
  style: Pick<
    Required<NonNullable<ButtonProps["style"]>>,
    | "borderRadius"
    | "borderStyle"
    | "backgroundColor"
    | "color"
    | "fontFamily"
    | "fontSize"
    | "fontWeight"
    | "textTransform"
  > &
    Pick<
      NonNullable<ButtonProps["style"]>,
      | "borderColor"
      | "borderWidth"
      | "fontStyle"
      | "paddingTop"
      | "paddingRight"
      | "paddingBottom"
      | "paddingLeft"
      | "padding"
    >;
}

const safeRGBHex = (color?: string | null): string | null => {
  if (!color) {
    return null;
  }
  try {
    return `#${rgbHex(color)}`;
  } catch {
    return color;
  }
};

export const getBrandButtonStyleProps = (
  buttonStyle: components["schemas"]["BrandButtonStyleSchema"]
): BrandButtonStyleProps => {
  var radius: ButtonProps["radius"];
  if (buttonStyle.border_radius.endsWith("px")) {
    const numberRadius = Number(buttonStyle.border_radius);
    if (numberRadius === 0) {
      radius = "none";
    } else if (numberRadius >= 100) {
      radius = "full";
    } else {
      radius = "large";
    }
  } else {
    radius = "large";
  }

  const paddings = buttonStyle.padding.split(" ");
  const hasCustomPaddingUnits = paddings.some(
    (padding) => !padding.endsWith("px")
  );
  var paddingStyle: Pick<
    CSSProperties,
    "paddingTop" | "paddingRight" | "paddingBottom" | "paddingLeft" | "padding"
  >;
  if (!hasCustomPaddingUnits) {
    if (paddings.length === 1) {
      paddings.push(paddings[0]);
      paddings.push(paddings[0]);
      paddings.push(paddings[0]);
    } else if (paddings.length === 2) {
      paddings.push(paddings[0]);
      paddings.push(paddings[1]);
    } else if (paddings.length === 3) {
      paddings.push(paddings[1]);
    }
    paddingStyle = {
      paddingTop: paddings[0],
      paddingRight: paddings[1],
      paddingBottom: paddings[2],
      paddingLeft: paddings[3],
    };
  } else {
    paddingStyle = {
      padding: buttonStyle.padding,
    };
  }

  return {
    radius,
    style: {
      borderColor: safeRGBHex(buttonStyle.border_color) ?? undefined,
      borderRadius: buttonStyle.border_radius,
      borderStyle: buttonStyle.border_style ?? undefined,
      borderWidth: buttonStyle.border_width ?? undefined,
      backgroundColor: safeRGBHex(buttonStyle.background_color)!,
      color: safeRGBHex(buttonStyle.color)!,
      fontSize: buttonStyle.font.size,
      fontWeight: buttonStyle.font.weight,
      fontFamily: buttonStyle.font.font_family,
      fontStyle: buttonStyle.font.style ?? undefined,
      ...paddingStyle,
      textTransform:
        (buttonStyle.text_transform as CSSProperties["textTransform"])!,
    },
  };
};

const getButtonBorderRadius = (
  radius: ButtonProps["radius"]
): string | null => {
  switch (radius) {
    case "none":
      return "0";
    case "full":
      return "100px";
    default:
      return null;
  }
};

type BrandButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  brandButtonStyle?: components["schemas"]["BrandButtonStyleSchema"];
  radius?: ButtonProps["radius"];
};

const BrandButton: React.FC<BrandButtonProps> = ({
  children,
  brandButtonStyle,
  style,
  radius,
  ...props
}) => {
  const { style: buttonStyle } = brandButtonStyle
    ? getBrandButtonStyleProps(brandButtonStyle)
    : ({ style: {} } as Partial<BrandButtonStyleProps>);

  const radiusStyle = radius && getButtonBorderRadius(radius);

  return (
    <div
      style={{
        cursor: "pointer",
        textAlign: "center",
        ...buttonStyle,
        ...style,
        ...(radiusStyle ? { borderRadius: radiusStyle } : {}),
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default BrandButton;
