import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useBrandStylingDispatch } from "~/contexts/BrandStylingContext";

type DeleteButtonStypeResponse =
  operations["brand_api_delete_button_style"]["responses"][200]["content"]["application/json"];

const useDeleteButtonStyle = ({
  buttonStyleId,
  onSuccess,
  onError,
}: {
  buttonStyleId: string;
  onSuccess?: () => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const brandStylingDispatch = useBrandStylingDispatch();

  const mutation = useMutation<DeleteButtonStypeResponse, AxiosError>({
    mutationFn: async () => {
      const { data } = await axios.delete(
        `/api/v1/brand/stylebook/button/${buttonStyleId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (!data.success) {
        onError?.(data.error ?? "Something went wrong");
        return;
      }
      brandStylingDispatch({
        type: "REMOVE_BUTTON_STYLE",
        payload: buttonStyleId,
      });
      onSuccess?.();
    },
    onError: (error) => {
      console.log("Button style delete failed:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return mutation;
};

export default useDeleteButtonStyle;
