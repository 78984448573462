import { ProductSort } from "../hooks/useProductsFilters";
import styles from "./ProductsTable.module.css";
import { components } from "@openapi";
import * as Avatar from "@radix-ui/react-avatar";
import { Dialog, Flex, IconButton, Table, Text } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "~/assets/icons";
import { currencyFormatter } from "~/utils/helpers";

type ShopifyProductType = components["schemas"]["ShopifyProductInfoExtra"];

const ProductSortIcons = new Map<ProductSort, React.ReactNode>([
  [ProductSort.ASC, <ChevronUpIcon size="18px" />],
  [ProductSort.DESC, <ChevronDownIcon size="18px" />],
  [ProductSort.UNSET, <ChevronRightIcon size="18px" />],
]);

const ProductRow = styled(Table.Row)`
  cursor: pointer;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const RowSpacing = styled.tr`
  height: 8px;
`;

const ProductsTable = ({
  products,
  onSelectProduct,
  setNameSort,
  nameSort,
}: {
  products: ShopifyProductType[];
  onSelectProduct: (product: ShopifyProductType) => void;
  setNameSort: Dispatch<SetStateAction<ProductSort>>;
  nameSort: ProductSort;
}) => {
  const nameIcon: React.ReactNode = ProductSortIcons.get(nameSort);

  return (
    <Table.Root variant="ghost">
      <Table.Header style={{ borderBottom: "none", padding: "0px" }}>
        <Table.Row style={{ borderBottom: "none", padding: "0px" }}>
          <Table.ColumnHeaderCell>
            <p>Image</p>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <IconButton
              variant="ghost"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() =>
                setNameSort(
                  (prev) => (prev + 1) % (Object.keys(ProductSort).length / 2)
                )
              }
            >
              <p>Name</p>
              {nameIcon}
            </IconButton>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <p>Stock</p>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <p>Price</p>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className={styles.productsTable}>
        {products.map((product) => (
          <>
            <RowSpacing />
            <Dialog.Close>
              <ProductRow
                onClick={() => onSelectProduct(product)}
                align="center"
                key={product.product_id}
              >
                <Table.RowHeaderCell>
                  <Flex align="center" gap="3">
                    <Avatar.Root>
                      <Avatar.Image
                        src={product.image_url ?? ""}
                        alt="image"
                        width="40px"
                        height="40px"
                        style={{
                          borderRadius: "20%",
                          border: "1px solid #0000000A",
                          display: "block",
                        }}
                      ></Avatar.Image>
                      <Avatar.Fallback
                        style={{
                          borderRadius: "20%",
                          display: "block",
                          width: "40px",
                          height: "40px",
                          background: "grey",
                        }}
                      />
                    </Avatar.Root>
                  </Flex>
                </Table.RowHeaderCell>
                <Table.Cell>
                  <Flex direction="column">
                    <Text
                      as="span"
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {product.title}
                    </Text>
                    <Text
                      as="span"
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#888282",
                      }}
                    >
                      {product.category_name}
                    </Text>
                  </Flex>
                </Table.Cell>
                <Table.Cell>{product.total_inventory}</Table.Cell>
                <Table.Cell>
                  {currencyFormatter().format(product.price)}
                </Table.Cell>
              </ProductRow>
            </Dialog.Close>
          </>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default ProductsTable;
