import { IconButton, Separator, TextField } from "@radix-ui/themes";
import { ChevronDownIcon, ChevronUpIcon } from "~/assets/icons";

type InputWithIncrementProps = TextField.RootProps & {
  onIncrement: () => void;
  onDecrement: () => void;
};

// TODO: Combine with NumberInput
const TextFieldWithIncrement: React.FC<InputWithIncrementProps> = ({
  onIncrement,
  onDecrement,
  style,
  ...props
}) => {
  return (
    <TextField.Root
      style={{
        fontWeight: 500,
        color: "#4C4747",
        fontSize: "16px",
        height: "50px",
        width: "min-content",
        minWidth: "100px",
        ...style,
      }}
      radius="large"
      size="3"
      {...props}
    >
      <TextField.Slot
        side="right"
        px={"0"}
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          gap: 0,
          height: "49px",
          borderLeft: "1px solid var(--border)",
        }}
      >
        <IconButton
          onClick={onIncrement}
          variant="ghost"
          disabled={props.disabled}
          style={{
            height: "24px",
            margin: 0,
            padding: "0 2px",
            color: "var(--icon-primary)",
          }}
        >
          <ChevronUpIcon />
        </IconButton>
        <Separator
          orientation="horizontal"
          style={{
            width: "100%",
          }}
        />
        <IconButton
          onClick={onDecrement}
          variant="ghost"
          disabled={props.disabled}
          style={{
            height: "24px",
            margin: 0,
            padding: "0 2px",
            color: "var(--icon-primary)",
          }}
        >
          <ChevronDownIcon />
        </IconButton>
      </TextField.Slot>
    </TextField.Root>
  );
};

export default TextFieldWithIncrement;
