import { Flex, Text, TextField } from "@radix-ui/themes";
import React from "react";

const TextFieldWithLabel = ({
  label,
  labelColor,
  width,
  style,
  ...props
}: {
  label: string;
  labelColor?: string;
  width?: string;
  style?: React.CSSProperties;
} & React.ComponentProps<typeof TextField.Root>) => {
  return (
    <Flex width={width} direction="column" gap="4px" style={style}>
      <Text style={{ color: labelColor }} as="label" size="2" weight="regular">
        {label}
      </Text>
      <TextField.Root size="3" color="gray" radius="large" {...props} />
    </Flex>
  );
};

export default TextFieldWithLabel;
