import {
  AdMediaTextElementSchema,
  AdMediaTextElementTypeStrings,
} from "../../../types/ads";
import { AdMediaUpdateableElement } from "../AdMediaContext";
import { TextAlignment } from "@openapi";
import { Flex, Heading, SegmentedControl, Text } from "@radix-ui/themes";
import { AlignCenter, AlignLeft, AlignRight } from "lucide-react";
import React from "react";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import SidePanelComponents from "~/components/core/editor/sidepane";
import RichTextArea from "~/components/core/inputs/rich-text/RichTextArea";
import ColorBox from "~/components/style-library/color-palette/ColorBox";
import BrandFontSelect from "~/components/style-library/typography/BrandFontSelect/BrandFontSelect";
import { FontSelectItem } from "~/components/style-library/typography/BrandFontSelect/FontSelect";

interface AdMediaTextElementComponentProps {
  element: Extract<AdMediaUpdateableElement, { type: "text" }>;
  onElementUpdate: (
    element: Extract<AdMediaUpdateableElement, { type: "text" }>
  ) => void;
}

const AdMediaTextElementComponent: React.FC<
  AdMediaTextElementComponentProps
> = ({ element, onElementUpdate }) => {
  const handleTextChange = (text: string) => {
    const updatedElement = { ...element, text };
    onElementUpdate(updatedElement);
  };

  const handleSwitchEnabled = (checked: boolean) => {
    const updatedElement = { ...element, is_enabled: checked };
    onElementUpdate(updatedElement);
  };

  const handleFontSizeChange = (fontSize: number) => {
    const updatedElement: AdMediaTextElementSchema = {
      ...element,
      font_size: `${fontSize}px`,
    };
    onElementUpdate(updatedElement);
  };
  const handleFontFamilyChange = (font: FontSelectItem) => {
    const updatedElement: AdMediaTextElementSchema = {
      ...element,
      font_family: font.name,
    };
    onElementUpdate(updatedElement);
  };
  const handleColorChange = (color: string) => {
    const updatedElement: AdMediaTextElementComponentProps["element"] = {
      ...element,
      color: color,
    };
    onElementUpdate(updatedElement);
  };

  return (
    <SidePanelComponents.EditorCard
      checkable
      checked={element.is_enabled}
      onCheckedChange={handleSwitchEnabled}
      title={element.title || AdMediaTextElementTypeStrings[element.text_type]}
    >
      <Flex direction="column" gap="4">
        <RichTextArea
          value={element.text}
          fontSize={parseInt(element.font_size, 10)}
          onChange={handleTextChange}
          onFontSizeChange={handleFontSizeChange}
          style={{
            fontSize: element.font_size,
            fontFamily: element.font_family,
            textAlign: element.text_alignment ?? undefined,
          }}
        />
        <Heading size="1">Font</Heading>
        <Flex direction="row" gap="16px" maxHeight="48px">
          <BrandFontSelect
            loadSelectedGoogleFont
            size={"3"}
            value={element.font_family}
            onChange={handleFontFamilyChange}
            containerStyle={{
              flex: "auto",
            }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <ColorBox
            style={{ flexDirection: "row-reverse", paddingLeft: 8 }}
            color={element.color ?? "#000000"}
            label="Color"
            onChange={handleColorChange}
          />
        </Flex>
        <Heading size="1">Text Alignment</Heading>
        <Flex>
          <DraperSegmentControl
            $size="large"
            value={element.text_alignment ?? ""}
            size="3"
            style={{ width: "100%" }}
            onValueChange={(value) => {
              const updatedElement: AdMediaTextElementSchema = {
                ...element,
                text_alignment: value as TextAlignment,
              };
              onElementUpdate(updatedElement);
            }}
          >
            <SegmentedControl.Item value={TextAlignment.left}>
              <Flex align="center" gap="8px">
                <AlignLeft size="20px" />
                <Text size="2">Left</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value={TextAlignment.center}>
              <Flex align="center" gap="8px">
                <AlignCenter size="20px" />
                <Text size="2">Center</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value={TextAlignment.right}>
              <Flex align="center" gap="8px">
                <AlignRight size="20px" />
                <Text size="2">Right</Text>
              </Flex>
            </SegmentedControl.Item>
          </DraperSegmentControl>
        </Flex>
      </Flex>
    </SidePanelComponents.EditorCard>
  );
};

export default AdMediaTextElementComponent;
