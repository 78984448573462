import { operations } from "@openapi";
import { Flex, IconButton, Text, TextField } from "@radix-ui/themes";
import { useState, KeyboardEvent, useRef, useEffect } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { XIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import Modal from "~/components/core/dialog/Modal";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const EmailPill = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 8px;
  gap: 4px;
`;

type SendTestEmailRequestData =
  operations["emails_api_send_test_email"]["requestBody"]["content"]["application/json"];
type SendTestEmailResponse =
  operations["emails_api_send_test_email"]["responses"][200]["content"]["application/json"];

const SendTestEmailDialog = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { id, sections } = useEmailState();

  const addEmail = (email: string) => {
    if (email && !emails.includes(email)) {
      setEmails([...emails, email]);
      setInputValue("");
    }
  };

  const removeEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === "," || event.key === " ") {
      event.preventDefault();
      addEmail(inputValue.trim());
    } else if (
      event.key === "Backspace" &&
      inputValue === "" &&
      emails.length > 0
    ) {
      event.preventDefault();
      const lastEmail = emails[emails.length - 1];
      removeEmail(lastEmail);
      setInputValue(lastEmail);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendTestEmailMutation = useDraperApiPostMutation<
    SendTestEmailResponse,
    SendTestEmailRequestData
  >({
    path: "/emails/send-test-email",
    onSuccess: () => {
      toast.success("Test email sent successfully");
    },
    onError: () => {
      toast.error("Failed to send test email");
    },
  });

  const handleSubmit = () => {
    if (emails.length > 0) {
      sendTestEmailMutation.mutate({
        brand_id: activeBrandID,
        emails,
        email_creative_id: id,
        sections: sections // Manually spread because we have to update AND create
          .filter((section) => section.type !== null)
          .map((section, index) => {
            return {
              ...section,
              id: section.id.startsWith("new-section-") ? "" : section.id, // set empty string for new sections
              index: index,
            };
          })
          .filter(Boolean),
      });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [emails]);

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title="Send a Test Email"
      submitBtnText="Send test email"
      onSubmit={handleSubmit}
      isSubmitDisabled={emails.length === 0}
      hideFooterBorder
    >
      <Flex direction="column" gap="4px" p="24px">
        <Text size="2">Send to</Text>

        <TextField.Root
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          size="3"
          color="teal"
          placeholder={emails.length === 0 ? "james@example.com" : ""}
          style={{ padding: "4px" }}
        >
          <TextField.Slot side="left">
            {emails.map((email, index) => (
              <EmailPill key={index}>
                <DraperText size={"2"}>{email}</DraperText>

                <IconButton
                  variant="ghost"
                  size="1"
                  color="gray"
                  radius="full"
                  onClick={() => removeEmail(email)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <XIcon size={12} />
                </IconButton>
              </EmailPill>
            ))}
          </TextField.Slot>
        </TextField.Root>

        <Text size="2" mt="4px">
          Press Enter, comma, or space to add an email. Press Backspace to edit
          the last email.
        </Text>
      </Flex>
    </Modal>
  );
};

export default SendTestEmailDialog;
