import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type GetCampaignsResponse =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"];
export type Campaigns =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"];
export type Campaign =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0];
export type CampaignStatus =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0]["status"];
export type ChannelsType =
  operations["campaign_api_campaigns"]["responses"][200]["content"]["application/json"]["campaigns"][0]["channels"];

export const getCampaignsQueryKey = (
  brandID: string,
  status: CampaignStatus
) => ["campaign/list", brandID, status];

const useGetCampaigns = ({
  status,
  limit = 3,
}: {
  status: CampaignStatus;
  limit?: number;
}) => {
  const activeBrandID = useActiveBrandID();
  const { data, isLoading, error } = useQuery({
    queryKey: getCampaignsQueryKey(activeBrandID, status),
    queryFn: async (): Promise<GetCampaignsResponse> => {
      const { data } = await axios.get("/api/v1/campaign/list", {
        params: {
          brand_id: activeBrandID,
          status: status,
          limit: limit,
        },
      });
      return data;
    },
  });
  return { campaigns: data, isLoading, error };
};
export default useGetCampaigns;
