import {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "../campaign/useGetCampaignDetails";
import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

type DuplicateAdCreativeResponse = Omit<
  operations["ads_api_duplicate_ad_creative"]["responses"]["200"]["content"]["application/json"],
  "files" | "variant_previews"
>;

interface DuplicateAdCreativeVars {
  adCreativeId: string;
  campaignId?: string;
}

const useDuplicateAdCreativeMediaMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: DuplicateAdCreativeResponse) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();

  const generateAdCreativeMedia = useMutation<
    DuplicateAdCreativeResponse,
    AxiosError,
    DuplicateAdCreativeVars
  >({
    mutationFn: async ({ adCreativeId }) => {
      const { data } = await axios.post(
        `/api/v1/ads/ad-creative/${adCreativeId}/duplicate`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data, vars) => {
      onSuccess?.(data);
      if (!vars.campaignId) {
        return;
      }
      queryClient.setQueryData<CampaignDetailResponse>(
        getCampaignDetailsQueryKey(vars.campaignId),
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            ad_creatives: [...oldData.ad_creatives, data],
            ad_count: oldData.ad_creatives.length + 1,
          };
        }
      );
    },
    onError: (error) => {
      const errorMessage =
        error.status !== 500 &&
        error.response?.data &&
        typeof error.response?.data === "string"
          ? error.response.data
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return generateAdCreativeMedia;
};

export default useDuplicateAdCreativeMediaMutation;
