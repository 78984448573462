import CampaignWizardLayout from "../../../components/campaign/wizard/layout/CampaignWizardLayout";
import {
  NewCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardNextStep,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { NewCampaignCards } from "../../../views/campaign/wizard/CampaignCards";
import { CampaignType } from "@openapi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const NewCampaignWizardPage = () => {
  const campaignType = useLocation().state?.campaignType ?? null;
  const { currentRequiredStep, currentStep } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();
  const nextStep = useCampaignWizardNextStep();

  useEffect(() => {
    dispatch({
      type: "SET_WIZARD_TYPE",
      payload: { wizardType: "NEW" },
    });
    if (campaignType) {
      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          campaign_type: campaignType as CampaignType,
        },
      });
      nextStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CampaignWizardLayout>
      {(Object.values(NewCampaignWizardSteps) as NewCampaignWizardSteps[])
        .filter((value) => !isNaN(value))
        .map((value) => (
          <div
            style={{
              display:
                currentRequiredStep >= Number(value) ? undefined : "none",
              opacity: currentRequiredStep >= Number(value) ? undefined : 0,
              pointerEvents: currentStep === Number(value) ? undefined : "none",
              width: "800px",
            }}
            id={`step-${value}`}
            key={value}
          >
            {NewCampaignCards[value]}
          </div>
        ))}
    </CampaignWizardLayout>
  );
};

export default NewCampaignWizardPage;
