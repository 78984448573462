import { Box, Flex, Link, Separator, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const HelpNumber = styled.span`
  width: 24px;
  height: 24px;
  background-color: #f2f5f6;
  color: #4c4747;
  border: 1px solid #ddd7d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

const NumberText = ({ number, text }: { number: number; text: string }) => {
  return (
    <Flex align={"center"} gap="2">
      <HelpNumber>{number}</HelpNumber>
      <Text size={"2"}>{text}</Text>
    </Flex>
  );
};

const KlaviyoConnectInstructions = () => {
  return (
    <Box width={"100%"}>
      <Separator size={"4"} my="2" />

      <Flex gap="2" direction={"column"} mt="4">
        <NumberText
          number={1}
          text="Click your organization name in
                      the bottom left."
        />
        <NumberText number={2} text="Navigate to Settings." />
        <NumberText number={3} text="Click API keys." />
        <NumberText number={4} text="Click Create Private API Key." />
        <NumberText
          number={5}
          text="Name it and select access level
                      to Full Access."
        />
        <NumberText number={6} text="Click Create on top right." />
        <NumberText
          number={7}
          text="Copy and use the generated
                      private key."
        />
        <Text size={"1"} style={{ color: "var(--text-tertiary)" }}>
          For detailed instructions visit{" "}
          <Link
            style={{ color: "var(--text-link)" }}
            href="https://help.klaviyo.com/hc/en-us/articles/7423954176283"
            target="_blank"
          >
            https://help.klaviyo.com/hc/en-us/articles/7423954176283
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default KlaviyoConnectInstructions;
