import { Product } from "../../hooks/useGetFilteredProducts";
import { Checkbox } from "@radix-ui/themes";

const ProductCheckbox = ({
  product,
  handleCheck,
  handleMultipleCheck,
  handleUncheck,
  isChecked,
}: {
  product: Product;
  handleCheck: (product: Product) => void;
  handleMultipleCheck?: (product: Product) => void;
  handleUncheck: (product: Product) => void;
  isChecked: boolean;
}) => {
  const onClick = (e: any) => {
    if (isChecked) {
      handleUncheck(product);
    } else {
      if (e.shiftKey) {
        handleMultipleCheck?.(product);
      }
      handleCheck(product);
    }
  };
  return (
    <Checkbox
      size="3"
      style={{ borderRadius: "8px", marginRight: "24px" }}
      onClick={onClick}
      checked={isChecked}
    />
  );
};
export default ProductCheckbox;
