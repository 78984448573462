import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type GetProductReponse =
  operations["shopify_integration_api_get_product"]["responses"][200]["content"]["application/json"];

const useShopifyProductQuery = (
  brandID: string | null,
  productID: string | null
) => {
  const {
    data: productData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["commerce-platform/product", brandID, productID],
    queryFn: async ({ signal }) => {
      if (!signal.aborted) {
        const { data } = await axios.get<GetProductReponse>(
          "/api/v1/commerce-platform/product",
          {
            params: {
              brand_id: brandID,
              product_id: productID,
            },
          }
        );
        return data;
      }
    },
    retry: false,
    enabled: !!brandID && !!productID,
  });

  return {
    productData,
    isLoading,
    isSuccess,
  };
};

export default useShopifyProductQuery;
