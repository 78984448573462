import { useEffect, useState } from "react";
import ExploreLayoutVariantsSection from "~/components/core/editor/sidepane/ExploreLayoutVariantsSection";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useHeaderFooterState } from "~/contexts/TemplateEditorContext";
import useEmailSectionTemplatesQuery from "~/hooks/emails/useEmailSectionTemplatesQuery";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";

const VariantSelector = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { templates, error, isLoading } = useEmailSectionTemplatesQuery(
    useActiveBrandID(),
    state.type
  );

  useEffect(() => {
    const activeTemplate = templates.findIndex(
      (template) => template.id === state.template.id
    );

    if (activeTemplate !== -1) {
      setCurrentIndex(activeTemplate);
    }
  }, [state, templates]);

  const isAllDisabled =
    templates?.length <= 1 || isLoading || !state || error !== null;

  const isPreviousDisabled = currentIndex <= 0 || isAllDisabled;
  const isNextDisabled = currentIndex + 1 >= templates.length || isAllDisabled;

  return (
    <ExploreLayoutVariantsSection
      isPreviousDisabled={isPreviousDisabled}
      isNextDisabled={isNextDisabled}
      onPrevious={() => {
        updateState({
          ...state,
          template: templates[currentIndex - 1],
        });
        setCurrentIndex(currentIndex - 1);
      }}
      onNext={() => {
        updateState({
          ...state,
          template: templates[currentIndex + 1],
        });
        setCurrentIndex(currentIndex + 1);
      }}
    />
  );
};

export default VariantSelector;
