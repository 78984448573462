import {
  Position,
  SVGElementSelectionAttributeMapping,
  SVGElementSelectionAttributeType,
} from "../../../types/ads";
import InternAdMediaTemplateToolsDropdownMenu from "./InternAdMediaTemplateToolsDropdownMenu";
import { Container, Spinner } from "@radix-ui/themes";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { SvgLoader } from "react-svgmt";

interface InternAdMediaTemplateSVGCanvasProps {
  svgUrl: string | null | undefined;
  selectedElement?: SVGElement | null;
  onElementSelect?: (element: SVGElement) => void;
  onElementMove?: (position: Position) => void;
  onAttributeChange?: () => void;
}

interface InternAdMediaTemplateSelectedSVGElementState {
  element: SVGElement | null;
  stroke: string | null;
  strokeWidth: string | null;
  styleOutline: string | null;
  border: string | null;
}

const InternAdMediaTemplateSVGCanvas: React.FC<
  InternAdMediaTemplateSVGCanvasProps
> = ({
  svgUrl,
  selectedElement: propsSelectedElement = null,
  onElementSelect = (element: SVGElement) => {},
  onElementMove = (position: Position) => {},
  onAttributeChange = () => {},
}) => {
  const svgContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedElement, setSelectedElement] =
    useState<InternAdMediaTemplateSelectedSVGElementState>({
      element: null,
      stroke: null,
      strokeWidth: null,
      styleOutline: null,
      border: null,
    });

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<Position>({
    x: 0,
    y: 0,
  });

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dragStartPoint = useRef<Position | null>(null);

  const handleElementSelection = useCallback(
    (element: SVGElement | null) => {
      if (selectedElement.element) {
        const selectedElementType =
          SVGElementSelectionAttributeMapping[
            selectedElement.element.tagName.toLocaleLowerCase()
          ];
        switch (selectedElementType) {
          case SVGElementSelectionAttributeType.Stroke:
            if (selectedElement.stroke) {
              selectedElement.element.setAttribute(
                "stroke",
                selectedElement.stroke
              );
            } else {
              selectedElement.element.removeAttribute("stroke");
            }
            if (selectedElement.strokeWidth) {
              selectedElement.element.setAttribute(
                "stroke-width",
                selectedElement.strokeWidth
              );
            } else {
              selectedElement.element.removeAttribute("stroke-width");
            }
            break;
          case SVGElementSelectionAttributeType.StyleOutline:
            if (selectedElement.styleOutline) {
              selectedElement.element.style.outline =
                selectedElement.styleOutline;
            } else {
              selectedElement.element.style.outline = "";
            }
            break;
          case SVGElementSelectionAttributeType.Border:
            selectedElement.element.style.border = "none";
            break;
        }
      }

      if (!element) return;

      const elementType =
        SVGElementSelectionAttributeMapping[
          element.tagName.toLocaleLowerCase()
        ];
      const newState = {
        element: element,
        stroke: element.getAttribute("stroke"),
        strokeWidth: element.getAttribute("stroke-width"),
        styleOutline: element.style.outline,
        border: null,
      };
      switch (elementType) {
        case SVGElementSelectionAttributeType.Stroke:
          element.setAttribute("stroke", "#D32F2F");
          element.setAttribute("stroke-width", "4px");
          break;
        case SVGElementSelectionAttributeType.StyleOutline:
          element.style.outline = "2px solid #D32F2F";
          break;
        case SVGElementSelectionAttributeType.Border:
          element.style.border = "2px solid #D32F2F";
          break;
      }

      setSelectedElement(newState);
    },
    [
      selectedElement.element,
      selectedElement.stroke,
      selectedElement.strokeWidth,
      selectedElement.styleOutline,
      setSelectedElement,
    ]
  );

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    const targetElement = event.target as SVGElement;
    handleElementSelection(targetElement);
    setIsDropdownOpen(true);
    setDropdownPosition({ x: event.clientX, y: event.clientY });

    if (onElementSelect) {
      onElementSelect(targetElement);
    }
  };

  const handleElementClick = (event: React.MouseEvent) => {
    var clickedElement = event.target as SVGElement;

    if (!clickedElement) {
      return;
    }

    if (clickedElement.tagName.toLowerCase() === "tspan") {
      const textElement = clickedElement.closest("text");
      clickedElement = textElement !== null ? textElement : clickedElement;
    }

    handleElementSelection(clickedElement);

    if (onElementSelect) {
      onElementSelect(clickedElement);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 2) return;

    if (!selectedElement.element) {
      return;
    }

    const boundingBox = selectedElement.element.getBoundingClientRect();
    if (
      event.clientX >= boundingBox.left &&
      event.clientX <= boundingBox.right &&
      event.clientY >= boundingBox.top &&
      event.clientY <= boundingBox.bottom
    ) {
      dragStartPoint.current = { x: event.clientX, y: event.clientY };
      setIsDragging(true);
    }

    event.preventDefault();
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging || !selectedElement.element || !dragStartPoint.current) {
      return;
    }

    let targetElement: SVGElement | null = selectedElement.element;

    if (targetElement.tagName.toLowerCase() === "tspan") {
      targetElement = targetElement.closest("text");
    }
    if (targetElement?.tagName.toLocaleLowerCase() === "div") {
      targetElement = targetElement.closest("foreignObject");
    }
    if (!targetElement) {
      return;
    }

    const dx = event.clientX - dragStartPoint.current.x;
    const dy = event.clientY - dragStartPoint.current.y;

    let x = parseFloat(targetElement.getAttribute("x") || "0");
    let y = parseFloat(targetElement.getAttribute("y") || "0");

    const transform = targetElement.getAttribute("transform");
    if (transform) {
      const translateMatch = /translate\(([^)]+)\)/.exec(transform);
      if (translateMatch) {
        const [translateX, translateY] = translateMatch[1]
          .split(",")
          .map(Number);
        x += translateX;
        y += translateY;
        targetElement.removeAttribute("transform");
      }
    }

    x += dx;
    y += dy;

    targetElement.setAttribute("x", x.toString());
    targetElement.setAttribute("y", y.toString());

    if (targetElement.tagName.toLowerCase() === "text") {
      targetElement.querySelectorAll("tspan").forEach((tspan) => {
        tspan.setAttribute("x", x.toString());
      });
    }

    if (onElementMove) {
      onElementMove({ x, y });
    }

    dragStartPoint.current = { x: event.clientX, y: event.clientY };
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    dragStartPoint.current = null;
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const moveElementByDelta = (dx: number, dy: number) => {
    let targetElement: SVGElement | null = selectedElement.element;

    if (targetElement?.tagName.toLowerCase() === "tspan") {
      targetElement = targetElement.closest("text");
    }
    if (targetElement?.tagName.toLowerCase() === "div") {
      targetElement = targetElement.closest("foreignObject");
    }
    if (!targetElement) {
      return;
    }

    let x = parseFloat(targetElement.getAttribute("x") || "0");
    let y = parseFloat(targetElement.getAttribute("y") || "0");

    const transform = targetElement.getAttribute("transform");
    if (transform) {
      const translateMatch = /translate\(([^)]+)\)/.exec(transform);
      if (translateMatch) {
        const [translateX, translateY] = translateMatch[1]
          .split(",")
          .map(Number);
        x += translateX;
        y += translateY;
        targetElement.removeAttribute("transform");
      }
    }

    x += dx;
    y += dy;

    targetElement.setAttribute("x", x.toString());
    targetElement.setAttribute("y", y.toString());

    if (targetElement.tagName.toLowerCase() === "text") {
      targetElement.querySelectorAll("tspan").forEach((tspan) => {
        tspan.setAttribute("x", x.toString());
      });
    }

    if (onElementMove) {
      onElementMove({ x, y });
    }
  };

  const resizeElementByDelta = (dw: number, dh: number) => {
    let targetElement: Element | null = selectedElement.element;

    if (!targetElement) return;

    if (targetElement instanceof SVGElement) {
      const width = parseFloat(targetElement.getAttribute("width") || "0") + dw;
      const height =
        parseFloat(targetElement.getAttribute("height") || "0") + dh;
      targetElement.setAttribute("width", width.toString());
      targetElement.setAttribute("height", height.toString());
    } else if (targetElement instanceof HTMLDivElement) {
      const currentWidth = targetElement.style.width;
      const currentHeight = targetElement.style.height;
      let newWidth = 0;
      let newHeight = 0;
      if (currentWidth.includes("%")) {
        const parentWidth =
          targetElement.parentElement?.offsetWidth || targetElement.offsetWidth;
        const widthValue = (parseFloat(currentWidth) / 100) * parentWidth;
        newWidth = widthValue + dw;
        targetElement.style.width = `${(newWidth / parentWidth) * 100}%`;
      } else {
        newWidth = parseFloat(currentWidth || "0") + dw;
        targetElement.style.width = `${newWidth}px`;
      }
      if (currentHeight.includes("%")) {
        const parentHeight =
          targetElement.parentElement?.offsetHeight ||
          targetElement.offsetHeight;
        const heightValue = (parseFloat(currentHeight) / 100) * parentHeight;
        newHeight = heightValue + dh;
        targetElement.style.height = `${(newHeight / parentHeight) * 100}%`;
      } else {
        newHeight = parseFloat(currentHeight || "0") + dh;
        targetElement.style.height = `${newHeight}px`;
      }
    }
    onAttributeChange && onAttributeChange();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!selectedElement.element) return;
    const moveAmount = 1;
    const resizeAmount = 1;

    if (event.shiftKey) {
      switch (event.key) {
        case "ArrowUp":
          resizeElementByDelta(0, -resizeAmount);
          break;
        case "ArrowDown":
          resizeElementByDelta(0, resizeAmount);
          break;
        case "ArrowLeft":
          resizeElementByDelta(-resizeAmount, 0);
          break;
        case "ArrowRight":
          resizeElementByDelta(resizeAmount, 0);
          break;
        default:
          break;
      }
    } else {
      switch (event.key) {
        case "ArrowUp":
          moveElementByDelta(0, -moveAmount);
          break;
        case "ArrowDown":
          moveElementByDelta(0, moveAmount);
          break;
        case "ArrowLeft":
          moveElementByDelta(-moveAmount, 0);
          break;
        case "ArrowRight":
          moveElementByDelta(moveAmount, 0);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedElement]);

  useEffect(() => {
    handleElementSelection(propsSelectedElement);
  }, [propsSelectedElement, handleElementSelection]);

  if (!svgUrl) {
    return (
      <Container>
        <Spinner size="3" />
      </Container>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <InternAdMediaTemplateToolsDropdownMenu
        selectedElement={selectedElement.element}
        open={isDropdownOpen}
        onOpenChange={handleDropdownClose}
        position={dropdownPosition}
        onAttributeChange={onAttributeChange}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          borderRadius: "8px",
          borderWidth: "2px",
          borderColor: "#afafaf",
          overflow: "hidden",
        }}
        onClick={handleElementClick}
        onMouseMove={(event) => handleMouseMove(event.nativeEvent)}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        ref={svgContainerRef}
      >
        <SvgLoader width="100%" height="100%" path={svgUrl} />
      </div>
    </div>
  );
};

export default InternAdMediaTemplateSVGCanvas;
