import * as Popover from "@radix-ui/react-popover";
import { Flex, SegmentedControl, Text, Theme } from "@radix-ui/themes";
import { ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import { MonitorIcon, SmartphoneIcon } from "~/assets/icons";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import ConfirmationAlert from "~/components/core/dialog/ConfirmationAlert";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import useUpdateBrandEmailSections from "~/hooks/style-library/useUpdateBrandEmailSections";
import { STYLE_LIBRARY_ROUTE } from "~/routes/constants";

const PopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
`;

const MenuItem = styled(Flex)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
`;

const TemplateEditorTopControlBar = () => {
  const { previewMode } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  const navigate = useNavigate();
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const updateBrandEmailSections = useUpdateBrandEmailSections({
    onSuccess: () => {
      toast.success("Template updated successfully");
    },
    onError: (errorMessage, axiosError) => {
      console.log(
        "Error updating brand email sections:",
        errorMessage,
        axiosError
      );
      toast.error("Something went wrong");
    },
  });

  const handleCancel = () => {
    navigate("/style-library");
  };

  const handleSave = () => {
    updateBrandEmailSections.mutate();
  };

  return (
    <>
      <EditorTopBar
        backButtonDestination={STYLE_LIBRARY_ROUTE}
        left={
          <Text ml="12px" size="4" weight="medium">
            Template
          </Text>
        }
        center={
          <DraperSegmentControl
            style={{ paddingTop: "6px", backgroundColor: "#F0EDEB" }}
            size="3"
            value={previewMode}
            onValueChange={(val) =>
              dispatch({
                type: "UPDATE_EDITOR_STATE",
                payload: {
                  previewMode: val as "desktop" | "mobile",
                },
              })
            }
          >
            <SegmentedControl.Item value="desktop">
              <MonitorIcon />
            </SegmentedControl.Item>
            <SegmentedControl.Item value="mobile">
              <SmartphoneIcon />
            </SegmentedControl.Item>
          </DraperSegmentControl>
        }
        right={
          <Flex gap="12px" align="center">
            <Popover.Root>
              <Popover.Trigger asChild>
                <AppButton
                  variant="outlined"
                  size="3"
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  Actions
                  <ChevronDownIcon strokeWidth={1.5} size={20} />
                </AppButton>
              </Popover.Trigger>
              <Popover.Portal>
                <Theme>
                  <PopoverContent sideOffset={5} align="start">
                    <Flex direction={"column"} gap="2">
                      <MenuItem
                        onClick={() => {
                          // setPreviewEmailDialogOpen(true);
                        }}
                      >
                        <Text>Preview</Text>
                      </MenuItem>
                    </Flex>
                  </PopoverContent>
                </Theme>
              </Popover.Portal>
            </Popover.Root>

            <AppButton
              variant="primary"
              radius="full"
              size="3"
              onClick={handleSave}
              disabled={updateBrandEmailSections.isPending}
            >
              {/* {isEditingInline ? "Done" : "Save"} */}
              Save
            </AppButton>
          </Flex>
        }
      />

      <ConfirmationAlert
        open={isCancelConfirmOpen}
        onOpenChange={setIsCancelConfirmOpen}
        title="Cancel current changes?"
        description="Are you sure? Some data might get lost"
        onConfirm={handleCancel}
        confirmText="Discard changes"
      />
    </>
  );
};

export default TemplateEditorTopControlBar;
