import { AdMediaUpdateableElement } from "../AdMediaContext";
import { Flex } from "@radix-ui/themes";
import SidePanelComponents from "~/components/core/editor/sidepane";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { AdMediaImageElementTypeStrings } from "~/types/ads";

interface AdMediaImageElementComponentProps {
  element: Extract<AdMediaUpdateableElement, { type: "image" }>;
  onElementUpdate: (
    element: Extract<AdMediaUpdateableElement, { type: "image" }>
  ) => void;
}

const AdMediaImageElementComponent: React.FC<
  AdMediaImageElementComponentProps
> = ({ element, onElementUpdate }) => {
  const handleSwitchEnabled = (checked: boolean) => {
    const updatedElement = { ...element, is_enabled: checked };
    onElementUpdate(updatedElement);
  };

  const handleUpload = (file: File) => {
    const updatedElement = { ...element, uploadedFile: file };
    onElementUpdate(updatedElement);
  };

  const handleCrop = (file: File) => {
    const updatedElement = { ...element, uploadedFile: file };
    onElementUpdate(updatedElement);
  };

  return (
    <SidePanelComponents.EditorCard
      checkable
      checked={element.is_enabled}
      onCheckedChange={handleSwitchEnabled}
      title={AdMediaImageElementTypeStrings[element.image_type]}
    >
      <Flex direction="column" gap="16px">
        <ImageSelector
          value={element.uploadedFile ?? element.file}
          onUpload={handleUpload}
          onCrop={handleCrop}
        />
      </Flex>
    </SidePanelComponents.EditorCard>
  );
};

export default AdMediaImageElementComponent;
