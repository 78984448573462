import { useState, useEffect } from "react";

/**
 * Hook to calculate the countdown from the current time to a target date.
 *
 * @param targetDate - The future date as a string, number, or Date object to count down to.
 * @returns An object with the remaining hours, minutes, and seconds.
 */
function useTimeCountdown(targetDate: Date | string | number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const calculateTimeLeft = (): {
    hours: number;
    minutes: number;
    seconds: number;
  } => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    if (difference > 0) {
      return {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return { hours: 0, minutes: 0, seconds: 0 };
  };

  const [timeLeft, setTimeLeft] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  }>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return timeLeft;
}

export default useTimeCountdown;
