import LoadingDots from "../../../misc/LoadingDots";
import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import { ImageAssetCategory, ImageAssetSource } from "@openapi";
import { CheckIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useImageAssetsQuery, {
  getImageAssetsQueryKey,
} from "~/hooks/media/useImageAssetsQuery";

const DEFAULT_PARAMS = {
  category: [
    ImageAssetCategory.other,
    ImageAssetCategory.background_image,
    ImageAssetCategory.lifestyle,
    ImageAssetCategory.product,
  ],
  source: [ImageAssetSource.file_upload, ImageAssetSource.scraping],
  limit: 18,
};
export const ImageAssetsDefaultKey = (brandId: string) =>
  getImageAssetsQueryKey({
    brandId,
    ...DEFAULT_PARAMS,
  });
const LibraryAssets = ({
  selectAsset,
  search,
  selectedAssets,
}: {
  selectAsset: (asset: AssetSelectorItem) => void;
  search: string;
  selectedAssets: AssetSelectorItem[];
}) => {
  const activeBrandID = useActiveBrandID();

  const { assetsData, isLoading, error, fetchNextPage, hasNextPage } =
    useImageAssetsQuery({
      brandId: activeBrandID,
      search: search,
      ...DEFAULT_PARAMS,
    });
  const assetImages = useMemo(
    () => assetsData?.flatMap((data) => data.assets),
    [assetsData]
  );
  if (isLoading) {
    return (
      <Flex justify={"center"} align={"center"} my="5">
        <LoadingDots />
      </Flex>
    );
  }
  if (error) {
    return <Text>Failed to fetch assets</Text>;
  }
  return (
    <InfiniteScroll
      height={"304px"}
      next={() => {
        fetchNextPage();
      }}
      hasMore={hasNextPage ?? false}
      dataLength={assetImages?.length ?? 0}
      loader={
        <Flex justify={"center"} align={"center"} my="5">
          <LoadingDots />
        </Flex>
      }
      scrollThreshold={"90%"}
    >
      <Flex wrap="wrap" gap="24px">
        {(assetImages ?? []).map((asset) => (
          <BrandAsset
            key={asset.id}
            originalFilename={asset.original_filename}
            url={asset.url}
            overlay={
              <HoverOverlay
                $isSelected={
                  !!selectedAssets.find((file) => file.url === asset.url)
                }
              >
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            }
            onClick={() => {
              selectAsset({ url: asset.url, asset });
            }}
          />
        ))}
      </Flex>
    </InfiniteScroll>
  );
};
export default LibraryAssets;
