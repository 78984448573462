import HomeSection from "../../HomeSection";
import CampaignDraftCard from "./CampaignDraftCard";
import useGetCampaigns from "@hooks/campaign/useGetCampaigns";
import { CampaignStatus } from "@openapi";
import { Flex } from "@radix-ui/themes";

const CampaignDraftSection = () => {
  const { campaigns, isLoading } = useGetCampaigns({
    status: CampaignStatus.draft,
    limit: 6,
  });

  if (!isLoading && campaigns?.campaigns?.length === 0) {
    return null;
  }

  return (
    <HomeSection
      title="Drafts"
      content={
        <Flex direction="row" gap="5" justify="start" wrap="wrap">
          {(campaigns?.campaigns ?? []).map((campaign) => (
            <CampaignDraftCard
              isLoading={isLoading}
              campaign={campaign}
              key={campaign.id}
            />
          ))}
        </Flex>
      }
    />
  );
};

export default CampaignDraftSection;
