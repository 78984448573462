import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

type GetProductsResponse =
  operations["shopify_integration_api_get_products_by_ids"]["responses"][200]["content"]["application/json"];

const useShopifyProductsQuery = (
  brandID: string | null,
  productIDs: string[] | null
) => {
  const {
    data: productsData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["commerce-platform/products-by-ids", brandID, productIDs],
    queryFn: async ({ signal }) => {
      if (!signal.aborted) {
        const { data } = await axios.post<GetProductsResponse>(
          "/api/v1/commerce-platform/products-by-ids",
          {
            brand_id: brandID,
            product_ids: productIDs,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      }
    },
    retry: false,
    enabled: !!brandID && !!productIDs,
  });

  return {
    productsData,
    isLoading,
    isSuccess,
  };
};

export default useShopifyProductsQuery;
