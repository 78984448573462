import { Flex, Text } from "@radix-ui/themes";

const HomeSection = ({
  title,
  content,
}: {
  title?: string;
  content: React.ReactNode;
}) => {
  return (
    <Flex gap="4" direction="column" overflow="auto" width="100%">
      {!!title && (
        <Text
          as="span"
          style={{
            fontSize: "20px",
            color: "#353539CC",
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      )}
      {content}
    </Flex>
  );
};
export default HomeSection;
