import { Flex, Spinner, Text } from "@radix-ui/themes";
import React, { useMemo } from "react";
import { useAdEditorState } from "~/contexts/AdEditorContext";

const TemplateEditorLayout = ({
  children,
}: {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}) => {
  const state = useAdEditorState();

  const isLoading = state.status === "loading";
  const errorMessage = state.status === "error" ? state.message : undefined;
  const disabledState = useMemo(() => {
    if (isLoading) {
      return <Spinner size="3" />;
    } else if (errorMessage) {
      return <Text color="red">{errorMessage}</Text>;
    }
    return null;
  }, [isLoading, errorMessage]);

  return (
    <Flex
      direction="column"
      width="100%"
      maxHeight="100dvh"
      height="100dvh"
      style={{
        backgroundColor: "var(--background_soft)",
      }}
    >
      {children[0]}

      <Flex
        width="100%"
        direction="row"
        flexGrow="1"
        overflow="auto"
        style={{
          borderRadius: "10px",
          ...(disabledState
            ? { alignItems: "center", justifyContent: "center" }
            : {}),
        }}
      >
        {disabledState ? disabledState : children.slice(1)}
      </Flex>
    </Flex>
  );
};

export default TemplateEditorLayout;
