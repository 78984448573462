import {
  AdMediaCollectionGroupElementSchema,
  AdMediaElementUnionSchema,
  AdMediaImageElementSchema,
  AdMediaProductGroupElementSchema,
} from "../../types/ads";
import _ from "lodash";
import React, { createContext, useContext, useState, ReactNode } from "react";

export type AdMediaUpdateableImageElement = AdMediaImageElementSchema & {
  uploadedFile?: File;
};

type AdMediaUpdateableGroupElement =
  | Exclude<
      AdMediaProductGroupElementSchema["elements"][number],
      AdMediaImageElementSchema
    >
  | AdMediaUpdateableImageElement;

type AdMediaUpdateableProductGroupElement = Omit<
  AdMediaProductGroupElementSchema,
  "elements"
> & {
  elements: AdMediaUpdateableGroupElement[];
};

type AdMediaUpdateableCollectionGroupElement = Omit<
  AdMediaCollectionGroupElementSchema,
  "elements"
> & {
  elements: AdMediaUpdateableGroupElement[];
};

export type AdMediaUpdateableElement =
  | Exclude<
      AdMediaElementUnionSchema,
      | AdMediaImageElementSchema
      | AdMediaProductGroupElementSchema
      | AdMediaCollectionGroupElementSchema
    >
  | AdMediaUpdateableImageElement
  | AdMediaUpdateableProductGroupElement
  | AdMediaUpdateableCollectionGroupElement;

interface AdMediaContextType {
  elements: AdMediaUpdateableElement[];
  setElements: React.Dispatch<React.SetStateAction<AdMediaUpdateableElement[]>>;
  resetElements: (
    elements: AdMediaUpdateableElement[],
    mediaId: string
  ) => void;
  updateElement: (updatedElement: AdMediaUpdateableElement) => void;
}

const AdMediaContext = createContext<AdMediaContextType | undefined>(undefined);

const refreshElement = <ElementType extends AdMediaUpdateableElement>(
  newElement: ElementType,
  oldElement?: ElementType
): ElementType => {
  if (!oldElement) {
    return newElement;
  }

  // make sure to update signed image urls
  if (oldElement.type === "image" && newElement.type === "image") {
    return {
      ...oldElement,
      file: newElement.file,
    };
  }

  if (
    (oldElement.type === "product_group" ||
      oldElement.type === "collection_group") &&
    newElement.type === oldElement.type
  ) {
    return {
      ...oldElement,
      elements: newElement.elements.map((el, i) => {
        const oldEl = oldElement.elements.find((oldEl) => oldEl.id === el.id);
        return refreshElement(el, oldEl);
      }),
    };
  }

  return oldElement;
};

export const AdMediaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [elements, setElements] = useState<AdMediaUpdateableElement[]>([]);
  const [elementsMediaId, setElementsMediaId] = useState<string | null>(null);
  const [elementsByMediaId, setElementsByMediaId] = useState<
    Record<string, AdMediaUpdateableElement[]>
  >({});

  const updateElement = (updatedElement: AdMediaUpdateableElement) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  const resetElements = (
    newElements: AdMediaUpdateableElement[],
    mediaId: string
  ) => {
    const prevMediaId = elementsMediaId;
    setElementsMediaId(mediaId);
    if (prevMediaId && prevMediaId !== mediaId) {
      setElementsByMediaId((prevElements) => ({
        ...prevElements,
        [prevMediaId]: elements,
      }));
    }
    const oldElements =
      prevMediaId === mediaId ? elements : elementsByMediaId[mediaId];
    const oldElementsById = _.keyBy(oldElements, "id");
    setElements(
      newElements.map((element) =>
        refreshElement(element, oldElementsById[element.id])
      )
    );
  };

  return (
    <AdMediaContext.Provider
      value={{ elements, setElements, resetElements, updateElement }}
    >
      {children}
    </AdMediaContext.Provider>
  );
};

export const useAdMediaContext = () => {
  const context = useContext(AdMediaContext);

  if (context === undefined) {
    throw new Error("useAdMediaContext must be used within an AdMediaProvider");
  }
  return context;
};
