import KinLogo from "../core/KinLogo";
import BackgroundGradient from "../core/layout/BackgroundGradient";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const Card = styled(Flex)`
  border-radius: 24px;
  border: 1px solid var(--border-primary);
  background-color: var(--primary-white);
  box-shadow: 0px 12px 120px 0px #00000014;
  box-shadow: 0px 12px 32px -16px #0009320a;
  max-width: 480px;
`;

const AuthPageBase = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex direction={"column"} gap={"9"} align={"center"} mt="9">
      <BackgroundGradient />
      <KinLogo height="48px" />

      <Card direction={"column"} gap={"2"} align={"center"}>
        {children}
      </Card>
    </Flex>
  );
};

export default AuthPageBase;
