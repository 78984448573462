import FormErrorText from "../auth/FormErrorText";
import AppButton from "../core/buttons/AppButton/AppButton";
import { operations } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Flex, Separator, Text, TextField } from "@radix-ui/themes";
import { useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Button = styled(AppButton)`
  width: max-content;
`;

const FullWidthSeparator = styled(Separator)`
  width: 100%;
  background-color: rgba(240, 240, 240, 1);
`;

const Label = styled(Text)`
  color: var(--text-secondary);
`;

type ChangePasswordResponse =
  operations["user_api_change_password"]["responses"][200]["content"]["application/json"];
type ChangePasswordRequestData =
  operations["user_api_change_password"]["requestBody"]["content"]["application/json"];

const PasswordField = styled(Form.Field)`
  flex: 1;
`;

const AccountSettingsGeneral = () => {
  const user = useAuthenticatedUserState();
  const [oldPasswordErrors, setOldPasswordErrors] = useState("");
  const [newPasswordErrors, setNewPasswordErrors] = useState("");
  const [serverErrors, setServerErrors] = useState("");

  const resetErrors = () => {
    setOldPasswordErrors("");
    setNewPasswordErrors("");
    setServerErrors("");
  };

  const { mutate: changePassword, isPending } = useDraperApiPostMutation<
    ChangePasswordResponse,
    ChangePasswordRequestData
  >({
    path: "/user/change-password",
    onSuccess: (result) => {
      resetErrors();
      if (result.success) {
        toast.success("Your password has been changed");
        return;
      }
      setOldPasswordErrors(result.errors?.old_password ?? "");
      setNewPasswordErrors(
        result.errors?.new_password ?? result.errors?.confirm_password ?? ""
      );
    },
    onError: (error) => {
      console.error(error);
      setServerErrors("Password change failed");
    },
  });

  const onSubmit = (data: FormData) => {
    const oldPassword = data.get("password") as string;
    const newPassword = data.get("newPassword") as string;

    changePassword({
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: newPassword,
    });
  };

  return (
    <Flex direction="column" gap="40px" p="32px">
      <Flex direction="column" gap="24px">
        <Text weight="medium">Email Address</Text>
        <TextField.Root
          type="email"
          size="3"
          radius="large"
          value={user.email}
          disabled
        />
      </Flex>
      <FullWidthSeparator />
      <Form.Root
        onSubmit={(event) => {
          event.preventDefault();
          const data = new FormData(event.currentTarget);
          onSubmit(data);
        }}
        onClearServerErrors={() => setServerErrors("")}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Text weight="medium">Password</Text>

        <Flex gap="24px">
          <PasswordField name="password">
            <Form.Label>
              <Label size="2">Current Password</Label>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                size="3"
                radius="large"
                required
                name="password"
                type="password"
                minLength={8}
                autoComplete="current-password"
              />
            </Form.Control>
            <Form.Message match="valueMissing">
              <FormErrorText>Please enter your password.</FormErrorText>
            </Form.Message>
            <Form.Message match="typeMismatch" forceMatch={!!oldPasswordErrors}>
              <FormErrorText>{oldPasswordErrors}</FormErrorText>
            </Form.Message>
          </PasswordField>
          <PasswordField name="newPassword">
            <Form.Label>
              <Label size="2">New Password</Label>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                size="3"
                radius="large"
                required
                name="newPassword"
                type="password"
                minLength={8}
                autoComplete="new-password"
              />
            </Form.Control>
            <Form.Message match="valueMissing">
              <FormErrorText>Please enter your new password.</FormErrorText>
            </Form.Message>
            <Form.Message match="typeMismatch" forceMatch={!!newPasswordErrors}>
              <FormErrorText>{newPasswordErrors}</FormErrorText>
            </Form.Message>
            <Form.Message match="tooShort">
              <FormErrorText>
                Password must be at least 8 characters
              </FormErrorText>
            </Form.Message>
          </PasswordField>
        </Flex>
        {serverErrors && (
          <Callout.Root
            color="red"
            role="alert"
            style={{ alignSelf: "flex-start" }}
          >
            <Callout.Icon>
              <ExclamationTriangleIcon />
            </Callout.Icon>
            <Callout.Text>{serverErrors}</Callout.Text>
          </Callout.Root>
        )}

        <Form.Submit asChild>
          <Button radius="large" disabled={isPending}>
            Update Password
          </Button>
        </Form.Submit>
      </Form.Root>
    </Flex>
  );
};

export default AccountSettingsGeneral;
