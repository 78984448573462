import MarkPopup from "@components/core/MarkPopup";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { ClockIcon } from "lucide-react";
import { useMemo } from "react";
import styled from "styled-components";
import useTimeCountdown from "~/hooks/common/useTimeCountdown";
import { nextRecommenderScheduleDate } from "~/utils/timeUtils";

const NullStateHero = styled(Flex)`
  width: 100%;
  flex: 1;
  height: 100%;
  min-width: 350px;
  max-width: calc(50% - 16px);
  background-color: var(--primary-white);
  padding: 16px;
  border-radius: 30px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 3.86px 7.71px 0px #0000000a;
`;

const CounterWrapper = styled(Flex)`
  border-radius: 30px;
  background-color: var(--primary-deep-purple);
  color: var(--primary-white);
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MarkPopupStyled = styled(MarkPopup)`
  position: absolute;
  top: -14px;
  left: -55px;
  z-index: 1;
  width: 120%;
  max-width: 480px;
`;

const Card = styled(Flex)`
  padding: 16px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const CountdownText = styled(Text)`
  font-variant-numeric: tabular-nums;
`;

const showCounter = true;

const leadingZero = (num: number, digits = 2) =>
  num.toString().padStart(digits, "0");

const CampaignPlaceholderSlot = styled.div`
  flex: 1;
  height: 180px;
  background-color: #ff9999;
  border-radius: 16px;
  border: 0.5px solid var(--black-6, #0000000f);
`;

const CampaignPlaceholder: React.FC = () => (
  <Card direction="column" gap="5">
    <Grid gap="4" maxWidth="100%" columns="repeat(3, minmax(0, 1fr))">
      <CampaignPlaceholderSlot />
      <CampaignPlaceholderSlot />
      <CampaignPlaceholderSlot />
    </Grid>

    <Flex gap="2" direction="column">
      <Text
        size="7"
        style={{
          fontWeight: "600",
        }}
      >
        Campaign
      </Text>
      <Text size="4" weight="medium" style={{ color: "#3b3b3b" }}>
        Coming soon...
      </Text>
    </Flex>
  </Card>
);

const RecommenderZeroState = () => {
  const scheduleDate = useMemo(() => nextRecommenderScheduleDate(), []);
  const { seconds, minutes, hours } = useTimeCountdown(scheduleDate.toDate());

  return (
    <Flex
      direction="column"
      gap="5"
      width="100%"
      maxWidth="1200px"
      overflow="hidden"
      pt="72px"
    >
      <Flex gap="6" height="383px" width="100%">
        <NullStateHero>
          <CounterWrapper>
            {showCounter ? (
              <Flex gap="18px">
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(hours)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Hours
                  </Text>
                </Flex>
                <Text size="9" weight="bold">
                  :
                </Text>
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(minutes)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Minutes
                  </Text>
                </Flex>
                <Text size="9" weight="bold">
                  :
                </Text>
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(seconds)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Seconds
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <ClockIcon size="72" />
            )}
          </CounterWrapper>
        </NullStateHero>
        <Flex
          direction="column"
          height="100%"
          justify="end"
          gap="5"
          maxWidth="424px"
          pb="61px"
          style={{ position: "relative", flex: "0.5" }}
        >
          <MarkPopupStyled sayHello>
            <Text>
              We’re currently analyzing your brand voice to tailor the best
              campaign ideas for you.
            </Text>
          </MarkPopupStyled>
          <Text size="7" weight="bold">
            Coming soon.
            <br />
            Keep your eyes open.
          </Text>
          <Text size="4" weight="medium" style={{ color: "#3b3b3b" }}>
            The clock is ticking. We are coming up with 3 big campaigns for you.
            Promise.
          </Text>
          {/* <AppButton
          style={{ width: "max-content" }}
          >
          <BellDotIcon />
          Notify Me
        </AppButton> */}
        </Flex>
      </Flex>
      <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="24px">
        <CampaignPlaceholder />
        <CampaignPlaceholder />
      </Grid>
    </Flex>
  );
};

export default RecommenderZeroState;
