import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

export const getTimeDifference = (dateTime: string) => {
  const hours = Math.floor(
    (new Date().getTime() - new Date(dateTime).getTime()) / 1000 / 3600
  );
  const days = Math.floor(hours / 24);
  return { days: days, hours: hours };
};

export const nextRecommenderScheduleDate = () => {
  const scheduleDates = [
    { day: 1, hour: 3 },
    { day: 3, hour: 3 },
    { day: 5, hour: 3 },
  ];
  const now = dayjs().tz("America/New_York"); // EST/EDT
  const hour = now.hour();
  const dayOfWeek = now.day();
  const nextScheduleDate =
    scheduleDates.find(
      (date) =>
        date.day > dayOfWeek || (date.day === dayOfWeek && date.hour > hour)
    ) ?? scheduleDates[0];

  const date = now
    .weekday(nextScheduleDate.day)
    .hour(nextScheduleDate.hour)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (dayOfWeek > nextScheduleDate.day) {
    return date.add(1, "week");
  }
  return date;
};
