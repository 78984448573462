import "cropperjs/dist/cropper.css";
import { createRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import Modal from "~/components/core/dialog/Modal";

const CropImageDialog = ({
  open,
  onOpenChange,
  img,
  onCrop,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  img: string;
  onCrop: (img: string) => void;
}) => {
  const cropperRef = createRef<ReactCropperElement>();

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      return cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    }
    return "#";
  };

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title="Crop Image"
      onSubmit={() => onCrop(getCropData())}
      submitBtnText="Crop Image"
    >
      <Cropper
        ref={cropperRef}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={1}
        src={img}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        checkCrossOrigin={false}
        crossOrigin="anonymous"
        guides={true}
      />
    </Modal>
  );
};

export default CropImageDialog;
