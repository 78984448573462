import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type RegenerateTextResponse =
  operations["emails_api_regenerate_text"]["responses"][200]["content"]["application/json"];

type RegenerateTextRequestData =
  operations["emails_api_regenerate_text"]["requestBody"]["content"]["application/json"];

const useEmailRegenerateTextMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: RegenerateTextResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const regenerateText = useDraperApiPostMutation<
    RegenerateTextResponse,
    RegenerateTextRequestData
  >({
    mutationKey: ["email-regenerate-text"],
    path: `/emails/regenerate-text`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });

  return {
    regenerateText: regenerateText.mutate,
    isLoading: regenerateText.isPending,
  };
};

export default useEmailRegenerateTextMutation;
