import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";

export type HighlightOptions = {
  /**
   * The types where the highlight can be applied
   * @default ['textStyle']
   * @example ['heading', 'paragraph']
   */
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    highlight: {
      /**
       * Set the text highlight
       * @param highlight The highlight to set
       * @example editor.commands.setHighlight('yellow')
       */
      setHighlight: (highlight: string) => ReturnType;

      //   /**
      //    * Toggle a highlight mark
      //    * @param attributes The highlight attributes
      //    * @example editor.commands.toggleHighlight({ color: 'red' })
      //    */
      //   toggleHighlight: (attributes?: { color: string }) => ReturnType;

      /**
       * Unset the text highlight
       * @example editor.commands.unsetHighlight()
       */
      unsetHighlight: () => ReturnType;
    };
  }
}

/**
 * This extension allows you to highlight your text.
 * @see https://tiptap.dev/api/extensions/highlight
 */
const Highlight = Extension.create<HighlightOptions>({
  name: "highlight",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          highlight: {
            default: null,
            parseHTML: (element) =>
              element.style.backgroundColor?.replace(/['"]+/g, ""),
            renderHTML: (attributes) => {
              if (!attributes.highlight) {
                return {};
              }

              return {
                style: `background-color: ${attributes.highlight}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setHighlight:
        (highlight) =>
        ({ chain }) => {
          return chain().setMark("textStyle", { highlight }).run();
        },
      unsetHighlight:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { highlight: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default Highlight;
