import classes from "./InstagramAdCard.module.css";
import { Flex } from "@radix-ui/themes";
import { Ellipsis, Heart, MessageCircle } from "lucide-react";
import { ChevronRightIcon, SendIcon } from "~/assets/icons";
import { ReactComponent as InstaSave } from "~/assets/instaSave.svg";
import AdMediaSVGCanvas from "~/components/ads/AdMediaSVGCanvas";
import { useAdEditorSelectedAdMediaState } from "~/contexts/AdEditorContext";

const InstagramAdCard = () => {
  const { ad: adData, media } = useAdEditorSelectedAdMediaState();
  const mediaUrl = media?.variants?.find(
    (variant) => variant.aspect_ratio === "square"
  )?.file;
  const header = (
    <Flex justify="between" className={classes.header}>
      <Flex gap="6px" align="center">
        <div className={classes.icon}></div>
        <Flex direction="column">
          <span className={classes.company_name}>
            {adData?.data.brand_name}
          </span>
          <span className={classes.sponsored}>Sponsored</span>
        </Flex>
      </Flex>
      <Ellipsis />
    </Flex>
  );

  const content = (
    <Flex className={classes.content} direction="column">
      <AdMediaSVGCanvas
        svgUrl={mediaUrl}
        customStyle={{ borderRadius: "0px" }}
      />
      <Flex align="center" justify="between" className={classes.content_footer}>
        <span className={classes.shop_now}>Shop Now</span>
        <ChevronRightIcon size="20px" />
      </Flex>
    </Flex>
  );
  const footer1 = (
    <Flex justify="between" className={classes.footer1} align="center">
      <Flex gap="14px" align="center">
        <Heart />
        <MessageCircle style={{ transform: "rotate(270deg)" }} />
        <SendIcon />
      </Flex>
      <InstaSave width="26px" height="26px" />
    </Flex>
  );
  const footer2 = (
    <span className={classes.footer2}>
      <b>{adData?.data.brand_name}</b> {adData?.data.primary_text}
    </span>
  );
  return (
    <Flex direction="column" className={classes.container}>
      {header}
      {content}
      {footer1}
      {footer2}
    </Flex>
  );
};

export default InstagramAdCard;
