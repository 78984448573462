import { ButtonVariants } from "./variants";
import { ButtonProps } from "@radix-ui/themes";
import { forwardRef } from "react";

type AppButtonProps = Omit<ButtonProps, "variant"> & {
  variant?: AppButtonVariants;
};

type AppButtonVariants = "primary" | "soft" | "outlined" | "dark" | "ghost";

/**
 * @param {AppButtonProps} props - Radix button props.
 * @param {AppButtonVariants} [props.variant] - The button variant to be applied. Defaults to `primary`.
 *
 * @example
 * // Render a soft variant button
 * <AppButton variant="soft" onClick={handleClick}>
 *   Click Me
 * </AppButton>
 */
const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ variant, ...props }, ref) => {
    switch (variant?.toUpperCase()) {
      case "PRIMARY":
        return <ButtonVariants.PRIMARY {...props} ref={ref} />;
      case "SOFT":
        return <ButtonVariants.SOFT {...props} ref={ref} />;
      case "OUTLINED":
        return <ButtonVariants.OUTLINED {...props} ref={ref} />;
      case "DARK":
        return <ButtonVariants.DARK {...props} ref={ref} />;
      case "GHOST":
        return <ButtonVariants.GHOST {...props} ref={ref} />;
      default:
        return <ButtonVariants.PRIMARY {...props} ref={ref} />;
    }
  }
);

export default AppButton;
