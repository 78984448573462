import * as Tooltip from "@radix-ui/react-tooltip";
import { Box, Button, Text, Flex, Heading, Table } from "@radix-ui/themes";
import { CheckCircleIcon, LoaderIcon, XCircleIcon } from "lucide-react";

const styles = {
  tableContainer: {
    border: "1px solid lightgray",
    borderRadius: "8px",
    padding: "4px",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bold",
    align: "center",
  },
  tableText: {
    fontSize: "12px",
    whiteSpace: "normal",
    align: "center",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    width: "100%",
    height: "100%",
  },
};

interface InternBrandPipelinesCardProps {}

const InternBrandPipelinesCard: React.FC<
  InternBrandPipelinesCardProps
> = ({}) => {
  // const { data } = useQuery({
  //   queryKey: ["brand-pipelines-status", activeBrandID],
  //   queryFn: async (): Promise<GetBrandPipelinesStatusResponse> => {
  //     const { data } = await axios.get(
  //       `/api/v1/brand/${activeBrandID}/pipelines/status`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-CSRFToken": Cookies.get("csrftoken") ?? "",
  //         },
  //       }
  //     );
  //     return data;
  //   },
  //   retry: true,
  //   staleTime: 3600,
  // });

  const renderRowWithTooltip = (
    name: string,
    status: string,
    lastRunAt: string | null
  ) => {
    const formatDate = (dateString: string | null) => {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    };
    const getStatusIcon = (status: string) => {
      switch (status.toLowerCase()) {
        case "good":
          return <CheckCircleIcon size="16px" color="green" />;
        case "bad":
          return <XCircleIcon size="16px" color="red" />;
        default:
          return <LoaderIcon size="16px" />;
      }
    };
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Table.Row>
            <Table.Cell style={styles.tableText}>{name}</Table.Cell>
            <Table.Cell style={styles.tableText}>
              {getStatusIcon(status)}
            </Table.Cell>
          </Table.Row>
        </Tooltip.Trigger>
        <Tooltip.Content side="top">
          <div
            style={{
              padding: "8px",
              background: "white",
              border: "solid 1px #dfdfdf",
              borderRadius: "4px",
            }}
          >
            <Text size="1">Last run at: {formatDate(lastRunAt)}</Text>
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    );
  };

  return (
    <Box height="100%">
      <Flex direction="column" height="100%" gap="18px">
        <Flex justify="between">
          <Heading size="5">Pipelines</Heading>
          <Button
            onClick={() => window.open("https://mage.getkinn.com", "_blank")}
          >
            Open Mage
          </Button>
        </Flex>
        <Flex direction="column" flexGrow="1">
          <div style={styles.grid}>
            <Flex direction="column" gap="8px">
              <Heading size="2">Injestion</Heading>
              <Flex
                direction="column"
                style={styles.tableContainer}
                flexGrow="1"
              >
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      {["Pipeline", "Status"].map((header, headerIndex) => (
                        <Table.Cell
                          key={headerIndex}
                          style={styles.tableHeader}
                        >
                          {header}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderRowWithTooltip(
                      "Shopify",
                      "loading",
                      "loading"
                      // data ? data.shopify.status : "loading",
                      // data?.shopify?.last_run_at || "loading"
                    )}
                    {renderRowWithTooltip(
                      "Meta",
                      "loading",
                      "loading"
                      // data ? data.meta.status : "loading",
                      // data?.meta?.last_run_at || "loading"
                    )}
                    {renderRowWithTooltip(
                      "Klaviyo",
                      "loading",
                      "loading"
                      // data ? data.klaviyo.status : "loading",
                      // data?.klaviyo?.last_run_at || "loading"
                    )}
                  </Table.Body>
                </Table.Root>
              </Flex>
            </Flex>
            <Flex direction="column" gap="8px">
              <Heading size="2">Recommender</Heading>
              <Flex
                direction="column"
                style={styles.tableContainer}
                flexGrow="1"
              >
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      {["Pipeline", "Status"].map((header, headerIndex) => (
                        <Table.Cell
                          key={headerIndex}
                          style={styles.tableHeader}
                        >
                          {header}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderRowWithTooltip(
                      "Recommender",
                      "loading",
                      "loading"
                      // data ? data.recommender.status : "loading",
                      // data?.recommender?.last_run_at || "loading"
                    )}
                  </Table.Body>
                </Table.Root>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternBrandPipelinesCard;
