import { Code, DataList, Flex, IconButton, Text, Box } from "@radix-ui/themes";
import { CopyIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import InternImageAssetCard from "~/components/intern/InternImageAssetCard";
import useInternCampaignsCampaignQuery from "~/hooks/intern/useInternCampaignsCampaignQuery";

const InternCampaignEmailContainer = () => {
  const { id, emailId } = useParams() as { id: string; emailId: string };
  const { data } = useInternCampaignsCampaignQuery({
    campaignId: id,
  });
  const email = data?.email_creatives.find((email) => email.id === emailId);
  if (!email) {
    return <div>Email not found</div>;
  }
  return (
    <DataList.Root>
      <DataList.Item>
        <DataList.Label minWidth="88px">Email ID</DataList.Label>
        <DataList.Value>
          <Flex align="center" gap="2">
            <Code>{email.id}</Code>
            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
            >
              <CopyIcon />
            </IconButton>
          </Flex>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label minWidth="88px">Title</DataList.Label>
        <DataList.Value>
          <Text>{email.title}</Text>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label minWidth="88px">Subtitle</DataList.Label>
        <DataList.Value>
          <Text>{email.subtitle}</Text>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label minWidth="88px">Current Hero Asset</DataList.Label>
        <DataList.Value>
          {email.hero_asset ? (
            <Box style={{ width: "250px" }}>
              <InternImageAssetCard asset={email.hero_asset} />
            </Box>
          ) : (
            <Text color="gray">No asset</Text>
          )}
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label minWidth="88px">Items</DataList.Label>
        <DataList.Value>
          <Flex direction="column" gap="2">
            {data?.items?.length ? (
              data.items.map((item) => (
                <Code key={item.id}>{item.commerce_platform_item_id}</Code>
              ))
            ) : (
              <Text>No items</Text>
            )}
          </Flex>
        </DataList.Value>
      </DataList.Item>
    </DataList.Root>
  );
};

export default InternCampaignEmailContainer;
