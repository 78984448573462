import { useState } from "react";

const useDraperStep = (localStorageVariableName: string) => {
  const savedStep = localStorage.getItem(localStorageVariableName);
  const [step, setStep] = useState(savedStep ? parseInt(savedStep) : 0);

  const updateStep = (step: number) => {
    setStep(step);
    localStorage.setItem(localStorageVariableName, step.toString());
  };
  const onNext = () => setStep(step + 1);
  const onPrev = () => setStep(Math.max(step - 1, 0));
  return { step, setStep: updateStep, onNext, onPrev };
};
export default useDraperStep;
