import { useAuthenticatedUserState } from "../../contexts/CurrentUserContext";
import SpinningLoader from "../core/SpinningLoader";
import AppButton from "../core/buttons/AppButton/AppButton";
import ChatMessageContainer from "./ChatMessageContainer";
import { operations } from "@openapi";
import { Flex, Select, Text, Avatar, TextField } from "@radix-ui/themes";
import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";
import { getCampaignDetailsQueryKey } from "~/hooks/campaign/useGetCampaignDetails";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Root = styled(Flex)`
  border-right: 1px solid var(--border-primary);
  width: 500px;
  height: 100%;
  padding: 24px;
  gap: 24px;
`;

const MessageContainer = styled(Flex)`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--border-primary);
  justify-content: center;
  align-items: center;
`;

const SchedulingUI = styled(Flex)`
  border-radius: 12px;
  border: 1px solid var(--border-primary);
  padding: 16px;
`;

type AdAccountType =
  operations["meta_api_ad_accounts"]["responses"]["200"]["content"]["application/json"][number];
type CampaignType =
  operations["meta_api_get_meta_campaigns"]["responses"]["200"]["content"]["application/json"]["campaigns"][number];
type AdSetType =
  operations["meta_api_get_meta_adsets"]["responses"]["200"]["content"]["application/json"]["ad_sets"][number];
type BusinessPageType =
  operations["meta_api_get_meta_business_pages"]["responses"]["200"]["content"]["application/json"]["data"][number];

type GenMetaAdCreativeResponse =
  operations["meta_api_gen_meta_ad_creative"]["responses"]["200"]["content"]["application/json"];
type GenMetaAdCreativeRequestData =
  operations["meta_api_gen_meta_ad_creative"]["requestBody"]["content"]["application/json"];

type CampaignResponse = {
  campaigns: CampaignType[];
  cursor: string | null;
};

type AdSetResponse = {
  ad_sets: AdSetType[];
  cursor: string | null;
};

type EmailPublishRequest =
  operations["emails_api_publish_emails"]["requestBody"]["content"]["application/json"];
type EmailPublishResponse =
  operations["emails_api_publish_emails"]["responses"]["200"]["content"]["application/json"];

const ChatContainer = ({
  campaignId,
  selectedAds,
  selectedEmails,
  selectedCampaignType,
  toggleSelectedCampaignType,
}: {
  campaignId: string;
  toggleSelectedCampaignType: () => void;
  selectedEmails: string[];
  selectedAds: string[];
  selectedCampaignType: "email" | "ads";
}) => {
  const [selectedAdAccount, setSelectedAdAccount] = useState<string | null>(
    null
  );
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const [selectedAdSet, setSelectedAdSet] = useState<string | null>(null);
  const [selectedPage, setSelectedPage] = useState<string | null>(null);

  const { activeBrand } = useAuthenticatedUserState();
  const activeBrandID = activeBrand?.id;

  const queryClient = useQueryClient();

  const { data: adAccounts } = useQuery<AdAccountType[]>({
    queryKey: ["adAccounts"],
    queryFn: async () => {
      const response = await axios.get("/api/v1/meta/ad-accounts", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return response.data;
    },
  });

  const {
    data: campaignsData,
    fetchNextPage: fetchNextCampaigns,
    hasNextPage: hasNextCampaigns,
    isFetchingNextPage: isFetchingNextCampaigns,
  } = useInfiniteQuery<CampaignResponse>({
    queryKey: ["campaigns", selectedAdAccount],
    queryFn: async ({ pageParam = null }) => {
      if (!selectedAdAccount) return { campaigns: [], cursor: null };
      const response = await axios.get("/api/v1/meta/campaigns", {
        params: {
          meta_ad_account_id: selectedAdAccount,
          limit: 100,
          after: pageParam,
        },
      });
      return response.data;
    },
    enabled: !!selectedAdAccount,
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: null,
  });

  const {
    data: adSetsData,
    fetchNextPage: fetchNextAdSets,
    hasNextPage: hasNextAdSets,
    isFetchingNextPage: isFetchingNextAdSets,
  } = useInfiniteQuery<AdSetResponse>({
    queryKey: ["adSets", selectedAdAccount, selectedCampaign],
    queryFn: async ({ pageParam = null }) => {
      if (!selectedAdAccount || !selectedCampaign)
        return { ad_sets: [], cursor: null };
      const response = await axios.get("/api/v1/meta/adsets", {
        params: {
          meta_ad_account_id: selectedAdAccount,
          campaign_id: selectedCampaign,
          limit: 100,
          after: pageParam,
        },
      });
      return response.data;
    },
    enabled: !!selectedAdAccount && !!selectedCampaign,
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: null,
  });

  const { data: businessPages } = useQuery<BusinessPageType[]>({
    queryKey: ["businessPages", selectedAdAccount],
    queryFn: async () => {
      if (!selectedAdAccount) return [];
      const response = await axios.get("/api/v1/meta/business-pages", {
        params: {
          meta_ad_account_id: selectedAdAccount,
        },
      });
      return response.data.data;
    },
    enabled: !!selectedAdAccount,
  });

  const campaigns =
    campaignsData?.pages.flatMap((page) => page.campaigns) ?? [];
  const adSets = adSetsData?.pages.flatMap((page) => page.ad_sets) ?? [];

  const createAdCreativeMutation = useDraperApiPostMutation<
    GenMetaAdCreativeResponse,
    GenMetaAdCreativeRequestData
  >({
    path: "/meta/create-ad-creative",
    mutationKey: ["create-ad-creative"],
    onSuccess: (data) => {
      console.log("Ad creative created successfully:", data);
    },
    onError: (error) => {
      console.error("Error creating ad creative:", error);
    },
  });

  const [publishState, setPublishState] = useState<
    "initial" | "loading" | "success" | "scheduling"
  >("initial");
  const [scheduleData, setScheduleData] = useState<{ [key: string]: string }>(
    {}
  );

  const publishEmailsMutation = useDraperApiPostMutation<
    EmailPublishResponse,
    EmailPublishRequest
  >({
    path: "/emails/publish",
    mutationKey: ["publish-emails"],
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getCampaignDetailsQueryKey(campaignId),
      });
    },
    onSuccess: () => {
      setPublishState("initial");
      toast.success("Emails published successfully");
    },
    onError: (error) => {
      console.error("Error publishing emails:", error);
      setPublishState("initial");
      toast.error("Failed to publish emails");
    },
  });

  const handleSendToDraft = () => {
    setPublishState("loading");
    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({ id })),
    });
  };

  const handleSchedule = () => {
    setPublishState("loading");
    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({
        id,
        publish_timestamp: scheduleData[id],
      })),
    });
  };

  console.log(selectedEmails, scheduleData);
  const handlePublishAds = () => {
    if (selectedAdAccount && selectedAdSet) {
      createAdCreativeMutation.mutate({
        meta_ad_account_id: selectedAdAccount,
        adset_id: selectedAdSet,
        ad_creative_ids: selectedAds,
      });
    }
  };

  const schedulingUI = (
    <SchedulingUI direction="column" gap="16px">
      {selectedEmails.map((id, index) => (
        <Flex key={id} direction="column" gap="8px">
          <Text weight="medium">Schedule Email {index + 1}:</Text>
          <Flex gap="8px">
            <TextField.Root style={{ height: "48px", borderRadius: "12px" }}>
              <TextField.Slot>
                <input
                  type="date"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setScheduleData((prev) => ({
                      ...prev,
                      [id]: e.target.value,
                    }))
                  }
                  style={{ all: "unset" }}
                />
              </TextField.Slot>
            </TextField.Root>
            <TextField.Root
              style={{
                height: "48px",
                borderRadius: "12px",
              }}
            >
              <TextField.Slot>
                <input
                  type="time"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setScheduleData((prev) => ({
                      ...prev,
                      [id]: `${prev[id]}T${e.target.value}`,
                    }))
                  }
                  style={{ all: "unset" }}
                />
              </TextField.Slot>
            </TextField.Root>
          </Flex>
        </Flex>
      ))}
      <Flex justify="end" align="center" gap="2">
        <AppButton
          disabled={publishEmailsMutation.isPending}
          radius="large"
          size="3"
          variant="outlined"
          onClick={() => setPublishState("initial")}
        >
          Cancel
        </AppButton>
        <AppButton
          id="submit-button"
          disabled={
            publishEmailsMutation.isSuccess || publishEmailsMutation.isPending
          }
          radius="large"
          size="3"
          variant="dark"
          onClick={handleSchedule}
        >
          Schedule
        </AppButton>
      </Flex>
    </SchedulingUI>
  );

  if (selectedCampaignType === "email") {
    return (
      <Root direction={"column"} gap="2">
        <ChatMessageContainer
          message={`You have ${
            selectedEmails.length === 1
              ? "1 email"
              : `${selectedEmails.length} emails`
          } to schedule, or you can send them to Klaviyo as drafts.`}
        />
        {publishState === "initial" && (
          <Flex gap="16px" wrap="wrap">
            <AppButton
              variant="outlined"
              size="3"
              radius="large"
              onClick={() => setPublishState("scheduling")}
            >
              Schedule
            </AppButton>
            <AppButton
              variant="outlined"
              size="3"
              radius="large"
              onClick={handleSendToDraft}
            >
              Send to Klaviyo as draft
            </AppButton>
          </Flex>
        )}
        {publishState === "scheduling" && schedulingUI}
        {publishState === "loading" && (
          <MessageContainer>
            <Flex gap="12px" align="center">
              <SpinningLoader />
              <Text>Sending draft emails to Klaviyo...</Text>
            </Flex>
          </MessageContainer>
        )}
        {publishState === "success" && (
          <>
            <MessageContainer>
              <Flex gap="12px" align="center">
                <CheckIcon />
                <Text>Emails sent to Klaviyo as drafts</Text>
              </Flex>
            </MessageContainer>
            <ChatMessageContainer message="Should we start reviewing the Meta ads?" />
            <Flex gap="16px">
              <AppButton
                onClick={toggleSelectedCampaignType}
                variant="outlined"
                size="3"
                radius="large"
              >
                Review Meta Ads
              </AppButton>
            </Flex>
          </>
        )}
      </Root>
    );
  }

  return (
    <Root direction={"column"}>
      <ChatMessageContainer
        message={`
        You have selected ${
          selectedAds.length === 1
            ? "1 Meta ad"
            : `${selectedAds.length} Meta ads`
        } to connect to a campaign.
      `}
      />
      <Flex direction="column" gap="12px">
        <Text weight="medium">Meta Account</Text>
        <Select.Root
          value={selectedAdAccount || undefined}
          onValueChange={(value) => {
            setSelectedAdAccount(value);
            setSelectedCampaign(null);
            setSelectedAdSet(null);
          }}
        >
          <Select.Trigger color="gray" radius="large" />
          <Select.Content>
            {adAccounts?.map((account) => (
              <Select.Item key={account.account_id} value={account.account_id}>
                {account.name}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
      {selectedAdAccount && (
        <Flex direction="column" gap="12px">
          <Text weight="medium">Meta Campaign</Text>
          <Select.Root
            value={selectedCampaign || undefined}
            onValueChange={(value) => {
              setSelectedCampaign(value);
              setSelectedAdSet(null);
            }}
          >
            <Select.Trigger color="gray" radius="large" />
            <Select.Content>
              {campaigns.map((campaign) => (
                <Select.Item key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </Select.Item>
              ))}
              {hasNextCampaigns && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextCampaigns()}
                >
                  {isFetchingNextCampaigns ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && (
        <Flex direction="column" gap="12px">
          <Flex justify="between" align="center">
            <Text weight="medium">Ad Set</Text>
            {/* <AppButton variant="ghost" radius="large" size="2">
              <PlusIcon size="20" /> New
            </AppButton> */}
          </Flex>
          <Select.Root
            value={selectedAdSet || undefined}
            onValueChange={(value) => setSelectedAdSet(value)}
          >
            <Select.Trigger color="gray" radius="large" />
            <Select.Content>
              {adSets.map((adSet) => (
                <Select.Item key={adSet.id} value={adSet.id}>
                  {adSet.name}
                </Select.Item>
              ))}
              {hasNextAdSets && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextAdSets()}
                >
                  {isFetchingNextAdSets ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && selectedAdSet && (
        <Flex direction="column" gap="12px">
          <Text weight="medium">Publishing Page</Text>
          <Select.Root
            value={selectedPage || undefined}
            onValueChange={(value) => setSelectedPage(value)}
          >
            <Select.Trigger color="gray" radius="large" />
            <Select.Content>
              {businessPages?.map((page) => (
                <Select.Item key={page.id} value={page.id}>
                  <Flex align="center" gap="2">
                    <Avatar
                      size="1"
                      src={page.picture_url}
                      fallback={page.name[0]}
                    />
                    <Text>{page.name}</Text>
                  </Flex>
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount &&
        selectedCampaign &&
        selectedAdSet &&
        selectedPage && (
          <AppButton
            radius="large"
            style={{ width: "max-content" }}
            onClick={handlePublishAds}
            disabled={createAdCreativeMutation.isPending}
          >
            {createAdCreativeMutation.isPending
              ? "Publishing..."
              : "Publish Ads"}
          </AppButton>
        )}
    </Root>
  );
};

export default ChatContainer;
