import CustomizationTypeSelect from "./customization/CustomizationTypeSelect";
import FooterView from "./customization/FooterView";
import HeaderView from "./customization/HeaderView";
import MenuLinksCustomization from "./customization/MenuLinksCustomization";
import SocialLinksCustomization from "./customization/SocialLinksCustomization";
import React from "react";
import EditorAside from "~/components/core/editor/layout/EditorAside";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";
import {
  CustomizationLabels,
  CustomizationViews,
} from "~/types/template-editor";
import { getParentView } from "~/utils/template-editor";

const TemplateEditorViewComponents: Record<
  CustomizationViews,
  React.ReactNode
> = {
  [CustomizationViews.TypeSelect]: <CustomizationTypeSelect />,
  [CustomizationViews.FooterOptions]: <FooterView />,
  [CustomizationViews.HeaderOptions]: <HeaderView />,
  [CustomizationViews.MenuLinks]: <MenuLinksCustomization />,
  [CustomizationViews.SocialLinks]: <SocialLinksCustomization />,
};

const TemplateEditorModifierAside = () => {
  const { currentView, viewType } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  return (
    <EditorAside
      showHeader={currentView !== CustomizationViews.TypeSelect}
      title={CustomizationLabels[currentView]}
      onBack={() =>
        dispatch({
          type: "CHANGE_CURRENT_VIEW",
          payload: getParentView(currentView, viewType),
        })
      }
    >
      {TemplateEditorViewComponents[currentView]}
    </EditorAside>
  );
};

export default TemplateEditorModifierAside;
