import brandVoicePageSections from "./brand-voice/sections";
import visualPageSections from "./visuals/sections";
import { Box, Heading } from "@radix-ui/themes";
import { Outlet, RouteObject } from "react-router-dom";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import StyleLibraryPage from "~/components/style-library/StyleLibraryPage";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";

export const getStyleLibraryRoutes = (): RouteObject[] => [
  {
    path: "",
    element: <StyleLibraryPage sections={visualPageSections} />,
  },
  {
    path: "voice",
    element: <StyleLibraryPage sections={brandVoicePageSections} />,
  },
];

const StyleLibraryRoute = () => {
  const brand = useAuthenticatedUserState().activeBrand;
  return (
    <BrandStylingProvider>
      <Box
        m={"6"}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <BackgroundGradient variant="3" />
        <Heading size="6">{brand.name}</Heading>

        <Outlet />
      </Box>
    </BrandStylingProvider>
  );
};

export default StyleLibraryRoute;
