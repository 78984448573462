import { AdMediaUpdateableElement } from "../AdMediaContext";
import AdCopyEditCard from "../editor/fields/AdCopyEditCard";
import AdLayoutVariants from "../editor/fields/AdLayoutVariants";
import AdProductCard from "../editor/fields/AdProductCard";
import AdProductCollectionCard from "../editor/fields/AdProductCollectionCard";
import AdMediaAccentColorsComponent from "../elements/AdMediaAccentColorsComponent";
import AdMediaImageElementComponent from "../elements/AdMediaImageElementComponent";
import AdMediaTextElementComponent from "./AdMediaTextElementComponent";
import { AdMediaElementType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import _ from "lodash";
import React, { useMemo } from "react";

interface AdMediaElementListComponentProps {
  elements: AdMediaUpdateableElement[];
  onElementUpdate: (element: AdMediaUpdateableElement) => void;
}

const AdMediaElementListComponent: React.FC<
  AdMediaElementListComponentProps
> = ({ elements, onElementUpdate }) => {
  const [shapeElements, productElements, componentElements] = useMemo(() => {
    const [shapes, rest] = _.partition(
      elements,
      (element) => element.type === AdMediaElementType.shape
    );
    return [
      shapes,
      ..._.partition(
        rest,
        (element) => element.type === AdMediaElementType.product_group
      ),
    ];
  }, [elements]);

  return (
    <Flex
      direction="column"
      width="100%"
      gap="6"
      style={{
        overflowY: "auto",
        justifyContent: "stretch",
      }}
    >
      <AdLayoutVariants />
      <AdCopyEditCard />

      <AdProductCard
        elements={productElements}
        onElementUpdate={onElementUpdate}
      />

      {componentElements.map((element) => {
        switch (element.type) {
          case AdMediaElementType.collection_group:
            return (
              <AdProductCollectionCard
                key={element.id}
                element={element}
                onElementUpdate={onElementUpdate}
              />
            );
          case AdMediaElementType.image:
            return (
              <AdMediaImageElementComponent
                key={element.id}
                element={element}
                onElementUpdate={onElementUpdate}
              />
            );
          case AdMediaElementType.text:
            return (
              <AdMediaTextElementComponent
                key={element.id}
                element={element}
                onElementUpdate={onElementUpdate}
              />
            );
          default:
            return <></>;
        }
      })}
      <AdMediaAccentColorsComponent
        elements={shapeElements}
        onElementUpdate={onElementUpdate}
      />
    </Flex>
  );
};

export default AdMediaElementListComponent;
