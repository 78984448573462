import { components, EmailSectionType } from "@openapi";

export enum CustomizationViews {
  TypeSelect,
  HeaderOptions,
  FooterOptions,
  MenuLinks,
  SocialLinks,
}

export type EmailHeaderSectionSchema = Omit<
  components["schemas"]["EmailBrandHeaderSectionSchema"],
  "type"
> & {
  type: EmailSectionType.header;
};
export type EmailFooterSectionSchema = Omit<
  components["schemas"]["EmailBrandFooterSectionSchema"],
  "type"
> & {
  type: EmailSectionType.footer;
};

export type EmailSectionImageElementState =
  | (Pick<
      components["schemas"]["EmailImageElementSchema"],
      "enabled" | "image_url"
    > & {
      id?: components["schemas"]["EmailImageElementSchema"]["id"];
    })
  | File;

export const isFileType = (file: any): file is File => {
  return "lastModified" in file;
};

export type EmailHeaderFooterSection =
  | components["schemas"]["EmailBrandHeaderSectionSchema"]
  | components["schemas"]["EmailBrandFooterSectionSchema"];

export const isHeaderSection = (
  section:
    | components["schemas"]["EmailBrandHeaderSectionSchema"]
    | components["schemas"]["EmailBrandFooterSectionSchema"]
): section is components["schemas"]["EmailBrandHeaderSectionSchema"] => {
  return section.type === EmailSectionType.header;
};

export const isFooterSection = (
  section:
    | components["schemas"]["EmailBrandHeaderSectionSchema"]
    | components["schemas"]["EmailBrandFooterSectionSchema"]
): section is components["schemas"]["EmailBrandFooterSectionSchema"] => {
  return section.type === EmailSectionType.footer;
};

export interface TemplateEditorInterface {
  currentView: CustomizationViews;
  viewType?: EmailSectionType.header | EmailSectionType.footer;
  headerOptions: components["schemas"]["EmailBrandHeaderSectionSchema"];
  footerOptions: components["schemas"]["EmailBrandFooterSectionSchema"];
  previewMode: "desktop" | "mobile";
  headerLogo?: File;
}

export const CustomizationLabels: Record<CustomizationViews, string> = {
  [CustomizationViews.TypeSelect]: "Campaign Template",
  [CustomizationViews.HeaderOptions]: "Customize Header",
  [CustomizationViews.FooterOptions]: "Customize Footer",
  [CustomizationViews.MenuLinks]: "Menu Links",
  [CustomizationViews.SocialLinks]: "Social Links",
};
