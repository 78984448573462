import { AdMediaUpdateableElement, useAdMediaContext } from "../AdMediaContext";
import AdMediaSVGCanvas from "../AdMediaSVGCanvas";
import { components } from "@openapi";
import { Flex, Spinner } from "@radix-ui/themes";
import { ImageDown } from "lucide-react";
import { useEffect, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useAdEditorSelectedAdMediaState,
  useAdEditorState,
} from "~/contexts/AdEditorContext";
import useGetAdCreativeMediaWithElements from "~/hooks/ads/useGetAdCreativeMediaWithElements";
import { getNumericAdMediaAspectRatio } from "~/utils/ads/helpers";
import { downloadElementImage } from "~/utils/helpers";

const EditorPreview: React.FC = () => {
  const { media } = useAdEditorSelectedAdMediaState();
  const state = useAdEditorState();
  const [previewMediaId, setPreviewMediaId] = useState<string | undefined>(
    undefined
  );
  const { resetElements } = useAdMediaContext();
  const { elementsQuery } = useGetAdCreativeMediaWithElements(media?.id, {
    skipMediaQuery: true,
  });

  useEffect(() => {
    if (!elementsQuery.data) {
      return;
    }
    if (previewMediaId !== media?.id) {
      setPreviewMediaId(media?.id);
    }
    const data = elementsQuery.data;
    // schema doesn't correctly discriminate the type
    resetElements(
      data.elements as unknown[] as AdMediaUpdateableElement[],
      media!.id
    );
  }, [elementsQuery.data]);

  const isLoading =
    !media || media.id !== previewMediaId || !elementsQuery.data;

  const [isDownloading, setIsDownloading] = useState<string[]>([]);
  const [hasDownloadError, setHasDownloadError] = useState<string[]>([]);
  const handleDownload = (
    variant: components["schemas"]["AdMediaVariantSchema"]
  ) => {
    setIsDownloading([...isDownloading, variant.id]);
    setHasDownloadError((prev) => prev.filter((id) => id !== variant.id));
    downloadElementImage(document.getElementById(variant.id)).then(
      (success) => {
        setIsDownloading((prev) => prev.filter((id) => id !== variant.id));
        if (!success) {
          setHasDownloadError((prev) => [...prev, variant.id]);
        }
      }
    );
  };

  return (
    <Flex
      p="4"
      pt="calc(var(--editor-top-bar-height) + var(--space-3))"
      mr="calc(-1 * var(--editor-side-panel-left-padding))"
      flexGrow="1"
      flexShrink="1"
      direction="column"
      gap="1"
      overflow="auto"
      minWidth="400px"
      style={{
        backgroundColor: "#FCF9F7",
      }}
      {...(isLoading ? { align: "center", justify: "center" } : {})}
    >
      {isLoading ? (
        <Spinner size="3" />
      ) : (
        <Flex gap="4" wrap="wrap" justify="center">
          {media?.variants.map((variant) => (
            <Flex key={variant.id} direction="column" gap="12px">
              {state.showDownloadButtons && (
                <AppButton
                  variant="outlined"
                  onClick={() => handleDownload(variant)}
                  style={{
                    width: "fit-content",
                    ...(hasDownloadError.includes(variant.id) && {
                      border: "1px solid red",
                    }),
                  }}
                >
                  {isDownloading.includes(variant.id) ? (
                    <>
                      <Spinner />
                      Downloading
                    </>
                  ) : (
                    <>
                      <ImageDown size={20} />
                      Download
                    </>
                  )}
                </AppButton>
              )}
              <AdMediaSVGCanvas
                id={variant.id}
                svgUrl={variant.file}
                zoom={state.zoom}
                aspectRatio={getNumericAdMediaAspectRatio(variant.aspect_ratio)}
              />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default EditorPreview;
