import { Card, Flex, Heading, Switch } from "@radix-ui/themes";
import React, { useMemo } from "react";
import styled from "styled-components";

const CustomCard = styled(Flex)`
  border-radius: 20px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 2px 8px 0px #0000000a;
  width: 100%;
`;

const DisablingContainer = styled(Flex)<{ $disabled: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  transition: opacity 0.3s ease;
`;

type AdEditorCardProps = {
  title: string;
  switchDisabled?: boolean;
  padding?: "0" | "1" | "2" | "3" | "4";
} & React.ComponentProps<typeof Card> &
  (
    | {
        checkable: false;
      }
    | {
        checkable: true;
        checked: boolean;
        /** Defaults to `disabled` */
        uncheckedMode?: "disabled" | "hidden";
        onCheckedChange: (checked: boolean) => void;
      }
  );

const EditorCard: React.FC<AdEditorCardProps> = ({
  children,
  title,
  switchDisabled,
  padding = "3",
  ...props
}) => {
  const content = useMemo(() => {
    if (!props.checkable) {
      return children;
    }
    if (props.uncheckedMode === "hidden") {
      return props.checked ? children : null;
    }
    return (
      <DisablingContainer
        $disabled={!props.checked}
        direction="column"
        gap="4"
        width="100%"
      >
        {children}
      </DisablingContainer>
    );
  }, [props.checkable, children]);

  return (
    <CustomCard>
      <Flex direction="column" gap="4" width="100%">
        <Flex
          direction="row"
          justify="between"
          minHeight="20px"
          align="center"
          px="3"
          pt="3"
        >
          <Heading size="2">{title}</Heading>
          {props.checkable && (
            <Switch
              checked={props.checked}
              onCheckedChange={props.onCheckedChange}
              disabled={switchDisabled}
            />
          )}
        </Flex>
        <Flex direction="column" px={padding} pb={padding} gap="2" width="100%">
          {content}
        </Flex>
      </Flex>
    </CustomCard>
  );
};

export default EditorCard;
