import LinkContainer from "./modifiers/LinkContainer";
import { EmailElementType } from "@openapi";
import { Button, Flex } from "@radix-ui/themes";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import {
  defaultMenuLink,
  useHeaderFooterState,
} from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";

const AddLinkContainer = styled(Flex)`
  border: 1px dashed #ddd7d7;
  border-radius: 12px;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;
const MenuLinksCustomization = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const links = state.menu?.menu_links ?? [];
    const [movedLink] = links.splice(result.source.index, 1);
    links.splice(result.destination.index, 0, movedLink);

    updateState({
      ...state,
      menu: {
        ...(state.menu ?? defaultMenuLink),
        menu_links: links.map((link, index) => ({
          ...link,
          index,
        })),
      },
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StrictModeDroppable droppableId="menu-links-droppable" type="group">
        {(provided) => (
          <Flex
            gap="16px"
            direction="column"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {(state.menu?.menu_links ?? []).map((link, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <LinkContainer
                      values={link}
                      onDelete={() => {
                        const links = [...(state.menu?.menu_links ?? [])];
                        links.splice(index, 1);
                        updateState({
                          ...state,
                          menu: {
                            ...(state.menu ?? defaultMenuLink),
                            menu_links: links,
                          },
                        });
                      }}
                      onChange={(values) => {
                        const newLinks = [...(state.menu?.menu_links ?? [])];
                        newLinks.splice(index, 1, values);
                        updateState({
                          ...state,
                          menu: {
                            ...(state.menu ?? defaultMenuLink),
                            menu_links: newLinks,
                          },
                        });
                      }}
                      dragHandleProps={draggableProvided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <AddLinkContainer>
              <Button
                onClick={() =>
                  updateState({
                    ...state,
                    menu: {
                      ...(state.menu ?? defaultMenuLink),
                      menu_links: [
                        ...(state.menu?.menu_links ?? []),
                        {
                          id: "",
                          type: EmailElementType.menu_link,
                          text: `Link ${state.menu?.menu_links?.length ?? 0}`,
                          index: state.menu?.menu_links?.length ?? 0,
                          link_url: "",
                          opens_new_tab: true,
                          enabled: true,
                        },
                      ],
                    },
                  })
                }
                size="4"
                variant="outline"
                color="gray"
                radius="large"
              >
                Add Link
              </Button>
            </AddLinkContainer>
          </Flex>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default MenuLinksCustomization;
