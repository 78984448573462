import { campaignRecommendationTypeContent } from "../recommenderSection/RecommenderCard";
import { components } from "@openapi";
import { Flex, Text, Separator, Skeleton } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const Image = styled.img<{
  $aspectRatio: string;
  $roundCorners: "left" | "right";
  height: string;
}>`
  width: 100%;
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.$aspectRatio};
  object-fit: cover;
  border-radius: ${(props) =>
    props.$roundCorners === "left" ? "12px 0 0 12px" : "0 12px 12px 0"};
  border: none;
`;

const CampaignDraftCard = ({
  campaign,
  isLoading,
}: {
  campaign: components["schemas"]["CampaignSchema"];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  if (!campaign?.campaign_recommendation) return null;

  const IconComponent =
    campaignRecommendationTypeContent[
      campaign.campaign_recommendation.item_set_type
    ]?.icon;

  const emailImages = campaign.email_images ?? [];
  const adImages = campaign.ad_images ?? [];

  return (
    <Card direction="column" p="16px" width="384px" gap="5" justify="between">
      <Flex direction="column" gap="4">
        <Skeleton loading={isLoading}>
          <Flex style={{ borderRadius: "12px" }}>
            <Image
              height="220px"
              $aspectRatio="0.5"
              $roundCorners="left"
              src={emailImages[0] ?? adImages[1] ?? ""}
              alt=""
            />
            <Image
              height="220px"
              $aspectRatio="0.5"
              $roundCorners="right"
              src={adImages[0] ?? emailImages[1] ?? ""}
              alt=""
            />
          </Flex>
        </Skeleton>

        <Skeleton loading={isLoading}>
          <DraperText size="7" weight="medium" clamp={2}>
            {
              campaign.campaign_recommendation?.creative_recommendations?.[0]
                ?.title
            }
          </DraperText>
        </Skeleton>

        <Flex gap="16px" align="center" style={{ color: "#3b3b3b" }}>
          <Flex align="center" gap="10px">
            {IconComponent && (
              <IconComponent
                size={24}
                style={{ color: "var(--primary-vibrant-orange)" }}
              />
            )}
            <Text size="3" weight="medium">
              <Skeleton loading={isLoading}>
                {
                  campaignRecommendationTypeContent[
                    campaign?.campaign_recommendation?.item_set_type
                  ]?.title
                }
              </Skeleton>
            </Text>
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            <Skeleton loading={isLoading}>
              {(campaign?.products_count || 0) > 1
                ? `${campaign?.products_count || 0} Products`
                : `${campaign?.products_count || 0} Product`}
            </Skeleton>
          </Text>
        </Flex>
      </Flex>

      <Skeleton loading={isLoading}>
        <AppButton
          variant="outlined"
          onClick={() => navigate(`/campaign/${campaign.id}`)}
          style={{ width: "max-content" }}
        >
          Resume Editing
          <ArrowRightIcon />
        </AppButton>
      </Skeleton>
    </Card>
  );
};

export default CampaignDraftCard;
