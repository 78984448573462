import StyleLibraryCardContent from "../StyleLibraryCardContent";
import EmailSectionPreview from "./preview/EmailSectionPreview";
import { Flex, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PencilIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useTemplateEditorState } from "~/contexts/TemplateEditorContext";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";

const TemplateCard = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  padding: 16px;
  font-size: 14px;
`;

const CampaignTemplateCardSection = () => {
  useHandlebarsInit();
  const { isLoading } = useBrandStyle();
  const { headerOptions, footerOptions } = useTemplateEditorState();

  const headerTemplate = useMemo(() => {
    const template = headerOptions.template;
    return Handlebars.compile(template.html);
  }, [headerOptions]);

  const footerTemplate = useMemo(() => {
    const template = footerOptions.template;
    return Handlebars.compile(template.html);
  }, [footerOptions]);

  const templates = useMemo(() => {
    return [
      {
        id: headerOptions.id,
        label: "Header",
        html: (
          <EmailSectionPreview template={headerTemplate} vars={headerOptions} />
        ),
      },
      {
        id: footerOptions.id,
        label: "Footer",
        html: (
          <EmailSectionPreview template={footerTemplate} vars={footerOptions} />
        ),
      },
    ];
  }, [headerOptions, footerOptions]);

  return (
    <StyleLibraryCardContent
      actions={
        <Link to="/style-library/template-editor">
          <AppButton variant="ghost" size="3">
            <PencilIcon width={20} height={20} strokeWidth={1.25} /> Edit
          </AppButton>
        </Link>
      }
      title="Campaign Template"
      isLoading={isLoading}
    >
      {templates.map((template, index) => (
        <TemplateCard direction="column" key={template.id || index}>
          <Text>{template.label}</Text>
          {template.html}
        </TemplateCard>
      ))}
    </StyleLibraryCardContent>
  );
};

export default CampaignTemplateCardSection;
