import { FontOrigin } from "@openapi";
import { Box, Flex, Text, TextField } from "@radix-ui/themes";
import { SearchIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { ChevronDownIcon } from "~/assets/icons";

const FontRow = styled(Text)`
  cursor: pointer;
  width: 100%;
  padding: 8px 12px;
  color: #332e2e;
  border-radius: 8px;
  box-sizing: border-box;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export interface FontSelectItem {
  name: string;
  origin: FontOrigin;
}

export interface FontSelectSection {
  title?: string;
  fonts: readonly FontSelectItem[];
}

type FontSelectProps = Omit<
  React.ComponentProps<typeof TextField.Root>,
  "onChange"
> & {
  containerStyle?: React.CSSProperties;
  fontSections: FontSelectSection[];
  value: string;
  onChange: (value: FontSelectItem) => void;
};

const FontSelect: React.FC<FontSelectProps> = ({
  containerStyle,
  value,
  onChange,
  fontSections,
  placeholder,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<FontSelectSection[]>(fontSections);
  const [focused, setFocused] = useState(false);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [currentLastGroupLength, setCurrentLastGroupLength] = useState(10);

  useEffect(() => {
    if (!search.trim().length) {
      setResults(fontSections);
      return;
    }
    const filteredFontSections = fontSections.map((section) => ({
      ...section,
      fonts: section.fonts.filter(({ name }) =>
        name.toLowerCase().startsWith(search.toLowerCase())
      ),
    }));
    setResults(filteredFontSections);
  }, [search, fontSections]);

  const getDropdownYPosition = (): number => {
    if (!boundingRect) return 0;
    const { bottom } = boundingRect;
    const y = bottom + 4;
    if (y + 335 > window.innerHeight) {
      return window.innerHeight - 335;
    }
    return y;
  };

  useEffect(() => {
    const listener: EventListener = (event) => {
      if (!focused || !inputRef.current) {
        return;
      }
      setBoundingRect(inputRef.current.getBoundingClientRect());
    };
    if (focused) {
      window.addEventListener("scroll", listener, true);
    } else {
      window.removeEventListener("scroll", listener, true);
    }
  }, [focused]);

  return (
    <Box
      style={{
        position: "relative",
        ...containerStyle,
      }}
    >
      <TextField.Root
        ref={inputRef}
        value={focused ? search ?? value : value}
        autoComplete="off"
        onChange={(event) => setSearch(event.currentTarget.value)}
        onFocus={(event) => {
          setBoundingRect(event.target.getBoundingClientRect());
          setFocused(true);
          event.target.focus({ preventScroll: true });
        }}
        onKeyDown={(event) => {
          if (event.key === "Escape" && "blur" in event.target) {
            (event.target as HTMLInputElement).blur();
          }
        }}
        onBlur={(e) =>
          setTimeout(() => {
            if (e.target === document.activeElement) return;
            setSearch("");
            setFocused(false);
          }, 100)
        }
        onScrollCapture={() => console.log("onscrollcapture")}
        onScroll={() => console.log("onscroll")}
        radius="large"
        placeholder={placeholder ?? value}
        {...props}
      >
        <TextField.Slot side="right" tabIndex={0} style={{ cursor: "pointer" }}>
          {!focused ? (
            <ChevronDownIcon width={20} />
          ) : (
            <SearchIcon width={20} />
          )}
        </TextField.Slot>
      </TextField.Root>
      {focused && (
        <Box
          p="2"
          mt="2"
          style={{
            position: "fixed",
            zIndex: 100,
            border: "1px solid #DDD7D7",
            borderRadius: "8px",
            backgroundColor: "white",
            minWidth: "200px",
            maxHeight: "325px",
            top: getDropdownYPosition(),
            width: boundingRect?.width ?? "inherit",
            overflow: "hidden",
          }}
        >
          <InfiniteScroll
            height="320px"
            next={() => setCurrentLastGroupLength((prev) => prev + 10)}
            hasMore={currentLastGroupLength !== results.at(-1)?.fonts.length}
            dataLength={currentLastGroupLength}
            loader={<h4>Loading...</h4>}
          >
            {results.map((section, index) => (
              <Flex key={index} direction="column" gap="4px" p="2">
                <Text style={{ color: "#838690" }}>
                  {section.title?.toUpperCase()}
                </Text>
                {section.fonts
                  .slice(
                    index === results.length - 1 ? 0 : undefined,
                    index === results.length - 1
                      ? currentLastGroupLength
                      : undefined
                  )
                  .map((font) => (
                    <FontRow
                      key={font.name}
                      onMouseDown={(e: React.MouseEvent<HTMLSpanElement>) => {
                        e.stopPropagation();
                        onChange(font);
                        setFocused(false);
                      }}
                    >
                      {font.name}
                    </FontRow>
                  ))}
              </Flex>
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  );
};

export default FontSelect;
