function getTextWidth(svgElement: SVGTextElement, text: string): number {
  const tempElement = svgElement.cloneNode(true) as SVGTextElement;
  tempElement.style.visibility = "hidden";
  tempElement.textContent = text;

  const svgParent = svgElement.parentNode!;
  svgParent.appendChild(tempElement);

  const width = tempElement.getBBox().width;

  svgParent.removeChild(tempElement);
  return width;
}

function breakString(
  svgElement: SVGTextElement,
  word: string,
  maxWidth: number,
  hyphenCharacter = "-"
) {
  const characters = word.split("");
  const lines: string[] = [];
  let currentLine = "";

  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(svgElement, nextLine + hyphenCharacter);

    if (lineWidth > maxWidth && currentLine !== "") {
      lines.push(currentLine + hyphenCharacter);
      currentLine = character;
    } else {
      currentLine = nextLine;
    }

    // Handle the last character
    if (index === characters.length - 1) {
      lines.push(currentLine);
    }
  });

  return { hyphenatedStrings: lines, remainingWord: "" };
}

function wrapLabel(
  svgElement: SVGTextElement,
  label: string,
  maxWidth: number
): string[] {
  const words = label.split(" ");
  const completedLines: string[] = [];
  let nextLine = "";

  words.forEach((word, index) => {
    const wordWidth = getTextWidth(svgElement, word + " ");
    const lineWidth = getTextWidth(svgElement, nextLine);

    if (wordWidth > maxWidth) {
      // Handle single words that are too long by breaking them with hyphens
      const { hyphenatedStrings } = breakString(svgElement, word, maxWidth);
      if (nextLine) {
        completedLines.push(nextLine);
      }
      completedLines.push(...hyphenatedStrings);
      nextLine = "";
    } else if (lineWidth + wordWidth > maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = nextLine ? nextLine + " " + word : word;
    }

    // Handle the last word
    if (index === words.length - 1 && nextLine) {
      completedLines.push(nextLine);
    }
  });

  return completedLines.filter((line) => line !== "");
}

function extractMaxDimensions(element: SVGElement) {
  const style = element.getAttribute("style");

  let maxWidth: number = Infinity;
  let maxHeight: number = Infinity;

  if (style) {
    const maxWidthMatch = style.match(/max-width:\s*([0-9.]+)px/);
    if (maxWidthMatch) {
      maxWidth = parseFloat(maxWidthMatch[1]);
    }

    const maxHeightMatch = style.match(/max-height:\s*([0-9.]+)px/);
    if (maxHeightMatch) {
      maxHeight = parseFloat(maxHeightMatch[1]);
    }
  }

  // Fallback to element bounds if max width/height not available in styles
  const boundingRect = element.getBoundingClientRect();
  if (!style || !style.includes("max-width")) {
    maxWidth = boundingRect.width || 100; // Default to 100px if width is zero
  }
  if (!style || !style.includes("max-height")) {
    maxHeight = boundingRect.height || 100; // Default to 100px if height is zero
  }

  return {
    maxWidth,
    maxHeight,
  };
}

function getFontFromElement(element: SVGElement) {
  const computedStyle = window.getComputedStyle(element);
  const fontWeight =
    computedStyle.fontWeight || element.getAttribute("font-weight") || "normal";
  const fontSize =
    computedStyle.fontSize || element.getAttribute("font-size") || "12px";
  const fontFamily =
    computedStyle.fontFamily ||
    element.getAttribute("font-family") ||
    "sans-serif";
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

function getLineHeightFromFont(font: string): number {
  const fontSizeMatch = font.match(/(\d+(\.\d+)?)px/);
  const fontSize = fontSizeMatch ? parseFloat(fontSizeMatch[1]) : 12;
  return fontSize * 1.2;
}

function binarySearchFontSize(
  element: SVGTextElement,
  textContent: string,
  maxWidth: number,
  maxHeight: number,
  minFontSize: number,
  maxFontSize: number
): { fontSize: number; wrappedLines: string[] } {
  let low = minFontSize;
  let high = maxFontSize;
  let bestFit = minFontSize;
  let bestWrappedLines: string[] = [];
  let wrappedLines: string[] = [];

  do {
    const midFontSize = Math.floor((low + high) / 2);
    element.setAttribute("font-size", String(midFontSize));
    const font = getFontFromElement(element);
    const lineHeight = getLineHeightFromFont(font);
    wrappedLines = wrapLabel(element, textContent, maxWidth);

    // Calculate total height of wrapped lines
    const totalHeight = wrappedLines.length * lineHeight;

    if (totalHeight <= maxHeight) {
      bestFit = midFontSize;
      bestWrappedLines = wrappedLines;
      low = midFontSize + 1;
    } else {
      high = midFontSize - 1;
    }
  } while (low <= high);

  return {
    fontSize: bestFit,
    wrappedLines: bestWrappedLines.length ? bestWrappedLines : wrappedLines,
  };
}

function clipLineWithEllipsis(
  svgElement: SVGTextElement,
  line: string,
  maxWidth: number
): string {
  let clippedLine = line;
  let textWidth = getTextWidth(svgElement, clippedLine + "...");

  while (textWidth > maxWidth && clippedLine.length > 0) {
    clippedLine = clippedLine.slice(0, -1);
    textWidth = getTextWidth(svgElement, clippedLine + "...");
  }

  return clippedLine + "...";
}

interface SVGTextElementProps {
  fontSize?: number;
  fontFamily?: string;
  fontWeight?: string;
  textAlignment?: string;
  color?: string;
}

export default function layoutAndWrapTextElement(
  element: SVGElement,
  textContent: string,
  {
    fontSize: inputFontSize,
    fontFamily: inputFontFamily,
    fontWeight: inputFontWeight,
    textAlignment: inputTextAlignment,
    color: inputColor,
  }: SVGTextElementProps = {}
) {
  if (element.tagName.toLowerCase() === "text") {
    const parent = element.parentElement!;
    const id = element.id;
    const newElement = parent!.querySelector<SVGForeignObjectElement>(`#${id}`);
    updateForeignObjectTextElement(newElement!, textContent, {
      fontSize: inputFontSize,
      fontFamily: inputFontFamily,
      fontWeight: inputFontWeight,
      textAlignment: inputTextAlignment,
      color: inputColor,
    });
    return;
  }

  if (element.tagName.toLowerCase() === "foreignobject") {
    updateForeignObjectTextElement(
      element as SVGForeignObjectElement,
      textContent,
      {
        fontSize: inputFontSize,
        fontFamily: inputFontFamily,
        fontWeight: inputFontWeight,
        textAlignment: inputTextAlignment,
        color: inputColor,
      }
    );
    return;
  }
}

function wrapTextElement(
  element: SVGTextElement,
  textContent: string,
  {
    fontSize: inputFontSize,
    fontFamily: inputFontFamily,
    fontWeight: inputFontWeight,
    color: inputColor,
  }: SVGTextElementProps = {}
) {
  // Capture the initial bounds before any changes
  const initialBounds = element.getBoundingClientRect();

  const { maxWidth, maxHeight } = extractMaxDimensions(element);

  if (inputFontFamily) {
    element.setAttribute("font-family", inputFontFamily);
  }
  if (inputFontWeight) {
    element.setAttribute("font-weight", inputFontWeight);
  }
  if (inputFontSize) {
    element.setAttribute("font-size", String(inputFontSize));
  }
  if (inputColor) {
    element.setAttribute("fill", inputColor);
  }

  const initialX = parseFloat(element.getAttribute("x") ?? "0");
  const initialY = parseFloat(element.getAttribute("y") ?? "0");

  const font = getFontFromElement(element);
  const lineHeight = getLineHeightFromFont(font);

  // Wrap the text
  let wrappedLines = wrapLabel(
    element as SVGTextElement,
    textContent,
    maxWidth
  );

  // Calculate total height of wrapped lines
  let totalHeight = wrappedLines.length * lineHeight;

  // If total height exceeds maxHeight, clip the text with "..."
  if (totalHeight > maxHeight) {
    const maxLines = Math.floor(maxHeight / lineHeight);
    wrappedLines = wrappedLines.slice(0, maxLines); // Keep only lines that fit
    const lastVisibleLine = wrappedLines.pop() || "";
    const clippedLine = clipLineWithEllipsis(
      element as SVGTextElement,
      lastVisibleLine,
      maxWidth
    );
    wrappedLines.push(clippedLine);
  }

  // Clear the existing text content
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }

  // Append each line as a tspan element
  wrappedLines.forEach((line, index) => {
    const tspan = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "tspan"
    );
    tspan.setAttribute("x", `${initialX}`);
    tspan.setAttribute("dy", `${index === 0 ? 0 : 1}em`);
    tspan.textContent = line;
    element.appendChild(tspan);
  });

  // Adjust the y coordinate while keeping the text vertically centered
  const newBounds = element.getBoundingClientRect();
  const yAdjustment = Math.max(
    initialY + (initialBounds.height - newBounds.height) / 2,
    0
  );
  element.setAttribute("y", yAdjustment.toString());
}

function swapElementWithForeignObject(element: SVGTextElement) {
  const initialX = parseFloat(element.getAttribute("x") ?? "0");
  const initialY = parseFloat(element.getAttribute("y") ?? "0");

  const { maxWidth, maxHeight } = extractMaxDimensions(element);
  const font = getFontFromElement(element);
  const lineHeight = getLineHeightFromFont(font);
  // estimation of baseline
  const baseline = lineHeight / 1.2 / 2;

  const container = document.createElement("foreignObject");
  container.id = element.id;
  const anchor = element.getAttribute("text-anchor");
  let x = initialX;
  let y = initialY;
  if (anchor === "middle") {
    x -= maxWidth / 2;
  } else if (anchor === "end") {
    x -= maxWidth;
  }
  y = y - maxHeight / 2 - baseline;

  container.setAttribute("x", x.toString());
  container.setAttribute("y", y.toString());
  container.setAttribute("width", maxWidth.toString());
  container.setAttribute("height", maxHeight.toString());
  container.setAttribute("fill", element.getAttribute("fill") || "black");
  const div = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
  div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
  container.appendChild(div);
  div.style.width = "100%";
  div.style.height = "100%";
  div.style.maxWidth = "100%";
  div.style.color =
    element.getAttribute("fill") || element.getAttribute("stroke") || "";
  if (anchor === "middle") {
    div.style.textAlign = "center";
  }
  div.style.alignContent = "center";
  div.style.overflow = "hidden";
  div.style.textOverflow = "ellipsis";
  div.style.lineHeight = "normal";
  const maxLines = Math.round(maxHeight / lineHeight);
  if (maxLines <= 1) {
    div.style.whiteSpace = "nowrap";
  }

  element.outerHTML = container.outerHTML;
}

function updateForeignObjectTextElement(
  element: SVGForeignObjectElement,
  textContent: string,
  {
    fontSize: inputFontSize,
    fontFamily: inputFontFamily,
    fontWeight: inputFontWeight,
    textAlignment: inputTextAlignment,
    color: inputColor,
  }: SVGTextElementProps = {}
) {
  const div = element.childNodes[0] as HTMLDivElement;
  div.innerHTML = textContent;
  if (inputFontFamily) {
    element.setAttribute("font-family", inputFontFamily);
    div.style.fontFamily = inputFontFamily;
  }
  if (inputFontWeight) {
    element.setAttribute("font-weight", inputFontWeight);
    div.style.fontWeight = inputFontWeight;
  }
  if (inputFontSize) {
    element.setAttribute("font-size", String(inputFontSize));
    div.style.fontSize = `${inputFontSize}px`;
  }
  if (inputTextAlignment) {
    div.style.textAlign = inputTextAlignment;
  }
  if (inputColor) {
    element.setAttribute("fill", inputColor);
    div.style.color = inputColor;
  }
}

// region images

export function updateImageElement(element: SVGElement, imageUrl: string) {
  const fill = element.getAttribute("fill");
  const fillId = fill?.match(/url\(#(.*)\)/)?.[1];
  if (!fillId) {
    console.error("Image element has no fill id");
    return;
  }
  const fillElement = document.getElementById(fillId)?.children[0];
  if (fillElement?.tagName === "image") {
    fillElement?.setAttribute("href", imageUrl);
    return;
  }
  if (fillElement?.tagName === "use") {
    const imageId = fillElement.getAttribute("xlink:href");
    if (imageId) {
      const imageElement = document.getElementById(imageId.replace("#", ""));
      imageElement?.setAttribute("href", imageUrl);
    }
    return;
  }
  console.error("Failed to find where to update image");
}

// endregion images

// region shapes

export function updateShapeElementColor(element: SVGElement, color: string) {
  element.setAttribute("fill", color);
}

// endregion
