import { useCampaignWizardState } from "../../contexts/CampaignWizardContext";
import { useCallback, useEffect, useRef } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

const useCenterCampaignCard = () => {
  const { currentStep } = useCampaignWizardState();
  const currentStepRef = useRef<number>(currentStep);
  const triggerRef = useRef<number>(0);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  const centerCurrentCard = useCallback(() => {
    const element = document.getElementById(`step-${currentStep}`);
    const step = currentStep;
    if (element) {
      setTimeout(() => {
        if (step !== currentStepRef.current) {
          return;
        }
        triggerRef.current += 1;
        const trigger = triggerRef.current;
        scrollIntoView(element, {
          behavior: "smooth",
          block: "center",
          inline: "center",
        });

        setTimeout(() => {
          if (step !== currentStepRef.current) {
            return;
          }
          if (triggerRef.current !== trigger) {
            return;
          }
          scrollIntoView(element, {
            behavior: "instant",
            block: "center",
            inline: "center",
          });
        }, 1000);
      }, 50);
    }
  }, [currentStep, currentStepRef]);

  return { centerCurrentCard };
};

export default useCenterCampaignCard;
