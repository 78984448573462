import ModifierContainer from "./ModifierContainer";
import SectionTextModifier from "./SectionTextModifier";
import { components } from "@openapi";
import { Card, Flex, Switch } from "@radix-ui/themes";
import { GripVertical } from "lucide-react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import DraperText from "~/components/core/DraperText";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const ListCard = ({
  title,
  listicleElement,
  fieldName,
  enabled,
  onSwitchToggle,
}: {
  title: string;
  listicleElement: components["schemas"]["EmailTextListElementSchema"];
  fieldName: string;
  enabled: boolean;
  onSwitchToggle: (enabled: boolean) => void;
}) => {
  return (
    <Card>
      <Flex align="center" justify="between" mb="2">
        <Flex align="center" gap="2">
          <GripVertical size={20} />
          <DraperText size={"3"} weight={"medium"}>
            {title}
          </DraperText>
        </Flex>
        <Flex align="center" gap="2">
          <DraperText size={"1"}>Show</DraperText>
          <Switch
            checked={enabled}
            onCheckedChange={onSwitchToggle}
            color="gray"
            highContrast
          />
        </Flex>
      </Flex>
      <Flex direction="column" gap="2">
        <SectionTextModifier
          textElement={listicleElement.title}
          fieldName={`${fieldName}.title.text`}
          label="Title"
        />

        <SectionTextModifier
          textElement={listicleElement.subtitle}
          fieldName={`${fieldName}.subtitle.text`}
          label="Subtitle"
        />
      </Flex>
    </Card>
  );
};

const ListicleModifier = ({
  items,
}: {
  items: components["schemas"]["EmailTextListElementSchema"][];
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId } = useEmailState();

  return (
    <DragDropContext
      onDragEnd={(result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        const newListItems = Array.from(items);
        const [reorderedProduct] = newListItems.splice(source.index, 1);
        newListItems.splice(destination.index, 0, reorderedProduct);

        updateSection({
          sectionId: selectedSectionId,
          field: "list_items",
          value: newListItems,
        });
      }}
    >
      <ModifierContainer title="Listicle" hideSwitch={true}>
        <StrictModeDroppable
          droppableId="listicle-modifier-droppable"
          type="group"
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Flex direction="column" gap="2">
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ListCard
                          title={`List Item ${index + 1}`}
                          fieldName={`list_items.${index}`}
                          listicleElement={item}
                          enabled={item.enabled}
                          onSwitchToggle={(enabled) => {
                            updateSection({
                              sectionId: selectedSectionId,
                              field: `list_items.${index}.enabled`,
                              value: enabled,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            </div>
          )}
        </StrictModeDroppable>
      </ModifierContainer>
    </DragDropContext>
  );
};

export default ListicleModifier;
