import { components } from "@openapi";
import { useMemo } from "react";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { getEmailStateImageUrl } from "~/types/template-vars";

const LogoSelector = ({
  img,
  onUpload,
}: {
  img: components["schemas"]["EmailImageElementSchema"] | null;
  onUpload: (img: File) => void;
}) => {
  const imageUrl = useMemo(() => {
    if (!img) return undefined;
    return getEmailStateImageUrl(img);
  }, [img]);

  return (
    <ImageSelector
      value={imageUrl}
      onUpload={onUpload}
      buttonText="Change Logo"
      placeholderImage="https://via.placeholder.com/150"
      noImageText="Logo not set"
    />
  );
};

export default LogoSelector;
