import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BlogPostModifier from "./modifiers/BlogPostModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isBlogSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const BlogView = () => {
  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (isNewSection(selectedSection)) {
    return null;
  }

  if (!isBlogSection(selectedSection)) {
    return null;
  }

  return (
    <>
      <GeneratedVariantSelector />

      <BlogPostModifier blogElement={selectedSection} />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default BlogView;
