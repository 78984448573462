import layoutAndWrapTextElement from "../../../utils/ads/intern/svg";
import { Button, Flex, Heading, Text } from "@radix-ui/themes";
import React, { useState, useEffect, ChangeEvent, FocusEvent } from "react";

interface AttributeInfo {
  name: string;
  value: string;
}

interface InternAdMediaTemplateVariantSVGElementInspectorTabProps {
  element: SVGElement | null;
  stateCounter: number;
  onAttributeChange: () => void;
}

interface InternSVGElementAttributeRowProps {
  initialName: string;
  initialValue: string;
  index: number;
  isNameDisabled?: boolean;
  isValueDisabled?: boolean;
  onAttributeChange: (
    name: string,
    value: string,
    originalName: string
  ) => void;
  onAttributeRemove: () => void;
}
const InternSVGElementAttributeRow: React.FC<
  InternSVGElementAttributeRowProps
> = ({
  initialName,
  initialValue,
  index,
  isNameDisabled = false,
  isValueDisabled = false,
  onAttributeChange,
  onAttributeRemove,
}) => {
  const [name, setName] = useState(initialName);

  const [value, setValue] = useState(initialValue);

  const [originalName, setOriginalName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
    setValue(initialValue);
    setOriginalName(initialName);
  }, [initialName, initialValue]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onAttributeChange(name, e.target.value, originalName);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (name.trim() === "") {
      onAttributeRemove();
    } else if (name !== originalName || value !== initialValue) {
      onAttributeChange(name, value, originalName);
      setOriginalName(name);
    }
  };

  return (
    <Flex
      align="center"
      justify="between"
      width="100%"
      style={{
        backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
        cursor: "pointer",
        paddingTop: "4px",
        paddingBottom: "4px",
        marginBottom: "4px",
      }}
    >
      <input
        type="text"
        value={name}
        disabled={isNameDisabled}
        onBlur={handleBlur}
        onChange={handleNameChange}
        style={{
          marginRight: "8px",
          flex: 1,
          border: "none",
          background: "transparent",
        }}
      />
      <input
        type="text"
        value={value}
        disabled={isValueDisabled}
        onBlur={handleBlur}
        onChange={handleValueChange}
        style={{
          flex: 3,
          border: "none",
          background: "transparent",
        }}
      />
    </Flex>
  );
};

const InternAdMediaTemplateVariantSVGElementInspectorTab: React.FC<
  InternAdMediaTemplateVariantSVGElementInspectorTabProps
> = ({ element, stateCounter, onAttributeChange }) => {
  const [svgStateCounter, setSVGStateCounter] = useState<number | null>(null);

  const [attributes, setAttributes] = useState<AttributeInfo[]>([]);

  const [styleAttributes, setStyleAttributes] = useState<AttributeInfo[]>([]);

  const [textContent, setTextContent] = useState<string>("");

  useEffect(() => {
    if (element) {
      const elementType = element.tagName.toLowerCase();

      const attributes = Array.from(element.attributes)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((attr) => ({ name: attr.name, value: attr.value }));

      // Ensure `id` attribute is included
      if (!attributes.find((attr) => attr.name === "id")) {
        attributes.push({
          name: "id",
          value: element.id || "",
        });
      }

      setAttributes(attributes);

      // Extract and set `style` attributes
      const styleAttr = element.getAttribute("style");
      const styleAttributes = styleAttr
        ? styleAttr
            .split(";")
            .map((style) => style.trim())
            .filter((style) => style)
            .map((style) => {
              const [name, value] = style.split(":").map((str) => str.trim());
              return { name, value };
            })
        : [];
      setStyleAttributes(styleAttributes);

      // Set text content for text and tspan elements
      if (
        elementType === "text" ||
        elementType === "tspan" ||
        elementType === "div"
      ) {
        setTextContent(element.textContent || "");
      }
    }

    if (svgStateCounter && svgStateCounter !== stateCounter) {
      onAttributeChange();
    }

    setSVGStateCounter(stateCounter);
  }, [element, stateCounter, svgStateCounter, onAttributeChange]);

  const handleAttributeChange = (
    name: string,
    value: string,
    originalName: string
  ) => {
    if (element) {
      if (name === "text-content") {
        if (element.tagName.toLowerCase() === "text") {
          layoutAndWrapTextElement(element, value);
        } else {
          element.textContent = value;
        }
        setTextContent(value);
        onAttributeChange();
        return;
      }

      if (name !== originalName) {
        element.removeAttribute(originalName);
      }
      element.setAttribute(name, value);
      onAttributeChange();
      setAttributes((prevAttributes) =>
        prevAttributes.map((attr) =>
          attr.name === originalName ? { name, value } : attr
        )
      );
    }
  };

  const handleStyleAttributeChange = (
    name: string,
    value: string,
    originalName: string
  ) => {
    if (element) {
      const updatedStyle = styleAttributes.map((attr) =>
        attr.name === originalName ? { name, value } : attr
      );
      const styleString = updatedStyle
        .map((attr) => `${attr.name}:${attr.value}`)
        .join("; ");
      element.setAttribute("style", styleString);
      onAttributeChange();
      setStyleAttributes(updatedStyle);
    }
  };

  const addNewAttribute = () => {
    setAttributes((prevAttributes) => [
      ...prevAttributes,
      { name: "", value: "" },
    ]);
  };

  const addNewStyleAttribute = () => {
    setStyleAttributes((prevStyleAttributes) => [
      ...prevStyleAttributes,
      { name: "", value: "" },
    ]);
  };

  const removeAttribute = (index: number) => {
    if (element) {
      const attributeName = attributes[index].name;
      element.removeAttribute(attributeName);

      setAttributes((prevAttributes) =>
        prevAttributes.filter((_, i) => i !== index)
      );

      onAttributeChange();
    }
  };

  const removeStyleAttribute = (index: number) => {
    if (element) {
      const updatedStyle = styleAttributes.filter((_, i) => i !== index);
      const styleString = updatedStyle
        .map((attr) => `${attr.name}:${attr.value}`)
        .join("; ");

      element.setAttribute("style", styleString);

      setStyleAttributes(updatedStyle);

      onAttributeChange();
    }
  };

  if (!element) {
    return (
      <Text as="p" size="1">
        No element selected
      </Text>
    );
  }

  return (
    <Flex direction="column" gap="8px">
      <Heading size="2">Layout</Heading>
      <InternSVGElementAttributeRow
        key="layout-width"
        initialName={"width"}
        initialValue={String(Math.round(element.clientWidth))}
        index={-3}
        isNameDisabled={true}
        isValueDisabled={true}
        onAttributeChange={handleAttributeChange}
        onAttributeRemove={() => {}}
      />
      <InternSVGElementAttributeRow
        key="layout-height"
        initialName={"height"}
        initialValue={String(Math.round(element.clientHeight))}
        index={-2}
        isNameDisabled={true}
        isValueDisabled={true}
        onAttributeChange={handleAttributeChange}
        onAttributeRemove={() => {}}
      />
      {(element.tagName.toLowerCase() === "text" ||
        element.tagName.toLowerCase() === "tspan" ||
        element.tagName.toLowerCase() === "div") && (
        <>
          <Heading size="2">Text</Heading>
          <InternSVGElementAttributeRow
            key="text-content"
            initialName={"text-content"}
            initialValue={textContent}
            index={-1}
            isNameDisabled={true}
            onAttributeChange={handleAttributeChange}
            onAttributeRemove={() => {}}
          />
        </>
      )}
      <Flex direction="row" align="center" justify="between">
        <Heading size="2">Attributes</Heading>
        <Button onClick={addNewAttribute} size="1">
          +
        </Button>
      </Flex>
      {attributes.map((attr, index) => (
        <InternSVGElementAttributeRow
          key={attr.name}
          initialName={attr.name}
          initialValue={attr.value}
          index={index}
          isNameDisabled={false}
          onAttributeChange={handleAttributeChange}
          onAttributeRemove={() => removeAttribute(index)}
        />
      ))}
      <Flex direction="row" align="center" justify="between">
        <Heading size="2">Style Attributes</Heading>
        <Button onClick={addNewStyleAttribute} size="1">
          +
        </Button>
      </Flex>
      {styleAttributes.map((styleAttribute, index) => (
        <InternSVGElementAttributeRow
          key={`style-${index}`}
          initialName={styleAttribute.name}
          initialValue={styleAttribute.value}
          index={index}
          onAttributeChange={handleStyleAttributeChange}
          onAttributeRemove={() => removeStyleAttribute(index)}
        />
      ))}
    </Flex>
  );
};

export default InternAdMediaTemplateVariantSVGElementInspectorTab;
