import { Grid } from "@radix-ui/themes";
import React from "react";
import CreateAccountHero from "~/components/auth/create-account/CreateAccountHero";
import CreateAccountPanel from "~/components/auth/create-account/CreateAccountPanel";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";

const CreateAccount = () => {
  return (
    <>
      <BackgroundGradient variant="2" />
      <Grid
        width="100%"
        height="100%"
        columns={{ initial: "1", md: "60% 1fr" }}
        rows={"1"}
      >
        <CreateAccountHero />
        <CreateAccountPanel />
      </Grid>
    </>
  );
};

export default CreateAccount;
