import * as Form from "@radix-ui/react-form";
import { Flex } from "@radix-ui/themes";
import React from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { ActionCard } from "~/components/core/cards/ActionCard";
import {
  useCampaignWizardNextStep,
  useCampaignWizardPreviousStep,
} from "~/contexts/CampaignWizardContext";

export interface CampaignWizardCardProps {
  children: React.ReactNode;
  badgeTitle: string;
  subtitle?: string | string[];
  nextButtonProps: {
    icon?: React.ReactNode;
    onClick?: () => void;
    text?: React.ReactNode | string;
    disabled?: boolean;
    validate?: boolean;
  };
  backButtonProps?: {
    onClick?: () => void;
    hide: boolean;
  };
  additionalButtons?: React.ReactNode;
  hideFooter?: boolean;
}

const CampaignWizardCard = ({
  children,
  badgeTitle,
  subtitle,
  nextButtonProps,
  backButtonProps,
  additionalButtons,
  hideFooter,
}: CampaignWizardCardProps) => {
  const previousStep = useCampaignWizardPreviousStep();
  const nextStep = useCampaignWizardNextStep();

  const handleClickBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    (backButtonProps?.onClick ?? previousStep)();
    e.currentTarget.blur();
  };

  const handleClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    (nextButtonProps.onClick ?? nextStep)();
    e.currentTarget.blur();
  };

  const nextButton = (
    <AppButton
      onClick={handleClickNext}
      variant="dark"
      disabled={nextButtonProps.disabled}
    >
      {nextButtonProps.icon}
      {nextButtonProps.text ?? "Looks good"}
    </AppButton>
  );

  return (
    <Flex overflowX="hidden" overflowY="auto" p="32px" maxHeight="100vh">
      <ActionCard.Root
        title={badgeTitle}
        subtitle={subtitle ?? ""}
        variant="compact"
        footer={
          !hideFooter && (
            <>
              {!backButtonProps?.hide && (
                <AppButton onClick={handleClickBack} variant="outlined">
                  Back
                </AppButton>
              )}
              {additionalButtons}
              {nextButtonProps.validate ? (
                <Form.Submit asChild>{nextButton}</Form.Submit>
              ) : (
                nextButton
              )}
            </>
          )
        }
      >
        {children}
      </ActionCard.Root>
    </Flex>
  );
};

export default CampaignWizardCard;
