import { components } from "@openapi";
import { Button, Flex, Switch, Text, TextField } from "@radix-ui/themes";
import { GripVerticalIcon } from "lucide-react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styled from "styled-components";
import { Trash2Icon } from "~/assets/icons";

const Container = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  gap: 8px;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
`;

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: #888282;
`;

const LinkContainer = ({
  values,
  onChange,
  dragHandleProps,
  onDelete,
}: {
  values: components["schemas"]["EmailMenuLinkElementSchema"];
  onChange: (
    values: components["schemas"]["EmailMenuLinkElementSchema"]
  ) => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onDelete: () => void;
}) => {
  return (
    <Container>
      <Flex justify="between" align="center">
        <div {...dragHandleProps}>
          <GripVerticalIcon />
        </div>
        <Button onClick={onDelete} size="4" variant="ghost" color="gray">
          <Trash2Icon /> Delete
        </Button>
      </Flex>
      <Flex direction="column" gap="4px">
        <Label as="label">Label</Label>
        <TextField.Root
          value={values.text}
          onChange={(e) =>
            onChange({
              ...values,
              text: e.target.value,
            })
          }
          size="3"
          radius="large"
          color="gray"
        />
      </Flex>
      <Flex direction="column" gap="4px">
        <Label as="label">URL</Label>
        <TextField.Root
          value={values.link_url}
          onChange={(e) =>
            onChange({
              ...values,
              link_url: e.target.value,
            })
          }
          placeholder="https://example.com"
          size="3"
          radius="large"
          color="gray"
        />
      </Flex>
      <Flex gap="16px" align="center">
        <Text size="2" weight="regular">
          Open in new tab
        </Text>
        <Switch
          checked={values.opens_new_tab}
          onCheckedChange={(c) =>
            onChange({
              ...values,
              opens_new_tab: c,
            })
          }
          color="gray"
          highContrast
        />
      </Flex>
    </Container>
  );
};

export default LinkContainer;
