import { components } from "@openapi";
import { Flex, Switch, Text, TextField } from "@radix-ui/themes";
import ModifierContainer from "~/components/editor/editor/sidepanel-views/modifiers/ModifierContainer";

const SocialLinkContainer = ({
  values,
  onChange,
}: {
  values: {
    link: components["schemas"]["EmailLinkElementSchema"];
    selector: string;
  };
  onChange: (values: components["schemas"]["EmailLinkElementSchema"]) => void;
}) => {
  return (
    <ModifierContainer
      title={values.selector}
      checked={values.link?.enabled ?? false}
      onSwitch={(c) =>
        onChange({
          ...values.link,
          enabled: c,
        })
      }
      hideSwitch={false}
    >
      <Flex direction="column" gap="4px">
        <TextField.Root
          value={values.link.link_url}
          onChange={(e) =>
            onChange({
              ...values.link,
              link_url: e.target.value,
            })
          }
          size="3"
          radius="large"
          color="gray"
          placeholder="https://www.example.com"
        />
      </Flex>
      <Flex gap="16px" align="center">
        <Text size="2" weight="regular">
          Open in new tab
        </Text>
        <Switch
          checked={values.link.opens_new_tab}
          onCheckedChange={(c) =>
            onChange({
              ...values.link,
              opens_new_tab: c,
            })
          }
          color="gray"
          highContrast
        />
      </Flex>
    </ModifierContainer>
  );
};

export default SocialLinkContainer;
