import { components, EmailSectionType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { useState } from "react";
import styled from "styled-components";
import ColorPaletteModifier from "~/components/core/editor/sidepane/ColorPaletteModifier";
import ExploreLayoutVariantsSection from "~/components/core/editor/sidepane/ExploreLayoutVariantsSection";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useEmailSectionTemplatesQuery from "~/hooks/emails/useEmailSectionTemplatesQuery";
import useInternEmailsSectionInitQuery from "~/hooks/intern/useInternEmailsSectionInitQuery";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const TemplateList = styled.div`
  width: 300px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding: 20px;
`;

const TemplateItem = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${(props) =>
    props.$isSelected ? "#e0e0e0" : "transparent"};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const PreviewContainer = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
`;

const InternEmailTemplatesComponent = () => {
  useHandlebarsInit();
  const [palette, setPalette] = useState<
    components["schemas"]["ColorPaletteSchema"] | null
  >(null);
  const [sectionType, setSectionType] = useState<EmailSectionType>(
    EmailSectionType.products
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const activeBrandID = useActiveBrandID();

  const {
    data: initData,
    isLoading: isInitLoading,
    error: initError,
  } = useInternEmailsSectionInitQuery({
    brandId: activeBrandID,
    sectionType: sectionType,
  });

  const { templates: sectionTemplates } = useEmailSectionTemplatesQuery(
    useActiveBrandID(),
    sectionType
  );

  if (initError) return <div>Error: {(initError as Error).message}</div>;

  const selectedTemplate = sectionTemplates[currentIndex];

  return (
    <Container>
      <TemplateList>
        {Object.keys(EmailSectionType).map((sectionType) => (
          <TemplateItem
            key={sectionType}
            $isSelected={selectedTemplate?.section_type === sectionType}
            onClick={() => {
              setSectionType(sectionType as EmailSectionType);
            }}
          >
            {sectionType}
          </TemplateItem>
        ))}
      </TemplateList>

      <PreviewContainer
        dangerouslySetInnerHTML={{
          __html:
            isInitLoading || !initData || !selectedTemplate
              ? "<div>Loading...</div>"
              : Handlebars.compile(selectedTemplate.html)({
                  ...initData,
                  palette,
                }),
        }}
      />

      <Flex direction="column" gap="16px" flexShrink={"0"}>
        <ExploreLayoutVariantsSection
          title={selectedTemplate?.name ?? ""}
          isPreviousDisabled={currentIndex === 0}
          isNextDisabled={currentIndex === sectionTemplates.length - 1}
          onPrevious={() => setCurrentIndex(Math.max(0, currentIndex - 1))}
          onNext={() =>
            setCurrentIndex(
              Math.min(sectionTemplates.length - 1, currentIndex + 1)
            )
          }
        />
        <ColorPaletteModifier
          palette={{
            id: null,
            background: "#FFFFFF",
            foreground: "#000000",
            accent_background: "#0000FF",
            accent_foreground: "#FFFFFF",
          }}
          onClick={setPalette}
        />
      </Flex>
    </Container>
  );
};

export default InternEmailTemplatesComponent;
