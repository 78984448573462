import { Flex, Text } from "@radix-ui/themes";
import styled from "styled-components";
import { ChevronLeftIcon } from "~/assets/icons";
import SidePanelComponents from "~/components/core/editor/sidepane";

const Container = styled(Flex)`
  padding-top: calc(var(--editor-top-bar-height) + var(--space-3));
  margin: 0px 16px;
  height: 100%;
  width: 432px;
  flex-shrink: 0;
`;

const Aside = styled(SidePanelComponents.SidePanelBox)`
  height: calc(100% - 16px);
  z-index: --var(--z-index-editor-side-bar);
  width: 100%;
  flex-direction: column;
  border-radius: 24px;
`;

const Header = styled(Flex)`
  border-bottom: 1px solid #ddd7d7;
  padding: 18px 0px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: min-content;
`;

const DoneButton = styled(Flex)`
  cursor: pointer;
  color: var(--text-link);
  gap: 6px;
  align-items: center;
  position: absolute;
  left: 16px;
`;

interface EditorAsideProps {
  showHeader?: boolean;
  title?: string;
  onBack?: () => void;
  children?: React.ReactNode;
}

const EditorAside = ({
  showHeader,
  title,
  onBack,
  children,
}: EditorAsideProps) => {
  return (
    <Container>
      <Aside>
        {showHeader && (
          <Header>
            <DoneButton onClick={onBack}>
              <ChevronLeftIcon />
              <Text size="4" weight="medium">
                Done
              </Text>
            </DoneButton>
            <Text size="4" weight="medium">
              {title}
            </Text>
          </Header>
        )}
        <Flex direction="column" gap="24px" p="24px" overflow="auto">
          {children}
        </Flex>
      </Aside>
    </Container>
  );
};

export default EditorAside;
