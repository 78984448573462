import Editor, { OnMount } from "@monaco-editor/react";
import { EmailElementType } from "@openapi";
import { useRef } from "react";
import { useHeaderFooterState } from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";

const CustomHTMLView = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);

  const editorRef = useRef<any>(null);

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <Editor
      height="62vh"
      defaultLanguage="html"
      defaultValue={state.custom_html?.html ?? ""}
      onMount={handleEditorDidMount}
      onChange={(value) => {
        updateState({
          ...state,
          custom_html: {
            ...(state.custom_html ?? {
              id: "",
              type: EmailElementType.custom_html,
              enabled: true,
              html:
                value ??
                "<div style='text-align: center; padding: 20px;'>Hello World</div>",
            }),
            html: value ?? "",
          },
        });
      }}
      theme="vs-dark"
    />
  );
};

export default CustomHTMLView;
