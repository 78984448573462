import AppButton from "../core/buttons/AppButton/AppButton";
import { Avatar, Flex, Text } from "@radix-ui/themes";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import markSrc from "~/assets/markBig.png";

const MarkAvatar = styled(Avatar)`
  width: 400px;
  height: 400px;
  border-radius: 50%;
`;
const MarkText = styled(Text)`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 72px;
  font-weight: 700;
`;
const IntroText = styled(Text)`
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
`;
const CenteredContainer = styled(Flex)`
  gap: min(120px, 13vh);
`;

const OnboardingVideo = ({ onNext }: { onNext: () => void }) => {
  const top = (
    <Flex direction="row" justify={"center"} align={"center"} gap="80px">
      <MarkText>Meet</MarkText>
      <MarkAvatar src={markSrc} fallback="M" />
      <MarkText>Mark</MarkText>
    </Flex>
  );
  const bottom = (
    <Flex direction="column" gap="8" justify={"center"} align={"center"}>
      <Flex direction="column" justify={"center"} align={"center"}>
        <IntroText style={{ color: "#FF4545" }}>
          Your AI Marketing Assistant.
        </IntroText>
        <IntroText>I'm here to help optimize your campaigns</IntroText>
        <IntroText>and enhance your marketing strategies.</IntroText>
      </Flex>
      <AppButton onClick={onNext}>
        Get Started <ArrowRightIcon size="20px" />
      </AppButton>
      {/* <AppButton
        size="4"
        radius="full"
        style={{ width: "fit-content" }}
        onClick={onNext}
      >
        Get Started
        <ArrowRight />
      </AppButton> */}
    </Flex>
  );
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      width="100%"
      height="100%"
    >
      <CenteredContainer direction="column">
        {top}
        {bottom}
      </CenteredContainer>
    </Flex>
  );
};

export default OnboardingVideo;
