import { EmailHeaderFooterSection } from "~/types/template-editor";

type EmailSectionPreviewProps = {
  template: Handlebars.TemplateDelegate;
  vars: EmailHeaderFooterSection;
};

const EmailSectionPreview: React.FC<EmailSectionPreviewProps> = ({
  template,
  vars,
}) => {
  return (
    <div
      style={{
        // TODO: this should hold the default email style
        padding: "16px",
        fontFamily: "Arial",
        fontSize: "16px",
        lineHeight: "24px",
      }}
      dangerouslySetInnerHTML={{
        __html: template(vars),
      }}
    />
  );
};

export default EmailSectionPreview;
