import { AccountSettingsComponents } from ".";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AccountSettingsTabsEnum } from "~/types/account-settings";

export const AccountSettingsSearchParam = "activeSettingsTab";

const AccountSettings = ({ children }: { children: React.ReactNode }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<AccountSettingsTabsEnum>(
    AccountSettingsTabsEnum.General
  );
  useEffect(() => {
    const activeTabParam = searchParams.get(AccountSettingsSearchParam);
    if (
      activeTabParam &&
      Object.values<string>(AccountSettingsTabsEnum).includes(activeTabParam)
    ) {
      setIsDialogOpen(true);
      setActiveTab(activeTabParam as AccountSettingsTabsEnum);
    }
  }, []);

  return (
    <AccountSettingsComponents.Dialog
      trigger={children}
      isOpen={isDialogOpen}
      setIsOpen={setIsDialogOpen}
    >
      <AccountSettingsComponents.Tabs
        activeTab={activeTab}
        setActiveTab={(tab) => setActiveTab(tab)}
      />
      <AccountSettingsComponents.Content activeTab={activeTab} />
    </AccountSettingsComponents.Dialog>
  );
};

export default AccountSettings;
