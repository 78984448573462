import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";
import { getCampaignDetailsQueryKey } from "~/hooks/campaign/useGetCampaignDetails";
import { getEmailCreativeQueryKey } from "~/hooks/emails/useEmailCreativeQuery";

type UpdateEmailMutationRequestData =
  operations["emails_api_update_email_creative"]["requestBody"]["content"]["application/json"] & {
    /** Used for query invalidation */
    campaignId?: string;
  };
type UpdateEmailMutationResponse =
  operations["emails_api_update_email_creative"]["responses"]["200"]["content"]["application/json"];

const useUpdateEmailCreative = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}) => {
  const queryClient = useQueryClient();
  const updateEmailMutation = useMutation<
    UpdateEmailMutationResponse,
    Error,
    UpdateEmailMutationRequestData
  >({
    mutationFn: async ({
      campaignId,
      ...email
    }): Promise<UpdateEmailMutationResponse> => {
      const response = await axios.post(
        `/api/v1/emails/email-creative/${email.id}`,
        email,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    onError: (error) => {
      toast.error("Failed to save email");
      onError?.();
    },
    onSuccess: (_data, email) => {
      if (email.campaignId) {
        queryClient.invalidateQueries({
          queryKey: getCampaignDetailsQueryKey(email.campaignId),
        });
      }
      queryClient.invalidateQueries({
        queryKey: getEmailCreativeQueryKey(email.id),
      });
      toast.success("Changes have been saved");
      onSuccess?.();
    },
    onSettled: () => {
      onSettled?.();
    },
  });
  return updateEmailMutation;
};

export default useUpdateEmailCreative;
