import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { useMemo, useState } from "react";

type FilterItemDefinition = {
  value: string;
  label: string;
};

interface FilterProps {
  title: String;
  items: FilterItemDefinition[];
  value?: string[];
  onValueChange?: (value: string[]) => void;
}

const InternMultiSelectMenu = ({
  title,
  items,
  value,
  onValueChange,
}: FilterProps) => {
  const [internalValue, setInternalValue] = useState<string[]>([]);

  const handleItemClick = function (item: FilterItemDefinition) {
    const newValue = selectedValues.includes(item.value)
      ? selectedValues.filter((value) => value !== item.value)
      : [...selectedValues, item.value];
    if (onValueChange) {
      onValueChange(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  const selectedValues = useMemo(() => {
    return value !== undefined ? value : internalValue;
  }, [value, internalValue]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger>
        <Button className="justify-between">
          {title}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="w-[var(--radix-dropdown-menu-trigger-width)]">
        {items.map((item) => {
          return (
            <DropdownMenu.CheckboxItem
              key={item.value}
              checked={selectedValues.includes(item.value)}
              onCheckedChange={() => handleItemClick(item)}
              onSelect={(e) => e.preventDefault()}
            >
              {item.label}
            </DropdownMenu.CheckboxItem>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default InternMultiSelectMenu;
