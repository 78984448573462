import { components, EmailSectionType, operations } from "@openapi";
import { produce } from "immer";
import _ from "lodash";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { addStylesheetURL } from "~/contexts/BrandStylingContext";
import useIsMounted from "~/hooks/common/useIsMounted";
import getCustomGoogleFontLink from "~/utils/brand-style/getCustomGoogleFontLink";
import {
  convertSectionToEnum,
  EmailCollectionGridSectionSchema,
  EmailListicleSectionSchema,
  isCollectionGridSection,
  isProductsSection,
} from "~/utils/emails/useSectionTypeCheck";
import nullthrows from "~/utils/nullthrows";
import { assertNever } from "~/utils/typeUtils";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

export type EmailCreative =
  operations["emails_api_get_email_creative"]["responses"][200]["content"]["application/json"];

export type NewEmailSection = {
  id: string;
  type: null;
  index: number;
  image?: {
    id: string;
    enabled: boolean;
    image_url: string;
  };
  products?: MaybeFeaturedProduct[];
  palette: ColorPaletteSchema;
};

export type BrandColorPaletteSchema =
  components["schemas"]["BrandColorPaletteSchema"];
export type ColorPaletteSchema = components["schemas"]["ColorPaletteSchema"];
export type EmailSection =
  | EmailCreative["variants"][number]["sections"][number]
  | NewEmailSection;
export type EmailVariant = Omit<
  EmailCreative["variants"][number],
  "sections"
> & {
  sections: EmailSection[];
};
export type HeroSectionSchema = components["schemas"]["EmailHeroSectionSchema"];
export type ProductsSection =
  components["schemas"]["EmailProductsSectionSchema"];
export type FooterSectionSchema =
  components["schemas"]["EmailFooterSectionSchema"];
type EmailSectionTemplateSchema =
  components["schemas"]["EmailSectionTemplateSchema"];
type EmailImageElementSchema = components["schemas"]["EmailImageElementSchema"];

export const EmailEditorViews = {
  ...EmailSectionType,
  GenerateVariant: "Generate Variant",
  Button: "Button",
  AddBlock: "Add Block",
} as const;

export type EmailEditorViews =
  (typeof EmailEditorViews)[keyof typeof EmailEditorViews];

export type EmailButtonElementSchema =
  components["schemas"]["EmailButtonElementSchema"];
interface ActionSetSelectedSection {
  type: "SET_SELECTED_SECTION";
  payload: string;
}
interface ActionAddVariant {
  type: "ADD_VARIANT";
  payload: EmailVariant;
}

interface ActionPushEditorView {
  type: "PUSH_EDITOR_VIEW";
  payload: EmailEditorViews;
}

interface ActionPopEditorView {
  type: "POP_EDITOR_VIEW";
}

interface ActionReplaceEditorView {
  type: "REPLACE_EDITOR_VIEW";
  payload: EmailEditorViews;
}

interface UpdateSectionField {
  type: "UPDATE_SECTION_FIELD";
  payload: {
    sectionId: string;
    field: string;
    value:
      | string
      | boolean
      | EmailButtonElementSchema
      | EmailImageElementSchema
      | ProductsSection["products"]
      | EmailCollectionGridSectionSchema["collections"]
      | EmailListicleSectionSchema["list_items"]
      | EmailSectionTemplateSchema
      | ColorPaletteSchema;
  };
}

interface UpdateEmailField {
  type: "UPDATE_EMAIL_FIELD";
  payload: {
    field: string;
    value: any;
  };
}

interface ActionInit {
  type: "INIT";
  payload: EmailCreative & {
    activeEmailId: string;
    currentVariantIndex: number;
  };
}

interface ActionSetActiveEmailId {
  type: "SET_ACTIVE_EMAIL_ID";
  payload: string | null;
}

interface ActionSetCurrentVariantIndex {
  type: "SET_CURRENT_VARIANT_INDEX";
  payload: number;
}

interface ActionSetActiveTipTapID {
  type: "SET_ACTIVE_TIPTAP_ID";
  payload: string;
}

interface ActionSetActiveButtonField {
  type: "SET_ACTIVE_BUTTON_FIELD";
  payload: string | null;
}

interface ActionApplyGlobalButtonStyle {
  type: "APPLY_ALL_BUTTON_STYLE";
  payload: {
    element: EmailButtonElementSchema;
    isGlobal: boolean;
  };
}

interface ActionMoveSections {
  type: "MOVE_SECTIONS";
  payload: {
    sourceIndex: number;
    destinationIndex: number;
  };
}

interface ActionDeleteSection {
  type: "DELETE_SECTION";
  payload: string;
}

interface ActionAddEmptySection {
  type: "ADD_EMPTY_SECTION";
  payload: {
    index: number;
  };
}

interface ActionInitSection {
  type: "INIT_SECTION";
  payload: EmailSection;
}

type ProductMetadataFields = "description" | "price" | "cta_button";
type CollectionMetadataFields = "title" | "cta_button";

interface ActionToggleProductMetadata {
  type: "TOGGLE_SECTION_METADATA";
  payload: {
    sectionId: string;
    fieldName: ProductMetadataFields | CollectionMetadataFields;
  };
}

interface ActionSetAudiences {
  type: "SET_AUDIENCES";
  payload: components["schemas"]["CDPAudienceRequestData"][];
}

interface ActionSetDeviceView {
  type: "SET_DEVICE_VIEW";
  payload: "desktop" | "mobile";
}

interface ActionUpdateNestedObjectById {
  type: "UPDATE_NESTED_OBJECT_BY_ID";
  payload: {
    objectId: string;
    field: string;
    value: string;
  };
}

interface ActionSetIsDirty {
  type: "SET_IS_DIRTY";
  payload: boolean;
}

interface ActionApplyPalette {
  type: "APPLY_PALETTE";
  payload: {
    sectionId: string;
    palette: ColorPaletteSchema;
    buttonStyle?: {
      backgroundColor: string;
      foregroundColor: string;
    };
  };
}

interface ActionSetElementIdFontName {
  type: "SET_ELEMENT_ID_FONT_NAMES";
  payload: {
    elementId: string;
    fontNames: string[];
  };
}

export type EmailReducerActions =
  | ActionSetSelectedSection
  | ActionPushEditorView
  | ActionAddVariant
  | ActionPopEditorView
  | ActionReplaceEditorView
  | UpdateSectionField
  | UpdateEmailField
  | ActionInit
  | ActionSetIsDirty
  | ActionSetActiveEmailId
  | ActionSetCurrentVariantIndex
  | ActionSetActiveTipTapID
  | ActionSetActiveButtonField
  | ActionApplyGlobalButtonStyle
  | ActionMoveSections
  | ActionDeleteSection
  | ActionAddEmptySection
  | ActionInitSection
  | ActionToggleProductMetadata
  | ActionSetAudiences
  | ActionSetDeviceView
  | ActionUpdateNestedObjectById
  | ActionApplyPalette
  | ActionSetElementIdFontName;

type EmailState = Omit<EmailCreative, "selected_variant_id" | "variants"> & {
  activeEmailId: string | null;
  activeTipTapID: string | null;
  // TODO: make nullable
  selectedSectionId: string;
  editorViewStack: EmailEditorViews[];
  activeButtonField: string | null;
  isDirty: boolean;
  variants: EmailVariant[];
  currentVariantIndex: number;
  audiences: components["schemas"]["CDPAudienceRequestData"][];
  deviceView: "desktop" | "mobile";
  // TODO: need to consider something similar for the publish step
  /** Used to determine the full list of custom fonts that needs to be loaded */
  fieldIdToFontName: Record<string, string[]>;
};

const initialState: EmailState = {
  id: "",
  name: "",
  description: "",
  subtitle: "",
  variants: [],
  related_commerce_item_ids: [],
  currentVariantIndex: 0,
  activeTipTapID: "",
  activeEmailId: null,
  editorViewStack: [EmailEditorViews.GenerateVariant],
  selectedSectionId: "",
  activeButtonField: null,
  audiences: [],
  deviceView: "desktop",
  fieldIdToFontName: {},
  isDirty: false,
};

const EmailContext = createContext(initialState);
const DispatchContext = createContext<Dispatch<EmailReducerActions> | null>(
  null
);

// Example 1: Updating a nested field in a product section
// updateNestedField(section, ['products', '0', 'title', 'text'], 'New Product Title')

// Example 2: Updating a nested field in a button element
// updateNestedField(section, ['elements', 'cta_button', 'background_color'], '#FF0000')
function updateNestedField(obj: any, path: string[], value: any): any {
  if (path.length === 1) {
    return { ...obj, [path[0]]: value };
  }
  const [current, ...rest] = path;
  if (Array.isArray(obj[current])) {
    const index = parseInt(rest[0], 10);
    if (isNaN(index)) {
      return {
        ...obj,
        [current]: obj[current].map((item: any) =>
          updateNestedField(item, rest, value)
        ),
      };
    } else {
      return {
        ...obj,
        [current]: [
          ...obj[current].slice(0, index),
          updateNestedField(obj[current][index], rest.slice(1), value),
          ...obj[current].slice(index + 1),
        ],
      };
    }
  }
  return {
    ...obj,
    [current]: updateNestedField(obj[current] || {}, rest, value),
  };
}

export const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const updateButtonStyle = (obj: any, style: EmailButtonElementSchema) => {
  if (obj && typeof obj === "object") {
    if (obj.type === "button") {
      Object.assign(obj, {
        ...obj,
        background_color: style.background_color,
        border_color: style.border_color,
        border_style: style.border_style,
        border_width: style.border_width ?? "0px",
        border_radius: style.border_radius,
        color: style.color,
        padding: style.padding,
        text_transform: style.text_transform,
        font_styles: {
          family: style.font_styles?.family ?? "",
          size: style.font_styles?.size ?? "",
          weight: style.font_styles?.weight ?? 0,
          style: style.font_styles?.style ?? "",
        },
      });
    }
    for (const key in obj) {
      updateButtonStyle(obj[key], style);
    }
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => updateButtonStyle(item, style));
  }
};

function reducer(state: EmailState, action: EmailReducerActions): EmailState {
  return produce(state, (draft) => {
    switch (action.type) {
      case "INIT":
        Object.assign(draft, initialState);
        Object.assign(draft, action.payload);
        break;
      case "PUSH_EDITOR_VIEW":
        draft.editorViewStack.push(action.payload);
        break;
      case "SET_IS_DIRTY":
        draft.isDirty = action.payload;
        break;
      case "ADD_VARIANT":
        draft.variants.push(action.payload);
        draft.currentVariantIndex = draft.variants.length - 1;
        break;
      case "POP_EDITOR_VIEW":
        if (draft.editorViewStack.length > 1) {
          draft.editorViewStack.pop();
        }
        break;
      case "REPLACE_EDITOR_VIEW":
        if (draft.editorViewStack.length > 1) {
          draft.editorViewStack.splice(
            draft.editorViewStack.length - 1,
            1,
            action.payload
          );
        } else {
          draft.editorViewStack.push(action.payload);
        }
        break;
      case "SET_SELECTED_SECTION":
        draft.selectedSectionId = action.payload;
        break;
      case "SET_CURRENT_VARIANT_INDEX":
        draft.isDirty = true;
        draft.currentVariantIndex = action.payload;
        break;
      case "UPDATE_SECTION_FIELD":
        if (!draft.variants[state.currentVariantIndex].sections) return;
        const sectionIndex = draft.variants[
          state.currentVariantIndex
        ].sections.findIndex(
          (section) => section.id === action.payload.sectionId
        );
        if (sectionIndex === -1) return;
        draft.isDirty = true;
        // unsafe, especially if some typing changes
        const fieldPath = action.payload.field.split(".");
        draft.variants[state.currentVariantIndex].sections[sectionIndex] =
          updateNestedField(
            draft.variants[state.currentVariantIndex].sections[sectionIndex],
            fieldPath,
            action.payload.value
          );
        break;
      case "UPDATE_EMAIL_FIELD":
        draft.isDirty = true;
        const emailFieldPath = action.payload.field.split(".");
        Object.assign(
          draft,
          updateNestedField(draft, emailFieldPath, action.payload.value)
        );
        break;
      case "SET_ACTIVE_EMAIL_ID":
        draft.activeEmailId = action.payload;
        break;
      case "SET_ACTIVE_TIPTAP_ID":
        draft.activeTipTapID = action.payload;
        break;
      case "SET_ACTIVE_BUTTON_FIELD":
        draft.activeButtonField = action.payload;
        break;
      case "APPLY_ALL_BUTTON_STYLE":
        if (!draft.variants[state.currentVariantIndex].sections) return;
        if (action.payload.isGlobal) {
          draft.variants[state.currentVariantIndex].sections.forEach(
            (section) => updateButtonStyle(section, action.payload.element)
          );
        } else {
          const selectedSection = draft.variants[
            state.currentVariantIndex
          ].sections.find((section) => section.id === draft.selectedSectionId);
          if (selectedSection) {
            updateButtonStyle(selectedSection, action.payload.element);
          }
        }
        break;
      case "MOVE_SECTIONS":
        if (!draft.variants[state.currentVariantIndex].sections) return;
        draft.isDirty = true;
        const { sourceIndex, destinationIndex } = action.payload;
        if (
          sourceIndex < 0 ||
          sourceIndex >=
            draft.variants[state.currentVariantIndex].sections.length ||
          destinationIndex < 0 ||
          destinationIndex >=
            draft.variants[state.currentVariantIndex].sections.length
        )
          return;
        const [movedSection] = draft.variants[
          state.currentVariantIndex
        ].sections.splice(sourceIndex, 1);
        draft.variants[state.currentVariantIndex].sections.splice(
          destinationIndex,
          0,
          movedSection
        );
        // Update indices of all sections
        draft.variants[state.currentVariantIndex].sections.forEach(
          (section, index) => {
            section.index = index;
          }
        );
        break;
      case "DELETE_SECTION":
        draft.isDirty = true;
        if (draft.selectedSectionId === action.payload) {
          // deselect deleted section
          if (draft.editorViewStack.length > 1) {
            draft.editorViewStack.pop();
          }
          draft.selectedSectionId = "";
        }
        if (!draft.variants[state.currentVariantIndex].sections) return;
        draft.variants[state.currentVariantIndex].sections = draft.variants[
          state.currentVariantIndex
        ].sections?.filter((section) => section.id !== action.payload);
        break;
      case "ADD_EMPTY_SECTION":
        draft.isDirty = true;
        if (!draft.variants[state.currentVariantIndex].sections)
          draft.variants[state.currentVariantIndex].sections = [];
        const newEmptySection: EmailSection = {
          id: `new-section-${Date.now()}`,
          type: null,
          index: action.payload.index,
          palette: {
            id: "new-palette-${Date.now()}",
            background: "#FFFFFF",
            foreground: "#000000",
            accent_background: "#000000",
            accent_foreground: "#FFFFFF",
          },
        };
        draft.variants[state.currentVariantIndex].sections.splice(
          action.payload.index,
          0,
          newEmptySection
        );
        draft.selectedSectionId = newEmptySection.id;
        break;
      case "INIT_SECTION":
        if (!draft.variants[state.currentVariantIndex].sections)
          draft.variants[state.currentVariantIndex].sections = [];
        const sectionToInitIndex = draft.variants[
          state.currentVariantIndex
        ].sections?.findIndex((section) => section.id === action.payload.id);
        if (sectionToInitIndex === -1) return;

        draft.variants[state.currentVariantIndex].sections[sectionToInitIndex] =
          {
            ...action.payload,
          };

        draft.selectedSectionId = action.payload.id;
        draft.editorViewStack.push(convertSectionToEnum(action.payload));
        break;
      case "TOGGLE_SECTION_METADATA":
        if (!draft.variants[state.currentVariantIndex].sections) {
          return;
        }
        const sectionToToggleIndex = draft.variants[
          state.currentVariantIndex
        ].sections?.findIndex(
          (section) => section.id === action.payload.sectionId
        );
        if (sectionToToggleIndex === -1) return;
        const toggleSection =
          draft.variants[state.currentVariantIndex].sections[
            sectionToToggleIndex
          ];
        if (isProductsSection(toggleSection)) {
          toggleSection.products.forEach((product) => {
            if (product[action.payload.fieldName as ProductMetadataFields]) {
              product[
                action.payload.fieldName as ProductMetadataFields
              ].enabled =
                !product[action.payload.fieldName as ProductMetadataFields]
                  .enabled;
            }
          });
        } else if (isCollectionGridSection(toggleSection)) {
          toggleSection.collections.forEach((collection) => {
            if (
              collection[action.payload.fieldName as CollectionMetadataFields]
            ) {
              collection[
                action.payload.fieldName as CollectionMetadataFields
              ].enabled =
                !collection[
                  action.payload.fieldName as CollectionMetadataFields
                ].enabled;
            }
          });
        }
        break;
      case "SET_AUDIENCES":
        draft.isDirty = true;
        draft.audiences = action.payload;
        break;
      case "SET_DEVICE_VIEW":
        draft.deviceView = action.payload;
        break;
      case "UPDATE_NESTED_OBJECT_BY_ID":
        draft.isDirty = true;
        const findObjectById = (obj: any): any => {
          if (obj.id === action.payload.objectId) {
            return obj;
          }
          for (const key in obj) {
            if (typeof obj[key] === "object" && obj[key] !== null) {
              if (Array.isArray(obj[key])) {
                for (let i = 0; i < obj[key].length; i++) {
                  const result = findObjectById(obj[key][i]);
                  if (result) return result;
                }
              } else {
                const result = findObjectById(obj[key]);
                if (result) return result;
              }
            }
          }
          return null;
        };

        const objectToUpdate = findObjectById(draft);
        if (objectToUpdate) {
          objectToUpdate[action.payload.field] = action.payload.value;
        }
        break;
      case "APPLY_PALETTE":
        if (!draft.variants[state.currentVariantIndex].sections) return;
        draft.isDirty = true;
        const sectionToApplyPalette = draft.variants[
          state.currentVariantIndex
        ].sections?.find((section) => section.id === action.payload.sectionId);
        if (sectionToApplyPalette) {
          sectionToApplyPalette.palette = {
            ...sectionToApplyPalette.palette,
            background: action.payload.palette.background,
            foreground: action.payload.palette.foreground,
            accent_background: action.payload.palette.accent_background,
            accent_foreground: action.payload.palette.accent_foreground,
          };
          const updateButtonBackgroundColor = (obj: any) => {
            if (obj && typeof obj === "object") {
              if (obj.type === "button" && action.payload.buttonStyle) {
                obj.background_color =
                  action.payload.buttonStyle.backgroundColor;
                obj.color = action.payload.buttonStyle.foregroundColor;
              }
              for (const key in obj) {
                updateButtonBackgroundColor(obj[key]);
              }
            } else if (Array.isArray(obj)) {
              obj.forEach(updateButtonBackgroundColor);
            }
          };
          updateButtonBackgroundColor(sectionToApplyPalette);
        }
        break;
      case "SET_ELEMENT_ID_FONT_NAMES":
        draft.fieldIdToFontName[action.payload.elementId] =
          action.payload.fontNames;
        break;
      default:
        assertNever(action);
    }
  });
}

export const EmailContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [emailState, dispatch] = useReducer(reducer, initialState);
  const [fontNamesToLinks, setFontNamesToLinks] = useState<
    Record<string, HTMLLinkElement>
  >({});
  const isMounted = useIsMounted();

  const customFonts = useMemo(() => {
    return _(emailState.fieldIdToFontName)
      .flatMap((fontNames) => fontNames)
      .uniq()
      .value();
  }, [emailState.fieldIdToFontName]);

  useEffect(() => {
    const newFonts = customFonts
      .filter((fontName) => !fontNamesToLinks[fontName])
      .map((fontName) => {
        const sanitizedFontName = fontName.replace(/['"]+/g, "");
        const googleFontUrl = getCustomGoogleFontLink(sanitizedFontName);
        if (!googleFontUrl) {
          return;
        }
        const link = addStylesheetURL(googleFontUrl);
        return { fontName, link };
      })
      .filter((font) => font !== undefined);

    setFontNamesToLinks((prev) => {
      const newFontNamesToLinks = { ...prev };
      newFonts.forEach(({ fontName, link }) => {
        newFontNamesToLinks[fontName] = link;
      });
      return newFontNamesToLinks;
    });
  }, [customFonts]);

  useEffect(() => {
    // unmount cleanup
    return () => {
      if (isMounted()) {
        return;
      }
      Object.values(fontNamesToLinks).forEach((link) => {
        link.remove();
      });
    };
  }, [isMounted]);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <EmailContext.Provider value={useMemo(() => emailState, [emailState])}>
        {children}
      </EmailContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useEmailState = () => {
  const state = useContext(EmailContext);

  const sections = useMemo(() => {
    return state.variants[state.currentVariantIndex]?.sections;
  }, [state.variants, state.currentVariantIndex]);

  const selectedSection = useMemo(() => {
    return sections?.find((section) => section.id === state.selectedSectionId);
  }, [sections, state.selectedSectionId]);

  return {
    ...state,
    sections,
    selectedSection,
  };
};

export function useEmailDispatch(): React.Dispatch<EmailReducerActions> {
  return nullthrows(
    useContext(DispatchContext),
    "Email Editor dispatch context is missing"
  );
}

export const useUpdateSectionField = () => {
  const dispatch = useEmailDispatch();
  return (payload: UpdateSectionField["payload"]) => {
    dispatch({
      type: "UPDATE_SECTION_FIELD",
      payload: payload,
    });
  };
};

export const useUpdateEmailField = () => {
  const dispatch = useEmailDispatch();
  return (payload: UpdateEmailField["payload"]) => {
    dispatch({
      type: "UPDATE_EMAIL_FIELD",
      payload: payload,
    });
  };
};

export const usePushEditorView = () => {
  const dispatch = useEmailDispatch();
  return (view: EmailEditorViews) => {
    dispatch({
      type: "PUSH_EDITOR_VIEW",
      payload: view,
    });
  };
};

export const usePopEditorView = () => {
  const dispatch = useEmailDispatch();
  return () => {
    dispatch({
      type: "POP_EDITOR_VIEW",
    });
  };
};

export const useReplaceEditorView = () => {
  const dispatch = useEmailDispatch();
  return (view: EmailEditorViews) => {
    dispatch({
      type: "REPLACE_EDITOR_VIEW",
      payload: view,
    });
  };
};

export const useInitEmailState = () => {
  const dispatch = useEmailDispatch();
  return (payload: ActionInit["payload"]) => {
    dispatch({
      type: "INIT",
      payload: payload,
    });
  };
};

export const useSetActiveEmailId = () => {
  const dispatch = useEmailDispatch();
  return (activeEmailId: string | null) => {
    dispatch({
      type: "SET_ACTIVE_EMAIL_ID",
      payload: activeEmailId,
    });
  };
};

export const useSetIsEmailDirty = () => {
  const dispatch = useEmailDispatch();
  return (isDirty: boolean) => {
    dispatch({
      type: "SET_IS_DIRTY",
      payload: isDirty,
    });
  };
};

export const useAddVariant = () => {
  const dispatch = useEmailDispatch();
  return (variant: EmailVariant) => {
    dispatch({
      type: "ADD_VARIANT",
      payload: variant,
    });
  };
};

export const useSetActiveTipTapID = () => {
  const dispatch = useEmailDispatch();
  return (activeTipTapID: string) => {
    dispatch({
      type: "SET_ACTIVE_TIPTAP_ID",
      payload: activeTipTapID,
    });
  };
};

export const useSetActiveButtonField = () => {
  const dispatch = useEmailDispatch();
  return (activeButtonField: string | null) => {
    dispatch({
      type: "SET_ACTIVE_BUTTON_FIELD",
      payload: activeButtonField,
    });
  };
};

export const useApplyAllButtonStyle = () => {
  const dispatch = useEmailDispatch();
  return (element: EmailButtonElementSchema, isGlobal: boolean) => {
    dispatch({
      type: "APPLY_ALL_BUTTON_STYLE",
      payload: {
        isGlobal: isGlobal,
        element: element,
      },
    });
  };
};

export const useMoveSections = () => {
  const dispatch = useEmailDispatch();
  return (sourceIndex: number, destinationIndex: number) => {
    dispatch({
      type: "MOVE_SECTIONS",
      payload: { sourceIndex, destinationIndex },
    });
  };
};

export const useDeleteSection = () => {
  const dispatch = useEmailDispatch();
  return (sectionId: string) => {
    dispatch({
      type: "DELETE_SECTION",
      payload: sectionId,
    });
  };
};

export const useAddEmptySection = () => {
  const dispatch = useEmailDispatch();
  return (index: number) => {
    dispatch({
      type: "ADD_EMPTY_SECTION",
      payload: { index },
    });
  };
};

export const useInitSection = () => {
  const dispatch = useEmailDispatch();
  return (section: EmailSection) => {
    dispatch({
      type: "INIT_SECTION",
      payload: section,
    });
  };
};

export const useToggleSectionMetadata = () => {
  const dispatch = useEmailDispatch();
  return (
    sectionId: string,
    fieldName: ProductMetadataFields | CollectionMetadataFields
  ) => {
    dispatch({
      type: "TOGGLE_SECTION_METADATA",
      payload: { sectionId, fieldName },
    });
  };
};

export const useSetAudiences = () => {
  const dispatch = useEmailDispatch();
  return (audiences: components["schemas"]["CDPAudienceRequestData"][]) => {
    dispatch({
      type: "SET_AUDIENCES",
      payload: audiences,
    });
  };
};

export const useSetDeviceView = () => {
  const dispatch = useEmailDispatch();
  return (deviceView: "desktop" | "mobile") => {
    dispatch({
      type: "SET_DEVICE_VIEW",
      payload: deviceView,
    });
  };
};

export const useUpdateNestedObjectById = () => {
  const dispatch = useEmailDispatch();
  return (payload: ActionUpdateNestedObjectById["payload"]) => {
    dispatch({
      type: "UPDATE_NESTED_OBJECT_BY_ID",
      payload: payload,
    });
  };
};

export const useApplyPalette = () => {
  const dispatch = useEmailDispatch();
  return (
    sectionId: string,
    palette: ColorPaletteSchema,
    buttonStyle?: {
      backgroundColor: string;
      foregroundColor: string;
    }
  ) => {
    dispatch({
      type: "APPLY_PALETTE",
      payload: { sectionId, palette, buttonStyle },
    });
  };
};

export default EmailContext;
