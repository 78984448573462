import { Flex, Heading } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import SidePanelComponents from "~/components/core/editor/sidepane";
import {
  useAdEditorDispatch,
  useAdEditorSelectedAdState,
} from "~/contexts/AdEditorContext";
import useGenerateAdCreativeMediaMutation from "~/hooks/ads/useGenerateAdCreativeMediaMutation";

const AdLayoutVariants: React.FC = () => {
  const state = useAdEditorSelectedAdState();
  const dispatch = useAdEditorDispatch();
  const [serverError, setServerError] = useState<string | null>(null);

  const { mutate: generateMedia, isPending: isGenerating } =
    useGenerateAdCreativeMediaMutation({
      onSuccess: (data) => {
        if (!data.success || !data.media.length) {
          setServerError("Failed to generate layout variants");
          return;
        }
        dispatch({
          type: "APPEND_AD_MEDIA",
          payload: {
            media: data.media,
          },
        });
      },
      onError: (error) => {
        setServerError(error);
      },
    });

  const handleRegenerate = () => {
    if (isGenerating) {
      return;
    }
    setServerError(null);
    generateMedia({ adCreativeId: state.data.id });
  };

  const handleMediaSelect = (index: number) => {
    if (state.media.status !== "loaded") {
      return;
    }
    if (state.media.selectedMediaIndex === index) {
      return;
    }
    dispatch({
      type: "SELECT_AD_MEDIA_INDEX",
      payload: {
        adCreativeId: state.data.id,
        selectedMediaIndex: index,
      },
    });
  };

  const mediaList = state.media.status === "loaded" ? state.media.list : [];

  const variants: React.ComponentProps<
    typeof SidePanelComponents.LayoutVariants
  >["variants"] = useMemo(() => {
    return mediaList.map((media) => ({
      preview:
        media.variants[0]?.preview_image ??
        media.variants[0]?.file ??
        undefined,
      id: media.id,
    }));
  }, [mediaList]);

  return (
    <Flex direction="column" gap="4">
      <Heading size="2">Explore Layout Variants</Heading>
      {state.media.status === "error" && (
        <Heading size="1" color="red" mx="2">
          {state.media.message}
        </Heading>
      )}
      {state.media.status === "loading" && <LoadingDots />}
      {state.media.status === "loaded" && (
        <SidePanelComponents.LayoutVariants
          isGenerating={isGenerating}
          generationError={serverError}
          onClickGenerate={handleRegenerate}
          onSelect={handleMediaSelect}
          variants={variants}
          selectedIndex={state.media.selectedMediaIndex}
        />
      )}
    </Flex>
  );
};

export default AdLayoutVariants;
