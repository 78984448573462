import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

interface Product {
  product_id: string;
  title: string;
  description: string;
  price: number;
  image_url: string;
}

interface Collection {
  collection_id: string;
  title: string;
  description: string;
  image_url: string | null;
}

interface ItemSet {
  id: string;
  type: string;
  products: Product[];
  collections: Collection[];
}

interface ApiResponse {
  item_sets: ItemSet[];
}

interface CampaignRecommendation {
  title: string;
  description: string;
}

interface GenerateRecommendationsResponse {
  campaign_recommendations: CampaignRecommendation[];
}

export default function RecommenderRoute() {
  const [itemSets, setItemSets] = useState<ItemSet[]>([]);
  const [recommendations, setRecommendations] = useState<{
    [key: string]: CampaignRecommendation[];
  }>({});
  const [loadingItemSetId, setLoadingItemSetId] = useState<string | null>(null);
  const activeBrandID = useActiveBrandID();

  const { data, isLoading } = useQuery<ApiResponse>({
    queryKey: ["recommended-item-sets", activeBrandID],
    queryFn: async (): Promise<ApiResponse> => {
      const response = await axios.post(
        "/api/v1/recommender/get-recommended-item-sets",
        {
          brand_id: activeBrandID,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: Infinity,
  });

  const generateRecommendations = useMutation<
    GenerateRecommendationsResponse,
    Error,
    string
  >({
    mutationFn: async (itemSetId: string) => {
      const response = await axios.post(
        `/api/v1/recommender/generate-recommendations`,
        {
          item_set_id: itemSetId,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    onMutate: (itemSetId) => {
      setLoadingItemSetId(itemSetId);
    },
    onSuccess: (data, itemSetId) => {
      setRecommendations((prev) => ({
        ...prev,
        [itemSetId]: data.campaign_recommendations,
      }));
      setLoadingItemSetId(null);
    },
    onError: () => {
      setLoadingItemSetId(null);
    },
  });

  useEffect(() => {
    if (data) {
      setItemSets(data.item_sets);
    }
  }, [data]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const styles = {
    recommender: {
      padding: "20px",
    },
    itemSet: {
      marginBottom: "20px",
    },
    itemScroll: {
      display: "flex",
      overflowX: "auto" as "auto",
      gap: "10px",
    },
    item: {
      width: "100%",
      maxWidth: "250px",
      border: "1px solid #ccc",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      textAlign: "center" as "center",
      flex: "0 0 auto" as "auto",
    },
    itemImage: {
      width: "100px",
      height: "100px",
      objectFit: "cover" as "cover",
      borderRadius: "5px",
    },
    itemTitle: {
      margin: "10px 0",
      overflow: "hidden",
      textOverflow: "ellipsis" as "ellipsis",
      whiteSpace: "nowrap" as "nowrap",
    },
    itemDescription: {
      margin: "5px 0",
      overflow: "hidden",
      textOverflow: "ellipsis" as "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical" as "vertical",
      height: "auto",
    },
    loading: {
      textAlign: "center" as "center",
      padding: "20px",
      fontSize: "18px",
    },
    recommendations: {
      marginTop: "10px",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "#f1f1f1",
    },
    recommendationTitle: {
      fontWeight: "bold" as "bold",
    },
    button: {
      marginTop: "10px",
      padding: "8px 16px",
      fontSize: "14px",
      color: "#fff",
      backgroundColor: "#007bff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    buttonLoading: {
      backgroundColor: "#5a9bd6",
    },
    buttonIcon: {
      marginRight: "8px",
    },
  };

  return (
    <div style={styles.recommender}>
      {isLoading ? (
        <div style={styles.loading}>Loading...</div>
      ) : (
        itemSets.map((itemSet) => (
          <div key={itemSet.id} style={styles.itemSet}>
            <h2>{itemSet.type.replace(/_/g, " ")}</h2>
            <button
              onClick={() => generateRecommendations.mutate(itemSet.id)}
              style={{
                ...styles.button,
                ...(loadingItemSetId === itemSet.id && styles.buttonLoading),
              }}
              disabled={loadingItemSetId === itemSet.id}
            >
              {loadingItemSetId === itemSet.id && (
                <span style={styles.buttonIcon} className="loader"></span>
              )}
              {loadingItemSetId === itemSet.id
                ? "Loading..."
                : "Generate Campaign Recommendations"}
            </button>
            <div style={styles.itemScroll}>
              {itemSet.products.length > 0 &&
                itemSet.products.map((product) => (
                  <div key={product.product_id} style={styles.item}>
                    <img
                      src={product.image_url}
                      alt={product.title}
                      style={styles.itemImage}
                    />
                    <h3 style={styles.itemTitle}>{product.title}</h3>
                    <p style={styles.itemDescription}>
                      {truncateText(product.description, 150)}
                    </p>
                    <p style={styles.itemDescription}>
                      ${product.price.toFixed(2)}
                    </p>
                  </div>
                ))}
              {itemSet.collections.length > 0 &&
                itemSet.collections.map((collection) => (
                  <div key={collection.collection_id} style={styles.item}>
                    <h3 style={styles.itemTitle}>{collection.title}</h3>
                    <p style={styles.itemDescription}>
                      {truncateText(collection.description, 150)}
                    </p>
                  </div>
                ))}
            </div>
            {recommendations[itemSet.id] && (
              <div style={styles.recommendations}>
                {recommendations[itemSet.id].map((recommendation, index) => (
                  <div key={index}>
                    <p style={styles.recommendationTitle}>
                      {recommendation.title}
                    </p>
                    <p>{recommendation.description}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}
