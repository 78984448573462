import { useDynamicHeader } from "../../contexts/DynamicHeaderContext";
import { TabNav } from "@radix-ui/themes";
import { useEffect } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import styled from "styled-components";

const DynamicHeaderTabNavRoot = styled(TabNav.Root)`
  box-shadow: none;
`;

const DynamicHeaderTabNavLink = styled(TabNav.Link)`
  font-size: 18px;
  margin: 10px;
`;

const AdsRoute = () => {
  const { setDynamicHeader } = useDynamicHeader();

  const location = useLocation();

  useEffect(() => {
    setDynamicHeader(
      <DynamicHeaderTabNavRoot>
        <DynamicHeaderTabNavLink
          asChild
          active={location.pathname.includes("/intern/ads/template")}
        >
          <Link to={"/intern/ads/templates"}>Templates</Link>
        </DynamicHeaderTabNavLink>
        <DynamicHeaderTabNavLink
          asChild
          active={location.pathname.includes("/intern/ads/creative")}
        >
          <Link to={"/intern/ads/creatives"}>Creatives</Link>
        </DynamicHeaderTabNavLink>
      </DynamicHeaderTabNavRoot>
    );
    return () => {
      setDynamicHeader(<></>);
    };
  }, [setDynamicHeader, location]);
  return (
    <div style={{ height: "100%" }}>
      <Outlet />
    </div>
  );
};

export default AdsRoute;
