import ListItem from "@tiptap/extension-list-item";
import { Fragment, NodeType } from "@tiptap/pm/model";
import { getNodeType } from "@tiptap/react";

function changeChildNodeTypes(
  content: Fragment,
  sourceTypeName: string,
  targetType: NodeType
): Fragment {
  let newContent = content;

  for (let i = 0; i < content.childCount; i++) {
    const node = content.child(i);

    if (node.type.name === sourceTypeName) {
      const targetNode = targetType.create(
        node.attrs,
        node.content,
        node.marks
      );
      newContent = newContent.replaceChild(i, targetNode);
    }
  }

  return newContent;
}

/**
 * Removes the paragraphs wrapping list items content
 * @see https://github.com/ueberdosis/tiptap/issues/118#issuecomment-2358893738
 */
export default ListItem.extend({
  content: "text*",

  addCommands() {
    return {
      liftListItem:
        () =>
        ({ tr, state, dispatch }) => {
          const paragraphType = getNodeType("paragraph", state.schema);

          const { $from, $to } = tr.selection;
          const range = $from.blockRange($to);

          if (!range) {
            return false;
          }

          if (dispatch) {
            const content = tr.doc.slice(range.start, range.end).content;

            const paragraphs = changeChildNodeTypes(
              content,
              "listItem",
              paragraphType
            );

            // -1 to delete the parent list
            tr.replaceWith(range.start - 1, range.end, paragraphs);
          }

          return true;
        },
      wrapInList:
        (typeOrName, attributes = {}) =>
        ({ tr, state, dispatch }) => {
          const listType = getNodeType(typeOrName, state.schema);
          const listItemType = getNodeType("listItem", state.schema);

          const { $from, $to } = tr.selection;
          const range = $from.blockRange($to);

          if (!range) {
            return false;
          }

          if (dispatch) {
            const content = tr.doc.slice(range.start, range.end).content;

            const listItems = changeChildNodeTypes(
              content,
              "paragraph",
              listItemType
            );

            const listNode = listType.create(attributes, listItems);

            tr.replaceWith(range.start, range.end, listNode);
          }

          return true;
        },
    };
  },
});
