import { assertNever } from "../utils/typeUtils";
import { useActiveBrandID } from "./CurrentUserContext";
import {
  components,
  EmailElementType,
  EmailSectionType,
  operations,
} from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";
import {
  CustomizationViews,
  TemplateEditorInterface,
} from "~/types/template-editor";
import { templateEditorInitialState } from "~/utils/template-editor";

const initialState: TemplateEditorInterface = templateEditorInitialState;

export const defaultMenuLink: components["schemas"]["EmailMenuLinkElementSchema"] =
  {
    id: "",
    type: EmailElementType.menu_link,
    enabled: true,
    index: 0,
    opens_new_tab: false,
    text: "Link 0",
    link_url: "",
  };

interface ChangeCurrentView {
  type: "CHANGE_CURRENT_VIEW";
  payload: CustomizationViews;
}

interface UpdateEditorState {
  type: "UPDATE_EDITOR_STATE";
  payload: Partial<TemplateEditorInterface>;
}

interface ResetState {
  type: "RESET_STATE";
  payload?: TemplateEditorInterface;
}

export type TemplateEditorActions =
  | UpdateEditorState
  | ChangeCurrentView
  | ResetState;

function reducer(
  state: TemplateEditorInterface,
  action: TemplateEditorActions
): TemplateEditorInterface {
  switch (action.type) {
    case "CHANGE_CURRENT_VIEW":
      return {
        ...state,
        viewType: (() => {
          switch (action.payload) {
            case CustomizationViews.HeaderOptions:
              return EmailSectionType.header;
            case CustomizationViews.FooterOptions:
              return EmailSectionType.footer;
            default:
              return state?.viewType;
          }
        })(),
        currentView: action.payload,
      };
    case "UPDATE_EDITOR_STATE":
      return {
        ...state,
        ...action.payload,
        headerOptions: {
          ...state.headerOptions,
          ...action.payload.headerOptions,
        },
        footerOptions: {
          ...state.footerOptions,
          ...action.payload.footerOptions,
        },
      };
    case "RESET_STATE":
      return action.payload ?? initialState;
    default:
      assertNever(action);
  }
}

const TemplateEditorContext =
  createContext<TemplateEditorInterface>(initialState);
const DispatchContext =
  createContext<React.Dispatch<TemplateEditorActions> | null>(null);

type BrandEmailSectionTemplatesResponse =
  operations["emails_api_get_brand_email_sections"]["responses"]["200"]["content"]["application/json"];

const getStateFromBrandEmailSections = (
  brandEmailSectionsData: BrandEmailSectionTemplatesResponse
): TemplateEditorInterface => {
  return {
    ...initialState,
    headerOptions: brandEmailSectionsData.header,
    footerOptions: brandEmailSectionsData.footer,
  };
};

export const getBrandEmailSectionsQueryKey = (brandID: string) => [
  "brand-email-sections-",
  brandID,
];

export const TemplateEditorProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const activeBrandID = useActiveBrandID();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: brandEmailSectionsData } =
    useQuery<BrandEmailSectionTemplatesResponse>({
      queryKey: getBrandEmailSectionsQueryKey(activeBrandID),
      queryFn: async () => {
        const { data } = await axios.get(
          `/api/v1/emails/brand-email-sections`,
          {
            params: {
              brand_id: activeBrandID,
            },
          }
        );
        return data;
      },
      enabled: !!activeBrandID,
    });

  useEffect(() => {
    if (!brandEmailSectionsData) {
      dispatch({
        type: "RESET_STATE",
      });
      return;
    }

    dispatch({
      type: "RESET_STATE",
      payload: getStateFromBrandEmailSections(brandEmailSectionsData),
    });
  }, [brandEmailSectionsData]);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <TemplateEditorContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </TemplateEditorContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useTemplateEditorState() {
  return useContext(TemplateEditorContext);
}

export function useHeaderFooterState() {
  const state = useTemplateEditorState();
  switch (state.viewType) {
    case EmailSectionType.header:
      return state.headerOptions;
    case EmailSectionType.footer:
      return state.footerOptions;
    default:
      throw new Error("Invalid viewType");
  }
}

export function useTemplateEditorDispatch(): React.Dispatch<TemplateEditorActions> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useTemplateEditorDispatch must be used within a TemplateEditorProvider"
    );
  }
  return dispatch;
}

export function useUpdateEditorState() {
  const dispatch = useTemplateEditorDispatch();
  return (payload: Partial<TemplateEditorInterface>) => {
    dispatch({ type: "UPDATE_EDITOR_STATE", payload });
  };
}
