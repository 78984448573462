import ChangeCategoryFontDialogContent from "./ChangeCategoryFontDialogContent";
import ChangeFontFamilyCard from "./ChangeFontFamilyCard";
import { components, TypographyCategory } from "@openapi";
import { Dialog, Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DialogActions from "~/components/core/dialog/DialogActions";
import DialogHeader from "~/components/core/dialog/DialogHeader";

type FontFamilyProps = components["schemas"]["BrandStylingFontFamilySchema"] & {
  fontWeight: components["schemas"]["FontWeight"];
};

interface ChangeFontFamilyDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChange?: (font: string) => void;
  fontFamilies: {
    [key in TypographyCategory]: FontFamilyProps | null;
  };
}

const ChangeFontFamilyDialog: React.FC<ChangeFontFamilyDialogProps> = ({
  isOpen,
  setIsOpen,
  fontFamilies,
}) => {
  const hasFonts = fontFamilies.header || fontFamilies.paragraph;
  const [selectedFontFamilyType, setSelectedFontFamilyType] =
    useState<TypographyCategory>();

  useEffect(() => {
    if (!isOpen) {
      setSelectedFontFamilyType(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      {selectedFontFamilyType && (
        <ChangeCategoryFontDialogContent
          category={selectedFontFamilyType}
          font={fontFamilies[selectedFontFamilyType]}
          onCancel={() => setSelectedFontFamilyType(undefined)}
          onSuccess={() => {
            setSelectedFontFamilyType(undefined);
          }}
        />
      )}
      {!selectedFontFamilyType && (
        <Dialog.Content>
          <DialogHeader title={hasFonts ? "Change Font" : "Add Font"} />
          <Dialog.Description />
          <Flex my="5" gap="5" direction={"column"}>
            <ChangeFontFamilyCard
              title="Heading font family"
              type={TypographyCategory.header}
              font={fontFamilies.header ?? null}
              onClick={setSelectedFontFamilyType}
            />
            <ChangeFontFamilyCard
              title="Paragraph font family"
              type={TypographyCategory.paragraph}
              font={fontFamilies.paragraph ?? null}
              onClick={setSelectedFontFamilyType}
            />
          </Flex>
          <DialogActions>
            <Dialog.Close>
              <AppButton variant="outlined" radius="large">
                Close
              </AppButton>
            </Dialog.Close>
          </DialogActions>
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
};

export default ChangeFontFamilyDialog;
