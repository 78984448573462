import { Link } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: var(--primary-vibrant-orange);
  font-weight: 700;
  text-decoration: none !important;
`;

const SignInLink = ({ style }: { style?: React.CSSProperties }) => {
  const navigate = useNavigate();

  const openLogin = (e: any) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <StyledLink style={style} href="" underline="hover" onClick={openLogin}>
      Login
    </StyledLink>
  );
};

export default SignInLink;
