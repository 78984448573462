import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternCampaignDetailResponse =
  operations["intern_api_intern_api_root"]["responses"][200]["content"]["application/json"];

export const getCampaignDetailsQueryKey = (campaignId: string) => [
  "campaign-",
  campaignId,
];

const useInternCampaignDetails = ({ campaignId }: { campaignId: string }) => {
  const campaignQuery = useQuery<InternCampaignDetailResponse>({
    queryKey: getCampaignDetailsQueryKey(campaignId),
    queryFn: async () => {
      const { data } = await axios.get(
        `/api/v1/intern/campaigns/${campaignId}`,
        {}
      );
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  return campaignQuery;
};

export default useInternCampaignDetails;
