import { operations } from "@openapi";
import { toast } from "sonner";
import { useInitSection } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type InitEmailSectionMutationParams =
  operations["emails_api_init_email_section"]["requestBody"]["content"]["application/json"];
type Response =
  operations["emails_api_init_email_section"]["responses"][200]["content"]["application/json"];

const useInitEmailSectionMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: Response) => Response;
} = {}) => {
  const initSection = useInitSection();

  return useDraperApiPostMutation<Response, InitEmailSectionMutationParams>({
    path: "/emails/section/init",
    onSuccess: (data) => {
      const updatedData = onSuccess ? onSuccess(data) : data;
      initSection(updatedData);
    },
    onError: (error) => {
      console.error("Failed to init section", error);
      toast.error("Failed to generate section");
    },
  });
};

export default useInitEmailSectionMutation;
