import { operations } from "@openapi";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Box, Button, Dialog, Flex, Heading, Table } from "@radix-ui/themes";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { Plus } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type GetBrandFineTunedModelsResponse =
  operations["brand_api_get_brand_fine_tuned_models"]["responses"][200]["content"]["application/json"];

type CreateBrandFineTunedModelResponse =
  operations["brand_api_create_brand_fine_tuned_model"]["responses"][200]["content"]["application/json"];

const styles = {
  tableContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    padding: "8px",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  tableText: {
    fontSize: "12px",
    whiteSpace: "normal",
  },
  tooltipContent: {
    background: "#fff",
    zIndex: 1000,
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

interface InternBrandVoiceModelsCardProps {
  parentState: number;
  refresh: () => void;
}

const InternBrandVoiceModelsCard: React.FC<InternBrandVoiceModelsCardProps> = ({
  parentState,
  refresh,
}) => {
  const activeBrandID = useActiveBrandID();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["brand-fine-tuned-models", activeBrandID],
    queryFn: async (): Promise<GetBrandFineTunedModelsResponse> => {
      const { data } = await axios.get(
        `/api/v1/brand/${activeBrandID}/fine-tuned-models`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: true,
    staleTime: 3600,
  });

  const { mutate: createModelMutation, isPending: isCreateModelPending } =
    useMutation<
      CreateBrandFineTunedModelResponse,
      AxiosError,
      {
        brandId: string;
      }
    >({
      mutationFn: async ({
        brandId,
      }): Promise<CreateBrandFineTunedModelResponse> => {
        const { data } = await axios.post(
          `/api/v1/brand/${activeBrandID}/fine-tuned-model`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      },
      onSuccess: (response) => {
        setIsDialogOpen(false);
        if (response.success) {
          refresh();
          alert("Brand voice fine-tuning job successfully created.");
        } else {
          alert(response.message);
        }
      },
      onError: (error) => {
        setIsDialogOpen(false);
        const errorMessage =
          error.status !== 500 && error.response?.data
            ? String(error.response?.data)
            : "Something went wrong";
        alert(`Error generating primary text: ${errorMessage}`);
      },
    });

  const sortedModels = useMemo(() => {
    if (!data?.models) return [];
    return data.models
      .filter((model) => model.purpose === "brand_voice")
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
  }, [data]);

  useEffect(() => {
    refetch();
  }, [parentState, refetch]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {"Loading..."}
      </div>
    );
  }

  const openChatUrl = (modelName: string) => {
    const encodedName = encodeURIComponent(modelName);
    const url = `https://platform.openai.com/playground/chat?models=${encodedName}`;
    window.open(url, "_blank");
  };

  return (
    <Box height="100%">
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Content>
          <Dialog.Title>Create Voice Model</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Are you sure you'd like to start a fine tuning job to train a new
            brand voice model? This may take up to 3 hours.
          </Dialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                createModelMutation({ brandId: activeBrandID });
              }}
              loading={isCreateModelPending}
            >
              OK
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Flex direction="column" height="100%" gap="18px">
        <Flex justify="between">
          <Heading size="5">Voice Models</Heading>
          <Button onClick={() => setIsDialogOpen(true)}>
            <Plus size="18px" />
          </Button>
        </Flex>
        <Flex direction="column" style={styles.tableContainer} flexGrow="1">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {["Model", "Created", ""].map((header, headerIndex) => (
                  <Table.Cell key={headerIndex} style={styles.tableHeader}>
                    {header}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedModels.map((model) => (
                <Tooltip.Root key={model.id}>
                  <Tooltip.Trigger asChild>
                    <Table.Row>
                      <Table.Cell style={styles.tableText}>
                        <span>{model.base_model}</span>
                      </Table.Cell>
                      <Table.Cell style={styles.tableText}>
                        {new Date(model.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell style={styles.tableText}>
                        <Button
                          size="1"
                          onClick={() => openChatUrl(model.name)}
                        >
                          Chat
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  </Tooltip.Trigger>
                  <Tooltip.Content side="top" style={styles.tooltipContent}>
                    {model.name}
                  </Tooltip.Content>
                </Tooltip.Root>
              ))}
            </Table.Body>
          </Table.Root>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternBrandVoiceModelsCard;
