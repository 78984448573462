import {
  useCurrentUserDispatch,
  useCurrentUserState,
} from "../../../contexts/CurrentUserContext";
import { Text, Flex, Select } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { UserIcon } from "~/assets/icons";
import AccountSettings from "~/components/account-settings/AccountSettings";
import useUserUpdateMutation from "~/hooks/user/useUserUpdateMutation";

const Root = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: inherit;
  text-decoration: none;
  margin-bottom: 40px;
  cursor: pointer;
`;

const Icon = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: var(--primary-white);
  color: var(--primary-deep-purple);
  padding: 16px;
  stroke-width: 1.5;
  border-radius: 50%;
  border: 1px solid var(--border-primary);
  transition: background-color 0.3s;
  &:hover {
    background-color: rgb(0, 0, 0, 0.07);
  }
`;

export default function CurrentUser() {
  const { user } = useCurrentUserState();
  const currentUserDispatch = useCurrentUserDispatch();

  const isLoading = user.status === "LOADING";
  const activeBrand = user.status === "LOGGED_IN" ? user.activeBrand : null;
  const brands = user.status === "LOGGED_IN" ? user.brands : null;
  const error = useMemo(() => {
    if (user.status === "LOGGED_OUT") {
      return "Please log in";
    } else if (user.status === "UNAUTHORIZED") {
      return "Unauthorized";
    }
    if (user.status === "LOGGED_IN" && !user.brands.length) {
      return "No brands found";
    }
  }, [user]);

  const { updateUser } = useUserUpdateMutation({
    userID: user.status === "LOGGED_IN" ? user.id : "",
  });

  const onUpdateUser = useCallback(
    (brandID: string) => {
      if (updateUser && user.status === "LOGGED_IN" && activeBrand) {
        updateUser({
          id: user.id,
          active_brand_id: brandID,
        });
      }
    },
    [updateUser, user, activeBrand]
  );

  if (isLoading || error != null) {
    return null;
  }

  if (user.status !== "LOGGED_IN") {
    return null;
  }

  return (
    <AccountSettings>
      <Root>
        <Icon>
          <UserIcon strokeWidth={1.3} />
        </Icon>
        <Text size="1" style={{ fontWeight: 600 }}>
          Account
        </Text>
        {user.brands.length > 1 && (
          <Select.Root
            size="1"
            value={user.activeBrand.id}
            onValueChange={(selectedBrandId) => {
              currentUserDispatch({
                type: "SET_ACTIVE_BRAND_ID",
                payload: selectedBrandId,
              });
              onUpdateUser(selectedBrandId);
            }}
          >
            <Select.Trigger
              color="gray"
              variant="ghost"
              style={{
                maxWidth: "70px",
              }}
            />
            <Select.Content>
              {brands?.map((brand) => {
                return (
                  <Select.Item key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select.Root>
        )}
      </Root>
    </AccountSettings>
  );
}
