import { operations } from "@openapi";
import { Box, Flex, Progress, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CircleStars } from "~/assets/CircleStars.svg";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";

const steps = [
  { value: 25, text: "Sit tight while we generate the creative assets..." },
  { value: 35, text: "Generating emails..." },
  { value: 70, text: "Building ads..." },
  { value: 85, text: "Adding links to the emails..." },
  { value: 100, text: "Making some final touches" },
];

type CampaignGenerationStatusResponse =
  operations["campaign_api_get_campaign_generation_status"]["responses"][200]["content"]["application/json"];

const CampaignGenerateLoadingScreen = () => {
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [cycleCount, setCycleCount] = useState(0);
  const { campaignId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isError } =
    useQuery<CampaignGenerationStatusResponse>({
      queryKey: ["campaign-generation-status", campaignId],
      queryFn: async () => {
        const { data } = await axios.get(
          `/api/v1/campaign/${campaignId}/generation-status`
        );
        return data;
      },
      refetchInterval: 5000,
      retry: false,
      staleTime: Infinity,
      enabled: !!campaignId,
    });

  useEffect(() => {
    if (data?.is_initial_generation_complete) {
      setCurrentStep(steps[steps.length - 1]);
      setTimeout(() => {
        navigate(`/campaign/${campaignId}`);
      }, 1000);
    } else {
      const interval = setInterval(() => {
        setCycleCount((prevCount) => prevCount + 1);
        const nextIndex =
          (cycleCount % (steps.length - 1)) +
          (cycleCount >= steps.length - 1 ? 1 : 0);
        setCurrentStep(steps[nextIndex]);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [cycleCount, data, campaignId, navigate]);

  if (!data) return <div>Error</div>;
  if (isLoading) return <div></div>;
  if (isError) return <div>Error</div>;

  return (
    <Flex
      gap="40px"
      direction="column"
      height="100dvh"
      width="100%"
      justify="center"
      align="center"
    >
      <BackgroundGradient />
      <CircleStars />
      <Text weight="bold" size="6">
        {currentStep.text}
      </Text>
      <Box maxWidth="400px">
        <Progress
          value={currentStep.value}
          size="3"
          style={{ height: "16px", width: "400px", backgroundColor: "white" }}
          radius="large"
        />
      </Box>
    </Flex>
  );
};

export default CampaignGenerateLoadingScreen;
