import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type CollectionsResponse =
  operations["shopify_integration_api_get_collections"]["responses"][200]["content"]["application/json"];
export type Collections =
  operations["shopify_integration_api_get_collections"]["responses"][200]["content"]["application/json"];
export type Collection =
  operations["shopify_integration_api_get_collections"]["responses"][200]["content"]["application/json"]["collections"][0];

export const PAGE_SIZE = 25;

const useGetShopifyCollections = ({
  after,
  query,
}: {
  after?: string;
  query?: string;
}) => {
  const key = "commerce-platform/collections";
  const activeBrandID = useActiveBrandID();
  const {
    data,
    isLoading,
    refetch,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [key, activeBrandID, query],
    queryFn: async ({
      pageParam,
    }: {
      pageParam: string | undefined;
    }): Promise<CollectionsResponse> => {
      const response = await axios.get(`/api/v1/${key}`, {
        params: {
          brand_id: activeBrandID,
          limit: PAGE_SIZE,
          after: pageParam,
          query: query,
        },
      });
      return response.data;
    },
    initialPageParam: after ?? undefined,
    getNextPageParam: (lastPage) => {
      return !lastPage.page_info.has_next_page
        ? undefined
        : lastPage.page_info.end_cursor;
    },
  });
  return {
    data: data,
    refetch: refetch,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
export default useGetShopifyCollections;
