import { Button, ButtonProps } from "@radix-ui/themes";
import { forwardRef, ForwardRefExoticComponent } from "react";

const AppButtonPrimary = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button radius="full" size="4" {...props} ref={ref}>
        {children}
      </Button>
    );
  }
);

const AppButtonSoft = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        variant="soft"
        color="violet"
        radius="full"
        size="4"
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

const AppButtonOutlined = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        variant="outline"
        color="gray"
        radius="full"
        size="4"
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

const AppButtonDark = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        color="gray"
        highContrast
        radius="full"
        size="4"
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

const AppButtonGhost = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, style, ...props }, ref) => {
    return (
      <Button
        variant="ghost"
        style={{ color: "var(--text-link)", ...style }}
        radius="large"
        size="4"
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

type AppButtonVariants = "PRIMARY" | "SOFT" | "OUTLINED" | "DARK" | "GHOST";

export const ButtonVariants: Record<
  AppButtonVariants,
  ForwardRefExoticComponent<
    ButtonProps & { ref?: React.LegacyRef<HTMLButtonElement> }
  >
> = {
  PRIMARY: AppButtonPrimary,
  SOFT: AppButtonSoft,
  OUTLINED: AppButtonOutlined,
  DARK: AppButtonDark,
  GHOST: AppButtonGhost,
};
