import {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "../campaign/useGetCampaignDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

interface DeleteAdCreativeParams {
  id: string;
  /** Used for updating states and queries data */
  campaignId?: string;
}

const useDeleteEmailCreativeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (vars: DeleteAdCreativeParams) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation<null, AxiosError, DeleteAdCreativeParams>({
    mutationFn: async (vars) => {
      const { data } = await axios.delete(
        `/api/v1/emails/email-creative/${vars.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (_, vars) => {
      onSuccess?.(vars);

      if (!vars.campaignId) {
        return;
      }
      queryClient.setQueryData<CampaignDetailResponse>(
        getCampaignDetailsQueryKey(vars.campaignId),
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            email_creatives: oldData.email_creatives.filter(
              (creative) => creative.id !== vars.id
            ),
            email_count: oldData.email_creatives.length,
          };
        }
      );
    },
    onError: (error) => {
      console.log("Delete failed:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });

  return mutation;
};

export default useDeleteEmailCreativeMutation;
