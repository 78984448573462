import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import useConnectKlaviyoForm from "../core/ConnectKlaviyoForm";
import AppButton from "../core/buttons/AppButton/AppButton";
import ActionCard from "../core/cards/ActionCard";
import KlaviyoConnectInstructions from "../core/misc/KlaviyoConnectInstructions";
import { Box, Flex, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";

const KlaviyoRoot = styled(Box)`
  width: 100%;
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  padding: 24px;
`;

const OnboardingKlaviyo = ({
  onNext,
  onPrev,
}: {
  onNext: () => void;
  onPrev: () => void;
}) => {
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const { isLoading, form, canContinue } = useConnectKlaviyoForm({
    onSuccess: () => setIsHelpOpen(false),
  });

  const klaviyoRoot = (
    <KlaviyoRoot>
      <Flex gap="4" direction={"column"}>
        {form}
        <Text
          size={"1"}
          weight={"bold"}
          onClick={() => setIsHelpOpen(!isHelpOpen)}
          style={{
            cursor: "pointer",
            color: "var(--text-link)",
          }}
        >
          {isHelpOpen ? "Close Guide" : "Help me get the Private Key"}
        </Text>
        {isHelpOpen && <KlaviyoConnectInstructions />}
      </Flex>
    </KlaviyoRoot>
  );
  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <ActionCard
        footer={
          <>
            <AppButton variant="outlined" onClick={onPrev}>
              Back
            </AppButton>
            <AppButton
              disabled={isLoading || !canContinue}
              variant="dark"
              onClick={onNext}
            >
              Continue
            </AppButton>
          </>
        }
        title="Klaviyo Account"
        subtitle={`Let’s connect your Klaviyo account.\n\nThis is required to analyze all your past emails and build a model for generating & publishing emails in your brand voice and style.`}
      >
        {isLoading ? <LoadingDots /> : klaviyoRoot}
      </ActionCard>
    </Flex>
  );
};

export default OnboardingKlaviyo;
