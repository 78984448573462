import { EmailSectionType, operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type EmailSectionInitResponse =
  operations["intern_api_get_section_template"]["responses"][200]["content"]["application/json"];

export const getEmailSectionInitQueryKey = (
  brandId: string,
  sectionType: EmailSectionType
) => ["email-section-init", brandId, sectionType];

const useInternEmailsSectionInitQuery = ({
  brandId,
  sectionType,
}: {
  brandId: string;
  sectionType: EmailSectionType;
}) => {
  const query = useQuery<EmailSectionInitResponse>({
    queryKey: getEmailSectionInitQueryKey(brandId, sectionType),
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/intern/emails/section/init`, {
        params: {
          brand_id: brandId,
          section_type: sectionType,
        },
      });
      return data;
    },
    retry: false,
    staleTime: Infinity,
    enabled: !!sectionType,
  });

  return query;
};

export default useInternEmailsSectionInitQuery;
