import AppButton from "../buttons/AppButton/AppButton";
import "./ColorPicker.css";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Text, Theme, TextField } from "@radix-ui/themes";
import parseColor from "parse-color";
import { useCallback, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";

const ColorTextBox = styled(TextField.Root)<{ $error?: boolean }>`
  border-radius: 12px;
  border: ${(props) => (props.$error ? "1px solid red" : "undefined")};
  color: #4c4747;
  font-size: 16px;
  height: 45px;
  white-space: nowrap;
`;

const ColorPicker = ({
  align = "center",
  children,
  defaultColor,
  onSaveColor,
  onChange,
}: {
  align?: "start" | "center" | "end";
  defaultColor?: string;
  onSaveColor?: (color: string) => void;
  children: React.ReactNode;
  onChange?: (color: string) => void;
}) => {
  const defaultHexColor = defaultColor
    ? parseColor(defaultColor).hex
    : undefined;
  const [savedColor, setSavedColor] = useState(defaultHexColor ?? "#32A5E");
  const [color, setColor] = useState("");
  const [open, setOpen] = useState(false);

  const setRgbColor = (position: [number, number], partialColor: string) => {
    const hexVal = Math.min(parseInt(partialColor || "0", 10), 255)
      .toString(16)
      .padStart(2, "0");
    const newColor = String.prototype.concat(
      color.slice(0, position[0]),
      hexVal,
      color.slice(position[1], color.length)
    );
    onChange?.(newColor);
    setColor(newColor);
  };
  const [showError, setShowError] = useState(false);
  return (
    <Popover.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
        if (value) {
          const color = defaultHexColor ?? "#32A5E";
          setSavedColor(color);
          setColor(color);
          return;
        }
        if (!value && savedColor !== color) {
          onChange?.(savedColor);
        }
      }}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Portal>
        <Theme>
          <Popover.Content
            sideOffset={8}
            className="color-picker-content"
            align={align}
          >
            <HexColorPicker
              color={color}
              onChange={(color) => {
                onChange?.(color);
                setColor(color);
              }}
              className="color-picker-saturation"
            />
            <Flex mt="12px" gap="12px">
              <Flex direction="column" gap="4px" width="95px">
                <Text color="gray">Hex</Text>
                <ColorTextBox
                  $error={showError}
                  size="3"
                  value={color}
                  onChange={(e) => {
                    setShowError(false);
                    if (e.target.value.length > 7) {
                      return;
                    }
                    onChange?.(e.target.value);
                    setColor(e.target.value);
                  }}
                />
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">R</Text>
                <ColorTextBox
                  size="3"
                  value={String(parseInt(color.slice(1, 3), 16))}
                  type="number"
                  onChange={(e) => {
                    setRgbColor([1, 3], e.target.value);
                  }}
                />
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">G</Text>
                <ColorTextBox
                  size="3"
                  value={String(parseInt(color.slice(3, 5), 16))}
                  type="number"
                  onChange={(e) => {
                    setRgbColor([3, 5], e.target.value);
                  }}
                />
              </Flex>
              <Flex direction="column" gap="4px" width="53px">
                <Text color="gray">B</Text>
                <ColorTextBox
                  size="3"
                  value={String(parseInt(color.slice(5, 7), 16))}
                  type="number"
                  onChange={(e) => {
                    setRgbColor([5, 7], e.target.value);
                  }}
                ></ColorTextBox>
              </Flex>
            </Flex>
            {showError && (
              <Text color="red">
                Hex value is incorrect, cannot update color
              </Text>
            )}

            <Flex mt="16px" justify="between">
              <Popover.Close asChild>
                <AppButton mr="16px" variant="outlined" radius="large">
                  Cancel
                </AppButton>
              </Popover.Close>
              <AppButton
                onClick={() => {
                  if (!/^#[A-Fa-f0-9]{6}$/.test(color)) {
                    setShowError(true);
                    return;
                  }
                  setSavedColor(color);
                  onSaveColor?.(color);
                  setOpen(false);
                }}
                radius="large"
                variant="dark"
              >
                Save Changes
              </AppButton>
            </Flex>
          </Popover.Content>
        </Theme>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ColorPicker;
