import AuthPageBase from "./AuthPageBase";
import { Flex, Text } from "@radix-ui/themes";
import { useLocation } from "react-router-dom";
import { ReactComponent as EmailCheckmarkIcon } from "~/assets/mailCompleted.svg";

const VerifyEmail = () => {
  const location = (useLocation().state as { email: string }) ?? {
    email: "rob@mail.com",
  };
  return (
    <AuthPageBase>
      <Flex direction="column">
        <Flex p="6" direction={"column"} gap={"2"}>
          <Flex justify="center">
            <EmailCheckmarkIcon />
          </Flex>
          <Text mt="32px" mb="4px">
            To start using Kin, activate your account with the link sent to:
          </Text>
          <Text size={"2"}>{location.email}</Text>
        </Flex>

        {/* <Footer justify={"center"} align={"center"}>
        <FadedText size={"1"}>
        Didn't receive an email?{" "}
        <StyledLink href="" underline="hover" onClick={() => navigate("/")}>
        Resend code
        </StyledLink>
        </FadedText>
      </Footer> */}
      </Flex>
    </AuthPageBase>
  );
};

export default VerifyEmail;
