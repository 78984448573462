import AccountSettingsGeneral from "./AccountSettingsGeneral";
import AccountSettingsIntegrations from "./AccountSettingsIntegrations";
import AccountSettingsPermissions from "./AccountSettingsPermissions";
import { ScrollArea } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import { AccountSettingsTabsEnum } from "~/types/account-settings";

const Content = styled(ScrollArea)`
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0px;
  background-color: var(--primary-white);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid rgba(240, 240, 240, 1);
  box-shadow: 0px 12px 120px 0px rgba(0, 0, 0, 0.08),
    0px 12px 32px -16px rgba(0, 9, 50, 0.04);
`;

const AccountSettingsViews: Record<AccountSettingsTabsEnum, React.ReactNode> = {
  [AccountSettingsTabsEnum.General]: <AccountSettingsGeneral />,
  [AccountSettingsTabsEnum.AccountPermissions]: <AccountSettingsPermissions />,
  [AccountSettingsTabsEnum.Integrations]: <AccountSettingsIntegrations />,
};

const AccountSettingsContent = ({
  activeTab,
}: {
  activeTab: AccountSettingsTabsEnum;
}) => {
  return <Content>{AccountSettingsViews[activeTab]}</Content>;
};

export default AccountSettingsContent;
