import { AdMediaAspectRatio } from "@openapi";
import { Box, Button, Checkbox, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { PencilIcon } from "~/assets/icons";

const Root = styled.div`
  border-radius: 12px;
  border: 1px solid var(--border-primary);
  max-width: 80%;
  width: 100%;
  flex-basis: 100%;
  display: flex;
  padding: 16px;
  background-color: var(--primary-white);
  box-shadow: 0px 1.57px 3.78px 0px rgba(88, 66, 45, 0.02),
    0px 6.93px 7.82px 0px rgba(88, 66, 45, 0.04),
    0px 17px 15.6px 0px rgba(88, 66, 45, 0.05),
    0px 32.74px 30.58px 0px rgba(88, 66, 45, 0.06);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Image = styled.img`
  height: 120px;
  object-fit: cover;
  background-color: var(--gray-5);
`;

const CampaignPublishDialogAdCard = ({
  title,
  contentHeader,
  contentSubheader,
  adId,
  previewImages,
  onSelect,
  selected,
}: {
  onSelect: () => void;
  selected: boolean;
  title: string;
  contentHeader: string;
  contentSubheader: string;
  adId: string;
  previewImages: {
    url: string;
    aspect_ratio: AdMediaAspectRatio;
  }[];
}) => {
  return (
    <Root>
      <Box width={"100%"}>
        <Header>
          <Text as="label" size="2">
            <Flex gap="2">
              <Checkbox checked={selected} onClick={onSelect} />
              <Text size="2" weight="bold">
                {title}
              </Text>
            </Flex>
          </Text>
          <Link to={`/ad/${adId}`}>
            <Button size={"2"} radius="large" color="gray" variant="ghost">
              <PencilIcon size={16} /> Edit
            </Button>
          </Link>
        </Header>

        <Flex direction={"row"} gap="16px">
          {!!previewImages.length ? (
            previewImages.map((image, idx) => (
              <Image
                key={idx}
                src={image.url}
                style={{
                  aspectRatio:
                    image.aspect_ratio === AdMediaAspectRatio.portrait
                      ? "3/4"
                      : "1 / 1",
                }}
                alt="Ad image"
              />
            ))
          ) : (
            <>
              <Skeleton width="120px" height="120px" />
              <Skeleton width="82px" height="120px" />
            </>
          )}

          <Flex direction={"column"}>
            <Text as="div" size="2" weight="bold">
              {contentHeader}
            </Text>
            <Text as="div" color="gray" size="2">
              {contentSubheader}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Root>
  );
};

export default CampaignPublishDialogAdCard;
