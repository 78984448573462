import React from "react";
import SidePanelComponents from "~/components/core/editor/sidepane";

type ModifierContainerProps = Pick<
  React.ComponentProps<typeof SidePanelComponents.EditorCard>,
  "children" | "title" | "switchDisabled"
> &
  (
    | {
        hideSwitch: true;
      }
    | {
        hideSwitch: false | undefined;
        checked?: boolean;
        onSwitch: (checked: boolean) => void;
        padding?: "0" | "1" | "2" | "3" | "4";
      }
  );

const ModifierContainer: React.FC<ModifierContainerProps> = ({
  title,
  children,
  switchDisabled,
  ...props
}) => {
  const translatedProps: React.ComponentProps<
    typeof SidePanelComponents.EditorCard
  > = {
    title,
    children,
    switchDisabled,
    ...(props.hideSwitch === true
      ? { checkable: false }
      : {
          checkable: true,
          checked: props.checked ?? true,
          onCheckedChange: props.onSwitch,
          padding: props.padding,
        }),
  };
  return <SidePanelComponents.EditorCard {...translatedProps} />;
};

export default ModifierContainer;
