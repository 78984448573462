import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type GetAudiencesData =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"];

const useCDPAudiencesQuery = () => {
  const activeBrandID = useActiveBrandID();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["cdp-audiences", activeBrandID],
    queryFn: async (): Promise<GetAudiencesData> => {
      const response = await axios.get("/api/v1/cdp/list", {
        params: {
          brand_id: activeBrandID,
        },
      });
      return response.data;
    },
  });

  return {
    audiencesData: data,
    isLoading,
    isError,
    error,
  };
};

export default useCDPAudiencesQuery;
