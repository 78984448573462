import { Flex } from "@radix-ui/themes";
import { PlusCircleIcon } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Container = styled(Flex)`
  width: 100%;
  height: 318px;
  padding: 16px;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #fcf7f2 0%, rgba(252, 247, 242, 0) 100%);
`;

const BottomCTA = () => {
  return (
    <Container
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        pointerEvents: "none",
      }}
    >
      <Link
        to="/campaigns/wizard/new"
        style={{
          pointerEvents: "auto",
        }}
      >
        <AppButton>
          <PlusCircleIcon />
          Create a Campaign
        </AppButton>
      </Link>
    </Container>
  );
};

export default BottomCTA;
