import { operations } from "../../../openapi";
import { BrandStylingResponse } from "../data/useBrandStyleQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type CreateBrandColorPaletteResponse =
  operations["brand_api_create_color_palette"]["responses"][200]["content"]["application/json"];

type CreateBrandColorPaletteRequestData =
  operations["brand_api_create_color_palette"]["requestBody"]["content"]["application/json"];

const useBrandColorPaletteCreateMutation = ({
  brandID,
  onSuccess,
  onError,
}: {
  brandID: string;
  onSuccess?: (data: CreateBrandColorPaletteResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  const createBrandColorPalette = useDraperApiPostMutation<
    CreateBrandColorPaletteResponse,
    CreateBrandColorPaletteRequestData
  >({
    mutationKey: ["brand-color-palette-create"],
    path: `/brand/${brandID}/stylebook/color_palette/create`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);

      queryClient.setQueryData<BrandStylingResponse>(
        ["brand-style", brandID],
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            color_palettes: [...oldData.color_palettes, data],
          };
        }
      );
    },
  });

  return {
    createBrandColorPalette: createBrandColorPalette.mutate,
    isLoading: createBrandColorPalette.isPending,
  };
};

export default useBrandColorPaletteCreateMutation;
