import { AdMediaAspectRatioStrings } from "../../../types/ads";
import { AdMediaAspectRatio, operations } from "@openapi";
import {
  Button,
  Container,
  Dialog,
  Flex,
  Select,
  Text,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "styled-components";

type CreateAdMediaTemplateVariantParams =
  operations["ads_api_create_ad_media_template_variant"]["requestBody"]["content"]["multipart/form-data"];

type CreateAdMediaTemplateVariantApiResponse =
  operations["ads_api_create_ad_media_template_variant"]["responses"][200]["content"]["application/json"];

interface InternAdMediaTemplateVariantCreationDialogProps {
  mediaTemplateId: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
}

const DragDropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const InternAdMediaTemplateVariantCreationDialog: React.FC<
  InternAdMediaTemplateVariantCreationDialogProps
> = ({ mediaTemplateId, isDialogOpen, setIsDialogOpen }) => {
  const [mediaAspectRatio, setMediaAspectRatio] = useState<AdMediaAspectRatio>(
    AdMediaAspectRatio.full_screen_vertical
  );

  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/svg+xml": [".svg"],
    },
  });

  const createTemplateVariantMutation = useMutation<
    CreateAdMediaTemplateVariantApiResponse,
    Error,
    CreateAdMediaTemplateVariantParams
  >({
    mutationFn: async (
      params: CreateAdMediaTemplateVariantParams
    ): Promise<CreateAdMediaTemplateVariantApiResponse> => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("media_aspect_ratio", params.media_aspect_ratio!);
      formData.append("media_template_file", params.media_template_file);
      console.log(formData);
      const { data } = await axios.post(
        `/api/v1/ads/ad-media-template/${mediaTemplateId}/variant`,
        formData,
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsLoading(false);
      if (data.success) {
        setIsDialogOpen(false);
        alert("Template variant created successfully!");
        console.log("Template variant created successfully");
        window.location.reload();
      } else {
        alert("Error creating template variant");
        console.error("Failed to create template variant");
      }
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error);
      console.error("Error creating template variant:", error);
    },
  });

  const handleCreateClick = () => {
    if (acceptedFiles.length < 1) {
      alert("SVG template is required. Please upload a template file.");
      return;
    }
    const mediaTemplateFile = acceptedFiles[0];
    createTemplateVariantMutation.mutate({
      media_aspect_ratio: mediaAspectRatio,
      media_template_file: mediaTemplateFile as unknown as string,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Create Ad Media Template Variant</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Create a new ad media template variant for the specified aspect
            ratio
          </Dialog.Description>
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="1" mb="1" weight="bold">
                Aspect Ratio
              </Text>
              <Select.Root
                defaultValue={AdMediaAspectRatio.full_screen_vertical}
                onValueChange={(value) =>
                  setMediaAspectRatio(value as AdMediaAspectRatio)
                }
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    {Object.values(AdMediaAspectRatio).map((ratio) => (
                      <Select.Item key={ratio} value={ratio}>
                        {AdMediaAspectRatioStrings[ratio]}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </label>
            <div className="container">
              <Text as="div" size="1" mb="1" weight="bold">
                SVG Template
              </Text>
              <DragDropContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <Text size="3">Drag and drop your SVG template here</Text>
                {acceptedFiles.map((file: any) => (
                  <li key={file.name}>
                    {file.name} - {file.size} bytes
                  </li>
                ))}
              </DragDropContainer>
            </div>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleCreateClick} loading={isLoading}>
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternAdMediaTemplateVariantCreationDialog;
