import { Box } from "@radix-ui/themes";
import { useCallback, useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import Modal from "~/components/core/dialog/Modal";

const usePreventDataLoss = ({
  onProceed,
  enabled,
  description,
  isLoading,
}: {
  description: string;
  enabled: boolean;
  onProceed: () => void;
  isLoading?: boolean;
}) => {
  //states
  const [isOpen, setIsOpen] = useState(false);

  //internal navigation
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && enabled
  );
  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsOpen(true);
    }
  }, [blocker.state]);

  //browser back / refresh / close tab
  const onBeforeUnload = useCallback(
    (event: Event) => {
      if (enabled) {
        event.preventDefault();
      }
    },
    [enabled]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [onBeforeUnload]);

  //export functions
  const onSucceed = () => {
    setTimeout(() => {
      blocker?.proceed?.();
    }, 50);
  };
  const onFail = () => {
    blocker?.reset?.();
  };
  //modal itself
  const component = (
    <Modal
      loading={isLoading}
      closeOnSubmit={false}
      closeOnCancel={false}
      width={"460px"}
      open={isOpen}
      title="Save Changes"
      onSubmit={() => {
        onProceed();
      }}
      onOpenChange={(isOpen) => {
        if (!isLoading) {
          setIsOpen(isOpen);
          if (!isOpen) {
            blocker?.reset?.();
          }
        }
      }}
      cancelBtnText="Discard Changes"
      submitBtnText={isLoading ? "Saving Changes..." : "Save Changes"}
      onCancel={() => {
        blocker?.proceed?.();
      }}
    >
      <Box p="3">{description}</Box>
    </Modal>
  );
  return {
    component: component,
    onSucceed: onSucceed,
    onFail: onFail,
  };
};
export default usePreventDataLoss;
