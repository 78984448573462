import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
`;

const AdCard = ({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) => {
  return (
    <Container gap="8px">
      <span>
        <b>{title}</b>
      </span>
      {children}
    </Container>
  );
};
export default AdCard;
