// This file is used to define constants for the routes

export const HOME_ROUTE = "/";
export const STYLE_LIBRARY_ROUTE = "/style-library";
export const BRAND_VOICE_ROUTE = `/brand-voice`;
export const CAMPAIGNS_ROUTE = "/campaigns";
export const NEW_CAMPAIGN_ROUTE = "/campaigns/wizard/new";

// Intern routes
export const INTERN_ROUTE = "/intern";
export const INTERN_CAMPAIGNS_ROUTE = "/intern/campaign";
