export const copyAttributes = (
  source: HTMLElement | SVGElement,
  target: HTMLElement | SVGElement,
  attributes: string[]
) => {
  attributes.forEach((attr) => {
    const value = source.getAttribute(attr);
    if (value) {
      target.setAttribute(attr, value);
    }
  });
};
