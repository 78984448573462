import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import ActionAlert from "../../../components/campaign/wizard/products/components/ActionAlert";
import ProductCard from "../../../components/campaign/wizard/products/components/ProductCard";
import NumberedPaginator from "../../../components/campaign/wizard/products/components/numberedPaginator/NumberedPaginator";
import ProductSelectorDialog, {
  MAX_FEATURED_PRODUCTS,
} from "../../../components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { components } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";
import useShopifyCollectionProductsQuery from "~/hooks/shopify/useShopifyCollectionProductsQuery";

type ShopifyProductInfo = components["schemas"]["ShopifyProductInfo"];

export type MaybeFeaturedProduct = Omit<
  ShopifyProductInfo,
  "total_inventory"
> & {
  is_featured: boolean;
};

const PAGE_SIZE = 9;

const CampaignProducts = () => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType, campaignData, isWizardStateInitialized } =
    useCampaignWizardState();
  const destination = campaignData?.destination;
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const { centerCurrentCard } = useCenterCampaignCard();

  const {
    campaignRecommendation,
    isLoading: isLoadingRecommendation,
    isSuccess: isSuccessRecommendation,
    isError: isErrorRecommendation,
  } = useCampaignRecommendationQuery(
    activeBrandID,
    wizardType === "RECOMMENDED" ? recommendationID ?? null : null
  );

  const {
    collectionProductsData,
    isLoading: isLoadingCollectionProducts,
    isSuccess: isSuccessCollectionProducts,
    isError: isErrorCollectionProducts,
  } = useShopifyCollectionProductsQuery(
    activeBrandID,
    wizardType === "NEW" ? destination?.collection_id ?? null : null
  );

  const isDataLoading = isLoadingCollectionProducts || isLoadingRecommendation;

  const [selectedProductsPage, setSelectedProductsPage] = useState(0);
  const description =
    isErrorCollectionProducts || isErrorRecommendation
      ? "There was an error loading the associated products. Please feel free to add products manually."
      : "Here are the products we've selected for the campaign. Feel free to make changes.";

  //products
  const selectProduct = (selectedProduct?: MaybeFeaturedProduct | null) => {
    if (!selectedProduct) {
      return;
    }
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(selectedProduct.product_id, selectedProduct);
      return updatedMap;
    });
  };
  const selectProducts = (
    selectedProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map([...prev, ...selectedProducts]);
      return updatedMap;
    });
    centerCurrentCard();
  };
  const handleDeselect = (id: string) => {
    if (!selectedProducts.has(id)) {
      return;
    }
    handleShowAlert(selectedProducts.get(id));
    const lastPage = Math.floor((selectedProducts.size - 2) / PAGE_SIZE);
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    if (selectedProductsPage > lastPage) {
      setSelectedProductsPage((prev) => Math.max(0, prev - 1));
    }
    centerCurrentCard();
  };
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(new Map<string, MaybeFeaturedProduct>([]));
  const [isSelectedProductsStateReady, setIsSelectedProductsStateReady] =
    useState(false);
  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    setSelectedProducts(
      new Map(
        campaignData.products?.map((product) => [
          product.product_id,
          product,
        ]) ?? []
      )
    );
    // a way to go around the 2 separate states updating each other, without doing more serious refactoring
    //  basically, we use this to avoid resetting the initial state of the products
    //  Alternatively, might work to move state updates on button click/moving of the current step instead
    setIsSelectedProductsStateReady(true);
  }, [isWizardStateInitialized]);

  const onFeatureToggle = (product: MaybeFeaturedProduct) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(product.product_id, {
        ...product,
        is_featured: !product.is_featured,
      });
      return updatedMap;
    });
  };

  //repopulating the state of selected products only once, and under the conditions above
  useEffect(() => {
    if (
      !isSelectedProductsStateReady ||
      !isSuccessCollectionProducts ||
      wizardType !== "NEW" ||
      campaignData.products?.length
    ) {
      return;
    }
    selectProducts(
      new Map(
        collectionProductsData?.products.map((product) => [
          product.product_id,
          {
            ...product,
            is_featured: false,
          },
        ])
      )
    );
  }, [isSelectedProductsStateReady, collectionProductsData]);

  //repopulating the state of selected products only once, and under the conditions above
  useEffect(() => {
    if (
      !isSelectedProductsStateReady ||
      !isSuccessRecommendation ||
      wizardType !== "RECOMMENDED" ||
      campaignData.products?.length
    ) {
      return;
    }
    selectProducts(
      new Map(
        campaignRecommendation?.products?.map((product) => [
          product.product_id,
          {
            ...product,
            is_featured: false,
          },
        ])
      )
    );
  }, [isSelectedProductsStateReady, campaignRecommendation]);

  useEffect(() => {
    if (!isSelectedProductsStateReady) {
      return;
    }
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        products: Array.from(selectedProducts.values()),
      },
    });
  }, [selectedProducts, dispatch, isSelectedProductsStateReady]);

  //popup
  const [showAlert, setShowAlert] = useState(false);
  const [removedProduct, setRemovedProduct] =
    useState<MaybeFeaturedProduct | null>();
  const [timeoutTimeId, setTimeoutTimeId] = useState<NodeJS.Timeout>();
  const handleShowAlert = (product?: MaybeFeaturedProduct) => {
    setRemovedProduct(product);
    setShowAlert(true);
    clearTimeout(timeoutTimeId);
    const timeId = setTimeout(() => {
      setRemovedProduct(null);
      setShowAlert(false);
    }, 3000);
    setTimeoutTimeId(timeId);
  };
  const onUndo = () => {
    selectProduct(removedProduct);
    setRemovedProduct(null);
    setShowAlert(false);
  };

  const featuredProductsCount = useMemo(() => {
    return Array.from(selectedProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [selectedProducts]);

  const featuredProductsSection = Array.from(selectedProducts).filter(
    ([_, product]) => product.is_featured
  ).length ? (
    <>
      <Text size="1">Featured Product(s)</Text>
      <Grid gap="3" columns="3" width="auto">
        {Array.from(selectedProducts)
          .filter(([_, product]) => product.is_featured)
          .map(([_, product]) => (
            <ProductCard
              key={product.product_id}
              onClick={handleDeselect}
              data={product}
              isFeatureDisabled={featuredProductsCount >= MAX_FEATURED_PRODUCTS}
              onFeatureToggle={onFeatureToggle}
            />
          ))}
      </Grid>
    </>
  ) : null;

  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);

  const handleConfirmProducts = (
    newProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts(newProducts);
    setSelectedProductsPage(Math.floor((newProducts.size - 1) / PAGE_SIZE));
    setIsProductSelectorOpen(false);
    centerCurrentCard();
  };

  return (
    <CampaignWizardCard
      badgeTitle="Products"
      nextButtonProps={{
        text: "Looks good",
        disabled: !selectedProducts.size,
      }}
      subtitle={description}
    >
      {featuredProductsSection}
      <Text size="1">{selectedProducts.size} products selected</Text>
      {isDataLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Flex direction="column" gap="4">
            <Grid gap="3" columns="3" width="auto">
              {Array.from(selectedProducts)
                .slice(
                  selectedProductsPage * PAGE_SIZE,
                  selectedProductsPage * PAGE_SIZE + PAGE_SIZE
                )
                .map(([id, product]) => (
                  <ProductCard
                    key={id}
                    onClick={handleDeselect}
                    onFeatureToggle={onFeatureToggle}
                    data={product}
                    isFeatureDisabled={
                      featuredProductsCount >= MAX_FEATURED_PRODUCTS
                    }
                  />
                ))}
            </Grid>
          </Flex>
          <Flex direction="row" justify="between">
            {selectedProducts.size > PAGE_SIZE ? (
              <NumberedPaginator
                totalPages={Math.ceil(selectedProducts.size / PAGE_SIZE)}
                currentPage={selectedProductsPage}
                setCurrentPage={setSelectedProductsPage}
              />
            ) : (
              <div />
            )}
            <ProductSelectorDialog
              initialSelectedProducts={selectedProducts}
              onConfirm={handleConfirmProducts}
              isOpen={isProductSelectorOpen}
              onOpenChange={setIsProductSelectorOpen}
              isFeatureEnabled={true}
            />
          </Flex>
        </>
      )}

      {showAlert && (
        <ActionAlert
          title={"Product removed"}
          subtitle={removedProduct?.title ?? ""}
          undo={onUndo}
        />
      )}
    </CampaignWizardCard>
  );
};

export default CampaignProducts;
