import { DropdownMenu } from "@radix-ui/themes";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { copyAttributes } from "~/utils/htmlUtils";

interface InternAdMediaTemplateToolsDropdownMenuProps {
  selectedElement: SVGElement | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  position: { x: number; y: number };
  onAttributeChange: () => void;
}

enum TextAlignment {
  Left = "left",
  Center = "center",
  Right = "right",
}

enum TextJustification {
  Start = "start",
  Center = "center",
  End = "end",
}

const InternAdMediaTemplateToolsDropdownMenu: React.FC<
  InternAdMediaTemplateToolsDropdownMenuProps
> = ({ selectedElement, open, onOpenChange, position, onAttributeChange }) => {
  const handleAddDarkOverlayToImage = () => {
    if (!selectedElement) return;

    const { x, y, width, height } = (
      selectedElement as SVGGraphicsElement
    ).getBBox();
    const overlay = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );

    overlay.setAttribute("x", x.toString());
    overlay.setAttribute("y", y.toString());
    overlay.setAttribute("width", width.toString());
    overlay.setAttribute("height", height.toString());
    overlay.setAttribute("fill", "rgba(0, 0, 0, 0.2)");

    const rx = selectedElement.getAttribute("rx");
    const ry = selectedElement.getAttribute("ry");
    if (rx !== null) {
      overlay.setAttribute("rx", rx);
    }
    if (ry !== null) {
      overlay.setAttribute("ry", ry);
    }

    const clipPath = selectedElement.getAttribute("clip-path");
    if (clipPath) {
      overlay.setAttribute("clip-path", clipPath);
    }

    if (selectedElement.parentElement !== null) {
      if (selectedElement.nextSibling) {
        selectedElement.parentElement.insertBefore(
          overlay,
          selectedElement.nextSibling
        );
      } else {
        selectedElement.parentElement.appendChild(overlay);
      }
    }
  };

  const handleAddImageBackground = () => {
    if (!selectedElement) return;

    const { x, y, width, height } = (
      selectedElement as SVGGraphicsElement
    ).getBBox();
    const overlay = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );

    overlay.setAttribute("x", x.toString());
    overlay.setAttribute("y", y.toString());
    overlay.setAttribute("width", width.toString());
    overlay.setAttribute("height", height.toString());
    overlay.setAttribute("fill", "rgba(255, 255, 255, 1.0)");

    const rx = selectedElement.getAttribute("rx");
    const ry = selectedElement.getAttribute("ry");
    if (rx !== null) {
      overlay.setAttribute("rx", rx);
    }
    if (ry !== null) {
      overlay.setAttribute("ry", ry);
    }

    const clipPath = selectedElement.getAttribute("clip-path");
    if (clipPath) {
      overlay.setAttribute("clip-path", clipPath);
    }

    if (selectedElement.parentElement !== null) {
      selectedElement.parentElement.insertBefore(overlay, selectedElement);
    }
  };

  const handleSetAspectScaling = (value: string) => {
    if (selectedElement?.tagName.toLowerCase() === "image") {
      selectedElement.setAttribute("preserveAspectRatio", value);
      onAttributeChange();
    }
  };

  const handleSetTextAlignment = (value: TextAlignment) => {
    let textAnchorValue: string;

    switch (value) {
      case TextAlignment.Left:
        textAnchorValue = "start";
        break;
      case TextAlignment.Center:
        textAnchorValue = "middle";
        break;
      case TextAlignment.Right:
        textAnchorValue = "end";
        break;
    }

    if (selectedElement?.tagName.toLowerCase() === "text") {
      selectedElement.setAttribute("text-anchor", textAnchorValue);
      onAttributeChange();
    } else if (selectedElement?.tagName.toLowerCase() === "div") {
      selectedElement.style.textAnchor = textAnchorValue;
      selectedElement.style.textAlign = value;
      onAttributeChange();
    }
  };

  const handleSetTextJustification = (value: TextJustification) => {
    if (selectedElement?.tagName.toLowerCase() === "div") {
      selectedElement.style.alignItems = value;
      onAttributeChange();
    }
  };

  const handleTextToForeignObject = () => {
    if (!selectedElement) return;

    const textContent = selectedElement.textContent || "";
    const computedStyle = getComputedStyle(selectedElement);
    const { x, y, width, height } = (
      selectedElement as SVGGraphicsElement
    ).getBBox();
    const foreignObject = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "foreignObject"
    );

    foreignObject.setAttribute("x", x.toString());
    foreignObject.setAttribute("y", y.toString());
    foreignObject.setAttribute("width", width.toString());
    foreignObject.setAttribute("height", height.toString());
    copyAttributes(selectedElement, foreignObject, ["transform"]);

    const div = document.createElement("div");
    div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.display = "flex";
    div.style.alignItems = "center";

    div.style.fontFamily = computedStyle.fontFamily;
    div.style.fontSize = computedStyle.fontSize;
    div.style.fontWeight = computedStyle.fontWeight;
    div.style.fontStyle = computedStyle.fontStyle;
    div.style.letterSpacing = computedStyle.letterSpacing;
    div.style.fill = computedStyle.fill;
    div.style.color = computedStyle.fill;
    div.style.textAnchor = computedStyle.textAnchor;
    div.style.justifyContent = computedStyle.textAnchor;
    div.style.textAlign = computedStyle.textAnchor;
    div.style.lineHeight = "normal";
    div.style.textOverflow = "ellipsis";
    div.innerHTML = textContent;

    if (computedStyle.textAnchor === "middle") {
      div.style.justifyContent = "center";
      div.style.textAlign = "center";
    }

    foreignObject.appendChild(div);
    selectedElement.parentNode?.replaceChild(foreignObject, selectedElement);

    onAttributeChange();
  };

  const handleConvertShapeToImage = ({
    includeRectStroke = false,
  }: {
    /** Keeps the original rect element if it includes a stroke */
    includeRectStroke?: boolean;
  } = {}) => {
    if (!selectedElement) return;

    const style = getComputedStyle(selectedElement);
    const fill = style.fill;
    var href: string | null = null;

    if (fill.startsWith("url(") && fill.endsWith(")")) {
      const patternId = fill.slice(5, -2);
      const patternElement = document.querySelector(patternId);

      if (
        patternElement &&
        patternElement.tagName.toLowerCase() === "pattern"
      ) {
        const imageElement = patternElement.querySelector("image");
        if (imageElement) {
          href =
            imageElement.getAttributeNS(
              "http://www.w3.org/1999/xlink",
              "href"
            ) || imageElement.getAttribute("href");
        }

        if (!href) {
          const useElement = patternElement.querySelector("use");
          if (useElement) {
            const useHref =
              useElement.getAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href"
              ) || useElement.getAttribute("href");
            if (useHref && useHref.startsWith("#")) {
              const referencedElement = document.querySelector(useHref);
              if (
                referencedElement &&
                referencedElement.tagName.toLowerCase() === "image"
              ) {
                href =
                  referencedElement.getAttributeNS(
                    "http://www.w3.org/1999/xlink",
                    "href"
                  ) || referencedElement.getAttribute("href");
              }
            }
          }
        }
        patternElement.parentNode?.removeChild(patternElement);
      }
    } else if (/^rgb\(\d+,\s*\d+,\s*\d+\)$/.test(fill)) {
      const { width, height } = (
        selectedElement as SVGGraphicsElement
      ).getBBox();
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext("2d");
      if (context) {
        context.fillStyle = fill;
        context.fillRect(0, 0, width, height);
        href = canvas.toDataURL("image/png");
      }
    }

    const { x, y, width, height } = (
      selectedElement as SVGGraphicsElement
    ).getBBox();
    const svgNs = "http://www.w3.org/2000/svg";
    const image = document.createElementNS(svgNs, "image");

    image.setAttribute("x", x.toString());
    image.setAttribute("y", y.toString());
    image.setAttribute("width", width.toString());
    image.setAttribute("height", height.toString());
    image.setAttribute("preserveAspectRatio", "xMidYMid meet");
    copyAttributes(selectedElement, image, ["transform"]);

    const strokeWidth = selectedElement.getAttribute("stroke-width");
    const insetWidth =
      selectedElement.tagName === "rect" && strokeWidth
        ? parseFloat(strokeWidth) / 2
        : 0;
    if (
      selectedElement.hasAttribute("rx") ||
      selectedElement.hasAttribute("ry") ||
      insetWidth > 0
    ) {
      const rx = selectedElement.getAttribute("rx");
      const ry = selectedElement.getAttribute("ry");
      image.setAttribute(
        "clip-path",
        `inset(${insetWidth} round ${rx ?? ry ?? "0"} ${ry ?? ""})`
      );
    }

    if (href) {
      image.setAttribute("href", href);
    }

    if (includeRectStroke && insetWidth > 0) {
      selectedElement.parentElement?.insertBefore(
        image,
        selectedElement.nextElementSibling
      );
    } else {
      selectedElement.parentNode?.replaceChild(image, selectedElement);
    }

    onAttributeChange();
  };

  const handleConvertPathToImage = () => {
    if (!selectedElement) return;

    const pathElement = selectedElement as SVGPathElement;
    const parentElement = pathElement.parentNode as SVGElement | null;

    if (!parentElement) return;

    const svgNs = "http://www.w3.org/2000/svg";
    const { x, y, width, height } = pathElement.getBBox();
    const pathData = pathElement.getAttribute("d");
    const imageElement = document.createElementNS(svgNs, "image");
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    let href = null;

    if (context) {
      context.fillStyle = "#dfdfdf";
      context.fillRect(0, 0, width, height);
      href = canvas.toDataURL("image/png");
    }

    imageElement.setAttribute("x", x.toString());
    imageElement.setAttribute("y", y.toString());
    imageElement.setAttribute("width", width.toString());
    imageElement.setAttribute("height", height.toString());
    imageElement.setAttribute("preserveAspectRatio", "xMidYMid slice");

    if (href) {
      imageElement.setAttribute("href", href);
    }

    let defs = document.querySelector("defs");
    if (!defs) {
      defs = document.createElementNS(svgNs, "defs");
      parentElement.insertBefore(defs, parentElement.firstChild);
    }

    if (pathData) {
      const clipPathId = uuidv4();
      const clipPath = document.createElementNS(svgNs, "clipPath");
      clipPath.setAttribute("id", clipPathId);

      const clipPathContent = document.createElementNS(svgNs, "path");
      clipPathContent.setAttribute("d", pathData);
      clipPath.appendChild(clipPathContent);

      defs.appendChild(clipPath);

      imageElement.setAttribute("clip-path", `url(#${clipPathId})`);
    }

    parentElement.replaceChild(imageElement, pathElement);
  };

  const handleAddTextShadow = () => {
    if (!selectedElement) return;
    selectedElement.style.textShadow = "2px 2px 3px rgba(0, 0, 0, 0.2)";
  };

  const handleCenterHorizontally = () => {
    if (!selectedElement || !selectedElement.ownerSVGElement?.parentElement)
      return;
    const { width } = (selectedElement as SVGGraphicsElement).getBBox();
    const newX = (1080 - width) / 2;
    selectedElement.setAttribute("x", newX.toString());
    onAttributeChange();
  };

  const handleDelete = () => {
    if (!selectedElement || !selectedElement.parentElement) return;

    const parent = selectedElement.parentElement;

    if (
      selectedElement.tagName.toLowerCase() === "div" &&
      parent.tagName === "foreignObject"
    ) {
      parent.remove();
    } else {
      selectedElement.remove();
    }

    onAttributeChange();
  };

  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <DropdownMenu.Content
        style={{
          position: "absolute",
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: "200px",
          zIndex: 100,
        }}
      >
        {["image"].includes(selectedElement?.tagName.toLowerCase() || "") && (
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>Image</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent>
              <DropdownMenu.Item onSelect={handleAddDarkOverlayToImage}>
                Add Dark Overlay
              </DropdownMenu.Item>
              <DropdownMenu.Item onSelect={handleAddImageBackground}>
                Add Background
              </DropdownMenu.Item>
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  Set Aspect Scaling
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetAspectScaling("xMidYMid meet");
                    }}
                  >
                    Scale to Fit
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetAspectScaling("xMidYMid slice");
                    }}
                  >
                    Scale to Fill
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        )}
        {["text", "div"].includes(
          selectedElement?.tagName.toLowerCase() || ""
        ) && (
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>Text</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent>
              {["text"].includes(
                selectedElement?.tagName.toLowerCase() || ""
              ) && (
                <DropdownMenu.Item onSelect={handleTextToForeignObject}>
                  Convert to ForeignObject
                </DropdownMenu.Item>
              )}
              {["div"].includes(
                selectedElement?.tagName.toLowerCase() || ""
              ) && (
                <DropdownMenu.Item onSelect={handleAddTextShadow}>
                  Add Shadow
                </DropdownMenu.Item>
              )}
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>Set Alignment</DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextAlignment(TextAlignment.Left);
                    }}
                  >
                    Left
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextAlignment(TextAlignment.Right);
                    }}
                  >
                    Right
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextAlignment(TextAlignment.Center);
                    }}
                  >
                    Center
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  Set Justification
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextJustification(TextJustification.Start);
                    }}
                  >
                    Start
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextJustification(TextJustification.End);
                    }}
                  >
                    End
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onSelect={(event: Event) => {
                      handleSetTextJustification(TextJustification.Center);
                    }}
                  >
                    Center
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        )}
        {["rect", "path"].includes(
          selectedElement?.tagName.toLowerCase() || ""
        ) && (
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>Shape</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent>
              <DropdownMenu.Item onSelect={() => handleConvertShapeToImage()}>
                Convert to Image
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onSelect={() =>
                  handleConvertShapeToImage({ includeRectStroke: true })
                }
              >
                Add Image Overlay
              </DropdownMenu.Item>
              {["path"].includes(
                selectedElement?.tagName.toLowerCase() || ""
              ) && (
                <DropdownMenu.Item onSelect={handleConvertPathToImage}>
                  Convert to Masked Image
                </DropdownMenu.Item>
              )}
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        )}

        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>Layout</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            <DropdownMenu.Item onSelect={handleCenterHorizontally}>
              Center Horizontally
            </DropdownMenu.Item>
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
        <DropdownMenu.Separator />
        <DropdownMenu.Item color="red" onSelect={handleDelete}>
          Delete
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default InternAdMediaTemplateToolsDropdownMenu;
