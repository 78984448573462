import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type GetArticlesResponse =
  operations["shopify_integration_api_get_articles"]["responses"][200]["content"]["application/json"];
type GetArticlesRequestData =
  operations["shopify_integration_api_get_articles"]["parameters"]["query"];

const useShopifyArticlesQuery = ({
  query,
  after,
}: {
  query?: string;
  after?: string;
}) => {
  const activeBrandID = useActiveBrandID();
  const {
    data: articlesData,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["shopify/articles", activeBrandID, query],
    queryFn: async ({
      pageParam,
    }: {
      pageParam: string | undefined;
    }): Promise<GetArticlesResponse> => {
      const { data } = await axios.get("/api/v1/commerce-platform/articles", {
        params: {
          brand_id: activeBrandID,
          query,
          cursor: pageParam,
        } as GetArticlesRequestData,
      });
      return data;
    },
    initialPageParam: after ?? undefined,
    getNextPageParam: (lastPage) =>
      lastPage.page_info.has_next_page
        ? lastPage.page_info.end_cursor
        : undefined,
    enabled: !!activeBrandID,
  });

  return {
    articlesData,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useShopifyArticlesQuery;
