import NumberInput from "../NumberInput";
import { Flex, Text } from "@radix-ui/themes";
import { useCurrentEditor } from "@tiptap/react";
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from "lucide-react";
import React from "react";
import styled from "styled-components";
import { ToolbarButton as TTToolbarButton } from "~/components/editor/editor/TipTap";

const ToolbarButton = styled(TTToolbarButton)`
  width: 32px;
  height: 32px;
`;

interface RichTextToolbarProps {
  fontSize: number;
  onFontSizeChange: (value: number) => void;
}

const RichTextToolbar: React.FC<RichTextToolbarProps> = ({
  fontSize,
  onFontSizeChange,
}) => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <Flex gap="2" justify="between" align="center">
      <Flex gap="2" align="center">
        <Text
          size="2"
          style={{
            color: "var(--text-tertiary)",
          }}
        >
          Size:
        </Text>
        <NumberInput
          radius="large"
          value={fontSize}
          style={{
            height: "32px",
            maxWidth: "74px",
            overflow: "hidden",
          }}
          onValueChange={(value) => {
            onFontSizeChange(value);
          }}
        />
      </Flex>
      <Flex
        gap="2"
        justify="end"
        style={{
          flex: "auto",
        }}
      >
        <ToolbarButton
          onClick={() => {
            if (
              !editor.isFocused &&
              editor.state.selection.from === 1 &&
              editor.state.selection.to === 1
            ) {
              editor.chain().selectAll().toggleBold().run();
            } else {
              editor.chain().toggleBold().run();
            }
          }}
          $isSelected={editor.isActive("bold")}
          disabled={!editor.can().chain().toggleBold().run()}
        >
          <BoldIcon size={20} />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => {
            if (
              !editor.isFocused &&
              editor.state.selection.from === 1 &&
              editor.state.selection.to === 1
            ) {
              editor.chain().selectAll().toggleItalic().run();
            } else {
              editor.chain().toggleItalic().run();
            }
          }}
          $isSelected={editor.isActive("italic")}
          disabled={!editor.can().chain().toggleItalic().run()}
        >
          <ItalicIcon size={20} />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => {
            if (
              !editor.isFocused &&
              editor.state.selection.from === 1 &&
              editor.state.selection.to === 1
            ) {
              editor.chain().selectAll().toggleStrike().run();
            } else {
              editor.chain().toggleStrike().run();
            }
          }}
          $isSelected={editor.isActive("strike")}
          disabled={!editor.can().chain().toggleStrike().run()}
        >
          <StrikethroughIcon size={20} />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => {
            if (
              !editor.isFocused &&
              editor.state.selection.from === 1 &&
              editor.state.selection.to === 1
            ) {
              editor.chain().selectAll().toggleUnderline().run();
            } else {
              editor.chain().toggleUnderline().run();
            }
          }}
          $isSelected={editor.isActive("underline")}
          disabled={!editor.can().chain().toggleUnderline().run()}
        >
          <UnderlineIcon size={20} />
        </ToolbarButton>
      </Flex>
    </Flex>
  );
};

export default RichTextToolbar;
