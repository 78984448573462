import AddButtonBorder from "./AddButtonBorder";
import AddButtonColors from "./AddButtonColors";
import AddButtonFont from "./AddButtonFont";
import AddButtonPadding from "./AddButtonPadding";
import AddButtonSection from "./AddButtonSection";
import AddButtonShape from "./AddButtonShape";
import { components } from "@openapi";
import {
  Dialog,
  Flex,
  ScrollArea,
  Spinner,
  Switch,
  Text,
} from "@radix-ui/themes";
import { useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandButton from "~/components/core/buttons/BrandButton";
import CloseIconButton from "~/components/core/buttons/CloseIconButton";
import ConfirmationAlert from "~/components/core/dialog/ConfirmationAlert";
import useButtonStyleProps from "~/hooks/style-library/useButtonStyleProps";

interface ButtonStyleDialogProps {
  buttonStyle?: components["schemas"]["BrandButtonStyleSchema"];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (
    style: components["schemas"]["UpdateButtonStyleRequestData"]
  ) => void;
  onDelete?: () => void;
  isUpdating?: boolean;
}

const ButtonStyleDialog: React.FC<ButtonStyleDialogProps> = ({
  buttonStyle,
  open,
  onOpenChange,
  onDelete,
  onSave,
  isUpdating = false,
}) => {
  const {
    state,
    shapeProps,
    colorProps,
    fontProps,
    borderProps,
    paddingProps,
    toButtonStyle,
  } = useButtonStyleProps(buttonStyle);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        style={{ padding: 0 }}
        onInteractOutside={(e) => {
          if (isUpdating) {
            e.preventDefault();
          }
        }}
      >
        <Dialog.Description />
        <Flex
          justify="between"
          align="center"
          p="16px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            {`${buttonStyle ? "Edit" : "Add"} Button Style`}
          </Dialog.Title>
          <Dialog.Close disabled={isUpdating}>
            <CloseIconButton />
          </Dialog.Close>
        </Flex>
        <Flex direction="column" pb="24px" px="0">
          <Flex
            mb="24px"
            style={{
              borderBottom: "1px solid #E2E2E2",
              backgroundColor: "#F0EDEB",
            }}
          >
            <BrandButton
              {...state}
              style={{
                ...state.style,
                margin: "24px auto",
              }}
            >
              Shop Now
            </BrandButton>
          </Flex>
          <ScrollArea>
            <Flex direction="column" maxHeight="40vh">
              <AddButtonSection title="Shape">
                <AddButtonShape {...shapeProps} />
              </AddButtonSection>
              <AddButtonSection title="Colors">
                <AddButtonColors {...colorProps} />
              </AddButtonSection>
              <AddButtonSection title="Font">
                <AddButtonFont {...fontProps} />
              </AddButtonSection>
              <AddButtonSection title="Border">
                <AddButtonBorder {...borderProps} />
              </AddButtonSection>
              <AddButtonSection
                title="Padding"
                rightSlot={
                  <Text as="label">
                    Apply to all sides
                    <Switch
                      checked={paddingProps.isAppliedForAllSides}
                      onClick={paddingProps.toggleSides}
                      color="gray"
                      highContrast
                      ml="16px"
                      mt="1"
                    />
                  </Text>
                }
              >
                <AddButtonPadding {...paddingProps} />
              </AddButtonSection>
            </Flex>
          </ScrollArea>
        </Flex>
        <Flex p="24px" style={{ borderTop: "1px solid #DDD7D7" }}>
          {onDelete && buttonStyle?.id && (
            <AppButton
              radius="large"
              variant="outlined"
              disabled={isUpdating}
              onClick={() => setIsDeleteConfirmOpen(true)}
            >
              {isUpdating && <Spinner />}
              Delete
            </AppButton>
          )}
          <Flex ml="auto" gap="3">
            <Dialog.Close>
              <AppButton
                radius="large"
                variant="outlined"
                disabled={isUpdating}
              >
                Cancel
              </AppButton>
            </Dialog.Close>
            <AppButton
              variant="dark"
              radius="large"
              disabled={isUpdating}
              onClick={() => onSave(toButtonStyle())}
            >
              {isUpdating && <Spinner />}
              {isUpdating ? "Saving" : "Save Changes"}
            </AppButton>
          </Flex>
        </Flex>
      </Dialog.Content>
      {onDelete && (
        <ConfirmationAlert
          open={isDeleteConfirmOpen}
          onOpenChange={setIsDeleteConfirmOpen}
          title="Delete Button Style"
          description="Are you sure? This action cannot be undone."
          onConfirm={onDelete}
          confirmText="Delete"
        />
      )}
    </Dialog.Root>
  );
};

export default ButtonStyleDialog;
