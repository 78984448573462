import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type GenerateVariantResponse =
  operations["emails_api_generate_variant"]["responses"][200]["content"]["application/json"];

type GenerateVariantRequestData =
  operations["emails_api_generate_variant"]["requestBody"]["content"]["application/json"];

const key = "/emails/generate-variant";
const useEmailRegenerateMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: GenerateVariantResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const generateVariant = useDraperApiPostMutation<
    GenerateVariantResponse,
    GenerateVariantRequestData
  >({
    mutationKey: [key],
    path: key,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });

  return {
    regenerateEmail: generateVariant.mutate,
    isLoading: generateVariant.isPending,
  };
};

export default useEmailRegenerateMutation;
