import { operations } from "@openapi";
import { Button, Container, Dialog, Flex, Text } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "styled-components";

type ImportAdMediaTemplateResponse =
  operations["ads_api_import_ad_media_template"]["responses"][200]["content"]["application/json"];

type ImportAdMediaTemplateParams =
  operations["ads_api_import_ad_media_template"]["requestBody"]["content"]["multipart/form-data"];

interface InternAdMediaTemplateImportDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  onCreate: () => void;
}

const DragDropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const InternAdMediaTemplateImportDialog: React.FC<
  InternAdMediaTemplateImportDialogProps
> = ({ isDialogOpen, setIsDialogOpen, onCreate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/zip": [".zip"],
    },
  });

  const importTemplateMutation = useMutation<
    ImportAdMediaTemplateResponse,
    Error,
    ImportAdMediaTemplateParams
  >({
    mutationFn: async (
      params: ImportAdMediaTemplateParams
    ): Promise<ImportAdMediaTemplateResponse> => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("import_file", params.import_file);
      const { data } = await axios.post(
        `/api/v1/ads/import-ad-media-template`,
        formData,
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess(data) {
      setIsLoading(false);
      if (data.success) {
        setIsDialogOpen(false);
        onCreate();
        alert("Template imported successfully!");
      } else {
        alert("Error importing template");
      }
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error.message || "An error occurred during import");
    },
  });

  const handleImportClick = () => {
    if (acceptedFiles.length < 1) {
      alert("Template zip file is required. Please upload a file.");
      return;
    }
    const zip_file = acceptedFiles[0];
    importTemplateMutation.mutate({
      import_file: zip_file as unknown as string,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Import Ad Media Template</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Import a new ad media template from an exported zip file.
          </Dialog.Description>
          <Flex direction="column" gap="3">
            <div className="container">
              <Text as="div" size="1" mb="1" weight="bold">
                Ad Template Zip
              </Text>
              <DragDropContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <Text size="3">Drag and drop your ad template zip here</Text>
                {acceptedFiles.map((file: any) => (
                  <li key={file.name}>
                    {file.name} - {file.size} bytes
                  </li>
                ))}
              </DragDropContainer>
            </div>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button onClick={handleImportClick} loading={isLoading}>
              Import
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternAdMediaTemplateImportDialog;
