import GOOGLE_FONTS from "./googleFonts";

export const GLOBAL_FONTS = new Set(["Inter", "Plus Jakarta Sans", "Roboto"]);

const getCustomGoogleFontLink = (
  fontName: string,
  { skipGlobalFonts }: { skipGlobalFonts: boolean } = { skipGlobalFonts: true }
): string | null => {
  // Should avoid loading global fonts from components, as it can lead to glitches,
  //    replacing the global font with some slightly different variation
  if (skipGlobalFonts && GLOBAL_FONTS.has(fontName)) {
    return null;
  }
  const googleFont = GOOGLE_FONTS.find((font) => font === fontName);
  return googleFont
    ? `https://fonts.googleapis.com/css2?family=${googleFont}`
    : null;
};

export default getCustomGoogleFontLink;
