import AppButton from "../core/buttons/AppButton/AppButton";
import { components } from "@openapi";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Checkbox, Dialog, IconButton } from "@radix-ui/themes";
import { Box, Flex, Skeleton, Text, TextField } from "@radix-ui/themes";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { XIcon } from "~/assets/icons";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyArticlesQuery from "~/hooks/shopify/useShopifyArticlesQuery";

const ArticleRow = styled(Flex)`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
`;

interface ArticleSelectorDialogProps {
  onConfirm: (articleIds: string[]) => void;
  triggerComponent: React.ReactNode;
  limit?: number;
  initialSelectedArticles?: string[];
}

const SearchAndResults = ({
  onSelect,
  height,
  selected,
}: {
  onSelect: (article: components["schemas"]["ShopifyArticle"]) => void;
  height?: string;
  selected?: string[];
}) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const { articlesData, isLoading, isSuccess, fetchNextPage, hasNextPage } =
    useShopifyArticlesQuery({
      query: debouncedSearch,
    });

  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      // This function will only be called after 300ms of inactivity
      setDebouncedSearch(value);
    }, 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debouncedSearchCall(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  return (
    <Flex
      direction="column"
      style={{
        border: "1px solid#DDD7D7",
        borderRadius: "16px",
      }}
    >
      <Box style={{ padding: "16px", borderBottom: "1px solid #DDD7D7" }}>
        <TextField.Root
          value={search}
          onChange={handleSearchChange}
          style={{ backgroundColor: "#F1F1F0", width: "100%" }}
          radius="large"
          color="teal"
          variant="soft"
          placeholder="Search articles…"
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      </Box>

      {isLoading ? (
        <Skeleton
          height="32px"
          width="auto"
          mx="4"
          my="2"
          style={{ borderRadius: "12px" }}
        />
      ) : (
        <InfiniteScroll
          height={height ?? "196px"}
          next={() => {
            fetchNextPage();
          }}
          hasMore={hasNextPage ?? false}
          dataLength={
            articlesData?.pages.flatMap((page) => page.articles).length ?? 0
          }
          loader={
            <Skeleton
              height="32px"
              width="auto"
              mx="4"
              my="2"
              style={{ borderRadius: "12px" }}
            />
          }
          scrollThreshold={"90%"}
        >
          <Flex direction="column" gap={"1"} py="12px" px="16px">
            {articlesData?.pages
              .flatMap((page) => page.articles)
              .map((article) => (
                <ArticleRow
                  onClick={() => onSelect(article)}
                  justify="start"
                  align="center"
                  gap="8px"
                  key={article.id}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selected?.includes(article.id)
                      ? "#F1F1F0"
                      : undefined,
                  }}
                >
                  <Checkbox
                    checked={selected?.includes(article.id)}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                    }}
                  />
                  <Text>{article.title}</Text>
                </ArticleRow>
              ))}
          </Flex>
        </InfiniteScroll>
      )}
    </Flex>
  );
};

const ArticleSelectorDialog: React.FC<ArticleSelectorDialogProps> = ({
  onConfirm,
  triggerComponent,
  limit = 3,
  initialSelectedArticles,
}) => {
  const activeBrandID = useActiveBrandID();
  const isSingleArticle = limit === 1;
  const [selectedArticles, setSelectedArticles] = useState<string[]>(
    initialSelectedArticles ?? []
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setSelectedArticles(initialSelectedArticles ?? []);
  }, [initialSelectedArticles]);

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        {isSingleArticle
          ? "Select Article to Spotlight"
          : "Select Articles to Feature"}
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <XIcon color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger>{triggerComponent}</Dialog.Trigger>
      <Dialog.Content>
        <Flex direction="column" gap="4">
          {header}

          <SearchAndResults
            height="296px"
            onSelect={(article) => {
              if (isSingleArticle) {
                setSelectedArticles([article.id]);
              } else if (selectedArticles.includes(article.id)) {
                setSelectedArticles(
                  selectedArticles.filter((id) => id !== article.id)
                );
                setError(null);
              } else if (selectedArticles.length < limit) {
                setSelectedArticles([...selectedArticles, article.id]);
              } else {
                setError(`You can only select up to ${limit} articles`);
              }
            }}
            selected={selectedArticles ?? undefined}
          />

          {error && <Text color="red">{error}</Text>}

          <Flex justify="end" pt="16px">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined">Cancel</AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  variant="dark"
                  onClick={() => {
                    if (!selectedArticles) {
                      return;
                    }
                    onConfirm?.(selectedArticles);
                  }}
                >
                  Add
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ArticleSelectorDialog;
