import SearchableExpandedSelect from "../campaign/wizard/inputs/SearchableExpandedSelect";
import AppButton from "../core/buttons/AppButton/AppButton";
import { Dialog, Flex, Text, IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { XIcon } from "~/assets/icons";

interface CollectionSelectorDialogProps {
  onConfirm: (collectionIds: string[]) => void;
  triggerComponent: React.ReactNode;
  limit?: number;
  initialSelectedCollections?: string[];
}

const CollectionSelectorDialog: React.FC<CollectionSelectorDialogProps> = ({
  onConfirm,
  triggerComponent,
  limit = 1,
  initialSelectedCollections,
}) => {
  const isSingleCollection = limit === 1;
  const [selectedCollections, setSelectedCollections] = useState<string[]>(
    initialSelectedCollections ?? []
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setSelectedCollections(initialSelectedCollections ?? []);
  }, [initialSelectedCollections]);

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        {isSingleCollection
          ? "Select Collection to Spotlight"
          : "Select Collections to Feature"}
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <XIcon color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger>{triggerComponent}</Dialog.Trigger>
      <Dialog.Content>
        <Flex direction="column" gap="4">
          {header}

          <SearchableExpandedSelect
            height="296px"
            onSelect={(collection) => {
              if (error) {
                setError(null);
              }
              if (isSingleCollection) {
                setSelectedCollections([collection.id]);
              } else if (selectedCollections.includes(collection.id)) {
                setSelectedCollections(
                  selectedCollections.filter((id) => id !== collection.id)
                );
              } else if (selectedCollections.length < limit) {
                setSelectedCollections([...selectedCollections, collection.id]);
              } else {
                setError(`You can only select up to ${limit} collections`);
              }
            }}
            selected={selectedCollections ?? undefined}
          />

          {error && <Text color="red">{error}</Text>}

          <Flex justify="end" pt="16px">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined">Cancel</AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  variant="dark"
                  onClick={() =>
                    selectedCollections && onConfirm?.(selectedCollections)
                  }
                >
                  Add
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CollectionSelectorDialog;
