import ColorBox from "../../color-palette/ColorBox";
import { Flex } from "@radix-ui/themes";
import { CSSProperties } from "react";

export interface AddButtonColorsProps {
  backgroundColor: CSSProperties["backgroundColor"];
  textColor: CSSProperties["color"];
  borderColor: CSSProperties["borderColor"];
  onBackgroundColorChange: (color: CSSProperties["backgroundColor"]) => void;
  onTextColorChange: (color: CSSProperties["color"]) => void;
  onBorderColorChange: (color: CSSProperties["borderColor"]) => void;
}

const AddButtonColors = ({
  backgroundColor,
  textColor,
  borderColor,
  onBackgroundColorChange,
  onTextColorChange,
  onBorderColorChange,
}: AddButtonColorsProps) => {
  return (
    <Flex justify="between">
      <ColorBox
        color={backgroundColor}
        label="Button"
        onChange={onBackgroundColorChange}
      />
      <ColorBox color={textColor} label="Text" onChange={onTextColorChange} />
      <ColorBox
        color={borderColor}
        label="Border"
        onChange={onBorderColorChange}
      />
    </Flex>
  );
};

export default AddButtonColors;
