import DraperText from "../DraperText";
import AppButton from "../buttons/AppButton/AppButton";
import { Flex } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { CropIcon, ImageIcon } from "~/assets/icons";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  padding: 16px;
  border: 1px dashed #ddd7d7;
  border-radius: 6px;
  background-color: var(--background_light_grey_2);
`;

interface ImageSelectorProps {
  value?: string | File;
  onUpload: (file: File) => void;
  alt?: string;
  placeholderImage?: string;
  noImageText?: string;
  buttonText?: string;
  onCrop?: (file: File) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  value,
  onUpload,
  alt,
  placeholderImage,
  noImageText = "No Image set",
  buttonText = "Change",
  onCrop,
}) => {
  const [cropOpen, setCropOpen] = useState(false);

  const imageUrl = useMemo(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value);
  }, [value]);
  const dimensions = useGetImageDimensionsAndSize(imageUrl);
  const fileName = imageUrl ? getFilenameFromUrl(imageUrl) : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/svg+xml": [],
      "image/gif": [],
    },
    onDrop: (acceptedFiles) => {
      onUpload(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const handleCrop = (data: string) => {
    const file = dataURIToFile(
      data,
      fileName ? `${fileName}.png` : "cropped-image.png"
    );
    onCrop?.(file);
  };

  return (
    <Flex direction="column" gap="16px">
      <Flex gap="16px">
        <ImagePreview src={imageUrl ?? placeholderImage} alt={alt ?? "logo"} />
        <Flex direction="column" gap="12px" overflow="hidden">
          <DraperText clamp={2}>
            {imageUrl ? fileName ?? "" : noImageText ?? "No Image set"}
          </DraperText>
          <DraperText clamp={1}>{dimensions}</DraperText>
        </Flex>
      </Flex>
      <Flex gap="3" justify="between">
        <AppButton
          {...getRootProps()}
          variant="outlined"
          size="3"
          radius="large"
          style={{
            flex: 1,
          }}
        >
          <ImageIcon />
          {buttonText}
        </AppButton>
        <input {...getInputProps()} />
        {onCrop && (
          <>
            <AppButton
              variant="outlined"
              size="3"
              radius="large"
              disabled={!imageUrl}
              style={{ flex: 1 }}
              onClick={() => setCropOpen(true)}
            >
              <CropIcon />
              Crop
            </AppButton>
            {imageUrl && (
              <CropImageDialog
                open={cropOpen}
                onOpenChange={setCropOpen}
                img={imageUrl}
                onCrop={handleCrop}
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ImageSelector;
