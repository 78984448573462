import { AdMediaElementType, AdMediaTextElementType } from "@openapi";
import { Button, Card, Flex, Heading, Text } from "@radix-ui/themes";
import {
  AdMediaElementTypeStrings,
  AdMediaImageElementTypeStrings,
  AdMediaTemplateCollectionGroupElementSchema,
  AdMediaTemplateElementUnionSchema,
  AdMediaTemplateGroupElementUnionSchema,
  AdMediaTemplateImageElementSchema,
  AdMediaTemplateProductGroupElementSchema,
  AdMediaTemplateShapeElementSchema,
  AdMediaTemplateTextElementSchema,
  AdMediaTextElementTypeStrings,
} from "~/types/ads";

interface InternAdMediaTemplateElementComponentProps {
  element: AdMediaTemplateElementUnionSchema;
  onDeleteElementClick: (id: string) => void;
  onEditElementClick: (id: string) => void;
}

const InternAdMediaTemplateElementComponent: React.FC<
  InternAdMediaTemplateElementComponentProps
> = ({ element, onDeleteElementClick, onEditElementClick }) => {
  return (
    <Card key={element.id}>
      <Flex direction="column" gap="0.5rem">
        <Flex direction="row" justify="between">
          <Flex direction="column">
            <Heading size="2" style={{ marginBottom: "6px" }}>
              {AdMediaElementTypeStrings[element.type]}
            </Heading>
            {element.type === AdMediaElementType.text && (
              <>
                <Text size="1">
                  <b>element id</b>: {element.target_element_id}
                </Text>
                <Text size="1">
                  <b>type</b>:{" "}
                  {
                    AdMediaTextElementTypeStrings[
                      (element as AdMediaTemplateTextElementSchema).text_type
                    ]
                  }
                </Text>
                <Text size="1">
                  <b>color type</b>:{" "}
                  {(element as AdMediaTemplateTextElementSchema).color_type}
                </Text>
                <Text size="1">
                  <b>title</b>:{" "}
                  {(element as AdMediaTemplateTextElementSchema).title}
                </Text>
                {[
                  AdMediaTextElementType.discount_amount,
                  AdMediaTextElementType.discount_code,
                ].includes(
                  (element as AdMediaTemplateTextElementSchema).text_type
                ) && (
                  <Text size="1">
                    <b>prefix</b>:{" "}
                    {(element as AdMediaTemplateTextElementSchema).prefix}
                  </Text>
                )}
                {[
                  AdMediaTextElementType.discount_amount,
                  AdMediaTextElementType.discount_code,
                ].includes(
                  (element as AdMediaTemplateTextElementSchema).text_type
                ) && (
                  <Text size="1">
                    <b>suffix</b>:{" "}
                    {(element as AdMediaTemplateTextElementSchema).suffix}
                  </Text>
                )}
                {(element as AdMediaTemplateTextElementSchema).text_type ===
                  AdMediaTextElementType.ai_generated && (
                  <Text size="1">
                    <b>description</b>:{" "}
                    {(element as AdMediaTemplateTextElementSchema).description}
                  </Text>
                )}
                <Text size="1">
                  <b>typography</b>:{" "}
                  {
                    (element as AdMediaTemplateTextElementSchema)
                      .typography_category
                  }
                </Text>
              </>
            )}
            {element.type === AdMediaElementType.image && (
              <>
                <Text size="1">
                  <b>element id</b>: {element.target_element_id}
                </Text>
                <Text size="1">
                  <b>type</b>:{" "}
                  {
                    AdMediaImageElementTypeStrings[
                      (element as AdMediaTemplateImageElementSchema).image_type
                    ]
                  }
                </Text>
              </>
            )}
            {element.type === AdMediaElementType.shape && (
              <>
                <Text size="1">
                  <b>element id</b>: {element.target_element_id}
                </Text>
                <Text size="1">
                  <b>fill color type</b>:{" "}
                  {
                    (element as AdMediaTemplateShapeElementSchema)
                      .fill_color_type
                  }
                </Text>
                <Text size="1">
                  <b>stroke color type</b>:{" "}
                  {
                    (element as AdMediaTemplateShapeElementSchema)
                      .stroke_color_type
                  }
                </Text>
              </>
            )}
            {element.type === AdMediaElementType.collection_group && (
              <Text size="1">
                <b>name</b>:{" "}
                {(element as AdMediaTemplateCollectionGroupElementSchema).name}
              </Text>
            )}
            {element.type === AdMediaElementType.product_group && (
              <Text size="1">
                <b>name</b>:{" "}
                {(element as AdMediaTemplateProductGroupElementSchema).name}
              </Text>
            )}
          </Flex>
          <Flex gap="2">
            <Button
              variant="outline"
              size="1"
              onClick={() => onEditElementClick(element.id)}
            >
              Edit
            </Button>
            <Button
              variant="outline"
              size="1"
              onClick={() => onDeleteElementClick(element.id)}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
        {(element.type === AdMediaElementType.product_group ||
          element.type === AdMediaElementType.collection_group) &&
          (element as AdMediaTemplateGroupElementUnionSchema).elements.map(
            (element) => (
              <InternAdMediaTemplateElementComponent
                element={element}
                onDeleteElementClick={onDeleteElementClick}
                onEditElementClick={onEditElementClick}
              />
            )
          )}
      </Flex>
    </Card>
  );
};

export default InternAdMediaTemplateElementComponent;
