import useConnectKlaviyoForm from "../core/ConnectKlaviyoForm";
import AppButton from "../core/buttons/AppButton/AppButton";
import MetaCard from "../core/cards/MetaCard";
import KlaviyoConnectInstructions from "../core/misc/KlaviyoConnectInstructions";
import { Flex, Separator, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import { MetaIcon } from "~/assets/icons";
import { AccountSettingsTabsEnum } from "~/types/account-settings";

const FullWidthSeparator = styled(Separator)`
  width: 100%;
  background-color: rgba(240, 240, 240, 1);
`;

const HelpText = styled(Text)`
  color: var(--text-link);
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
`;

const AddMetaAccountButton = styled(AppButton)`
  background-color: rgba(0, 100, 224, 1);
  color: var(--primary-white);
  width: max-content;
`;

const SHOW_ADD_ACCOUNT = false;
const AccountSettingsIntegrations = () => {
  const [shouldShowHelp, setShouldShowHelp] = useState(false);
  const { form } = useConnectKlaviyoForm({
    onSuccess: () => setShouldShowHelp(false),
  });
  return (
    <Flex gap="24px" direction="column">
      <Flex direction="column" gap="24px" px="32px" pt="32px">
        <Text weight="medium">Klaviyo</Text>
        {form}
        <Flex direction="column">
          <HelpText onClick={() => setShouldShowHelp((prev) => !prev)}>
            Help me get the Private key
          </HelpText>
          {shouldShowHelp && <KlaviyoConnectInstructions />}
        </Flex>
      </Flex>
      <FullWidthSeparator />
      <Flex direction="column" gap="24px" px="32px" pb="32px">
        <Text weight="medium">Meta Accounts</Text>
        <MetaCard
          searchParams={{
            activeSettingsTab: AccountSettingsTabsEnum.Integrations,
          }}
        />
        {SHOW_ADD_ACCOUNT && (
          <AddMetaAccountButton radius="large">
            <MetaIcon /> Add Account
          </AddMetaAccountButton>
        )}
      </Flex>
    </Flex>
  );
};

export default AccountSettingsIntegrations;
