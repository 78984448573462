export function dataURIToFile(dataURI: string, fileName: string): File {
  var arr = dataURI.split(","),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export async function downloadFile(url: string): Promise<File | undefined> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], url.split("/").pop() ?? "file");
  } catch (error) {
    console.error("Error downloading file:", error);
    return undefined;
  }
}
