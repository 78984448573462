import { campaignStatusToColorAndText } from "./card/CampaignCardHeader";
import { CampaignStatus } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import styled from "styled-components";

const StatusPill = styled(Text)<{ $isActive: boolean }>`
  padding: 8px 12px;
  border-radius: 120px;
  background-color: ${({ $isActive }) =>
    $isActive ? "rgba(105, 51, 220, 0.25)" : "transparent"};
  color: ${({ $isActive }) =>
    $isActive ? "rgba(47, 4, 138, 1)" : "rgba(0, 0, 0, 0.7)"};
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.4s, color 0.4s;
`;

const StatusFilters = ({
  selectedStatus,
  setSelectedStatus,
}: {
  selectedStatus: CampaignStatus | null | undefined;
  setSelectedStatus: (status: CampaignStatus | null | undefined) => void;
}) => {
  return (
    <Flex gap="12px">
      <StatusPill
        key={"all"}
        $isActive={selectedStatus === null}
        onClick={() => setSelectedStatus(null)}
      >
        {"All"}
      </StatusPill>
      {Object.values(CampaignStatus)
        .filter(
          (state) =>
            ![CampaignStatus.draft, CampaignStatus.cancelled].includes(state)
        )
        .map((status) => (
          <StatusPill
            key={status}
            $isActive={selectedStatus === status}
            onClick={() => setSelectedStatus(status)}
          >
            {campaignStatusToColorAndText(status).text}
          </StatusPill>
        ))}
    </Flex>
  );
};

export default StatusFilters;
