import AppButton from "../core/buttons/AppButton/AppButton";
import ActionCard from "../core/cards/ActionCard";
import MetaCard from "../core/cards/MetaCard";
import { Flex } from "@radix-ui/themes";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

const OnboardingMeta = ({
  onNext,
  onPrev,
}: {
  onNext: () => void;
  onPrev: () => void;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const isMetaConnected = (adAccounts?.length ?? 0) > 0;

  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <ActionCard
        footer={
          <>
            <AppButton variant="outlined" onClick={onPrev}>
              Back
            </AppButton>
            <AppButton variant="outlined" onClick={onNext}>
              Skip
            </AppButton>
            <AppButton
              variant="dark"
              onClick={onNext}
              disabled={!isMetaConnected}
            >
              Continue
            </AppButton>
          </>
        }
        title="Meta Account"
        subtitle={`Now let’s connect your Meta account.\n\nI’m going to take a look at all your ads and get a sense for what’s performed well to inform our ad campaigns.`}
      >
        <MetaCard />
      </ActionCard>
    </Flex>
  );
};

export default OnboardingMeta;
