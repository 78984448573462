import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { PencilIcon, TargetGoalIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const MissionStatementCardSection = () => {
  const handleEdit = () => {
    console.log("Edit");
  };

  return (
    <StyleLibraryCardContent
      title="Mission Statement"
      icon={<TargetGoalIcon />}
      actions={
        <AppButton variant="ghost" size="3" onClick={handleEdit}>
          <PencilIcon size={20} strokeWidth={1.25} /> Edit
        </AppButton>
      }
    >
      TODO
    </StyleLibraryCardContent>
  );
};
export default MissionStatementCardSection;
