import CloseIconButton from "../../../../core/buttons/CloseIconButton";
import AssetSelectorSections from "./sections/AssetSelectorSections";
import LibraryAssets from "./sections/LibraryAssets";
import RelatedImages from "./sections/RelatedImages";
import { ImageAssetCategory } from "@openapi";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Dialog, Flex, Heading, TextField } from "@radix-ui/themes";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import UploadImageButton from "~/components/core/buttons/UploadImageButton";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";

export const HoverOverlay = styled.div<{ $isSelected?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$isSelected ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export type AssetSelectorItem = {
  url: string;
  asset?: ImageAssetSchema;
};

const AssetSelectorDialog = ({
  singleAsset,
  isDialogOpen,
  onToggleDialogOpen,
  preselectedAssets,
  onSelect,
  relatedAssets,
}: {
  singleAsset?: boolean;
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  preselectedAssets?: AssetSelectorItem[];
  relatedAssets?: AssetSelectorItem[] /** Items might only contain a url (which may or may or may not be an asset) */;
  onSelect: (assets: AssetSelectorItem[]) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const [selectedAssets, setSelectedAssets] = useState<AssetSelectorItem[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  // TODO: account for different keys. For now, this is the closest thing to what we have in prod
  useEffect(() => {
    if (isDialogOpen) {
      setSelectedAssets(preselectedAssets ?? []);
    }
  }, [isDialogOpen]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");

  const { mutate: uploadImageAssets, isPending } = useMediaBrandImagesUpload({
    data: {
      brandID: activeBrandID,
      category: ImageAssetCategory.other,
    },
    onSuccess: (data) => {
      setSelectedAssets(data.new_assets ?? []);
      handleSearchChange("");
      const scrollableTarget = document.getElementsByClassName(
        "infinite-scroll-component "
      )?.[0];
      scrollableTarget?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
    onError: (error) => {
      if (error.response?.status === 403) {
        setUploadError?.("You do not have permission to upload images.");
      } else {
        console.log(error);
        setUploadError?.("An error occurred while uploading images.");
      }
    },
  });

  //search
  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSearchCall(value);
  };
  const selectAsset = (asset: AssetSelectorItem) => {
    if (singleAsset) {
      setSelectedAssets([asset]);
    } else {
      !!selectedAssets.find((file) => file.url === asset.url)
        ? setSelectedAssets(
            selectedAssets.filter((file) => file.url !== asset.url)
          )
        : setSelectedAssets([...selectedAssets, asset]);
    }
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
      <Dialog.Content maxWidth="780px" style={{ padding: 0 }}>
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="bold" mb="0">
            Select Image
          </Dialog.Title>
          <Dialog.Close>
            <CloseIconButton />
          </Dialog.Close>
        </Flex>
        <Flex direction="column" pl="24px" pt="16px">
          <Flex gap="5" mr="5">
            <TextField.Root
              mb="16px"
              value={search}
              onChange={(event) => handleSearchChange(event.target.value)}
              style={{
                backgroundColor: "#F1F1F0",
                width: "calc(100% - 24px)",
              }}
              radius="large"
              color="teal"
              variant="soft"
              placeholder="Search…"
            >
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
            </TextField.Root>
            <UploadImageButton
              setUploadError={setUploadError}
              onUpload={uploadImageAssets}
              isLoading={isPending}
            />
          </Flex>
          {uploadError && (
            <Heading size="1" color="red" align={"center"} mb="2">
              {uploadError}
            </Heading>
          )}
          <AssetSelectorSections
            sections={[
              relatedAssets
                ? {
                    title: "Related Images",
                    section: (
                      <RelatedImages
                        relatedAssets={relatedAssets?.slice(0, 6)}
                        selectAsset={selectAsset}
                        selectedAssets={selectedAssets}
                      />
                    ),
                  }
                : null,
              {
                title: "Library Assets",
                section: (
                  <LibraryAssets
                    search={debouncedSearch}
                    selectedAssets={selectedAssets}
                    selectAsset={selectAsset}
                  />
                ),
              },
            ]}
          />
        </Flex>
        <Flex px="24px" py="12px" justify="end">
          <Flex gap="3">
            <Dialog.Close>
              <AppButton variant="outlined" size="3" radius="large">
                Cancel
              </AppButton>
            </Dialog.Close>
            <Dialog.Close>
              <AppButton
                disabled={!selectedAssets.length}
                onClick={() => onSelect(selectedAssets)}
                variant="dark"
                size="3"
                radius="large"
              >
                Add
              </AppButton>
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AssetSelectorDialog;
