import { getFacebookRedirectUrl } from "../meta/authentication/FacebookLogin";
import { operations } from "@openapi";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type ConnectMetaResponse =
  operations["meta_api_connect_meta_account"]["responses"]["200"]["content"]["application/json"];
type ConnectMetaRequestData =
  operations["meta_api_connect_meta_account"]["requestBody"]["content"]["application/json"];

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div`
  width: 60px;
  height: 60px;
`;

const Spinner = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #e6e6e6;
  border-top: 5px solid #000000;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.h2`
  margin-top: 16px;
  font-size: 1.5rem;
  color: #333;
`;

const MetaCallback = () => {
  let [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const sv = JSON.parse(state ?? "{}");
  const maybeBrandID = sv.brand_id;

  const connectMetaAccountMutation = useDraperApiPostMutation<
    ConnectMetaResponse,
    ConnectMetaRequestData
  >({
    path: "/meta/connect",
    onError: (error) => {
      const message = {
        success: false,
        brand_id: maybeBrandID,
        state: sv.state,
        error: error.message,
      };
      if (window.opener) {
        window.opener.postMessage(message, window.location.origin);
        window.close();
      } else {
        console.error("No opener found. Cannot postMessage.");
      }
    },
    onSuccess: (data) => {
      //commented code in case we'll ever switch back to window
      // const message = {
      //   success: true,
      //   brand_id: maybeBrandID,
      //   state: sv.state,
      // };
      // if (window.opener) {
      //   window.opener.postMessage(message, window.location.origin);
      //   window.close();
      // } else {
      //   console.error("No opener found. Cannot postMessage.");
      // }
      const state = searchParams.get("state");
      if (state) {
        const state = searchParams.get("state");
        const params = new URLSearchParams(JSON.parse(state ?? ""));
        window.location.href = `/?${params}`;
      } else {
        window.location.href = `/`;
      }
    },
  });

  useEffect(() => {
    if (code && maybeBrandID) {
      connectMetaAccountMutation.mutate({
        brand_id: maybeBrandID,
        token_code: code,
        redirect_uri: getFacebookRedirectUrl(),
      });
    } else {
      const error = searchParams.get("error");
      const errorCode = searchParams.get("error_code");
      const errorDescription = searchParams.get("error_description");
      const errorReason = searchParams.get("error_reason");

      const message = {
        success: false,
        brand_id: maybeBrandID,
        state: sv.state,
        error: `Error: ${error} ${errorCode} ${errorDescription} ${errorReason}`,
      };
      if (window.opener) {
        window.opener.postMessage(message, window.location.origin);
        window.close();
      } else {
        console.error("No opener found. Cannot postMessage.");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <BackgroundGradient />
      <CenteredContainer>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
        <LoadingText>Connecting</LoadingText>
      </CenteredContainer>
    </>
  );
};

export default MetaCallback;
