import { Box } from "@radix-ui/themes";
import { styled } from "styled-components";

const EmptyContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  background-color: #edebf0;
  border-radius: 12px;
  height: 130px;
  width: 100%;
`;

const StyleLibraryCardEmptyContent = ({
  text,
  button,
}: {
  text: string;
  button?: React.ReactNode;
}) => {
  return (
    <EmptyContent>
      {text}
      {button}
    </EmptyContent>
  );
};

export default StyleLibraryCardEmptyContent;
