import { components } from "@openapi";
import { RadioCards } from "@radix-ui/themes";
import { useMemo } from "react";
import PalettePicker from "~/components/core/inputs/PalettePicker";
import ModifierContainer from "~/components/editor/editor/sidepanel-views/modifiers/ModifierContainer";
import useBrandStyleQuery from "~/hooks/data/useBrandStyleQuery";

const ColorPaletteModifier = ({
  onClick,
  palette,
  title = "Color Palette",
}: {
  onClick: (palette: components["schemas"]["ColorPaletteSchema"]) => void;
  palette: components["schemas"]["ColorPaletteSchema"];
  title?: string;
}) => {
  const { data: brandStyling } = useBrandStyleQuery();
  const palettes = brandStyling?.color_palettes ?? [];
  const selectedPalette = useMemo(() => {
    return (
      palettes.find((p) => p.id === palette.id) ||
      palettes.find(
        (p) =>
          p.accent_background === palette.accent_background &&
          p.background === palette.background
      )
    );
  }, [palette, palettes]);

  return (
    <ModifierContainer title={title} hideSwitch>
      <RadioCards.Root
        defaultValue={selectedPalette?.id ?? ""}
        columns={{ initial: "1", sm: "2", md: "3" }}
      >
        {palettes.map((palette) => {
          return (
            <RadioCards.Item
              key={palette.id}
              value={palette.id ?? ""}
              onClick={() => {
                onClick(palette);
              }}
            >
              <PalettePicker palette={palette} />
            </RadioCards.Item>
          );
        })}
      </RadioCards.Root>
    </ModifierContainer>
  );
};

export default ColorPaletteModifier;
