import classes from "./BrandAssets.module.css";
import BrandImageAsset, { ImageAssetSchema } from "./BrandImageAsset";
import { Tooltip, Text, Box } from "@radix-ui/themes";
import React from "react";
import patternSrc from "~/assets/pattern.png";

const BrandAsset = ({
  originalFilename,
  url,
  overlay,
  onClick,
}: {
  originalFilename?: string;
  url: string;
  onClick?: () => void;
  overlay?: React.ReactNode;
}) => {
  return (
    <Tooltip
      content={originalFilename}
      side={"bottom"}
      hidden={!originalFilename}
    >
      <Box className={classes.assetContainer} onClick={onClick}>
        <Box
          position={"relative"}
          style={{
            cursor: "pointer",
            overflow: "hidden",
            borderRadius: "var(--radius-3)",
            background: `url(${patternSrc})`,
            backgroundSize: "100% 100%",
          }}
        >
          <BrandImageAsset url={url} />
          {overlay}
        </Box>

        {!!originalFilename && (
          <Text size={"3"} className={classes.filename}>
            {originalFilename}
          </Text>
        )}
      </Box>
    </Tooltip>
  );
};

export default BrandAsset;
