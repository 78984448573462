import BrandFontSelect from "../BrandFontSelect/BrandFontSelect";
import { FontSelectItem } from "../BrandFontSelect/FontSelect";
import {
  components,
  FontOrigin,
  FontWeight,
  operations,
  PathsApiV1BrandBrand_idStylebookTypographyCategoryCategoryPostRequestBodyMultipartFormDataFont_origin,
  TypographyCategory,
} from "@openapi";
import * as Form from "@radix-ui/react-form";
import {
  Dialog,
  Flex,
  Heading,
  IconButton,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { PaperclipIcon } from "lucide-react";
import { useRef, useState } from "react";
import { Trash2Icon, UploadIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DialogActions from "~/components/core/dialog/DialogActions";
import DialogHeader from "~/components/core/dialog/DialogHeader";
import { useBrandStylingDispatch } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { getFontWeightDescription } from "~/utils/brand-style/helpers";

type SetTypographyCategoryStyleParams =
  operations["brand_api_set_typography_category_style"]["requestBody"]["content"]["multipart/form-data"];
type SetTypographyCategoryStyleResponse =
  operations["brand_api_set_typography_category_style"]["responses"][200]["content"]["application/json"];

interface ChangeCategoryFontDialogContentProps {
  category: TypographyCategory;
  font: components["schemas"]["BrandStylingFontFamilySchema"] | null;
  onCancel: () => void;
  onSuccess: () => void;
}

const convertFontOrigin = (
  origin: FontOrigin
): PathsApiV1BrandBrand_idStylebookTypographyCategoryCategoryPostRequestBodyMultipartFormDataFont_origin => {
  return PathsApiV1BrandBrand_idStylebookTypographyCategoryCategoryPostRequestBodyMultipartFormDataFont_origin[
    FontOrigin[
      origin
    ] as keyof typeof PathsApiV1BrandBrand_idStylebookTypographyCategoryCategoryPostRequestBodyMultipartFormDataFont_origin
  ];
};

const ChangeCategoryFontDialogContent: React.FC<
  ChangeCategoryFontDialogContentProps
> = ({ font, category, onCancel, onSuccess }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [fontFile, setFontFile] = useState<File | null>(null);
  const [serverErrors, setServerErrors] = useState("");
  const title = `${font ? "Change" : "Add"} ${
    category === "header" ? "Heading" : "Paragraph"
  } Font`;
  const activeBrandID = useActiveBrandID();
  const brandStylingDispatch = useBrandStylingDispatch();

  const updateTypographyCategoryStyle = useMutation<
    SetTypographyCategoryStyleResponse,
    Error,
    SetTypographyCategoryStyleParams
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        `/api/v1/brand/${activeBrandID}/stylebook/typography/category/${category}`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      console.log("Styles updated successfully:", data);
      brandStylingDispatch({
        type: "SET_IS_DIRTY",
        payload: true,
      });
      onSuccess();
    },
    onError: (error) => {
      setServerErrors(error.message);
    },
  });

  const [hasCustomFont, setHasCustomFont] = useState(
    font?.font_face?.origin === FontOrigin.storage ||
      font?.font_face?.origin === FontOrigin.store
  );
  const hasFontFile = !!fontFile || hasCustomFont;
  const [selectedFont, setSelectedFont] = useState<FontSelectItem | null>(
    font?.font_face
      ? {
          name: font.font_face.name,
          origin: font.font_face.origin,
        }
      : null
  );

  const onFontFamilyChange = (font: FontSelectItem) => {
    console.log("CHANGING");
    console.log(font);
    setHasChanges(true);
    setSelectedFont(font);
  };

  const onSubmit = (data: FormData) => {
    const values = Object.fromEntries(data);

    updateTypographyCategoryStyle.mutate({
      font_upload: hasFontFile ? (fontFile as unknown as string | null) : null, // need to pass files as string
      font_family_name: hasFontFile
        ? (values.font_name as string)
        : (values.font_select as string),
      font_weight: values.font_weight as unknown as FontWeight | null,
      font_origin: hasFontFile
        ? convertFontOrigin(FontOrigin.storage)
        : convertFontOrigin(selectedFont!.origin),
    });
  };

  return (
    <Dialog.Content>
      <DialogHeader title={title} />
      <Dialog.Description />
      <Form.Root
        onSubmit={(event) => {
          event.preventDefault();
          const data = new FormData(event.currentTarget);
          onSubmit(data);
        }}
        onClearServerErrors={() => setServerErrors("")}
      >
        {hasFontFile && (
          <Flex
            direction={"column"}
            p={"4"}
            my="5"
            gap={"2"}
            style={{
              borderRadius: "var(--radius-3)",
              border: "1px solid rgb(228, 228, 231)",
              color: "var(--text-secondary)",
            }}
          >
            <Flex
              direction={"row"}
              justify={"between"}
              align={"center"}
              gap="2"
            >
              <Flex gap="2" direction={"row"} align={"center"}>
                <PaperclipIcon size={20} />
                <Text size="3">{fontFile?.name ?? font?.name}</Text>
              </Flex>

              <IconButton
                variant="surface"
                size="2"
                color="gray"
                type="button"
                onClick={() => {
                  setFontFile(null);
                  setHasCustomFont(false);
                }}
              >
                <Trash2Icon />
              </IconButton>
            </Flex>
            <Form.Field name="font_name">
              <Flex direction={"column"}>
                <Form.Label style={{ fontSize: "14px", color: "#888282" }}>
                  Font family name
                </Form.Label>
                <Form.Control asChild>
                  <TextField.Root
                    type="text"
                    required
                    readOnly={!fontFile}
                    defaultValue={
                      fontFile?.name.replace(/\.[^/.]+$/, "") || font?.name
                    }
                    onChange={(e) => {
                      setHasChanges(true);
                    }}
                  />
                </Form.Control>
                <Form.Message match="valueMissing" style={{ color: "red" }}>
                  Please enter the font family name.
                </Form.Message>
              </Flex>
            </Form.Field>
          </Flex>
        )}

        {!hasFontFile && (
          <Flex gap="2" align="start" direction={"column"} width={"100%"}>
            <Form.Field name="font_select" style={{ width: "100%" }}>
              <Form.Label style={{ fontSize: "14px", color: "#888282" }}>
                Font Family
              </Form.Label>
              <Form.Control asChild>
                <BrandFontSelect
                  size={"3"}
                  value={selectedFont?.name ?? ""}
                  onChange={onFontFamilyChange}
                  loadSelectedGoogleFont={true}
                  placeholder="Select font"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field name="font_weight" style={{ width: "100%" }}>
              <Form.Label style={{ fontSize: "14px", color: "#888282" }}>
                Font Weight
              </Form.Label>
              <Form.Control asChild>
                <Select.Root
                  size="3"
                  defaultValue={String(
                    font?.font_weight ?? FontWeight.Value400
                  )}
                  onValueChange={(e) => {
                    setHasChanges(true);
                  }}
                >
                  <Select.Trigger style={{ width: "100%" }} />
                  <Select.Content>
                    <Select.Group>
                      {Object.values(FontWeight)
                        .filter(
                          (weight): weight is FontWeight =>
                            typeof weight !== "string"
                        )
                        .map((weight) => (
                          <Select.Item key={weight} value={String(weight)}>
                            {getFontWeightDescription(weight)}
                          </Select.Item>
                        ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Form.Control>
            </Form.Field>

            <Form.Field name="font_upload">
              <Form.Control asChild>
                <input
                  type="file"
                  id="file"
                  accept=".ttf,.otf,.woff,.woff2"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) return;
                    setFontFile(file);
                    setHasChanges(true);
                  }}
                />
              </Form.Control>
            </Form.Field>
            <Text size="3" style={{ color: "var(--text-secondary)" }}>
              Or upload custom font file(s)
            </Text>
            <AppButton
              variant="dark"
              type="button"
              onClick={() => {
                inputFile.current?.click();
              }}
            >
              <UploadIcon />
              Upload
            </AppButton>
          </Flex>
        )}

        <DialogActions>
          <AppButton variant="outlined" onClick={onCancel}>
            Cancel
          </AppButton>
          <Form.Submit asChild>
            <AppButton variant="dark" disabled={!hasChanges}>
              {font ? "Change Font" : "Add Font"}
            </AppButton>
          </Form.Submit>
        </DialogActions>
        {serverErrors ? (
          <Heading mt="2" size="2" color="red" align={"right"}>
            {serverErrors}
          </Heading>
        ) : (
          <></>
        )}
      </Form.Root>
    </Dialog.Content>
  );
};

export default ChangeCategoryFontDialogContent;
