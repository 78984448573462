import { Flex, Text } from "@radix-ui/themes";

type Section = { section: JSX.Element; title: string };
const AssetSelectorSections = ({
  sections,
}: {
  sections: (Section | null)[];
}) => {
  const filteredSections = sections.filter((section) => section);
  return (
    <Flex direction="column" width="fit-content" gap="5">
      {filteredSections.map((section) => (
        <Flex direction="column" gap="3">
          <Text size="3" weight="medium">
            {section!.title}
          </Text>
          {section!.section}
        </Flex>
      ))}
    </Flex>
  );
};
export default AssetSelectorSections;
