import {
  Flex,
  IconButton,
  Radio,
  Skeleton,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckIcon, PencilIcon } from "~/assets/icons";
import Modal from "~/components/core/dialog/Modal";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";

const TextInput = styled(TextField.Root)`
  height: 48px;
`;

const EditableTextComponent = ({
  isActive,
  label,
  initialValue,
  onChange,
}: {
  isActive: boolean;
  label: string;
  initialValue: string;
  onChange: (value: string) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialValue);

  useEffect(() => {
    !isActive && setText(initialValue);
  }, [isActive]);

  return (
    <Flex direction="row" justify="between" align="center">
      <Flex direction="row" align="baseline" gap="1">
        <Text
          size={"1"}
          style={{
            color: "var(--text-tertiary)",
          }}
        >
          {label}
        </Text>
        <Text
          size="3"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </Text>
      </Flex>
      <IconButton
        onClick={() => setIsEditing(true)}
        variant="ghost"
        radius="full"
      >
        <PencilIcon size={16} color="var(--primary-deep-purple)" />
      </IconButton>

      <Modal
        open={isEditing}
        onOpenChange={(value) => setIsEditing(value)}
        title={`Edit ${label}`}
        submitBtnText="Save"
        maxWidth="480px"
        onCancel={() => setIsEditing(false)}
        onSubmit={() => {
          setIsEditing(false);
          onChange(text);
        }}
      >
        <Flex direction="column" gap="3" m="5">
          <Text size="3">{label}</Text>
          <TextInput
            required
            placeholder={label}
            value={text}
            onChange={(e) => setText(e.target.value)}
            autoFocus
          />
        </Flex>
      </Modal>
    </Flex>
  );
};

const RadioWithText = ({
  isActive,
  value,
  title,
  description,
  onClick,
  collectionTitle,
  generatedDiscountCode,
}: {
  isActive: boolean;
  value?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  collectionTitle: string | null;
  generatedDiscountCode: string | null;
}) => {
  const dispatch = useCampaignWizardDispatch();
  const {
    campaignData: { discount, destination },
  } = useCampaignWizardState();

  if (!value || !title || !description) {
    return (
      <Flex
        direction="column"
        p="20px"
        gap="12px"
        style={{
          border: "1px solid #DDD7D7",
          borderRadius: "20px",
          width: "100%",
        }}
        height={"175px"}
      >
        <Flex gap="8px" align="center" width={"100%"}>
          <Skeleton>
            <Radio value=""></Radio>
          </Skeleton>
          <Skeleton
            height="17px"
            width="50%"
            style={{ borderRadius: "25px" }}
          />
        </Flex>
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
        <Skeleton height="17px" width="100%" style={{ borderRadius: "25px" }} />
      </Flex>
    );
  }

  let discountInput = null;
  let landingPathInput = null;
  if (generatedDiscountCode && discount) {
    discountInput = (
      <EditableTextComponent
        isActive={isActive}
        label="Promo Code"
        initialValue={generatedDiscountCode}
        onChange={(value) => {
          dispatch({
            type: "UPDATE_CAMPAIGN_DATA",
            payload: {
              discount: {
                ...discount,
                code: value,
              },
            },
          });
        }}
      />
    );
  }

  if (collectionTitle && !destination?.collection_id) {
    landingPathInput = (
      <EditableTextComponent
        isActive={isActive}
        label="Landing Page Title"
        initialValue={collectionTitle}
        onChange={(value) => {
          dispatch({
            type: "UPDATE_CAMPAIGN_DATA",
            payload: {
              destination: {
                ...destination,
                name: value,
              },
            },
          });
        }}
      />
    );
  }

  return (
    <Flex
      onClick={onClick}
      direction="column"
      p="20px"
      gap="12px"
      width="100%"
      style={{
        border: `1px solid ${
          isActive ? "var(--border-primary)" : "var(--border-secondary)"
        }`,
        borderRadius: "8px 24px 24px 24px",
        cursor: "pointer",
        backgroundColor: isActive ? "var(--background_soft)" : "#ffffff",
      }}
    >
      <Flex gap="2" direction="row">
        <CheckIcon
          size="20"
          color={"var(--primary-deep-purple)"}
          style={{
            flexShrink: 0,
            padding: "6px",
            opacity: isActive ? 1 : 0.2,
          }}
        />
        <Flex direction="column" gap="3">
          <Text style={{ fontSize: "20px", color: "#121111", fontWeight: 500 }}>
            {title}
          </Text>
          <Text size="3">{description}</Text>
          {discountInput}
          {landingPathInput}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RadioWithText;
