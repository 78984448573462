import { JobStatus, operations, TemporalWorkflow } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type JobStatusResponse =
  operations["temporal_api_status"]["responses"][200]["content"]["application/json"];

const useJobStatusQuery = (
  brandID: string,
  workflowName: TemporalWorkflow,
  endStatus?: JobStatus[]
) => {
  const {
    data: jobStatus,
    isLoading,
    isSuccess,
    error,
  } = useQuery<JobStatusResponse>({
    queryKey: ["temporal-status", brandID],
    queryFn: async (): Promise<JobStatusResponse> => {
      const { data } = await axios.get<JobStatusResponse>(
        `/api/v1/temporal/status`,
        {
          params: {
            brand_id: brandID,
            workflow_name: workflowName,
          },
        }
      );
      return data;
    },
    enabled: !!brandID,
    refetchInterval: endStatus
      ? (data) =>
          data.state.data && endStatus.includes(data.state.data) ? false : 2000
      : false,
  });

  return {
    jobStatus: jobStatus ?? null,
    isLoading,
    isSuccess,
    error,
  };
};

export default useJobStatusQuery;
