import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import RadioWithText from "../../../components/campaign/wizard/inputs/RadioWithText";
import useCampaignCreativesProps from "./hooks/useCampaignCreativesProps";
import * as Label from "@radix-ui/react-label";
import {
  Box,
  Flex,
  IconButton,
  ScrollArea,
  Text,
  TextField,
} from "@radix-ui/themes";
import React from "react";
import { useEffect } from "react";
import { SendIcon } from "~/assets/icons";

const CampaignCreatives = () => {
  const {
    cardProps,
    onSendPrompt,
    prompt,
    setPrompt,
    isCreativeUpdating,
    campaignRecommendationCreativeMessages,
    activeMessageRef,
    activeCreative,
    onActiveCreativeChange,
  } = useCampaignCreativesProps();

  const promptComponent = (
    <Box width={"100%"}>
      <Label.Root htmlFor="prompt">
        <Text as="div" size="3" mb={"2"}>
          Adjust campaign idea
        </Text>
      </Label.Root>
      <TextField.Root
        id="prompt"
        type="text"
        placeholder={"eg. make the tone of voice more informal"}
        size="3"
        radius="large"
        value={prompt}
        onChange={(e) => setPrompt(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevents any default behavior like submitting forms
            onSendPrompt();
          }
        }}
        style={{
          height: "45px",
        }}
      >
        <TextField.Slot side="right">
          <IconButton
            variant="solid"
            disabled={prompt === "" || isCreativeUpdating}
            onClick={onSendPrompt}
          >
            <SendIcon color="white" size="20" />
          </IconButton>
        </TextField.Slot>
      </TextField.Root>
    </Box>
  );

  useEffect(() => {
    if (activeMessageRef.current) {
      activeMessageRef.current.scrollTop =
        activeMessageRef.current.scrollHeight;
    }
  }, [activeMessageRef, campaignRecommendationCreativeMessages]);

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle="Campaign content will be generated based on this idea. Would you like to adjust it?"
    >
      <Flex
        direction="column"
        gap="24px"
        style={{ maxHeight: "45vh", width: "650px" }}
      >
        <ScrollArea ref={activeMessageRef}>
          <Flex
            direction="column"
            gap="24px"
            pr="4"
            position={"relative"}
            align={"end"}
          >
            {(campaignRecommendationCreativeMessages ?? []).map((c) => {
              const creative = c.recommendation; // could be null from optimistic updating
              const message = c.message;
              return (
                <React.Fragment key={creative?.id}>
                  <RadioWithText
                    isActive={activeCreative?.id === creative?.id}
                    onClick={() => onActiveCreativeChange(creative)}
                    key={creative?.id}
                    value={creative?.id}
                    title={creative?.title}
                    description={creative?.description}
                    collectionTitle={creative?.collection_title ?? null}
                    generatedDiscountCode={creative?.discount_code ?? null}
                  />

                  {message && (
                    <Box
                      style={{
                        borderRadius: "20px 8px 20px 20px",
                        border: "1px solid #DDD7D7",
                        padding: "20px",
                        backgroundColor: "#F0EDEB",
                      }}
                    >
                      {message}
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        </ScrollArea>

        {promptComponent}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignCreatives;
