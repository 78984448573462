import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import RecommenderCard from "./RecommenderCard";
import RecommenderHero from "./RecommenderHero";
import RecommenderZeroState from "./RecommenderZeroState";
import { Flex, Grid } from "@radix-ui/themes";
import { useMemo } from "react";

const RecommenderSection = () => {
  const { data, isLoading } = useGetCampaignRecommendations();

  const topRecommendations = useMemo(() => {
    return data?.pages
      .flatMap((page) => page.campaign_recommendations)
      .slice(0, 3);
  }, [data]);

  if (!isLoading && !topRecommendations?.length) {
    return <RecommenderZeroState />;
  }

  return (
    <Flex direction="column" gap="7" width="100%" overflow="hidden" pt="72px">
      <RecommenderHero
        isSkeleton={isLoading}
        recommendation={topRecommendations?.[0]}
      />
      <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="6">
        {topRecommendations?.slice(1)?.map((recommendation) => (
          <RecommenderCard
            key={recommendation.id}
            recommendation={recommendation}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default RecommenderSection;
