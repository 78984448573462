import { operations } from "../../../openapi";
import { BrandResponse } from "./useBrandQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

export type UpdateBrandResponse =
  operations["brand_api_update_brand"]["responses"][200]["content"]["application/json"];

type UpdateBrandRequestData =
  operations["brand_api_update_brand"]["requestBody"]["content"]["application/json"];

const useBrandUpdateMutation = ({
  brandID,
  onSuccess,
  onError,
  onMutate,
  skipOptimisticUpdate,
}: {
  brandID: string;
  onSuccess?: (data: UpdateBrandResponse) => void;
  onError?: (error: Error) => void;
  onMutate?: () => void;
  skipOptimisticUpdate?: boolean;
}) => {
  const queryClient = useQueryClient();

  const updateBrand = useDraperApiPatchMutation<
    UpdateBrandResponse,
    UpdateBrandRequestData
  >({
    mutationKey: ["brand-update"],
    path: `/brand/${brandID}`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onMutate: (data) => {
      onMutate?.();
      if (skipOptimisticUpdate) return;
      const prevBrand = queryClient.getQueryData<BrandResponse>([
        "brand",
        brandID,
      ]);
      queryClient.setQueryData(["brand", brandID], {
        ...prevBrand,
        ...data,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["brand", brandID],
      });
    },
  });

  return {
    updateBrand: updateBrand.mutate,
    isLoading: updateBrand.isPending,
  };
};

export default useBrandUpdateMutation;
