import FontSelect, { FontSelectSection } from "./FontSelect";
import { FontOrigin } from "@openapi";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  addStylesheetURL,
  useBrandStyle,
} from "~/contexts/BrandStylingContext";
import getCustomGoogleFontLink from "~/utils/brand-style/getCustomGoogleFontLink";
import GOOGLE_FONTS from "~/utils/brand-style/googleFonts";

type BrandFontSelectProps = Omit<
  React.ComponentProps<typeof FontSelect>,
  "fontSections"
> & {
  loadSelectedGoogleFont?: boolean;
};

const BrandFontSelect: React.FC<BrandFontSelectProps> = ({
  loadSelectedGoogleFont,
  value,
  onChange,
  ...props
}) => {
  const [linkedFont, setLinkedFont] = useState<HTMLLinkElement | null>(null);
  const { data: brandStyle } = useBrandStyle();
  const fonts = useMemo(() => {
    if (!brandStyle) {
      return [];
    }
    return [
      ...brandStyle.typography.custom_font_families,
      ...brandStyle.typography.unused_custom_fonts,
    ]
      .map((family) => {
        return { name: family.name, origin: family.origin };
      })
      .sort((f1, f2) => f1.name.localeCompare(f2.name));
  }, [brandStyle]);
  const sections: FontSelectSection[] = useMemo(
    () => [
      {
        title: "Custom Fonts",
        fonts: fonts,
      },
      {
        title: "Google Fonts",
        fonts: GOOGLE_FONTS.map((font) => {
          return { name: font, origin: FontOrigin.google };
        }),
      },
    ],
    [fonts]
  );

  useEffect(() => {
    if (!loadSelectedGoogleFont || !value) {
      return;
    }

    if (linkedFont) {
      linkedFont.remove();
    }

    const googleFontLink = getCustomGoogleFontLink(value);
    if (!googleFontLink) {
      return;
    }

    const newLinkedFont = addStylesheetURL(googleFontLink);
    setLinkedFont(newLinkedFont);
  }, [loadSelectedGoogleFont, value]);

  useEffect(() => {
    return () => {
      linkedFont?.remove();
    };
  }, []);
  return (
    <FontSelect
      {...props}
      value={value}
      onChange={onChange}
      fontSections={sections}
    />
  );
};

export default BrandFontSelect;
