import { AdMediaUpdateableElement, useAdMediaContext } from "../AdMediaContext";
import AdMediaElementListComponent from "../elements/AdMediaElementListComponent";
import { Box } from "@radix-ui/themes";
import SidePanelComponents from "~/components/core/editor/sidepane";

const EditorSidePanel: React.FC = () => {
  const { elements, setElements } = useAdMediaContext();

  // not really safe in case of partial updates, as the original element might be outdated
  const handleElementUpdate = (updatedElement: AdMediaUpdateableElement) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  return (
    <Box
      maxHeight="100%"
      overflowY="auto"
      pr="3"
      pb="3"
      pt="calc(var(--editor-top-bar-height) + var(--space-3))"
      // required so that it doesn't clip the box shadow of children
      pl="calc(var(--editor-side-panel-left-padding) + var(--space-3))"
      width="500px"
      flexGrow="0"
      flexShrink="0"
    >
      <SidePanelComponents.SidePanelBox p="4">
        <AdMediaElementListComponent
          elements={elements}
          onElementUpdate={handleElementUpdate}
        />
      </SidePanelComponents.SidePanelBox>
    </Box>
  );
};

export default EditorSidePanel;
