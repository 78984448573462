import InternAssetsReclassifyDialog from "../../assets/intern/InternAssetsReclassifyDialog";
import InternMultiSelectMenu from "../../assets/intern/InternMultiSelectMenu";
import { ImageAssetCategory } from "@openapi";
import { operations } from "@openapi";
import { Box, Button, Flex, Heading } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import InternImageAssetCard from "~/components/intern/InternImageAssetCard";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type BrandImageAssetsParams =
  operations["brand_api_get_brand_image_assets"]["parameters"]["query"];

type BrandImageAssetsResponse =
  operations["brand_api_get_brand_image_assets"]["responses"][200]["content"]["application/json"];

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const InternAssetsComponent = () => {
  const activeBrandID = useActiveBrandID();

  const pageSize = 20;

  const [categories, setCategories] = useState<ImageAssetCategory[]>(
    Object.values(ImageAssetCategory)
  );

  const [isRecategorizeDialogOpen, setIsRecategorizeDialogOpen] =
    useState<boolean>(false);

  const observer = useRef<IntersectionObserver | null>(null);

  const { data, isLoading, fetchNextPage } =
    useInfiniteQuery<BrandImageAssetsResponse>({
      queryKey: ["brand-image-assets", activeBrandID, pageSize, categories],
      queryFn: async ({ pageParam }): Promise<BrandImageAssetsResponse> => {
        const page = Number(pageParam);
        const params: BrandImageAssetsParams = {
          page: page,
          page_size: pageSize,
          categories,
        };
        const { data } = await axios.get(
          `/api/v1/brand/${activeBrandID}/image-assets`,
          {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params, { indices: false });
            },
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
          }
        );
        return data;
      },
      getNextPageParam: (lastPage) =>
        lastPage.has_more ? lastPage.page + 1 : undefined,
      initialPageParam: 0,
      retry: 1,
      staleTime: 3600,
    });

  const lastAssetRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && fetchNextPage) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, fetchNextPage]
  );

  const totalAssetCount =
    data && data.pages.length > 0 ? data.pages[0].total : 0;

  return (
    <Box>
      <InternAssetsReclassifyDialog
        isDialogOpen={isRecategorizeDialogOpen}
        setIsDialogOpen={setIsRecategorizeDialogOpen}
      />

      <Flex align="center" justify="between">
        <Heading style={{ padding: "16px" }}>
          Assets ({totalAssetCount})
        </Heading>
        <Flex gap="8px">
          <Button
            onClick={() => {
              setIsRecategorizeDialogOpen(true);
            }}
          >
            Re-categorize Images
          </Button>
          <InternMultiSelectMenu
            title="Filter by Categories"
            items={Object.values(ImageAssetCategory).map((value) => ({
              value: value,
              label: value,
            }))}
            value={categories}
            onValueChange={(value) => {
              const enumValues = value.map(
                (value) =>
                  ImageAssetCategory[value as keyof typeof ImageAssetCategory]
              );
              setCategories(enumValues.filter((value) => value !== undefined));
            }}
          />
        </Flex>
      </Flex>
      {isLoading && !data ? (
        <p>Loading...</p>
      ) : (
        <CardGrid>
          {data &&
            data.pages.flatMap((page, pageIndex) =>
              page.items.map((asset, index) => {
                const isLastItem =
                  pageIndex === data.pages.length - 1 &&
                  index === page.items.length - 1;
                return (
                  <div key={asset.id} ref={isLastItem ? lastAssetRef : null}>
                    <InternImageAssetCard
                      asset={asset}
                      queryKey={[
                        "brand-image-assets",
                        activeBrandID,
                        pageSize,
                        categories,
                      ]}
                    />
                  </div>
                );
              })
            )}
        </CardGrid>
      )}
      {isLoading && <p>Loading more...</p>}
    </Box>
  );
};

export default InternAssetsComponent;
