import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import useCampaignTypeProps, {
  CampaignTypeUnion,
  campaignTypeButtonText,
} from "./hooks/useCampaignTypeProps";
import { Grid } from "@radix-ui/themes";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const CampaignType = () => {
  const { cardProps, setCampaignType, campaignType } = useCampaignTypeProps();

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle="What type of campaign will this be?"
    >
      <Grid columns="2" gap="16px">
        {Object.keys(campaignTypeButtonText).map((value) => (
          <AppButton
            key={value}
            onClick={() => setCampaignType(value as CampaignTypeUnion)}
            variant="soft"
            radius="large"
            style={{
              width: "310px",
              border: "1px solid",
              borderColor:
                campaignType === value ? "var(--text-link)" : "transparent",
            }}
          >
            {campaignTypeButtonText[value as CampaignTypeUnion].icon}
            {campaignTypeButtonText[value as CampaignTypeUnion].text}
          </AppButton>
        ))}
      </Grid>
    </CampaignWizardCard>
  );
};

export default CampaignType;
