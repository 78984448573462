import AppButton from "../../buttons/AppButton/AppButton";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import { SparklesIcon } from "~/assets/icons";

interface GenerateVariantButtonProps {
  isGenerating: boolean;
  serverError?: string | null;
  onClick: () => void;
}

const GenerateVariantButton: React.FC<GenerateVariantButtonProps> = ({
  isGenerating,
  serverError,
  onClick,
}) => {
  return (
    <Flex direction="column">
      <AppButton
        variant="soft"
        radius="large"
        disabled={isGenerating}
        onClick={onClick}
      >
        <Spinner loading={isGenerating}>
          <SparklesIcon size={20} />
        </Spinner>
        {isGenerating ? "Generating..." : "Generate new version"}
      </AppButton>
      {serverError && (
        <Text size="1" color="red">
          {serverError}
        </Text>
      )}
    </Flex>
  );
};

export default GenerateVariantButton;
