import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { LikeIcon, PlusIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const DosCardSection = () => {
  const handleAdd = () => {
    console.log("Edit");
  };

  return (
    <StyleLibraryCardContent
      title="Dos"
      titleStyle={{
        color: "#009211",
      }}
      icon={<LikeIcon />}
      helperText="TODO"
      actions={
        <AppButton onClick={handleAdd} variant="ghost" size={"3"}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          Add Do
        </AppButton>
      }
    >
      TODO
    </StyleLibraryCardContent>
  );
};
export default DosCardSection;
