import { operations, ImageAssetOrientation } from "@openapi";
import { Button, Dialog, Flex, Heading, Select } from "@radix-ui/themes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

interface InternAssetHeroImageDialogProps {
  assetID: string | null;
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  queryKey?: any[];
}

type GenerateHeroRequestData =
  operations["media_api_generate_hero"]["requestBody"]["content"]["application/json"];

type GenerateHeroResponse =
  operations["media_api_generate_hero"]["responses"][200]["content"]["application/json"];

const InternAssetHeroImageDialog: React.FC<InternAssetHeroImageDialogProps> = ({
  assetID,
  isDialogOpen,
  setIsDialogOpen,
  queryKey,
}) => {
  const queryClient = useQueryClient();

  const activeBrandID = useActiveBrandID();

  const [isLoading, setIsLoading] = useState(false);

  const [orientation, setOrientation] = useState<ImageAssetOrientation>(
    ImageAssetOrientation.landscape
  );

  const mutation = useMutation<
    GenerateHeroResponse,
    Error,
    { assetID: string; requestData: GenerateHeroRequestData }
  >({
    mutationFn: async ({
      assetID,
      requestData,
    }): Promise<GenerateHeroResponse> => {
      setIsLoading(true);
      const { data } = await axios.post(
        `/api/v1/media/image/${assetID}/generate-hero`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (response) => {
      setIsLoading(false);
      setIsDialogOpen(false);
      if (queryKey) {
        queryClient.invalidateQueries({
          queryKey,
        });
      }
      alert(
        "Hero asset job successfully created. Please refresh in about 10-20 seconds."
      );
    },
    onError: (error) => {
      setIsLoading(false);
      alert(error);
    },
  });

  return (
    <Dialog.Root
      open={isDialogOpen && assetID !== null}
      onOpenChange={setIsDialogOpen}
    >
      <Dialog.Content>
        <Dialog.Title>Generate Hero Image</Dialog.Title>
        <Dialog.Description size="2">
          Use AI to generate a new hero image asset in the specified
          orientation. Generating hero images requires an existing product or
          product cutout image asset. Abstract brand style hero images to come
          soon. Landscape is 1920x1080 and portrait is 1080x1350. This operation
          may take up to 10 seconds.
        </Dialog.Description>
        <Flex
          direction="column"
          style={{ marginTop: "8px", marginBottom: "8px" }}
        >
          <Flex direction="column" gap="4px">
            <Heading size="1">Orientation</Heading>
            <Select.Root
              defaultValue={orientation}
              onValueChange={(value) =>
                setOrientation(value as ImageAssetOrientation)
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  {Object.values(ImageAssetOrientation).map((orientation) => (
                    <Select.Item key={orientation} value={orientation}>
                      {orientation}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
        </Flex>
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => setIsDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              if (assetID) {
                mutation.mutate({
                  assetID,
                  requestData: {
                    brand_id: activeBrandID,
                    orientation: orientation,
                  },
                });
              }
            }}
          >
            Create
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default InternAssetHeroImageDialog;
