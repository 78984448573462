import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import type { operations } from "@openapi";
import {
  Dialog,
  Text,
  Box,
  Button,
  Tooltip,
  CheckboxGroup,
  Flex,
  Heading,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { CSSProperties } from "styled-components";
import PalettePicker from "~/components/core/inputs/PalettePicker";

type SuccessResponse =
  operations["brand_api_get_stylebook"]["responses"][200]["content"]["application/json"];

export function parseFontFamily(definition: string) {
  const match = definition.match(/font-family:\s*(['"]?)([^;'"]+)\1\s*;/);
  return match ? match[2].trim() : null;
}

export function getUniqueFontFamilies(
  fontFaceDefinitions: Array<string>
): Array<string> {
  const uniqueFontFamilies = new Set<string>();

  fontFaceDefinitions.forEach((definition) => {
    const fontFamily = parseFontFamily(definition);
    if (fontFamily) {
      uniqueFontFamilies.add(fontFamily);
    }
  });

  return Array.from(uniqueFontFamilies);
}

export default function StylesRoute() {
  const activeBrandID = useActiveBrandID();

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);

  const [shouldOverwriteStyle, setShouldOverwriteStyle] =
    useState<boolean>(false);

  const [newStyleElement, setNewStyleElement] =
    useState<HTMLStyleElement | null>(null);
  const { data, error, isLoading, isSuccess } = useQuery({
    queryKey: ["brand-style", activeBrandID],
    queryFn: async (): Promise<SuccessResponse> => {
      const { data } = await axios.get("/api/v1/brand/stylebook", {
        params: {
          id: activeBrandID,
        },
      });
      return data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (activeBrandID) {
      console.log("useEffect: removing newStyleElement");
      newStyleElement && newStyleElement.remove();
      setNewStyleElement(null);
    }
  }, [activeBrandID]); // NOTE: Leave out newStyleElement to avoid removing right when set... come back to this

  useEffect(() => {
    if (isSuccess) {
      console.log("useEffect: adding newStyleElement on success");
      const styleElement = document.createElement("style");
      styleElement.appendChild(document.createTextNode(data.fonts.join("")));
      document.head.appendChild(styleElement);
      setNewStyleElement(styleElement);
    }
  }, [isSuccess, data?.fonts]);

  const mutation = useMutation({
    mutationKey: ["update-stylebook", activeBrandID],
    mutationFn: async () => {
      const response = await axios.post(
        "/api/v1/brand/update-stylebook",
        {
          id: activeBrandID,
          fonts: data?.fonts,
          logos: data?.logos,
          styles: data?.styles,
          assets: data?.assets,
          header_palette: data?.header_color_palette,
          footer_palette: data?.footer_color_palette,
          color_palettes: data?.color_palettes,
          overwrite: shouldOverwriteStyle,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    onSuccess: (data) => {
      console.log("Style updated successfully:", data);
      toast.success("Style updated successfully");
    },
    onError: (error) => {
      console.error("Error updating style:", error);
      toast.error("Error updating style");
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (data == null) return <div>No styling returned</div>;

  const { fonts, logos, styles, color_palettes } = data;

  const stylingComponent = (
    <>
      <Dialog.Root
        open={isUpdateDialogOpen}
        onOpenChange={setIsUpdateDialogOpen}
      >
        <Dialog.Content>
          <Dialog.Title>Update Stylebook</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Update the currently selected brand's stylebook. The overwrite
            option will replace the entire stylebook.
          </Dialog.Description>
          <CheckboxGroup.Root
            onValueChange={(value) => {
              setShouldOverwriteStyle(value.includes("overwrite"));
            }}
          >
            <CheckboxGroup.Item key="overwrite" value="overwrite">
              Overwrite
            </CheckboxGroup.Item>
          </CheckboxGroup.Root>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button
              onClick={() => {
                setIsUpdateDialogOpen(false);
                mutation.mutate();
              }}
              loading={isLoading}
            >
              Update
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Box>
        <Text size={"6"} weight={"bold"}>
          Fonts
        </Text>
        <Tooltip content="Click here to update brand stylebook" style={{}}>
          <Button
            style={{ float: "right" }}
            onClick={() => {
              setIsUpdateDialogOpen(true);
            }}
            disabled={mutation.isPending || !isSuccess || !data}
          >
            {mutation.isSuccess ? "Updated" : "Update Stylebook"}
          </Button>
        </Tooltip>
        <Box
          m={"2"}
          p="4"
          style={{
            borderRadius: "var(--radius-3)",
            display: "flex",
            gap: "2rem",
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
          }}
        >
          {getUniqueFontFamilies(fonts).map((f) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text size={"5"}>{f}</Text>
                  <h1 style={{ fontFamily: f }}>Heading 1</h1>
                  <h2 style={{ fontFamily: f }}>Heading 2</h2>
                  <h3 style={{ fontFamily: f }}>Heading 3</h3>
                  <h4 style={{ fontFamily: f }}>Heading 4</h4>
                  <h5 style={{ fontFamily: f }}>Heading 5</h5>
                  <h6 style={{ fontFamily: f }}>Heading 6</h6>
                  <p style={{ fontFamily: f }}>Paragraph</p>
                </div>
              </div>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Text size={"6"} weight={"bold"}>
          Logos
        </Text>
        <Box
          m={"2"}
          p="4"
          style={{
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
            borderRadius: "var(--radius-3)",
          }}
        >
          {logos?.map((l) => {
            return (
              <div
                style={{
                  width: "200px",
                }}
                dangerouslySetInnerHTML={{
                  __html: l.html,
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box>
        <Text size={"6"} weight={"bold"}>
          Color Palettes
        </Text>
        <Flex
          p="4"
          direction="column"
          gap="16px"
          style={{
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
            borderRadius: "var(--radius-3)",
          }}
        >
          <Flex gap="8px">
            {color_palettes.map((palette) => (
              <PalettePicker palette={palette} />
            ))}
          </Flex>
          <Flex direction="column" gap="8px">
            <Heading size="2">Ad Priority Order</Heading>
            <Flex gap="8px">
              {color_palettes
                .slice()
                .sort((a, b) => a.ad_priority - b.ad_priority)
                .map((palette) => (
                  <PalettePicker palette={palette} />
                ))}
            </Flex>
          </Flex>
          <Flex direction="column" gap="8px">
            <Heading size="2">Email Priority Order</Heading>
            <Flex gap="8px">
              {color_palettes
                .slice()
                .sort((a, b) => a.email_priority - b.email_priority)
                .map((palette) => (
                  <PalettePicker palette={palette} />
                ))}
            </Flex>
          </Flex>
          <Flex direction="column" gap="8px">
            <Heading size="2">Header</Heading>
            <PalettePicker palette={data.header_color_palette} />
          </Flex>
          <Flex direction="column" gap="8px">
            <Heading size="2">Footer</Heading>
            <PalettePicker palette={data.footer_color_palette} />
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Text size={"6"} weight={"bold"}>
          Button Styles
        </Text>
        <Box
          m={"2"}
          p="4"
          style={{
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
            borderRadius: "var(--radius-3)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {styles.map((json) => {
            return (
              <div
                style={{
                  color: json["color"],
                  backgroundColor: json["background_color"],
                  borderWidth: json["border_width"],
                  borderStyle: json["border_style"],
                  borderColor: json["border_color"],
                  borderRadius: json["border_radius"],
                  fontFamily: json["font_family"],
                  fontSize: json["font_size"],
                  fontWeight: json["font_weight"],
                  textTransform: json[
                    "text_transform"
                  ] as CSSProperties["textTransform"],
                  textAlign: json["text_align"] as CSSProperties["textAlign"],
                  margin: 4,
                  padding: json["padding"],
                }}
              >
                Shop Now
              </div>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Text size={"6"} weight={"bold"}>
          Assets
        </Text>
        <Box
          m={"2"}
          p="4"
          style={{
            background: "white",
            border: "1px solid rgb(228, 228, 231)",
            borderRadius: "var(--radius-3)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {data.assets?.map((t) => {
            return (
              <div
                style={{
                  width: "200px", // Set to 100% width or any specific width
                  height: "200px", // Set to a specific height
                  backgroundImage: `url(${t.src})`,
                  backgroundSize: "cover", // Make sure the image covers the entire div
                  backgroundPosition: "center", // Center the image
                  backgroundRepeat: "no-repeat", // Prevent image repetition,
                  margin: "8px",
                }}
              ></div>
            );
          })}
        </Box>
      </Box>
    </>
  );
  return <>{stylingComponent}</>;
}
