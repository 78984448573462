import { EmailCreativeStatus, AdCreativeStatus } from "@openapi";
import {
  Text,
  ScrollArea,
  IconButton,
  Popover,
  Flex,
  Button,
  TextField,
} from "@radix-ui/themes";
import _ from "lodash";
import { EllipsisVerticalIcon } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { Calendar1Icon, PencilIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import Modal from "~/components/core/dialog/Modal";

const Root = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  width: 450px;
  border: 1px solid rgba(240, 240, 240, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-white);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #ddd7d7;
  gap: 8px;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const ImageTitle = styled(Text)`
  padding: 0 16px;
`;

const ScrollableContent = styled(ScrollArea)`
  flex: 1;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const Foreground = styled(Link)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 0;
  width: 100%;
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  overflow: visible;
`;

const ForegroundContent = styled.div`
  background: rgba(0, 0, 0, 0.32);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
`;

const EditButton = styled(AppButton)`
  background-color: var(--primary-white);
  color: var(--primary-deep-purple);
`;

interface PreviewImage {
  title?: string;
  url: string;
}

interface CampaignOverviewCardProps {
  title: string;
  previewImages: PreviewImage[];
  status: EmailCreativeStatus | AdCreativeStatus;
  publishedAt: string | null;
  variant: "email" | "ad";
  editPath: string;
  onRename: (newHeadline: string) => void;
  onDuplicate: () => void;
  onDelete: () => void;
}

const CampaignOverviewCard = ({
  title: inputTitle,
  previewImages,
  status,
  publishedAt,
  variant,
  editPath,
  onRename,
  onDuplicate,
  onDelete,
}: CampaignOverviewCardProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [title, setTitle] = useState(inputTitle);
  const navigate = useNavigate();
  const clickCountRef = useRef(0);
  const clickTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setTitle(inputTitle);
  }, [inputTitle]);

  const handleStatusClick = () => {
    clickCountRef.current += 1;

    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
    }

    clickTimerRef.current = setTimeout(() => {
      if (clickCountRef.current >= 10) {
        navigate(editPath);
      }
      clickCountRef.current = 0;
    }, 300);
  };

  let statusIndicator = (
    <Flex align="center" gap="8px" onClick={handleStatusClick}>
      <StatusDot color="#FEB23F" />
      <Text style={{ color: "#FEB23F" }}>Unpublished</Text>
    </Flex>
  );
  if (
    status === EmailCreativeStatus.active ||
    status === AdCreativeStatus.active
  ) {
    statusIndicator = (
      <Flex
        align="center"
        gap="8px"
        onClick={handleStatusClick}
        style={{
          color:
            variant === "email" ? "rgba(76, 71, 71, 0.8)" : "var(--text-link)",
          cursor: "pointer",
        }}
      >
        {variant === "email" ? (
          <>
            <Calendar1Icon />
            <Text>Sent on {publishedAt}</Text>
          </>
        ) : (
          <>
            <Calendar1Icon />
            <Text>Published on {publishedAt}</Text>
          </>
        )}
      </Flex>
    );
  } else if (
    status === EmailCreativeStatus.scheduled ||
    status === AdCreativeStatus.scheduled
  ) {
    statusIndicator = (
      <Flex align="center" gap="8px" onClick={handleStatusClick}>
        <StatusDot color="#3499F6" />
        <Text style={{ color: "#3499F6" }}>Scheduled</Text>
      </Flex>
    );
  } else if (
    status === EmailCreativeStatus.draft ||
    status === AdCreativeStatus.draft
  ) {
    statusIndicator = (
      <Flex align="center" gap="8px" onClick={handleStatusClick}>
        <StatusDot color="#3499F6" />
        <Text style={{ color: "#3499F6" }}>Draft</Text>
      </Flex>
    );
  }
  return (
    <Root>
      <Header>
        <Flex justify="between" align="center" style={{ width: "100%" }}>
          <Text weight="medium">{title}</Text>
          <Popover.Root>
            <Popover.Trigger>
              <IconButton radius="large" color="gray" variant="ghost">
                <EllipsisVerticalIcon />
              </IconButton>
            </Popover.Trigger>
            <Popover.Content sideOffset={5} align="start">
              <Flex direction="column" gap="12px">
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={onDuplicate}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Duplicate
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    onClick={() => setIsDeleteModalOpen(true)}
                    disabled={
                      status === EmailCreativeStatus.active ||
                      status === AdCreativeStatus.active
                    }
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Delete
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={() => setIsRenameModalOpen(true)}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Rename
                    </Text>
                  </Button>
                </Popover.Close>
              </Flex>
            </Popover.Content>
          </Popover.Root>
          <Modal
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={onDelete}
            submitBtnText="Delete"
            maxWidth="460px"
            open={isDeleteModalOpen}
            onOpenChange={(open) => setIsDeleteModalOpen(open)}
            title={`Delete ${_.capitalize(variant)}`}
          >
            <Flex p="24px">
              <Text>
                Are you sure you want to delete {title}? This action can't be
                undone.
              </Text>
            </Flex>
          </Modal>
          <Modal
            onCancel={() => setIsRenameModalOpen(false)}
            onSubmit={() => onRename(title)}
            submitBtnText="Rename"
            maxWidth="460px"
            open={isRenameModalOpen}
            onOpenChange={(open) => {
              setTitle(inputTitle);
              setIsRenameModalOpen(open);
            }}
            title="Rename"
          >
            <TextField.Root
              type="text"
              size="3"
              radius="large"
              autoFocus
              style={{
                margin: "24px",
                height: "48px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Modal>
        </Flex>

        {statusIndicator}
      </Header>
      <ScrollableContent>
        <Flex
          direction="column"
          style={{ position: "relative", height: "100%" }}
        >
          <Foreground to={editPath}>
            <ForegroundContent>
              <EditButton radius="large" variant="outlined" mt="8">
                <PencilIcon size={16} />
                <Text size="3">Edit</Text>
              </EditButton>
            </ForegroundContent>
          </Foreground>
          <Flex
            direction="column"
            style={{ overflowY: "auto", height: "100%" }}
          >
            {previewImages.map((image, index) => (
              <ImageContainer key={index}>
                {image.title && <ImageTitle>{image.title}</ImageTitle>}
                <ImagePreview
                  src={image.url}
                  alt={image.title || `Preview ${index + 1}`}
                />
              </ImageContainer>
            ))}
          </Flex>
        </Flex>
      </ScrollableContent>
    </Root>
  );
};

export default CampaignOverviewCard;
