import { Position } from "../../../types/ads";
import InternAdMediaTemplateSVGCanvas from "./InternAdMediaTemplateSVGCanvas";
import InternAdMediaTemplateVariantInfoTab from "./InternAdMediaTemplateVariantInfoTab";
import InternAdMediaTemplateVariantSVGElementInspectorTab from "./InternAdMediaTemplateVariantSVGElementInspectorTab";
import InternAdMediaTemplateVariantSVGLayersTab from "./InternAdMediaTemplateVariantSVGLayersTab";
import { operations } from "@openapi";
import {
  AlertDialog,
  Box,
  Button,
  Card,
  Container,
  DropdownMenu,
  Flex,
  Heading,
  Spinner,
  Tabs,
  Text,
} from "@radix-ui/themes";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import {
  ArrowBigUp,
  ArrowLeft,
  Ellipsis,
  InfoIcon,
  Layers2,
  SquareArrowDown,
  SquareArrowLeft,
  SquareArrowRight,
  SquareArrowUp,
  SquareDashedMousePointer,
  SquareMousePointer,
} from "lucide-react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

type AdMediaTemplateVariantApiResponse =
  operations["ads_api_get_ad_media_template_variant"]["responses"][200]["content"]["application/json"];

type DeleteAdMediaTemplateVariantApiResponse =
  operations["ads_api_delete_ad_media_template_variant"]["responses"][200]["content"]["application/json"];

type UpdateAdMediaTemplateVariantApiResponse =
  operations["ads_api_update_ad_media_template_variant"]["responses"][200]["content"]["application/json"];

interface UpdateAdMediaTemplateVariantParams {
  svgElement: SVGElement | null;
}

enum InternAdMediaTemplateVariantTab {
  INFO = "info",
  ELEMENT_LAYERS = "element_layers",
  ELEMENT_INSPECTOR = "element_inspector",
}

const InternAdMediaTemplateVariantComponent = () => {
  const { templateId, variantId } = useParams();
  const navigate = useNavigate();
  const [selectedLeftTab, setSelectedLeftTab] =
    useState<InternAdMediaTemplateVariantTab>(
      InternAdMediaTemplateVariantTab.INFO
    );
  const [selectedRightTab, setSelectedRightTab] =
    useState<InternAdMediaTemplateVariantTab>(
      InternAdMediaTemplateVariantTab.ELEMENT_INSPECTOR
    );
  const [selectedElement, setSelectedElement] = useState<SVGElement | null>(
    null
  );
  const svgContainerId = "svg_container";
  const [hasSVGAttributesMutated, setHasSVGAttributesMutated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [svgStateCounter, setSVGStateCounter] = useState(0);
  const queryClient = useQueryClient();
  const { data: variantResponse, isLoading } = useQuery({
    queryKey: ["ad-media-template-variant", templateId, variantId],
    queryFn: async (): Promise<AdMediaTemplateVariantApiResponse> => {
      const { data } = await axios.get(
        `/api/v1/ads/ad-media-template/${templateId}/variant/${variantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const deleteVariantMutation = useMutation<
    DeleteAdMediaTemplateVariantApiResponse,
    Error
  >({
    mutationFn: async (): Promise<DeleteAdMediaTemplateVariantApiResponse> => {
      const { data } = await axios.delete(
        `/api/v1/ads/ad-media-template/${templateId}/variant/${variantId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      navigate(-1);
    },
    onError: (error) => {
      alert(error);
      console.error("Error deleting template variant:", error);
    },
  });

  const updateVariantMutation = useMutation<
    UpdateAdMediaTemplateVariantApiResponse,
    Error,
    UpdateAdMediaTemplateVariantParams
  >({
    mutationFn: async (
      params: UpdateAdMediaTemplateVariantParams
    ): Promise<UpdateAdMediaTemplateVariantApiResponse> => {
      const formData = new FormData();

      if (params.svgElement) {
        const svgString = new XMLSerializer().serializeToString(
          params.svgElement
        );
        const svgBlob = new Blob([svgString], {
          type: "image/svg+xml;charset=utf-8",
        });
        const file = new File([svgBlob], "temp.svg", { type: "image/svg+xml" });
        formData.append("media_template_file", file);
      }

      setIsSaving(true);

      const { data } = await axios.post(
        `/api/v1/ads/ad-media-template/${templateId}/variant/${variantId}/update`,
        formData,
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );

      setIsSaving(false);

      return data;
    },
    onSuccess: (data) => {
      if (data.success) {
        setHasSVGAttributesMutated(false);
        alert("Media template variant saved successfully!");
        console.log("Media template variant saved successfully!");
        queryClient.invalidateQueries({
          queryKey: ["ad-media-template-variant", templateId, variantId],
        });
      } else {
        alert("Error updating template variant");
        console.error("Failed to update template variant");
      }
    },
    onError: (error) => {
      alert(error);
      console.error("Error updating template variant:", error);
    },
  });

  const handleSVGLayersSelectElement = (element: SVGElement) => {
    setSelectedElement(element);
    setSelectedLeftTab(InternAdMediaTemplateVariantTab.ELEMENT_LAYERS);
  };

  const handleSVGCanvasSelectElement = (element: SVGElement) => {
    setSelectedElement(element);
    setSelectedLeftTab(InternAdMediaTemplateVariantTab.ELEMENT_LAYERS);
  };

  const handleSVGCanvasMoveElement = (position: Position) => {
    setSVGStateCounter((prev) => (prev + 1) % 1000000);
  };

  const handleSVGAttributeChange = () => {
    setHasSVGAttributesMutated(true);
  };

  const saveSVG = async () => {
    try {
      const svgContainer = document.getElementById(svgContainerId);

      if (!svgContainer) {
        console.error(`No element found with id ${svgContainerId}`);
        return;
      }

      const svgElement = svgContainer.querySelector("svg");

      if (!svgElement) {
        console.error("No SVG element found within the container");
        return;
      }
      setSelectedElement(null);
      updateVariantMutation.mutate({ svgElement });
    } catch (error) {
      console.error("An error occurred while saving the SVG:", error);
    }
  };

  const downloadSVG = () => {
    try {
      const svgContainer = document.getElementById(svgContainerId);

      if (!svgContainer) {
        console.error(`No element found with id ${svgContainerId}`);
        return;
      }

      const svgElement = svgContainer.querySelector("svg");

      if (!svgElement) {
        console.error("No SVG element found within the container");
        return;
      }

      const svgString = new XMLSerializer().serializeToString(svgElement);
      const svgBlob = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(svgBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${variantId}.svg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("An error occurred while downloading the SVG:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  return (
    <Flex height="100%">
      <Card
        style={{
          maxWidth: "350px",
          minWidth: "350px",
          height: "100%",
          flexShrink: 0,
          padding: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Flex direction="column" gap="0.5rem" height="100%">
          <Flex align="center" gap="0.5rem" justify="between">
            <Flex align="center" gap="0.5rem">
              <Button
                variant="ghost"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowLeft></ArrowLeft>
              </Button>
              <Heading size="4">Template Variant</Heading>
            </Flex>
            <AlertDialog.Root>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Delete Template Variant</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure? This variant and all of its SVG templates will
                  be permanently deleted.
                </AlertDialog.Description>
                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={() => {
                        deleteVariantMutation.mutate();
                      }}
                    >
                      Delete Variant
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button variant="ghost">
                    <Ellipsis></Ellipsis>
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <AlertDialog.Trigger>
                    <DropdownMenu.Item color="red">Delete</DropdownMenu.Item>
                  </AlertDialog.Trigger>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </AlertDialog.Root>
          </Flex>
          <Tabs.Root
            value={selectedLeftTab}
            onValueChange={(value) =>
              setSelectedLeftTab(value as InternAdMediaTemplateVariantTab)
            }
            style={{ flexGrow: "1", overflowY: "auto", overflowX: "hidden" }}
          >
            <Tabs.List style={{ marginBottom: "10px" }}>
              <Tabs.Trigger value={InternAdMediaTemplateVariantTab.INFO}>
                <Box>
                  <InfoIcon size="18" />
                </Box>
              </Tabs.Trigger>
              <Tabs.Trigger
                value={InternAdMediaTemplateVariantTab.ELEMENT_LAYERS}
              >
                <Box>
                  <Layers2 size="18" />
                </Box>
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content
              value="info"
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              <Heading size="3" style={{ marginBottom: "10px" }}>
                Info
              </Heading>
              <InternAdMediaTemplateVariantInfoTab
                templateId={templateId}
                variantId={variantId}
                createdAt={variantResponse?.variant.created_at}
                mediaAspectRatio={variantResponse?.variant.media_aspect_ratio}
              />
            </Tabs.Content>
            <Tabs.Content
              value={InternAdMediaTemplateVariantTab.ELEMENT_LAYERS}
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              <Heading size="3" style={{ marginBottom: "10px" }}>
                Layers
              </Heading>
              <InternAdMediaTemplateVariantSVGLayersTab
                svgContainerId={svgContainerId}
                selectedElement={selectedElement}
                onAttributeChange={handleSVGAttributeChange}
                onElementSelect={handleSVGLayersSelectElement}
              />
            </Tabs.Content>
          </Tabs.Root>
        </Flex>
      </Card>
      <Flex
        flexGrow="1"
        flexShrink="1"
        direction="column"
        height="100%"
        gap="1rem"
        style={{
          padding: "12px",
          margin: "0px 10px 0px 10px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          overflow: "clip",
        }}
      >
        <Flex justify="between">
          <Heading size="5">SVG</Heading>
          <Flex justify="end" gap="1rem">
            <Button
              variant="outline"
              color="amber"
              style={{ bottom: 20, right: 20 }}
              onClick={downloadSVG}
            >
              Download
            </Button>
            <Button
              variant="solid"
              color="blue"
              style={{ bottom: 20, right: 20 }}
              onClick={saveSVG}
              loading={isSaving}
              disabled={!hasSVGAttributesMutated}
            >
              Save
            </Button>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          gap="4px"
          style={{
            padding: "16px",
            backgroundColor: "#f8f9fa",
            borderRadius: "20px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <Heading size="3" style={{ marginBottom: "8px" }}>
            Key Bindings
          </Heading>
          <Flex gap="8px">
            <Flex gap="2px">
              <SquareArrowUp size={16} />
              <SquareArrowDown size={16} />
              <SquareArrowLeft size={16} />
              <SquareArrowRight size={16} />
            </Flex>
            <Text size="2">Move element</Text>
          </Flex>
          <Flex gap="8px" align="center">
            <Flex gap="2px" align="center">
              <ArrowBigUp size={16} />
              +
              <SquareArrowUp size={16} />
              <SquareArrowDown size={16} />
              <SquareArrowLeft size={16} />
              <SquareArrowRight size={16} />
            </Flex>
            <Text size="2">Adjust width/height</Text>
          </Flex>
          <Flex gap="8px" align="center">
            <SquareDashedMousePointer size={16} />
            <Text size="2">Right Click - Contextual menu</Text>
          </Flex>
        </Flex>
        <Container
          id={svgContainerId}
          size="3"
          flexShrink="1"
          style={{ overflow: "auto" }}
        >
          <InternAdMediaTemplateSVGCanvas
            svgUrl={variantResponse?.variant.media_template_file}
            selectedElement={selectedElement}
            onElementSelect={handleSVGCanvasSelectElement}
            onElementMove={handleSVGCanvasMoveElement}
            onAttributeChange={handleSVGAttributeChange}
          />
        </Container>
      </Flex>
      <Card
        style={{
          width: "350px",
          height: "100%",
          flexGrow: 0,
          flexShrink: 0,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Tabs.Root
          value={selectedRightTab}
          onValueChange={(value) =>
            setSelectedRightTab(value as InternAdMediaTemplateVariantTab)
          }
          style={{ height: "100%" }}
        >
          <Tabs.List style={{ marginBottom: "10px" }}>
            <Tabs.Trigger
              value={InternAdMediaTemplateVariantTab.ELEMENT_INSPECTOR}
            >
              <Box>
                <SquareMousePointer size="18" />
              </Box>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content
            value={InternAdMediaTemplateVariantTab.ELEMENT_INSPECTOR}
            style={{ height: "100%", overflowY: "auto" }}
          >
            <Heading size="3" style={{ marginBottom: "10px" }}>
              Element Inspector
            </Heading>
            <InternAdMediaTemplateVariantSVGElementInspectorTab
              element={selectedElement}
              stateCounter={svgStateCounter}
              onAttributeChange={handleSVGAttributeChange}
            />
          </Tabs.Content>
        </Tabs.Root>
      </Card>
    </Flex>
  );
};

export default InternAdMediaTemplateVariantComponent;
