import { Flex } from "@radix-ui/themes";
import Cookies from "js-cookie";
import { LogOutIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCurrentUserDispatch } from "~/contexts/CurrentUserContext";
import { AccountSettingsTabsEnum } from "~/types/account-settings";

const Tab = styled(Flex)<{ isActive: boolean }>`
  padding: 8px 16px;
  border-radius: 120px;
  background-color: ${(props) =>
    props.isActive ? "rgba(47, 4, 138, 0.08)" : "transparent"};
  color: ${(props) =>
    props.isActive ? "var(--primary-deep-purple)" : "var(--text-secondary)"};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;
  &:hover {
    background-color: rgba(47, 4, 138, 0.08);
    color: var(--primary-deep-purple);
  }
`;

const AccountSettingsTabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: AccountSettingsTabsEnum;
  setActiveTab: (tab: AccountSettingsTabsEnum) => void;
}) => {
  const currentUserDispatch = useCurrentUserDispatch();
  let navigate = useNavigate();
  const onLogout = () => {
    fetch("/api/v1/user/auth/logout/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    })
      .then((res) => res.json())
      .then((_) => {
        currentUserDispatch({
          type: "SET_USER",
          payload: {
            status: "LOGGED_OUT",
          },
        });
        navigate("/login");
      });
  };
  return (
    <Flex direction="column" gap="8px" height="100%" py="16px">
      {[
        AccountSettingsTabsEnum.General,
        AccountSettingsTabsEnum.Integrations,
      ].map((tab) => (
        <Tab
          tabIndex={0}
          isActive={activeTab === tab}
          onClick={() => setActiveTab(tab)}
          key={tab}
        >
          {tab}
        </Tab>
      ))}
      <Tab gap="8px" isActive={false} mt="auto" tabIndex={0} onClick={onLogout}>
        <LogOutIcon />
        Sign out
      </Tab>
    </Flex>
  );
};

export default AccountSettingsTabs;
